import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStore, loadingFacturacion, processAction, setdataFacturacion, setError, setFacturacion, setFacturacion_Edit, setID } from "./slice";
import { startloadSolFacturacion } from "../reportes/thunks";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


export const loadDataFactruracion = () => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingFacturacion());
        await URL_API.get(`Facturacion/getListFacturacionRegistrado/`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setdataFacturacion({ dataFacturacion: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
        });
    }
}








export const startloadFacturacion = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingFacturacion());
        await URL_API.get(`Facturacion/getFacturacion/${id_cliente}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setFacturacion({ facturacion: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
        });
    }
}

export const startRegistroFacturacion = (data, filesA, capturante, id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

        let usuarioCap = capturante; let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;
        let idCliente = id_cliente;
        // console.log(filesA);
        let cfs = filesA;

        let formData = new FormData();
        // for (let i = 0; i < cfs.length; i++) {
        formData.append('file[]', cfs);
        // }

        // console.log(formData);
        console.log(id_cliente);

        await URL_API.post(`Facturacion/postFacturacion`, {
            rfc: data.rfcFacturacion,
            r_social: data.rSocialFacturacion,
            so_mercantil: data.rSociedadFacturacion,
            regimen: data.regimenFacturacion,
            telefono: data.telefonoFacturacion,
            email: data.emailFacturacion,
            direccion: data.direccionFacturacion,
            cp: data.cpFacturacion,
            cliente_id: id_cliente,
            usuario: usuarioCap,
            fecha: fechaRegistro,
            hora: horaRegistro,
            estado: 1,
        }).then(function (response) {
            if (response.data.status = 200) {

                let id = response.data.last_id;
                console.log(id);

                // if (cfs.length != 0) {
                // console.log('IN');
                URL_API.post(`Facturacion/postUploadCSF/${id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }).then(function (response) {
                    console.log(response)
                    if (response.data.status = 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Facturacion registrada con exito'
                        })
                        dispatch(startloadFacturacion(id_cliente));
                    }
                }).catch(function (error) {
                    console.log(error)
                    return;
                });
                // }
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Facturacion... Intente de Nuevo'
            })
            dispatch(startloadFacturacion(id_cliente));
            return;
        });
    }
}

export const startEditFacturacion = (data, id, id_cliente) => {

    // console.log(id_cliente);
    
    return async (dispatch, getState) => {
        dispatch(processAction());
        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        await URL_API.put(`Facturacion/putFacturacion/${id}`, {
            rfc: data.rfcFacturacion,
            r_social: data.rSocialFacturacion,
            so_mercantil: data.rSociedadFacturacion,
            regimen: data.regimenFacturacion,
            telefono: data.telefonoFacturacion,
            email: data.emailFacturacion,
            direccion: data.direccionFacturacion,
            cp: data.cpFacturacion,

            estado: 1,

        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Facturacion Actualizada con exito'
                })

                if (id_cliente == 0) {
                    dispatch(loadDataFactruracion());
                } else {
                    dispatch(startloadFacturacion(id_cliente));
                }
                
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro Actualizo la Facturacion... Intente de Nuevo'
            })
            dispatch(startloadFacturacion(id_cliente));
            return;
        });
    }
}


export const startDelFacturacion = (id, cliente_id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Facturacion/putFacturacionDel/${id}`).then(function (response) {
            if (response.data.status == 200) {
                if (cliente_id == 0) {
                    dispatch(loadDataFactruracion());
                } else {
                    dispatch(startloadFacturacion(cliente_id));
                }
                Toast.fire({
                    icon: 'success',
                    title: 'Facturacion Desactivada con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActFacturacion = (id, cliente_id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Facturacion/putFacturacionAct/${id}`).then(function (response) {
            if (response.data.status == 200) {

                if (cliente_id == 0) {
                    dispatch(loadDataFactruracion());
                } else {
                    dispatch(startloadFacturacion(cliente_id));
                }

                Toast.fire({
                    icon: 'success',
                    title: 'Facturacion Activada con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadFacturacion = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.get(`Facturacion/getFacturacionInd/${id}`).then(function (response) {
            // console.log(response.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setFacturacion_Edit({ facturacion_edit: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const searchFacturacion = (data) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingFacturacion());

        await URL_API.get(`Facturacion/getFacturacionSearch/${data.param}/${data.textSearch}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;

            if (response.data.status == 200) {
                dispatch(setFacturacion({ facturacion: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }
        }).catch(function (error) {
            dispatch(setError())
        });
    }
}


export const clearStoreSearch = () => {
    return (dispatch, getState) => {
        dispatch(clearStore());
    }
}

export const asignedFacturacion = ($data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        // dispatch(setCliente_Edit({ cliente_edit: $data }))
        dispatch(setFacturacion_Edit({ facturacion_edit: $data }))
    }

}
export const asignedID = ($data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        // dispatch(setCliente_Edit({ cliente_edit: $data }))
        dispatch(setID({ id: $data }))
    }

}




export const startAsignacionFacturacion = (folio,idFacturacion, opc) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        console.log(folio);
        console.log(idFacturacion);
        console.log(opc);
        
        await URL_API.put(`Facturacion/putAsignarFacturacion/${folio}/${idFacturacion}/${opc}`).then(function (response) {
            if (response.data.status == 200) {

               
                    dispatch(loadDataFactruracion());
                    dispatch(startloadSolFacturacion());
              

                Toast.fire({
                    icon: 'success',
                    title: 'Facturacion Activada con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}