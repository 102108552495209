import React from 'react'
import { P_Empleados } from './P_Empleados'
import { P_Recepcionistas } from './P_Recepcionistas'
import { ArrowLeft } from 'react-feather'
import { Link } from 'react-router-dom'

export const NewComision = () => {
    return (
        <div>
            <div className='row'>
                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <Link to={`/finance`} className='breadcrumb-item btn-link'>
                                <ArrowLeft size='20' /> Atras
                            </Link>
                            <li className="breadcrumb-item active" aria-current="page">Nuevo pago comisiones</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className="row">
                <div className="mb-1">
                    <div className="mb-3">
                        <h4 className=" text-black fw-bold mb-0">Nuevo Pago de Comisiones</h4>
                    </div>
                    <div className="card">

                        <div className="card-body">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true" >Empleados</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false" >Recepcionistas</button>
                                </li>

                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" >
                                    <P_Empleados />
                                </div>
                                <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" >
                                    <P_Recepcionistas />
                                </div>

                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>

    )
}
