import React, { useEffect } from 'react'
import { AlertTriangle, ArrowLeft, Plus, Save, Slash } from 'react-feather'
import { startUpdateProducto, startloadProductos, updExistenciaProducto } from '../../../../app/store/catalagos/thunks'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

export const EditProduct = () => {
    const dispatch = useDispatch();
    const { productosAlmEdit, categoriasAlm, marcasAlm, proveedoresAlm, } = useSelector(state => state.catalogos);

    const goback = () => {
        dispatch(startloadProductos())
    }
    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const { register: registerCat, handleSubmit: handleSubmitCat, resetField: resetFieldCat, formState: { errors: errorsCat } } = useForm({ model: 'all' });

    const { register, handleSubmit, trigger, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            categoriaProducto: "",
            marcaProducto: "",
            proveedorProducto: "",
            nombreProducto: "",
            descripcionProducto: "",
            existenciaProducto: "",
            existenciaProducto: "",
            existenciaMINProducto: "",
            existenciaMAXProducto: "",
            costoProducto: "",
            costoPUBProducto: "",
            codigoBarraProducto: ""
        }
    });

    useEffect(() => {

        setValue('nombreProducto', productosAlmEdit.NOMBRE);
        setValue('categoriaProducto', productosAlmEdit.ALM_CATEGORIA);
        setValue('marcaProducto', productosAlmEdit.ALM_MARCA);
        setValue('proveedorProducto', productosAlmEdit.ALM_PROV);
        setValue('descripcionProducto', productosAlmEdit.DESCRIPCION);
        setValue('existenciaMINProducto', productosAlmEdit.EXISTENCIA_MIN);
        setValue('existenciaMAXProducto', productosAlmEdit.EXISTENCIA_MAX);
        setValue('costoProducto', productosAlmEdit.COSTO);
        setValue('costoPUBProducto', productosAlmEdit.COSTO_PUBLICO);
        setValue('codigoBarraProducto', productosAlmEdit.COD_BARRA);
        setValue('modeloProducto', productosAlmEdit.MODELO);
    }, []);


    const handleSaveProducto = async (e) => {
        const Validation = await trigger(['nombreProducto', 'categoriaProducto', 'proveedorProducto', 'descripcionProducto', 'existenciaMINProducto', 'existenciaMAXProducto', 'costoProducto', 'costoPUBProducto', 'codigoBarraProducto', 'modeloProducto']);
        let id = productosAlmEdit.ID;
        if (Validation) {
            dispatch(startUpdateProducto(e, id))
        }
    }

    const handleSaveCat = (data) => {

        let id = productosAlmEdit.ID;
        // console.log(id);
        // console.log(data.noExistencia);

        Swal.fire({
            title: 'Atención',
            text: "Está por ACTUALIZAR las existencias del Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(updExistenciaProducto(id, data.noExistencia));
            } else {
                resetFieldCat("noExistencia");
            }
        })

    }


    return (

        <div className='row'>
            <div className='col-12'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item btn-link" onClick={goback}><ArrowLeft size='20' /> Atras</li>
                        <li className="breadcrumb-item active" aria-current="page">Detalles del Producto</li>
                    </ol>
                </nav>
            </div>

            <div className='row'>
                <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7'>

                    <div className="card">
                        <div className="card-header">
                            <h5 className="fw-bold mb-0">{productosAlmEdit.NOMBRE} - {productosAlmEdit.MODELO}</h5>
                        </div>

                        <form onSubmit={handleSubmit(handleSaveProducto)}>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="mb-2">
                                            <label className="form-label">Codigo barra:</label>
                                            <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} autoComplete='off' {...register("codigoBarraProducto", { required: 'Cod. Barra Requerido.' })} />
                                            {
                                                errors.codigoBarraProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.codigoBarraProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="mb-2">
                                            <label className="form-label">Categoria:</label>
                                            <select className="form-select" {...register("categoriaProducto", { required: 'Categoria es requerida.' })} >
                                                <option value="">Seleccione un categoria</option>
                                                {
                                                    categoriasAlm.map((item, index) => (
                                                        item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                            : <option key={index} style={{ display: "none" }} ></option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                errors.categoriaProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.categoriaProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="mb-2">
                                            <label className="form-label">Marca:</label>
                                            <select className="form-select" {...register("marcaProducto", { required: 'Marca es requerida.' })}>
                                                <option value="">Seleccione una marca</option>
                                                {
                                                    marcasAlm.map((item, index) => (
                                                        item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                            : <option key={index} style={{ display: "none" }} ></option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                errors.marcaProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.marcaProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="mb-2">
                                            <label className="form-label">Proveedor:</label>
                                            <select className="form-select" {...register("proveedorProducto", { required: 'Proveedor es requerida.' })}>
                                                <option value="">Seleccione un proveedor</option>
                                                {
                                                    proveedoresAlm.map((item, index) => (
                                                        item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                            : <option key={index} style={{ display: "none" }} ></option>
                                                    ))
                                                }
                                            </select>
                                            {
                                                errors.proveedorProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.proveedorProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="mb-2">
                                            <label className="form-label">Nombre:</label>
                                            <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreProducto", { required: 'Nombre del producto es requerido.' })} />
                                            {
                                                errors.nombreProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="mb-2">
                                            <label className="form-label">Modelo:</label>
                                            <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("modeloProducto", { required: 'Modelo del producto es requerido.' })} />
                                            {
                                                errors.modeloProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.modeloProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                        <div className="mb-2">
                                            <label className="form-label">Descripcion:</label>
                                            <textarea className="form-control" rows="3" {...register("descripcionProducto", {
                                                required: 'Descripcion requerida.',
                                                minLength: { value: 5, message: 'La descripcion del producto debe tener 5 caracteres minimo.' },
                                            })} ></textarea>
                                            {
                                                errors.descripcionProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="mb-2">
                                            <label className="form-label"> MIN:</label>
                                            <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("existenciaMINProducto", { required: 'Requerido.' })} />
                                            {
                                                errors.existenciaMINProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.existenciaMINProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="mb-2">
                                            <label className="form-label"> MAX:</label>
                                            <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("existenciaMAXProducto", { required: 'Requerido.' })} />
                                            {
                                                errors.existenciaMAXProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.existenciaMAXProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="mb-2">
                                            <label className="form-label">Costo HDL:</label>
                                            <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("costoProducto", { required: 'Costo Requerido.' })} />
                                            {
                                                errors.costoProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.costoProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                        <div className="mb-2">
                                            <label className="form-label">Costo Publico:</label>
                                            <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("costoPUBProducto", { required: 'Costo Requerido.' })} />
                                            {
                                                errors.costoPUBProducto &&
                                                <div className='text-left-5 mt-2'>
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errors.costoPUBProducto.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                    </div>


                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-end">
                                        <button type="button" className="btn btn-outline-danger me-2" onClick={goback} > <Slash size='20' /> Cancelar</button>
                                        <button type="submit" className="btn btn-outline-primary" > <Save size='20' /> Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
                    <div className="card">

                        <div className="card-body">

                            <form onSubmit={handleSubmitCat(handleSaveCat)}>
                                <div className='row'>
                                    <div className='col-9'>
                                        <h5 className="fw-bold mb-2">Existencia: {productosAlmEdit.STOCK}</h5>

                                        <label className="form-label">Nueva existencia:</label>
                                        <input className="form-control me-2" type="number" autoComplete='off' onKeyDown={solo_num} min={0} {...registerCat('noExistencia', { required: 'Escribe Existencia' })} />
                                        {
                                            errorsCat.noExistencia &&
                                            <div className='text-left-5 mt-1 '>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errorsCat.noExistencia.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                    <div className='col-1 mt-5'>
                                        <button type="submit" className="btn btn-outline-primary mt-1">  <Plus size="22" /> </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>

    )
}
