import React, { useEffect, useRef } from 'react'
import { AlertTriangle, Save, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startRegistroEquipo } from '../../../../app/store/equipos/thunks';

export const AddEquipo = () => {

    const { cliente_edit, isLoading, status } = useSelector(state => state.clientes);
    const { categorias, marcas } = useSelector(state => state.catalogos);
    const { usuario } = useSelector(state => state.usuario);
    const { equipos, status_equipo } = useSelector(state => state.equipos);
    // console.log(categorias, marcas);
    const closeModal = useRef(null);
    const dispatch = useDispatch();
    const { register: registerEquipo, handleSubmit: handleSubmitEquipo, reset: resetEquipo, setValue, formState: { errors: errosEquipo } } = useForm({
        defaultValues: {
            categoriaEquipo: "",
            marcaEquipo: "",
            modeloEquipo: "",
            soEquipo: "",
            noSerie: "",
        }
    });

    const handleSaveEquipo = (data) => {
        let id_cliente = cliente_edit.ID;
        dispatch(startRegistroEquipo(data, usuario, id_cliente))
    }

    const clearForm = () => {
        resetEquipo();
    }

    useEffect(() => {
        if (status_equipo == 'EQUIPOS_CARGADOS') {
            resetEquipo();
            closeModal.current.click();
        }
    }, [status_equipo])

    return (
        <div className="modal fade" id="newEquipCustomer" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Nuevo equipo de {cliente_edit.NOMBRE}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>

                    <form onSubmit={handleSubmitEquipo(handleSaveEquipo)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Categoria:</label>
                                        <select className="form-select" {...registerEquipo("categoriaEquipo", { required: 'Categoria del equipo es requerido' })}>
                                            <option value="">Seleccione una categoria</option>
                                            {
                                                categorias.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errosEquipo.categoriaEquipo &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errosEquipo.categoriaEquipo.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Marca:</label>
                                        <select className="form-select" {...registerEquipo("marcaEquipo", { required: 'Marca del equipo es requerido' })}>
                                            <option value="">Seleccione una marca</option>
                                            {
                                                marcas.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errosEquipo.marcaEquipo &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errosEquipo.marcaEquipo.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Modelo:</label>
                                        <input type="text" className="form-control" aria-label="First name"  {...registerEquipo("modeloEquipo", { required: 'Modelo del equipo es requerido' })} />
                                        {
                                            errosEquipo.modeloEquipo &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errosEquipo.modeloEquipo.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Sistema Operativo:</label>
                                        <input type="text" className="form-control" aria-label="First name" {...registerEquipo("soEquipo", { required: 'S.O. del equipo es requerido' })} />
                                        {
                                            errosEquipo.soEquipo &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errosEquipo.soEquipo.message}</small></span>
                                            </div>
                                        }
                                    </div>

                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">No. de Serie:</label>
                                        <input type="text" className="form-control" aria-label="First name" {...registerEquipo("noSerie", { required: 'No. de Serie del equipo es requerido' })} />
                                        {
                                            errosEquipo.noSerie &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errosEquipo.noSerie.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size='20' /> Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary">
                                <Save size='20' /> Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
