import React, { useEffect, useState } from 'react'
import { ArrowLeft, Plus, Save, Search, Slash, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { startChangeState, startCloseSale, startloadSale, startloadSales } from '../../../../app/store/sales/thunks';
import { NewAnticipo } from '../../orderTracking/modals/NewAnticipo';
import Swal from 'sweetalert2';
import { loadCliente } from '../../../../app/store/clientes/thunks';
import { useForm } from 'react-hook-form';
import { AddFacturacion } from '../../customers/modals/AddFacturacion';
import { SearchFacturacion } from '../../orderClose/modals/SearchFacturacion';


export const EditSale = () => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });


    const { sale_edit, status, isLoading } = useSelector(state => state.ventasPedido);
    const { descuentos, tipos_pagos, servicios, productosAlm, } = useSelector(state => state.catalogos);
    const { cliente_edit } = useSelector(state => state.clientes);
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const [Pagos, setPagos] = useState([]);

    const { register: registerCC, trigger: triggerCC, setFocus: setFocusCC, getValues: getValuesCC, setValue: setValueCC, formState: { errors: errorsCC }, handleSubmit: handleSubmitCC, reset: resetCC } = useForm(
        {
            mode: 'all',
            defaultValues: {
                // enciende: 0, cargador_bat: 0, sonido: 0, red: 0, ensamblado: 0, teclado: 0, limpieza: 0, retorno: 0, checklist: 0, solo_rev: 0
            }
        });



    const goback = () => {
        dispatch(startloadSales());
    }

    const handleChange = (e, folio) => {
        let estado = e.target.value;
        Swal.fire({
            title: 'Atención',
            text: "Está por cambiar de Estado de la Compra por Pedido, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startChangeState(folio, estado));
            } else {
                // dispatch(startloadOrden(folio));
                dispatch(startloadSale(folio));
                dispatch(loadCliente(cliente_edit.cliente_id));
            }
        })
    }

    const solo_num = (e) => {
        const allowedKeys = [
            'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];

        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');

        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
            e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
            return;
        }

        if (
            (e.key >= '0' && e.key <= '9') || // Números
            allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
            return true;
        } else {
            e.preventDefault(); // Evitar cualquier otra tecla
        }
    };

    let sale = sale_edit[0];
    let anticipos = sale["ANTICIPOS"];
    let total_anticipos = 0;

    anticipos.forEach(item => {
        total_anticipos = total_anticipos + Number(item.MONTO);
    });

    let totalC = Number(sale.COSTO) - Number(total_anticipos);


    // Rutina MUltiples pagos
    const changeCambio = (g) => {

        // console.log(g);


        let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
        // let iva = document.getElementById('iva_orden').value.replace(/[$,]/g, '');
        // let subtotal = document.getElementById('subotal_orden').value.replace(/[$,]/g, '');
        // let formaPago = document.getElementById('formaPago_orden').value;
        let pago = g.target.value;

        // console.log(pago);

        // subtotal_x = acumuladoServicios + acumuladoRefacciones + AcumuladocostoAlmacenaje; IVA = subtotal_x * 0.16; total = subtotal_x + IVA;
        if (Pagos.length != 0) {
        } else {
            let cambio = pago - total;
            setValueCC('cambio', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio));
        }

    }

    const addFormaPago = () => {
        let pago = document.getElementById('pago_orden').value;
        let formaPago = document.getElementById('formaPago_orden').value;
        let text = document.getElementById('formaPago_orden');
        let selectedIndex = text.selectedIndex;
        let selectedText = text.options[selectedIndex].text;
        let comentarios = document.getElementById('comentarios_orden').value;
        let item = {
            "forma_pago": formaPago,
            "string": selectedText,
            "comentarios": comentarios,
            "total": pago
        }
        setPagos([...Pagos, item]);

    }

    const handleRemovePago = (e) => {
        let arrayANTERIOR = [...Pagos];
        arrayANTERIOR.splice(e, 1);
        setPagos([...arrayANTERIOR]);
    }

    useEffect(() => {
        let totalpago = 0;
        let total = 0;

        // total = document.getElementById('total_orden').value.replace(/[$,]/g, '');

        let totalOrdenElement = document.getElementById('total_orden');

        if (totalOrdenElement) {
            // Si el elemento existe, eliminamos los símbolos de formato
            total = totalOrdenElement.value.replace(/[$,]/g, '');
        } 




        Pagos.forEach(item => {
            totalpago = totalpago + Number(item.total);
        });

        let cambio = totalpago - total;
        setValueCC('cambio', Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(cambio));
    }, [Pagos]);


    const cancel = () => {
        dispatch(startloadSales());

    }

    const showDivFacturacion = (e) => {
        document.getElementById("facturacion_div").style.display = "block";
    }

    const HideDivFacturacion = (e) => {
        document.getElementById("facturacion_div").style.display = "none";
        setValueCC('id_facturacion', "");
        setValueCC('rfc_factruracion', "");
        setValueCC('razon_soc_facturacion', "");
        setValueCC('regimen_facturacion', "");
        setValueCC('telefono_facturacion', "");
        setValueCC('correo_facturacion', "");
        setValueCC('direccion_facturacion', "");
        setValueCC('cp_facturacion', "");
    }


    const closeOrden = () => {

        console.log('alive');
        let total = document.getElementById('total_orden').value.replace(/[$,]/g, '');
        let pago = document.getElementById('pago_orden').value;

        let formaPago = document.getElementById('formaPago_orden').value;
        let comentarios = document.getElementById('comentarios_orden').value;
        // let pago = document.getElementById('pago_orden').value;

        let idFacturacion = 0;

        if (Pagos.length != 0) {

            let acumulador = 0;

            Pagos.forEach(item => {
                acumulador = acumulador + Number(item.total);
            });

            if (acumulador < total) {
                Toast.fire({
                    icon: 'error',
                    title: 'Debe realizar el cobro correspondiente para la venta por pedido'
                });
                return;
            } else {
                // console.log('DISPATCH');
                dispatch(startCloseSale(sale.FOLIO, usuario, total, formaPago, comentarios, pago, Pagos))
            }
            // 
        } else {

            if (pago < total) {
                Toast.fire({
                    icon: 'error',
                    title: 'Debe realizar el cobro correspondiente para la venta por pedido'
                });
                return;
            } else {
                // console.log('DISPATCH');
                // dispatch(startVentaProductos(usuario, idFacturacion, ProductosCompra, idCliente, total, iva, subtotal, formaPago, comentarios, pago, Pagos));
                dispatch(startCloseSale(sale.FOLIO, usuario, total, formaPago, comentarios, pago, Pagos))
            }
        }
    }





    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <NewAnticipo folio={sale.FOLIO} />
            {/* <AddFacturacion /> <SearchFacturacion /> */}
            <div className='row'>
                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item btn-link" onClick={goback}><ArrowLeft size='20' /> Atras</li>
                            <li className="breadcrumb-item active" aria-current="page">Detalles de Venta por Pedido</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <div className="card ">
                        <div className="card-body">
                            <label className="mb-1 fw-bold text-dark">Venta por pedido:</label>
                            <div className="d-flex">
                                <input className="form-control me-2" type="search" aria-label="Search" disabled value={sale.FOLIO} />

                            </div>

                        </div>
                        <hr className="my-0" />
                        <div className="card-body">

                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                                </div>

                            </div>
                            <hr className="my-0" />

                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div className="mb-1">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" value={cliente_edit.NOMBRE} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Telefono:</label>
                                        <input type="text" className="form-control" value={cliente_edit.TELEFONO} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Celular:</label>
                                        <input type="text" className="form-control" value={cliente_edit.CELULAR} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Correo electronico:</label>
                                        <input type="text" className="form-control" value={cliente_edit.EMAIL} disabled />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-12 mt-2">

                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="requierofacura" id="inlineRadio1" value="0" defaultChecked onClick={e => HideDivFacturacion(e)} />
                                    <label className="form-check-label" >No, requiero factura</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="requierofacura" id="inlineRadio2" value="1" onClick={e => showDivFacturacion(e)} />
                                    <label className="form-check-label" >Si, requiero factura</label>
                                </div>
                            </div> */}

                            {/* <div id="facturacion_div" className="col-12 mt-4" style={{ display: "none" }}>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <h5 className="fw-bold  border-0">Datos de facturación </h5>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 text-start mb-1">
                                    <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#newFacturacion">
                                        <Plus size='20' />
                                    </button>
                                    <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#searchFacturacion">
                                        <Search size='20' />
                                    </button>
                                </div>
                                <hr className="my-0" />


                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl123 col-xxl-12 mt-2">

                                    <input type="hidden" className="form-control"
                                        disabled  {...registerCC("id_facturacion")}
                                    />
                                    <div className="mb-1">
                                        <label className="form-label">RFC:</label>
                                        <input type="text" className="form-control"
                                            disabled {...registerCC("rfc_factruracion")}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">

                                    <div className="mb-1">
                                        <label className="form-label">Razon Social:</label>
                                        <input type="text" className="form-control"
                                            {...registerCC("razon_soc_facturacion")} disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-1">
                                        <label className="form-label">Regimen:</label>
                                        <input type="text" className="form-control"
                                            {...registerCC("regimen_facturacion")} disabled
                                        />
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-1">
                                        <label className="form-label">Telefono:</label>
                                        <input type="text" className="form-control"
                                            {...registerCC("telefono_facturacion")} disabled
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-1">
                                        <label className="form-label">Correo Electronico:</label>
                                        <input type="text" className="form-control"
                                            {...registerCC("correo_facturacion")} disabled
                                        />
                                    </div>
                                </div>


                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-2">
                                        <label className="form-label">Dirección:</label>
                                        <textarea className="form-control" rows="4"
                                            {...registerCC("direccion_facturacion")} disabled
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                                    <div className="mb-1">
                                        <label className="form-label">CP:</label>
                                        <input type="text" className="form-control"
                                            {...registerCC("cp_facturacion")} disabled
                                        />
                                    </div>
                                </div>

                            </div> */}

                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9'>
                    <div className="card">

                        <div className="card-body">

                            {/*  */}

                            <div className="row mb-3">
                                <div className='col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                    <h5 className="card-title fw-bold text-dark mb-2 mt-1">Detalles del Pedido </h5>
                                    <button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#newAnticipo">
                                        Agregar anticipo
                                    </button>
                                </div>
                                <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4  ">
                                    <label className="mb-1">Estado Pedido:</label>
                                    <select className="form-select " defaultValue={sale.ESTADO} onChange={e => handleChange(e, sale.FOLIO)}>
                                        <option value={1}>Espera</option>
                                        <option value={2}>Solicitada</option>
                                        <option value={3}>Llego / Recibida</option>
                                        <option value={4}>Entrega a Cliente</option>
                                    </select>
                                </div>

                                <div className='col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2'>
                                    <h5 className="mt-4"> Total de Anticipos:
                                        <span className="fw-bold text-left-10">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)}</span>
                                    </h5>
                                </div>
                            </div>
                            <hr />

                            <div className="row mb-3">

                                <div className="row">

                                    <div className="col-12">
                                        <div className="mb-2">
                                            <label className="form-label">Producto:</label>
                                            <input type="text" className="form-control" disabled defaultValue={sale.PRODUCTO} />
                                        </div>
                                    </div>


                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                        <div className="mb-2">
                                            <label className="form-label">Fecha Sol:</label>
                                            <input type="text" className="form-control" aria-label="First name" autoComplete='off' disabled defaultValue={sale.FECHA} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                        <div className="mb-2">
                                            <label className="form-label">Hora Sol:</label>
                                            <input type="text" className="form-control" aria-label="First name" autoComplete='off' disabled defaultValue={sale.HORA_SOL} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                        <div className="mb-2">
                                            <label className="form-label">Costo:</label>
                                            <input type="number" className="form-control" aria-label="First name" autoComplete='off' disabled defaultValue={sale.COSTO} />
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="mb-2">
                                            <label className="form-label">Descripcion:</label>
                                            <textarea className="form-control" rows="6" disabled defaultValue={sale.DESCRIPCION}></textarea>

                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <div className="mb-2">
                                            <label className="form-label">Enlace:</label>
                                            <textarea className="form-control" rows="6" disabled defaultValue={sale.ENLACE} ></textarea>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            {
                                sale.ESTADO == 4 ?
                                    <>
                                        <div className='row' >
                                            <div className='col-12'>

                                                <div className="table-responsive">
                                                    <table className="table table-striped table-sm">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">CONCEPTO</th>
                                                                <th scope="col">COSTO</th>
                                                                <th scope="col">ACUMULADO</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{sale.PRODUCTO}</td>
                                                                <td >
                                                                    <input type="text" className="form-control text-end " style={{ width: '120px' }} disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sale.COSTO)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control text-end " style={{ width: '120px' }} disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sale.COSTO)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Anticpos</td>
                                                                <td >
                                                                    <input type="text" className="form-control text-end " style={{ width: '120px' }} disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)} />
                                                                </td>
                                                                <td>
                                                                    <input type="text" className="form-control text-end " style={{ width: '120px' }} disabled defaultValue={Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" className="text-end fw-bold border-0">
                                                                    TOTAL A COBRAR:
                                                                </td>
                                                                <td className="border-0">
                                                                    <input type="text" id='total_orden' className="form-control text-end" disabled style={{ width: '120px' }} defaultValue={totalC} />
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan="2" className="text-end fw-bold border-0">CAMBIO</td>
                                                                <td className="border-0">
                                                                    <input type="text" className="form-control text-end" id='cambio_orden' disabled style={{ width: '120px' }} {...registerCC("cambio")}
                                                                    // {...registerCC("cambio")}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="my-0" />


                                        <div className='card-body'>
                                            <div className='row'>

                                                <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                                                    <label className="mb-1 fw-bold text-dark">Forma de Pago</label>
                                                    <select className="form-select " id='formaPago_orden'  >
                                                        {
                                                            tipos_pagos.map((item, index) => (
                                                                item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                                    : <option key={index} style={{ display: "none" }} ></option>
                                                            ))
                                                        }
                                                    </select>
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                                    <label className="mb-1 fw-bold text-dark">Comentarios</label>
                                                    <input type="text" className="form-control text-start" defaultValue={'NA'} id='comentarios_orden' />
                                                </div>

                                                <div className='col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                                                    <label className="mb-1 fw-bold text-dark">Pago</label>
                                                    <input type="number" className="form-control text-end" id='pago_orden' onKeyDown={solo_num} min={1}
                                                        onChange={g => changeCambio(g)}
                                                    // style={{ width: '120px' }}
                                                    />
                                                </div>
                                                <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
                                                    <button type="button" className="btn btn-outline-primary mt-4" onClick={addFormaPago}><Plus size='20' /></button>
                                                </div>




                                            </div>

                                            <div className='row'>
                                                <div className='table-responsive'>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">ACCIONES</th>
                                                                <th scope="col">FORMA DE PAGO</th>
                                                                <th scope="col">COMENTARIOS</th>
                                                                <th scope="col">PAGO</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {Pagos.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td colSpan={4}>No se han agregado mas de una forma de pago</td>
                                                                    </tr>
                                                                </> :
                                                                Pagos.map((item, index) => (
                                                                    <tr key={index} >
                                                                        <th scope="row">
                                                                            <button type="button" className="btn btn-outline-danger" onClick={() => handleRemovePago(index)}
                                                                            //
                                                                            ><Trash2 size='20' /></button>
                                                                        </th>
                                                                        <td>{item.string}</td>
                                                                        <td>{item.comentarios}</td>
                                                                        <td>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.total)}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="text-end">
                                            <button type="button" className="btn btn-outline-danger mt-2"
                                                onClick={cancel}
                                            >
                                                <Slash size='20' /> Cancelar
                                            </button>
                                            <button type="submit" className="btn btn-outline-primary mt-2 ms-3" onClick={closeOrden}>
                                                <Save size='20' /> Guardar
                                            </button>
                                        </div>



















                                    </> : <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
