import React from 'react';
import { Slash, Save, AlertTriangle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import { startRegistroRol } from '../../../../../app/store/catalagos/thunks';

export const NewRol = () => {

    const { usuario } = useSelector(state => state.usuario);
    const { status, isLoading } = useSelector(state => state.catalogos);

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            nombreRol: "",
            salarioPuesto: "",
            descripcionPuesto: "",
        }
    });


    const dispatch = useDispatch();
    const closeModal = useRef(null);

    const handleSaveRol = (data) => {
        if (data.dash_ALM == false && data.dash_admin == false && data.dash_aux == false && data.dash_caja == false && data.dash_repc == false && data.dash_tecnico == false && data.dash_aux == false) {
            document.getElementById("alert_dashboard").style.display = "block";
        }
        else {
            document.getElementById("alert_dashboard").style.display = "none";
        }

        let usuario_cap = usuario;
        dispatch(startRegistroRol(data, usuario_cap));

    }

    const clearForm = () => {
        reset();
    }

    useEffect(() => {
        if (status == 'Roles Obtenidos') {
            reset();
        }
    }, [status])
    if (status == 'Roles Obtenidos') closeModal.current.click();

    return (
        <div className="modal fade" id="newRol" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Nuevo Rol</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>

                    <form onSubmit={handleSubmit(handleSaveRol)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off'  {...register("nombreRol", { required: 'Nombre de Rol es requerido.', minLength: { value: 5, message: 'El nombre debe contener minimo 5 caracteres' } })} />
                                        {
                                            errors.nombreRol &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreRol.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Descripción:</label>
                                        <textarea className="form-control" rows="6"
                                            {...register('descripcionRol', {
                                                required: 'La descripción del Rol es requerido.',
                                                minLength: { value: 10, message: 'La Descripción debe contener por los menos 10 caracteres' },
                                                maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                            })}></textarea>
                                        {
                                            errors.descripcionRol &&
                                            <div className='text-left-20 mt-2'>
                                                <AlertTriangle size="18" className='text-danger' />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionRol.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <label className="form-label">Opciones de Dashboard a Mostrar</label>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"  {...register("dash_admin")} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Administrador</label>
                                            </div>

                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" {...register("dash_repc")} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked" >Recepcionista</label>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" {...register("dash_caja")} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Caja</label>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" {...register("dash_tecnico")} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Tecnico</label>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" {...register("dash_aux")} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Aux.
                                                    Operativo</label>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch"  {...register("dash_ALM")} />
                                                <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Almacen</label>
                                            </div>
                                        </div>

                                        <div className='text-left-20 mt-2' id="alert_dashboard" style={{ display: "none" }}>
                                            <AlertTriangle size="18" className='text-danger' />
                                            <span className='text-left-10 fw-bold text-danger'><small>Debe indicar por lo menos un Dashboard a mostrar</small></span>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size="20" /> Cancelar
                            </button>
                            {
                                status == "PROCESANDO_PETICION" ?
                                    <>
                                        <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Guardando...
                                        </button></> :
                                    <>
                                        <button type="submit" className="btn btn-outline-primary"  >
                                            <Save size="20" /> Guardar
                                        </button>
                                    </>
                            }
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )
}
