import React, { useEffect, useRef } from 'react'
import { AlertTriangle, Save, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startRegistroProducto } from '../../../../app/store/catalagos/thunks';

export const NewProduct = () => {

    const { categoriasAlm, marcasAlm, proveedoresAlm, status } = useSelector(state => state.catalogos);
    const { usuario } = useSelector(state => state.usuario);
    // console.log('cATEGORIAS', categoriasAlm);
    // console.log('MARCAS ', marcasAlm);
    // console.log('pROVEEDOTRES', proveedoresAlm);
    const dispatch = useDispatch();
    const closeModal = useRef(null);

    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            categoriaProducto: "",
            marcaProducto: "",
            proveedorProducto: "",
            nombreProducto: "",
            descripcionProducto: "",
            existenciaProducto: "",
            existenciaProducto: "",
            existenciaMINProducto: "",
            existenciaMAXProducto: "",
            costoProducto: "",
            costoPUBProducto: "",
            codigoBarraProducto: ""

        }
    });

    const handleSaveProducto = (data) => {

        // console.log(data);
        dispatch(startRegistroProducto(usuario, data));
        clearForm();
        closeModal.current.click();
    }
    const clearForm = () => {
        reset();
    }


    return (
        <div className="modal fade" id="newProduct" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">Nuevo Producto</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>

                    <form onSubmit={handleSubmit(handleSaveProducto)}>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-2">
                                        <label className="form-label">Codigo barra:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} autoComplete='off' {...register("codigoBarraProducto", { required: 'Cod. Barra Requerido.' })} />
                                        {
                                            errors.codigoBarraProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.codigoBarraProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Categoria:</label>
                                        <select className="form-select" {...register("categoriaProducto", { required: 'Categoria es requerida.' })} >
                                            <option value="">Seleccione un categoria</option>
                                            {
                                                categoriasAlm.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.categoriaProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.categoriaProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Marca:</label>
                                        <select className="form-select" {...register("marcaProducto", { required: 'Marca es requerida.' })}>
                                            <option value="">Seleccione una marca</option>
                                            {
                                                marcasAlm.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.marcaProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.marcaProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-2">
                                        <label className="form-label">Proveedor:</label>
                                        <select className="form-select" {...register("proveedorProducto", { required: 'Proveedor es requerida.' })}>
                                            <option value="">Seleccione un proveedor</option>
                                            {
                                                proveedoresAlm.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.proveedorProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.proveedorProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-2">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreProducto", { required: 'Nombre del producto es requerido.' })} />
                                        {
                                            errors.nombreProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-2">
                                        <label className="form-label">Modelo:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("modeloProducto", { required: 'Modelo del producto es requerido.' })} />
                                        {
                                            errors.modeloProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.modeloProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div className="mb-2">
                                        <label className="form-label">Descripcion:</label>
                                        <textarea className="form-control" rows="3" {...register("descripcionProducto", {
                                            required: 'Descripcion requerida.',
                                            minLength: { value: 5, message: 'La descripcion del producto debe tener 5 caracteres minimo.' },
                                        })} ></textarea>
                                        {
                                            errors.descripcionProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Existencia Actual (Stock):</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("existenciaProducto", { required: 'Existencia del prod. es requerido.' })} />
                                        {
                                            errors.existenciaProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.existenciaProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="mb-2">
                                        <label className="form-label"> MIN:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("existenciaMINProducto", { required: 'Requerido.' })} />
                                        {
                                            errors.existenciaMINProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.existenciaMINProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                    <div className="mb-2">
                                        <label className="form-label"> MAX:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("existenciaMAXProducto", { required: 'Requerido.' })} />
                                        {
                                            errors.existenciaMAXProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.existenciaMAXProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo HDL:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("costoProducto", { required: 'Costo Requerido.' })} />
                                        {
                                            errors.costoProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo Publico:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} autoComplete='off' {...register("costoPUBProducto", { required: 'Costo Requerido.' })} />
                                        {
                                            errors.costoPUBProducto &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoPUBProducto.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}> <Slash size='20' /> Cancelar</button>
                            <button type="submit" className="btn btn-outline-primary" > <Save size='20' /> Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
