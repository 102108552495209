import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTriangle, Save, Slash } from 'react-feather'


export const EditEquipo = () => {
  const { equipo_edit, status_equipo } = useSelector(state => state.equipos);
  const { cliente_edit, isLoading, status } = useSelector(state => state.clientes);
  const { categorias, marcas } = useSelector(state => state.catalogos);
  // console.log(equipo_edit);
  const dispatch = useDispatch();
  const { register: registerEquipoEdit, handleSubmit: handleSubmitEquipoEdit, reset: resetEquipoEdit, setValue, formState: { errors: errosEquipoEdit } } = useForm({
    defaultValues: {
      categoriaEquipo: equipo_edit.CATEGORIA_ID,
      marcaEquipo: equipo_edit.MARCA_ID,
      modeloEquipo: equipo_edit.MODELO,
      soEquipo: equipo_edit.SIS_OP,
      noSerie: equipo_edit.NO_SERIE,
    }
  });

  const clearForm = () => {
    resetEquipoEdit();
  }
  const handleSaveEquipo = (data) => {
    let id_cliente = cliente_edit.ID;
    // dispatch(startRegistroEquipo(data, usuario, id_cliente))
  }

  const closeModal = useRef(null);
  useEffect(() => {
    if (status_equipo == 'EQUIPOS_CARGADOS') {
      resetEquipoEdit();
      closeModal.current.click();
    }
  }, [status_equipo])
  return (
    <div className="modal fade" id="editEquipoCustomer" data-bs-backdrop="static" data-bs-keyboard="false"
      aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Editar {equipo_edit.MARCA} {equipo_edit.MODELO} de {cliente_edit.NOMBRE}</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
          </div>

          <form onSubmit={handleSubmitEquipoEdit(handleSaveEquipo)}>
            <div className="modal-body">
              <div className="row">

                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Categoria:</label>
                    <select className="form-select" {...registerEquipoEdit("categoriaEquipo", { required: 'Categoria del equipo es requerido' })}>
                      <option value="">Seleccione una categoria</option>
                      {
                        categorias.map((item, index) => (
                          item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                            : <option key={index} style={{ display: "none" }} ></option>
                        ))
                      }
                    </select>
                    {
                      errosEquipoEdit.categoriaEquipo &&
                      <div className='text-left-5 mt-2'>
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errosEquipoEdit.categoriaEquipo.message}</small></span>
                      </div>
                    }
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Marca:</label>
                    <select className="form-select" {...registerEquipoEdit("marcaEquipo", { required: 'Marca del equipo es requerido' })}>
                      <option value="">Seleccione una marca</option>
                      {
                        marcas.map((item, index) => (
                          item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                            : <option key={index} style={{ display: "none" }} ></option>
                        ))
                      }
                    </select>
                    {
                      errosEquipoEdit.marcaEquipo &&
                      <div className='text-left-5 mt-2'>
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errosEquipoEdit.marcaEquipo.message}</small></span>
                      </div>
                    }
                  </div>
                </div>

                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Modelo:</label>
                    <input type="text" className="form-control" aria-label="First name"  {...registerEquipoEdit("modeloEquipo", { required: 'Modelo del equipo es requerido' })} />
                    {
                      errosEquipoEdit.modeloEquipo &&
                      <div className='text-left-5 mt-2'>
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errosEquipoEdit.modeloEquipo.message}</small></span>
                      </div>
                    }
                  </div>
                </div>
                <div className="col-6">
                  <div className="mb-3">
                    <label className="form-label">Sistema Operativo:</label>
                    <input type="text" className="form-control" aria-label="First name" {...registerEquipoEdit("soEquipo", { required: 'S.O. del equipo es requerido' })} />
                    {
                      errosEquipoEdit.soEquipo &&
                      <div className='text-left-5 mt-2'>
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errosEquipoEdit.soEquipo.message}</small></span>
                      </div>
                    }
                  </div>

                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label className="form-label">No. de Serie:</label>
                    <input type="text" className="form-control" aria-label="First name" {...registerEquipoEdit("noSerie", { required: 'No. de Serie del equipo es requerido' })} />
                    {
                      errosEquipoEdit.noSerie &&
                      <div className='text-left-5 mt-2'>
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'><small>{errosEquipoEdit.noSerie.message}</small></span>
                      </div>
                    }
                  </div>
                </div>
              </div>

            </div>
            <div className="modal-footer">
              <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
              <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                <Slash size='20' /> Cancelar
              </button>
              <button type="submit" className="btn btn-outline-primary">
                <Save size='20' /> Guardar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
