
import React, { useEffect } from 'react'
import { Activity, Box, DollarSign, FilePlus, MinusCircle, Package, Percent, PlusCircle, Sliders, Users } from 'react-feather'
import { Link, useParams } from 'react-router-dom'
import { Settings } from './panels/Settings';
import { useDispatch, useSelector } from 'react-redux';
import { loadCat_egresos, loadCat_ingresos } from '../../../app/store/catalagos/thunks';
import { NewIngreso } from './modals/NewIngreso';
import { NewEgreso } from './modals/NewEgreso';
import { Tab_Ingresos } from './panels/Tab_Ingresos';
import { Tab_Egresos } from './panels/Tab_Egresos';
import { Tab_Reporte } from './panels/Tab_Reporte';
import { startloadDescuentos, startloadEgresos, startloadIndicadores, startloadIngresos } from '../../../app/store/finanzas/thunk';
import { Tab_Descuentos } from './panels/Tab_Descuentos';
import { Corte } from './panels/Corte';
import { NewCorte } from './panels/NewCorte';
import { Commisions } from './panels/Commisions';
import { NewComision } from './panels/NewComision';
import { NewCorteALT } from './panels/NewCorteALT';

export const Finance = () => {
  const { indicadores, ingresos, egresos, descuentos, status, isLoading } = useSelector(state => state.finanzas);

  let paramsURL = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(loadCat_ingresos());
    dispatch(loadCat_egresos());
    dispatch(startloadIndicadores())
    dispatch(startloadIngresos())
    dispatch(startloadEgresos())
    dispatch(startloadDescuentos())
  }, []);

  // console.log(paramsURL.settings);


  // console.log(ingresos);
  let dataIngresos = ingresos; let dataEgresos = egresos; let dataDescuentos = descuentos;



  if (!paramsURL.settings) {
    let egresos = 0; let ingresos = 0; let comision = 0;

    if (indicadores.EGRESOS != null) egresos = indicadores.EGRESOS;
    if (indicadores.INGRESOS != null) ingresos = indicadores.INGRESOS;
    if (indicadores.COMISIONES != null) comision = indicadores.COMISIONES;
    return (
      <>
        <NewIngreso /> <NewEgreso />
        <h1 className="h4 mb-3 fw-bold">Dashboard: Finanzas</h1>
        {/* <div className='card card-body d-flex'>
          <Link to={`/finance/cort`} className="btn btn-outline-primary fw-bold ">
            Cortes de caja. Vamos alla...
          </Link>
          <Link to={`/finance/commissions`} className="btn btn-outline-primary fw-bold w-100 mt-2">
            Pago de Comisiones. Vamos alla...
          </Link>


        </div> */}

        {/* Indicadores */}
        <div className="row">

          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title text-dark">Balance General <br /><span
                      className="small text-muted">Ultimos 30 dias</span></h5>
                  </div>
                  <div className="col-auto">
                    <div className="stat text-primary">
                      <DollarSign size="20" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <span className="h1 mt-3 mb-1">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(ingresos - (Number(egresos) + Number(comision)))}</span>
                </div>

              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title text-dark">Ingresos <br /><span
                      className="small text-muted">Ultimos 30 dias</span></h5>
                  </div>
                  <div className="col-auto">
                    <div className="stat text-primary">
                      <PlusCircle size="20" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <span className="h1 mt-3 mb-1">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(Number(ingresos))}</span>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title text-dark">Egresos <br /><span
                      className="small text-muted">Ultimos 30 dias</span></h5>
                  </div>
                  <div className="col-auto">
                    <div className="stat text-primary">
                      <MinusCircle size="20" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <span className="h1 mt-3 mb-1">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(Number(egresos))}</span>
                </div>

              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title text-dark">Descuentos <br /><span
                      className="small text-muted">Ultimos 30 dias</span></h5>
                  </div>
                  <div className="col-auto">
                    <div className="stat text-primary">
                      <Percent size="20" />
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <span className="h1 mt-3 mb-1">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(indicadores.DESCUENTOS)}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title text-dark">Comisiones pagadas <br /><span
                      className="small text-muted">Ultimos 30 dias</span></h5>
                  </div>
                  <div className="col-auto">
                    <div className="stat text-primary">
                      <DollarSign size="20" />
                    </div>
                  </div>
                </div>
                <div className="text-center">

                  <span className="h1 mt-3 mb-1">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(indicadores.COMISIONES)}</span>
                </div>

              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-12">
                    <h5 className="card-title text-dark">Acceso Rapido </h5>
                  </div>
                  <div className='col-6 col-sm-6 col-md-12 col-xl-12 col-xxl-12 mb-2'>
                    <Link to={`/finance/cort`} className="btn btn-outline-primary fw-bold w-100 text-start">
                      <Activity size={20} /> Cortes de caja.
                    </Link>

                  </div>

                  <div className='col-6 col-sm-6 col-md-12 col-xl-12 col-xxl-12 '>
                    <Link to={`/finance/commissions`} className="btn btn-outline-dark fw-bold w-100 text-start">
                      <DollarSign size={20} />Pago de Comisiones.
                    </Link>

                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center">

                  <div className="btn-group me-2 mb-2 ">

                    <button type="button" className="btn btn-outline-primary me-3" data-bs-toggle="modal" data-bs-target="#newIngreso">
                      <PlusCircle size='20' />  Nuevo Ingreso
                    </button>
                    <button type="button" className="btn btn-outline-danger me-3" data-bs-toggle="modal" data-bs-target="#newEgreso">
                      <MinusCircle size='20' /> Nuevo Egreso
                    </button>



                    <Link to={`/finance/settings`} className='btn btn-outline-dark'>
                      <Sliders size='20' /> Catalogos
                    </Link>
                  </div>
                </div>

                <ul className="nav nav-tabs " id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Ingresos</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Egresos</button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Descuentos</button>
                  </li>

                </ul>
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <Tab_Ingresos indicadores={indicadores} ingresos={dataIngresos} isLoading={isLoading} />

                  </div>
                  <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <Tab_Egresos indicadores={indicadores} egresos={dataEgresos} isLoading={isLoading} />
                  </div>
                  <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <Tab_Descuentos indicadores={indicadores} egresos={dataDescuentos} isLoading={isLoading} />
                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>

      </>
    )
  }

  if (paramsURL.settings == 'settings') {
    return (
      <>
        <Settings />
      </>
    )
  }

  if (paramsURL.settings == 'cort') {
    return (
      <>
        <Corte />
      </>
    )
  }

  if (paramsURL.settings == 'newCort') {
    return (
      <>
        <NewCorte />
      </>
    )
  }

  if (paramsURL.settings == 'newCortAlt') {
    return (
      <>
        <NewCorteALT />
      </>
    )
  }

  if (paramsURL.settings == 'commissions') {
    return (
      <>
        <Commisions />
      </>
    )
  }
  if (paramsURL.settings == 'newComission') {
    return (
      <>
        <NewComision />
      </>
    )
  }

}
