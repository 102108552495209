import React, { useEffect } from 'react'
import { NewEmployee } from './modals/NewEmployee'
import { Trash2, Eye, Edit2, RefreshCcw, Edit, Search } from 'react-feather';
// import { ViewEmployee } from './modals/ViewEmployee';
// import { EditEmployee } from './modals/EditEmployee';
import { useDispatch, useSelector } from 'react-redux';
import { loadEmpleado, searchEmpleados, startActEmp, startDelEmp, startloadEmpleados } from '../../../app/store/empleados/thunks';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { EditEmployee } from './panels/EditEmployee';
// import { ModEmployee } from './modals/ModEmployee';
export const Employees = () => {


  // Cargamos empleados cada vez que se renderiza la vista
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startloadEmpleados())
  }, [])

  const { empleados, isLoading, status } = useSelector(state => state.empleados);

  // console.log(empleados);

  const handledDelEmp = (id) => {

    let empleado = empleados.filter(item => item.ID == id);
    let id_acceso = empleado[0].ACCESO_ID;
    let id_emp = empleado[0].ID;

    Swal.fire({
      title: 'Atención',
      text: "Está por DESACTIVAR a un Empleado, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startDelEmp(id_emp, id_acceso));
      }
    })
  }

  const handledActEmp = (id) => {

    let empleado = empleados.filter(item => item.ID == id);
    let id_acceso = empleado[0].ACCESO_ID;
    let id_emp = empleado[0].ID;
    Swal.fire({
      title: 'Atención',
      text: "Está por REACTIVAR a un Empleado, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startActEmp(id_emp, id_acceso));
      }
    })
  }

  const handledEditEmpleado = (e) => {
    dispatch(loadEmpleado(e));
  }
  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
      param: "1"
    }
  });

  const handledSearch = (data) => {
    // console.log(data);
    dispatch(searchEmpleados(data))
  }

  const handledsearchGeneral = () => {
    // reset();
    dispatch(startloadEmpleados());
  }



  if (isLoading) {
    return (
      <>
        <div className='div_center'>
          <div className='text-center'>
            <div className="spinner"></div>
          </div>
          <div className='text-center text-left-15'>
            <span className='fw-bold'>Cargando</span>
          </div>
          <div className='text-center text-left-15'>
            <div className="spinner"></div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <NewEmployee />
      {
        (status == "EMPLEADOS_CARGADOS") ?
          <>
            <h1 className="h4 mb-3 fw-bold">Empleados</h1>

            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                      <form onSubmit={handleSubmit(handledSearch)}>

                        <div className='mb-2'>
                          <label className="mb-1 text-dark">Buscar por:</label>
                          <div className="d-flex">
                            <select className="form-select w-75 me-1" {...register("param")}>
                              <option value='0'>CLAVE</option>
                              <option value='1'>NOMBRE</option>
                              <option value='2'>TELEFONO</option>
                              <option value='3'>CELULAR</option>
                              <option value='4'>RFC</option>
                            </select>

                            <input className="form-control me-2" type="search" aria-label="Search" {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                            <button className="btn btn-outline-secondary" type="submit">
                              <Search size="20" />
                            </button>
                            <button className="btn btn-outline-success ms-2" type="button" onClick={handledsearchGeneral}>
                              <RefreshCcw size="20" />
                            </button>
                          </div>
                        </div>
                      </form>
                      <button type="button" className="btn btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#newEmployee">Agregar Nuevo</button>
                    </div>

                    <div className="table-responsive p-2 mt-2 table-scrollbar-3 mb-4">
                      <table className="table table-striped table-hover table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="th-sticky">ID</th>
                            <th scope="col" className="th-sticky text-start">NOMBRE</th>
                            <th scope="col" className="th-sticky text-start">USUARIO</th>
                            <th scope="col" className="th-sticky text-center">PUESTO</th>
                            <th scope="col" className="th-sticky text-center">ROL</th>
                            <th scope="col" className="th-sticky text-center">ESTADO</th>
                            <th scope="col" className="th-sticky text-center">ACCIONES</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            empleados.length === 0 ?
                              <>
                                <tr>
                                  <td scope="row" colSpan={7}> No se han encontrado datos.</td>

                                </tr>
                              </> :
                              empleados.map((item, index) => (
                                <tr key={index}>
                                  <td scope="row">{item.ID}</td>
                                  <td className="text-start">{item.NOMBRE}</td>
                                  <td className="text-start">{item.USER}</td>
                                  <td className="text-center">{item.PUESTO}</td>
                                  <td className="text-center text-uppercase">{item.ROL}</td>
                                  <td className="text-center">
                                    {
                                      item.ESTADO == 1 ?
                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> </>
                                    }
                                    {
                                      item.ESTADO == 2 ? <><span className="status-btn pending-btn"> ELIMINADO </span> </> : <></>
                                    }
                                    {
                                      item.ESTADO == 3 ? <><span className="status-btn vacaciones-btn"> VACACIONES </span> </> : <></>
                                    }
                                  </td>
                                  <td className="text-center">
                                    <button type="button" className="btn btn-outline-primary border-0" onClick={() => handledEditEmpleado(item.ID)}>
                                      <Edit size="20" /> / <Eye size="20" />
                                    </button>

                                    {
                                      item.ESTADO == 1 ? <>
                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelEmp(item.ID)}>
                                          <Trash2 size="20" />
                                        </button></> :
                                        <>
                                          <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActEmp(item.ID)}>
                                            <RefreshCcw size="20" />
                                          </button>
                                        </>
                                    }
                                  </td>
                                </tr>
                              ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </> : <></>
      }
      {
        (status == "EMPLEADO_ENCONTRADO") ?

          <>
            <EditEmployee />
          </> : <></>
      }
    </>
  )



}
