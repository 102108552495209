import React, { useRef } from 'react'
import { AlertTriangle, Plus, Save, Slash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { updateRegistroClasficacion } from '../../../../../app/store/catalagos/thunks';

export const ClasificacionClienteEdit = () => {

    const { register: registerCla, handleSubmit: handleSubmitCla, resetField: resetFieldCla,reset: resetCla, formState: { errors: errorsCla } } = useForm({ model: 'all' });
    const { tiposCliente_edit, status, isLoading } = useSelector(state => state.catalogos);
    const closeModal = useRef(null);
    const dispatch = useDispatch();
    const handleSaveClasificacion = (data) => {

        let filesA = document.getElementById("fileUpo").files[0];
        let nombreCla = data.nombreClasificacion;

        if (data.nombreClasificacion == undefined) nombreCla = "";
        if (filesA == undefined) filesA = "";

        dispatch(updateRegistroClasficacion(filesA, tiposCliente_edit.ID, nombreCla));
        resetFieldCla("nombreClasificacion");
        resetFieldCla("imagenClasificacion");
    }


    // useEffect(() => {
    //     if (status == 'Tipos_Clientes_Obtenidos') {
    //         // resetEquipo();
    //         closeModal.current.click();
    //     }
    // }, [status])

    const clearForm = () => {
        resetCla();
    }

    useEffect(() => {
        if (status == 'Tipos Clientes Obtenidos') {
            clearForm();
            closeModal.current.click();
        }
    }, [status])

    // if (status == 'Tipos Clientes Obtenidos')


    if (isLoading) {
        return (
            <>
                <div className="modal fade" id="editClaCliente" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">

                            <div className='text-center'>
                                <div className="spinner"></div>
                            </div>
                            <div className='text-center text-left-15'>
                                <span className='fw-bold'>Cargando</span>
                            </div>
                            <div className='text-center text-left-15'>
                                <div className="spinner"></div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        )
    }

    return (
        <div className="modal fade" id="editClaCliente" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Editar Clasificacion Cliente: {tiposCliente_edit.NOMBRE}</h5>
                        <button type="button" onClick={clearForm} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>

                    <form onSubmit={handleSubmitCla(handleSaveClasificacion)}>
                        <div className="modal-body">
                            <div className='row'>

                                <div className="mb-2">
                                    <label className="form-label">Nombre:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off'  {...registerCla("nombreClasificacion", { required: 'Escribe el Nombre de la Clasificacion', minLength: { value: 3, message: 'El Nombre de la Clasificacion debe tener por lo menos 3 caracters' } })} />
                                    {
                                        errorsCla.nombreClasificacion &&
                                        <div className='text-left-5 mt-1 '>
                                            {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errorsCla.nombreClasificacion.message}</small></span>
                                        </div>
                                    }
                                </div>
                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 '>
                                    <label className="form-label">Imagen:</label>
                                    <input className="form-control" type="file" id='fileUpo' name='fileUpo' {...registerCla('imagenClasificacion', { required: 'La imagen representativa es requerida' })} />
                                    {
                                        errorsCla.imagenClasificacion &&
                                        <div className='text-left-5 mt-1 '>
                                            {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errorsCla.imagenClasificacion.message}</small></span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}> <Slash size='20' />  Cancelar</button>
                            <button type="submit" className="btn btn-outline-primary"> <Save size='20' />  Guardar </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
