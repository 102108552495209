import React from 'react'
import { useEffect } from 'react';
import { ArrowLeft, Plus, Printer, RefreshCcw, Save, Search, Slash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startloadEmpleados } from '../../../../app/store/empleados/thunks';
import { Link } from 'react-router-dom';
import { clearStoreComision, startloadComisiones_f } from '../../../../app/store/finanzas/thunk';
import { P_Empleados } from './P_Empleados';
import { P_Recepcionistas } from './P_Recepcionistas';

export const Commisions = () => {

  const dispatch = useDispatch();
  const { indicadores, comisiones_reg, descuentos, status, isLoading } = useSelector(state => state.finanzas);
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ model: 'all' });
  let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes; let today = f.getFullYear() + "-" + mes + "-" + dia;

  const searchOrdenes = (data) => {
    dispatch(startloadComisiones_f(data.fechaInicial, data.fechaFinal))
  }

  let total = 0;
  comisiones_reg.forEach(item => {
    total = total + Number(item.TOTAL)
  });



  const generateRep = ($id) => {
    // window.open(`./assets/reports/reporteCorte.html?id=${$id}`, '_blank');
    window.open(`./assets/reports/reporteComision.html?id=${$id}`, '_blank');
  }

  const cleanCort = () => {
    // dispatch(clearStoreCorts())
  }


  return (
    <>
      <div className='row'>
        <div className='col-12'>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <Link to={`/finance`} className='breadcrumb-item btn-link' onClick={cleanCort}>
                <ArrowLeft size='20' /> Atras
              </Link>
              <li className="breadcrumb-item active" aria-current="page">Pago de Comisiones</li>
            </ol>
          </nav>
        </div>
      </div>
      <div className='row'>
        <div className="col-12">
          <div className="card">
            <div className="card-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom">
              <h4 className=" text-black fw-bold mb-0">Pago de Comisiones.</h4>
              <div>
                <Link to={`/finance/newComission`} className="btn btn-outline-primary fw-bold ">
                  <Plus size='20' />  Nuevo Pago de Comisiones
                </Link>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(searchOrdenes)} className='border-bottom'>
                <div className="row ">
                  <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <div className="mb-3">
                      <label htmlFor="formGroupExampleInput" className="form-label">Fecha Inicio:</label>
                      <input type='date' className='form-control'  {...register("fechaInicial")} />
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                    <div className="mb-3">
                      <label htmlFor="formGroupExampleInput" className="form-label">Fecha Final:</label>
                      <input type='date' className='form-control' defaultValue={today} {...register("fechaFinal")} />
                    </div>
                  </div>

                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                    <div className="mb-3 mt-4">
                      <button type="submit" className="btn btn-outline-secondary">
                        <Search size="20" /> Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </form>

              {
                (isLoading) ?
                  <>
                    <div className='div_center'>
                      <div className='text-center'>
                        <div className="spinner"></div>
                      </div>
                      <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                      </div>
                      <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                      </div>
                    </div>
                  </> :
                  <>
                    <div className="table-responsive my-0 table-scrollbar-2 mt-3">
                      <table className="table table-striped table-hover table-sm">
                        <thead>
                          <tr>
                            <th scope="col" className="text-center th-sticky">#</th>
                            <th scope="col" className="text-center th-sticky">CAPTURO</th>
                            <th scope="col" className="text-center th-sticky">FECHA REG.</th>
                            <th scope="col" className="text-center th-sticky">HORA</th>
                            <th scope="col" className="text-end th-sticky">MONTO TOTAL</th>
                            <th scope="col" className="text-center th-sticky">ACCIONES</th>
                            {/* <th scope="col" className="text-center th-sticky">DESCRIPCION</th> */}
                            {/* <th scope="col" className="text-center th-sticky">APLICADO</th> */}

                          </tr>
                        </thead>
                        <tbody className="">
                          {
                            comisiones_reg?.length == 0 ?
                              <>
                                <tr>
                                  <td colSpan={7}>No se encontraron descuentos</td>
                                </tr>
                              </> :
                              comisiones_reg?.map((item, index) => (
                                <tr key={index}>
                                  <td className='text-center'>{item.ID}</td>
                                  <td className="text-center">
                                    {item.CAPTURO}
                                  </td>
                                  <td className="text-center">{item.FECHA.split("-").reverse().join("-")}</td>
                                  <td className="text-center">{item.HORA}</td>
                                  
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</td>
                                  <td className="text-center">
                                    <button type="button" className="btn btn-outline-dark" onClick={() => generateRep(item.ID)}>
                                      <Printer size={20} />
                                    </button>
                                  </td>

                                </tr>
                              ))
                          }
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colSpan={4} className='text-end fw-bold'>TOTAL:</td>
                            <td className='text-end fw-bold'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format((total).toFixed(2) )}</td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                  </>
              }
            </div>
          </div>
        </div>


      </div>
    </>
  )
}
