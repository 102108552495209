import React from 'react'
import { useEffect } from 'react';
import { ArrowLeft, RefreshCcw, Save, Search, Slash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startloadEmpleados } from '../../../../app/store/empleados/thunks';
import { Link } from 'react-router-dom';
import { clearStoreComision, startRegistroComision, startloadComisiones } from '../../../../app/store/finanzas/thunk';

export const P_Empleados = () => {

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ defaultValues: {}, model: 'all' });
    const { empleados } = useSelector(state => state.empleados);
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const { status, comisiones } = useSelector(state => state.finanzas);


    const clearStoreComi = () => {
        window.location.reload();
      }

    const searchOrdenes = (data) => {
        console.log(data);
        let recepcionista = 1;
        dispatch(startloadComisiones(data.tecnico, data.fechaInicial, data.fechaFinal,recepcionista))
    }

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(startloadEmpleados());
    }, [])

    let total_comision = 0;
    useEffect(() => {

        if (status == 'COMISIONES_CARGADAS') {

            comisiones.forEach(it => {
                total_comision = total_comision + Number(it.COMISION_TEC)

            });
            document.getElementById("total_comision").value = (total_comision).toFixed(2);
        }

    }, [status])

    const saveComision = () => {

        let dateI = document.getElementById('fechaInicial_id').value;
        let dateF = document.getElementById('fechaFinal_id').value;
        let comision = document.getElementById('total_comision').value;
        let empleado = comisiones[0].TECNICO_ASIGNADO;
        console.log(comision);
        // console.log(comisiones[0].TECNICO_ASIGNADO);
        console.log();
        console.log(dateI);
        console.log(dateF);

        dispatch(startRegistroComision(usuario, dateI, dateF, empleado, 0, comision))

    }

    const cancelComision = () => {
        window.location.reload();
    }

    return (
        <div className="card">
            <div className="card-body ">

                <form onSubmit={handleSubmit(searchOrdenes)}>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                            <div className="mb-3">
                                <label htmlFor="formGroupExampleInput" className="form-label">Técnico:</label>
                                <select className="form-select "  {...register("tecnico")}>
                                    <option value={'TODOS'} >Todos los empleados</option>
                                    {
                                        empleados.map((item, index) => (
                                            item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} - {item.ROL}</option>
                                                : <option key={index} style={{ display: "none" }} ></option>
                                        ))
                                    }

                                </select>
                            </div>
                        </div>

                        {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="mb-3">
                                    <label htmlFor="formGroupExampleInput" className="form-label">Estado:</label>
                                    <select className="form-select " {...register("estadoOrden")}>
                                        <option value='TODOS'>Todos los estados </option>
                                        <option value={0}>Sin asignar</option>
                                        <option value={1}>Asignada</option>
                                        <option value={2}>Sin Diagnostico</option>
                                        <option value={3}>Con Diagnostico</option>
                                        <option value={4}>Autorizada</option>
                                        <option value={5}>Esperando de componente</option>
                                        <option value={6}>Completada/Terminada</option>
                                    </select>
                                </div>
                            </div> */}

                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="mb-3">
                                <label htmlFor="formGroupExampleInput" className="form-label">Fecha inicio:</label>
                                <input type="date" className="form-control" id='fechaInicial_id'{...register("fechaInicial")} />
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="mb-3">
                                <label htmlFor="formGroupExampleInput" className="form-label">Fecha fin:</label>
                                <input type="date" className="form-control" id='fechaFinal_id' {...register("fechaFinal")} />
                            </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-1">
                            <div className="mb-3 mt-4 d-flex">
                                <button type="submit" className="btn btn-outline-secondary me-2">
                                    <Search size="20" /> Buscar
                                </button>
                                <button type="button" className="btn btn-outline-dark" onClick={clearStoreComi}>
                                    <RefreshCcw size="20" />
                                </button>
                            </div>

                        </div>

                    </div>
                </form>

                <hr className='my-0' />
                <div className="table-responsive my-0 table-scrollbar-2 mt-3">

                    <table className="table table-striped table-hover table-sm">
                        <thead>
                            <tr>
                                <th scope="col" className="text-center">#</th>
                                <th scope="col" className="text-center">FOLIO</th>
                                <th scope="col" className="text-center">TÉCNICO</th>
                                <th scope="col" className="text-center">SERVICIO</th>
                                <th scope="col" className="text-start">DESCRIPCION</th>
                                <th scope="col" className="text-center">AUTORIZADO</th>
                                <th scope="col" className="text-center">ESTADO</th>
                                <th scope="col" className="text-center">CONTROL CALIDAD</th>
                                <th scope="col" className="text-end">COMISION</th>

                            </tr>
                        </thead>
                        <tbody className="">
                            {
                                comisiones?.length == 0 ?
                                    <>
                                        <tr>
                                            <td colSpan={8}>No se encontraron comisiones</td>
                                        </tr>
                                    </> :
                                    comisiones?.map((item, index) => (
                                        <tr key={index}>

                                            <td className='text-center'>{index + 1}</td>
                                            <td className='text-center'>{item.FOLIO}</td>
                                            <td className='text-center'>{item.TECNICO}</td>
                                            <td className='text-center'>{item.NOMBRE}</td>
                                            <td className='text-start'>{item.DESCRIPCION}</td>
                                            <td className='text-center'>
                                                {
                                                    item.AUTORIZO == 1 ? <>
                                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                                }
                                            </td>
                                            <td className='text-center'>
                                                {
                                                    item.ESTADO == 1 ? <>
                                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                                }
                                            </td>
                                            <td className='text-center'>
                                                {
                                                    item.CONTROL_CC == 1 ? <>
                                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                                }
                                            </td>
                                            <td className='text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COMISION_TEC)}</td>


                                        </tr>
                                    ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={8} className='text-end fw-bold'>TOTAL:</td>
                                <td className='text-end fw-bold'>
                                    <input type='text' className='form-control text-end' id='total_comision' style={{ width: '120px' }} disabled />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='row mt-3'>
                    <div>
                        <button type="button" className="btn btn-outline-danger me-2" onClick={cancelComision}>
                            <Slash size={20} /> <span className='text-left-5'>Cancelar</span>
                        </button>
                        <button type="button" className="btn btn-outline-primary" onClick={saveComision}>
                            <Save size={20} /> <span className='text-left-5'>Guardar</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
