import React from 'react'
import { Catalogos } from './panels/Catalogos'
import { Principales } from './panels/Principales'

export const Global = () => {
  const solo_num = (e) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
    ];
  
    const inputValue = e.target.value;
    const hasDecimal = inputValue.includes('.');
  
    // Si ya existe un punto decimal, solo permitir dos dígitos después de él
    if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
      e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
      return;
    }
  
    if (
      (e.key >= '0' && e.key <= '9') || // Números
      allowedKeys.includes(e.key) // Otras teclas permitidas
    ) {
      return true;
    } else {
      e.preventDefault(); // Evitar cualquier otra tecla
    }
  };

  return (
    <>
      <Principales solo_num={solo_num} />
      <Catalogos solo_num={solo_num}/>
    </>
  )
}
