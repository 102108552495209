import { createSlice } from '@reduxjs/toolkit';

export const catalogosSlice = createSlice({
    name: 'catalogosSlice',
    initialState: {
        status: 'EN_ESPERA',
        credenciales: [],
        marcas: [],
        marcas_edit:[],
        marcasAlm: [],
        marcasAlm_edit: [],
        categorias: [],
        categorias_edit: [],
        categoriasAlm: [],
        categoriasAlm_edit: [],
        productosAlm: [],
        productosAlmEdit: [],
        puestos: [],
        puesto_edit: [],
        roles: [],
        rol_edit: [],
        tiposClientes: [],
        tiposCliente_edit:[],
        proveedoresAlm: [],
        proveedoresAlm_edit: [],
        servicios: [],
        servicio_edit: [],
        como_conocieron: [],
        como_conocieron_edit: [],
        descuentos: [],
        descuentos_edit: [],
        tarifaAlm: [],
        tipos_pagos: [],
        tipos_pagos_edit:[],

        // Proceso Solicitrudes
        solicitudesProd: [],
        solicitudesProddetail: [],

        // Finanzas
        cat_ingresos: [],
        cat_ingresos_edit: [],
        cat_egresos: [],
        cat_egresos_edit: [],

        isProcessAction: false,
        isLoading: false,
    },
    reducers: {

        // Procesamiento de Peticiones GET/READ y transicion de estados
        loadingGlobal: (state, action) => { //Se ejecuta mientras se realiza la busqueda en backend
            state.isLoading = true;
            state.status = 'BUSCANDO';
        },
        notloadingGlobal: (state, action) => {// Se ejecuta ya que termina la busqueda en backend
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        lodingGlobalComplete: (state, action) => {
            state.isLoading = false;
            state.status = 'CATALOGOS_CARGADOS';
        },
        //Procesamiento de Operaciones CRUD y transicion de estados
        processAction: (state, action) => {//Se ejecuta mientras se realiza la operacion en el backend
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';

        },
        notprocessAction: (state, action) => {//Se ejecuta ya que se realiza la operacion en el backend
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        setMarcas: (state, action) => {
            state.status = "Marcas Obtenidas";
            state.marcas = action.payload.marcas;
            state.isLoading = false;
        },
        setMarcaEdit: (state, action) => {
            state.status = "Marca Obtenida";
            state.marcas = action.payload.marcas;
            state.isLoading = false;
        },
        setMarcas_Edit: (state, action) => {
            state.status = "Marcas Obtenida";
            state.marcas_edit = action.payload.marcas_edit;
            state.isLoading = false;
        },
        setCConocieron: (state, action) => {
            state.status = "Motivos Conocieron";
            state.como_conocieron = action.payload.como_conocieron;
            state.isLoading = false;
        },
        setCConocieron_Edit: (state, action) => {
            state.status = "Motivo Conocieron";
            state.como_conocieron_edit = action.payload.como_conocieron_edit;
            state.isLoading = false;
        },
        setDescuentos: (state, action) => {
            state.status = "Descuentos Cargados";
            state.descuentos = action.payload.descuentos;
            state.isLoading = false;
        },
        setDescuentos_Edit: (state, action) => {
            state.status = "Descuento Cargado";
            state.descuentos_edit = action.payload.descuentos_edit;
            state.isLoading = false;
        },
        setCategorias: (state, action) => {
            state.status = "Categorias Obtenidas";
            state.categorias = action.payload.categorias;
            state.isLoading = false;
        },
        setCategorias_Edit: (state, action) => {
            state.status = "Categoria Obtenida";
            state.categorias_edit = action.payload.categorias_edit;
            state.isLoading = false;
        },
        setPuestos: (state, action) => {
            state.status = "Puestos Obtenidos";
            state.puestos = action.payload.puestos;
            state.puesto_edit = [];
            state.isLoading = false;
        },
        setPuesto_Edit: (state, action) => {
            state.status = "Puesto Obtenido";
            state.puesto_edit = action.payload.puesto;
            state.isLoading = false;
        },
        setRoles: (state, action) => {
            state.status = "Roles Obtenidos";
            state.roles = action.payload.roles;
            state.rol_edit = [];
            state.isLoading = false;
        },
        setRol_Edit: (state, action) => {
            state.status = "Rol Obtenido";
            state.rol_edit = action.payload.rol;
            state.isLoading = false;
        },
        setCredenciales: (state, action) => {
            state.status = "Credenciales OK";
            state.credenciales = action.payload.credenciales;
            state.isLoading = false;
        },
        setTarifaAlm: (state, action) => {
            state.status = "Tarifa OK";
            state.tarifaAlm = action.payload.tarifaAlm;
            state.isLoading = false;
        },
        setCategoriasAlm: (state, action) => {
            state.status = "Categorias Obtenidas (Alm)";
            state.categoriasAlm = action.payload.categoriasAlm;
            state.isLoading = false;
        },
        setCategoriasAlm_edit: (state, action) => {
            state.status = "Categoria Obtenida (Alm)";
            state.categoriasAlm_edit = action.payload.categoriasAlm_edit;
            state.isLoading = false;
        },
        setMarcasAlm: (state, action) => {
            state.status = "Marcas Obtenidas (Alm)";
            state.marcasAlm = action.payload.marcasAlm;
            state.isLoading = false;
        },
        setMarcasAlm_edit: (state, action) => {
            state.status = "Marca Obtenida (Alm)";
            state.marcasAlm_edit = action.payload.marcasAlm_edit;
            state.isLoading = false;
        },
        setProveedoresAlm: (state, action) => {
            state.status = "Proveedores Obtenidos (Alm)";
            state.proveedoresAlm = action.payload.proveedoresAlm;
            state.isLoading = false;
        },
        setProveedoresAlm_edit: (state, action) => {
            state.status = "Proveedor Obtenido (Alm)";
            state.proveedoresAlm_edit = action.payload.proveedoresAlm_edit;
            state.isLoading = false;
        },
        setProductosALM: (state, action) => {
            state.status = "Productos_Obtenidos";
            state.productosAlm = action.payload.productosAlm;
            state.isLoading = false;
        },
        setProductosALMEdit: (state, action) => {
            state.status = "Producto_Obtenido";
            state.productosAlmEdit = action.payload.productosAlmEdit;
            state.isLoading = false;
        },
        setTiposClientes: (state, action) => {
            state.status = "Tipos Clientes Obtenidos";
            state.tiposClientes = action.payload.tclientes;
            state.isLoading = false;
        },
        setTiposCliente_Edit: (state, action) => {
            state.status = "Tipo Cliente Obtenido";
            state.tiposCliente_edit = action.payload.tiposCliente_edit;
            state.isLoading = false;
        },
        setServicios: (state, action) => {
            state.status = "Servicios Obtenidos";
            state.servicio_edit = [];
            state.servicios = action.payload.servicios;
            state.isLoading = false;
        },
        setServicio_Edit: (state, action) => {
            state.status = "Servicios Obtenido";
            state.servicio_edit = action.payload.servicio;
            state.isLoading = false;
        },
        clearStore: (state, action) => {
            state.status = 'EN_ESPERA';
            state.productosAlmEdit = [];
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.status = "Productos_Obtenidos";
            state.productosAlm = [];
            state.productosAlmEdit = [];
        },
        // Solicitudes de Productos
        setSolicitudes: (state, action) => {
            state.isLoading = false;
            state.status = "Solicitudes_Obtenidos";
            state.solicitudesProd = action.payload.solicitudesProd;
        },
        setTipospagos: (state, action) => {
            state.isLoading = false;
            state.status = "Tipos_pagos_obtenidos";
            state.tipos_pagos = action.payload.tipos_pagos;
        },
        setTipospagos_Edit: (state, action) => {
            state.status = "Tipos_pago_obtenido";
            state.tipos_pagos_edit = action.payload.tipos_pagos_edit;
            state.isLoading = false;
        },

        setDetailSolicitud: (state, action) => {
            state.isLoading = false;
            state.status = "Solicitud_encontrada";
            state.solicitudesProddetail = action.payload.solicitudesProddetail;
        },
        clearStoreEditSolicitud: (state, action) => {
            state.status = "CATALOGOS_CARGADOS";
            state.solicitudesProddetail = []
        },
        setCat_ingresos: (state, action) => {
            state.status = "Categorias INGRESOS Obtenidas";
            state.cat_ingresos = action.payload.cat_ingresos;
            state.isLoading = false;
        },
        setCat_ingresos_Edit: (state, action) => {
            state.status = "Categoria INGRESOS Obtenida";
            state.cat_ingresos_edit = action.payload.cat_ingresos_edit;
            state.isLoading = false;
        },
        setCat_egresos: (state, action) => {
            state.status = "Categorias EGRESOS Obtenidas";
            state.cat_egresos = action.payload.cat_egresos;
            state.isLoading = false;
        },
        setCat_egresos_edit: (state, action) => {
            state.status = "Categoria EGRESOS Obtenida";
            state.cat_egresos_edit = action.payload.cat_egresos_edit;
            state.isLoading = false;
        },
    }
});


// Action creators are generated for each case reducer function
export const { setProveedoresAlm,setCat_ingresos,setCat_egresos,setCat_ingresos_Edit,setCat_egresos_edit, setDetailSolicitud,setTipospagos_Edit,setDescuentos_Edit,setProveedoresAlm_edit,setCategoriasAlm_edit,setMarcasAlm_edit,setMarcaEdit, setError,setMarcas_Edit,setCategorias_Edit, clearStoreEditSolicitud, setTiposCliente_Edit,setTipospagos, clearStore, setProductosALMEdit, setSolicitudes, setCategoriasAlm, setMarcasAlm, setProductosALM, processAction, notprocessAction, setMarcas, setCategorias, setPuestos, setPuesto_Edit, setTarifaAlm, setRoles, setRol_Edit, setDescuentos, setTiposClientes, setCConocieron, setServicios, setCredenciales, setServicio_Edit, loadingGlobal, lodingGlobalComplete, notloadingGlobal,setCConocieron_Edit } = catalogosSlice.actions;