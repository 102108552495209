import React from 'react'
import { useRef } from 'react';
import { AlertTriangle, Save, Slash } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { startRegistroAnticipo } from '../../../../app/store/ordenes/thunks';

export const NewAnticipo = ({ folio }) => {
    const dispatch = useDispatch();
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const { tipos_pagos } = useSelector(state => state.catalogos);


    // console.log(tipos_pagos);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const closeModal = useRef(null);


    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            montoAnticipo: "",
            descripcionAnticipo: ""
        }
    });

    const saveAnticipo = (data) => {
        // console.log(data);
        dispatch(startRegistroAnticipo(usuario, folio, data));
        // closeModal.current.clic();
        closeModal.current.click();
    }

    const clearForm = () => {
        reset();
    }



    return (
        <div className="modal fade" id="newAnticipo" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Agregar Anticipo, Folio: {folio}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={clearForm} aria-label="Close"></button>
                    </div>
                    <form onSubmit={handleSubmit(saveAnticipo)}>
                        <div className="modal-body row">

                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4' mb-2">
                                <label className="form-label">Monto ($):</label>
                                <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} min={0}

                                    {...register("montoAnticipo", { required: 'Monto del anticipo' })}
                                />
                                {
                                    errors.montoAnticipo &&
                                    <div className='text-left-5 mt-2'>
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10 fw-bold text-danger'><small>{errors.montoAnticipo.message}</small></span>
                                    </div>
                                }
                            </div>

                            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                                <label className="mb-1 text-dark">Forma de Pago</label>
                                <select className="form-select " {...register("tipo_pago", { required: 'Tipo de Pago' })} >
                                    <option value="" className='text-uppercase' >Seleccione</option>
                                    {
                                        tipos_pagos.map((item, index) => (
                                            item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                : <option key={index} style={{ display: "none" }} ></option>
                                        ))
                                    }
                                </select>
                                {
                                    errors.tipo_pago &&
                                    <div className='text-left-5 mt-2'>
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10 fw-bold text-danger'><small>{errors.tipo_pago.message}</small></span>
                                    </div>
                                }
                            </div>

                            <div className="col-12 mb-2">
                                <label className="form-label">Comentarios / Descripcion:</label>
                                <textarea className="form-control" rows="5" {...register("descripcionAnticipo", { required: 'Descripcion/Comentarios' })}></textarea>
                                {
                                    errors.descripcionAnticipo &&
                                    <div className='text-left-5 mt-2'>
                                        <AlertTriangle size="18" color="#dc3545" />
                                        <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionAnticipo.message}</small></span>
                                    </div>
                                }
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" onClick={clearForm} className="btn btn-outline-danger" data-bs-dismiss="modal"><Slash size='20' /> Cancelar</button>
                            <button type="submit" className="btn btn-outline-primary"> <Save size='20' /> Guardar</button>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    )
}
