import { createSlice } from '@reduxjs/toolkit';

export const usuarioSlice = createSlice({
    name: 'usuario',
    initialState: {

        status: 'NO_AUTENTICADO',
        isProcess: false,
        isProcessAction: false,
        id_emp: '', id_acceso: '', puesto_id: '',
        nombre: '',
        email: '',
        celular: '',
        telefono: '',
        usuario: '',
        rol: '',
        puesto: '',
        avatar: '',
        msg: ''


        // status: 'AUTENTICADO',
        // isProcess: false,
        // isProcessAction: false,
        // id_emp: '2', id_acceso: '1',   puesto_id: 2,
        // nombre: 'Noe Misael Montoya Ibarra',
        // email: 'missael.montoya.ibarra.dev@gmail.com',
        // celular: '6621074722',
        // telefono: '6621098922',
        // usuario: 'nibarra',
        // rol: 'Administrador',
        // puesto: 'Desarrollador',
        // avatar: 'N.png'
        // status: 'NO_AUTENTICADO',
        // isProcess: false,
        // isProcessAction: false,
        // id_emp: '1', id_acceso: '1',
        // nombre: '',
        // email: '',
        // celular: '',
        // telefono: '',
        // usuario: '',
        // rol: '',
        // puesto: '',
        // avatar: ''
    },
    reducers: {
        start_login: (state, action) => {
            state.isProcess = false;
            state.usuario = action.payload.usuario;
            state.nombre = action.payload.nombre;
            state.email = action.payload.email;
            state.rol = action.payload.rol;
            state.puesto = action.payload.puesto;
            state.avatar = action.payload.avatar;
            state.celular = action.payload.celular;
            state.telefono = action.payload.telefono;
            state.status = 'PROCESANDO';
        },
        close_login: (state, action) => {
            state.isProcess = false;
            state.id_emp = '';
            state.id_acceso = '';
            state.puesto_id = '';
            state.nombre = '';
            state.email = '';
            state.celular = '';
            state.telefono = '';
            state.usuario = '';
            state.rol = '';
            state.puesto = '';
            state.avatar = '';
            state.msg = '';
            state.status = 'NO_AUTENTICADO';
        },

        processAction: (state, action) => {//Se ejecuta mientras se realiza la operacion en el backend
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {//Se ejecuta ya que se realiza la operacion en el backend
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },

        setDataUsuario: (state, action) => {
            state.isProcess = false;
            state.usuario = action.payload.usuario;
            state.nombre = action.payload.nombre;
            state.id_emp = action.payload.id_emp;
            state.id_acceso = action.payload.id_acceso;
            state.puesto_id = action.payload.puesto_id;
            state.email = action.payload.email;
            state.rol = action.payload.rol;
            state.puesto = action.payload.puesto;
            state.avatar = action.payload.avatar;
            state.celular = action.payload.celular;
            state.telefono = action.payload.telefono;
            state.status = 'AUTENTICADO';
        },
        processUsuario: (state, action) => {
            state.isProcess = true;
            state.status = 'PROCESANDO_PETICION';
        },

        not_processUsuario: (state, action) => {
            state.isProcess = false;
            state.status = 'NO_AUTENTICADO';
        },
        set_error: (state, action) => {
            state.status = 'NO_AUTENTICADO';
            state.msg = action.payload.msg;
        }

    }
});


// Action creators are generated for each case reducer function
export const { processUsuario, not_processUsuario, start_login,close_login, set_error, processAction, setDataUsuario } = usuarioSlice.actions;