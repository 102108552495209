import React, { useState } from 'react'
import { AppRoutes } from './components/AppRoutes'
import { Footer } from './components/frame/Footer'
import { Navbar } from './components/frame/Navbar'
import { Sidebar } from './components/frame/Sidebar'

export const Layout = () => {
  const [showSidebar, setshowSidebar] = useState(true);
  return (
    <div className='wrapper'>
      <Sidebar showSidebar={showSidebar} setshowSidebar={setshowSidebar} />
      <div className='main'>
        <Navbar handleClick={() => setshowSidebar(!showSidebar)} />
        <main className='content'>
          <div className='container-fluid p-0'>
            <AppRoutes />
          </div>
        </main>
        <Footer />
      </div>
    </div>
  )
}
