import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startChangeStateFacturacion, startloadSolFacturacion } from '../../../../app/store/reportes/thunks';
import Swal from 'sweetalert2';
import { Eye, Repeat } from 'react-feather';
import { connectionFiles } from '../../../../app/filesConnection';
import { SearchFacturacion } from '../../orderClose/modals/SearchFacturacion';
import { asignedID } from '../../../../app/store/facturacion/thunks';


export const ListPending = () => {

    const { isLoading, status_report, facturacion } = useSelector(state => state.reportes);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(startloadSolFacturacion())
    }, []);

    // console.log(facturacion);


    const refreshData = () => {
        dispatch(startloadSolFacturacion())
    }

    const handleChangeState = (e, row) => {

        let tipo = row.FOLIO.includes('HDLV') == true ? 2 : 1;
        let folio = row.FOLIO;
        let estado = e.target.value;

        Swal.fire({
            title: 'Atención',
            text: "Está por cambiar de Estado a la Solicitud de Facturación, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startChangeStateFacturacion(tipo, folio, estado));
            } else {
                dispatch(startloadSolFacturacion());
            }
        })
    }

    const handledAsignarFacturacion = (id) => {
        // console.log(id);


        dispatch(asignedID(id))

    }

    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }


    return (
        <>
            <SearchFacturacion opc={1} />
            <div className="row">
                <div className="col-12">
                    <div className="card">

                        <div className="card-body">

                            <button type="button" className="btn btn-outline-secondary" onClick={refreshData}>
                                <Repeat size='20' />
                            </button>

                            <div className="row">
                                <div className="table-responsive table-responsive p-2 mt-2 table-scrollbar-3 mb-4">
                                    <table className="table tbale-hover table-sm">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">FOLIO</th>
                                                <th scope="col" className="text-center">FECHA SOL.</th>
                                                <th scope="col" className="text-start">CLIENTE</th>
                                                <th scope="col" className="text-start">DATOS FACTURACIÓN</th>
                                                <th scope="col" className="text-center">CSF</th>
                                                <th scope="col" className="text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                facturacion.length == 0 ?
                                                    <>
                                                        <tr>
                                                            <td colSpan={7}>No hay facturas pendientes de emitir</td>
                                                        </tr>
                                                    </> :
                                                    facturacion.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{index + 1}</td>
                                                            <td>
                                                                {
                                                                    item.FOLIO.includes('HDLV') ?
                                                                        <> <a type="button" href={`./assets/reports/ordenVenta.html?folio=${item.FOLIO}`} target='_blank' className="btn btn-link border-0">  {item.FOLIO} </a> </> :
                                                                        <><a type="button" href={`./assets/reports/ordenEntrega.html?folio=${item.FOLIO}`} target='_blank' className="btn btn-link border-0">  {item.FOLIO} </a></>
                                                                }
                                                            </td>
                                                            <td>{item.FECHA}</td>
                                                            <td>{item.CLIENTE}</td>
                                                            <td className="text-start" >

                                                                {
                                                                    (item.RAZON_SOCIAL == '' || item.RAZON_SOCIAL == null) ?
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-primary"
                                                                                onClick={e => handledAsignarFacturacion(item.FOLIO)} data-bs-toggle="modal" data-bs-target="#searchFacturacion">
                                                                                Asignar
                                                                            </button>
                                                                            <span className='text-left-10 fw-bold text-danger'> No se han asignado datos de facturacion</span>
                                                                        </> :
                                                                        <>
                                                                            RAZÓN SOC: {item.RAZON_SOCIAL}, REGIMEN: {item.REGIMEN_FISCAL}, SOC. MERCAN: {item.SOCIEDAD_MERCANTIL},
                                                                            EMAIL: {item.CORREO}, DIRECCION: {item.DIRECCION}, {item.COD_POSTAL}, TEL: {item.TELEFONO}
                                                                        </>
                                                                }

                                                            </td>
                                                            <td className="text-center">
                                                                {
                                                                    (item.CSF == null || item.CSF == "") ?
                                                                        <> No registrado</> :
                                                                        <a type="button" href={`${connectionFiles['csfURL']}/${item.CSF}`} target='_blank' className="btn btn-outline-dark border-0">  <Eye size="20" /></a>
                                                                }

                                                            </td>
                                                            <td className="text-center" style={{ width: 130 }}>
                                                                <select className="form-select" defaultValue={0} onChange={e => handleChangeState(e, item)} disabled={item.RAZON_SOCIAL == '' || item.RAZON_SOCIAL == null}>
                                                                    {/* <option value={0}>Seleccion</option> */}
                                                                    <option value={0}>Pendiente</option>
                                                                    <option value={1}>Realizado</option>
                                                                </select>
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
