import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthRoutes } from '../auth/routes/AuthRoutes'
import { AppRoutes } from '../layout/components/AppRoutes'
import { Layout } from '../layout/Layout'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { close_login, setDataUsuario } from '../app/store/usuarios/slice'

export const AppRouter = () => {
    const dispatch = useDispatch();
    const { status, msg, isLoading } = useSelector(state => state.usuario);

    useEffect(() => {

        // console.log(localStorage.getItem('user_logged'));

        if (!localStorage.getItem('user_logged')) {
            dispatch(close_login())
        } else {

            let data = JSON.parse(localStorage.getItem('user_logged'));

            dispatch(setDataUsuario({
                usuario: data.USER,
                nombre: data.NOMBRE,
                id_emp: data.EMP_ID,
                id_acceso: data.ACC_ID,
                puesto_id: data.PUESTO_ID,
                email: data.EMAIL,
                rol: data.ROL,
                puesto: data.PUESTO,
                avatar: data.AVATAR,
                celular: data.CELULAR,
                telefono: data.TELEFONO,
            }))

        }
    }, [])

    return (
        <Routes>

            {
                /* Validacion para proteccion de rutas */
                (status == 'AUTENTICADO') ? <Route path='/*' element={<Layout />} /> : <Route path="/auth/*" element={<AuthRoutes />} />
            }
            <Route path='/*' element={<Navigate to='/auth/login' />} />
        </Routes>
    )
}
