import React, { useRef, useState } from 'react'
import { AlertOctagon, AlertTriangle, ArrowLeft, CheckCircle, Clipboard, Edit, Eye, File, FileText, Plus, Printer, Repeat, Save, Search, Trash2 } from 'react-feather'
import { NewPart } from './modals/NewPart'
import { Evidencias } from './modals/Evidencias'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { changeStockProd, changeStockProdA, startAddControlCalidad, startAsignacionOrden, startChangeRefaccion, startChangeServicio, startChangeServicioAutorizado, startChangeState, startRegistroDiagnostico, startUpdDescripFalla, startloadOrden, startloadOrdenesDash } from '../../../app/store/ordenes/thunks'
import { clearStore } from '../../../app/store/ordenes/slice'
import { Galeria } from './modals/Galeria'
import Swal from 'sweetalert2';
import { ViewEvidencias } from './modals/ViewEvidencias'
import { NewServicios } from './modals/NewServicios'
import { ViewInspeccionCC } from './modals/ViewInspeccionCC'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { NewAnticipo } from './modals/NewAnticipo'
import { M_GaleriaRetiros } from '../../utils/M_GaleriaRetiros'


export const OrderTracking = () => {

  const { servicios } = useSelector(state => state.catalogos);
  const { empleados } = useSelector(state => state.empleados);
  const { usuario, id_emp } = useSelector(state => state.usuario);
  // console.log(servicios);
  const { orden_edit, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);
  // console.log(orden_edit);
  const [Servicios, setServicios] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const inputRef = useRef(null);

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
    }
  });

  const { register: registerCC, trigger: triggerCC, setFocus: setFocusCC, getValues: getValuesCC, setValue: setValueCC, formState: { errors: errorsCC }, handleSubmit: handleSubmitCC, reset: resetCC } = useForm(
    {
      mode: 'all',
      defaultValues: {
        // enciende: 0, cargador_bat: 0, sonido: 0, red: 0, ensamblado: 0, teclado: 0, limpieza: 0, retorno: 0, checklist: 0, solo_rev: 0
      }
    });


  let paramsURL = useParams();


  useEffect(() => {
    if (!paramsURL.folio) {
      // console.log('TODO NORMAL');
    } else {
      // console.log('ejecutar busqueda argumento');
      reset({});
      dispatch(startloadOrden(paramsURL.folio))
    }
  }, [paramsURL])

  // useEffect(() => {
  //   // Establece el foco en el input cuando se carga la página
  //   inputRef.current.focus();
  // }, []);



  const handleSaveCC = (data) => {
    // console.log(data);
    // let filesA = document.getElementById("fotografia_up").files[0];
    let filesA = document.getElementById("fileUpo").files[0];
    if (filesA == undefined) filesA = '';
    let folio = orden_edit[0].FOLIO; let tecnico = orden_edit[0].TECNICO_ASIGNADO;
    dispatch(startAddControlCalidad(folio, data, id_emp, tecnico, filesA));
    clearForm();
  }

  const clearForm = () => {
    resetCC();
  }

  const handledSearch = (data) => {
    reset({});
    dispatch(startloadOrden(data.textSearch))
  }

  const goNewSearch = () => {
    dispatch(clearStore())
  }

  const handleChange = (e, folio) => {
    let estado = e.target.value;

    let refacciones = orden_edit[0]?.REFACCIONES;
    let countFaltantesRefa = 0;
    refacciones.forEach(item => {

      if (item.ESTADO != 5 && item.ESTADO != 6) {
        countFaltantesRefa = countFaltantesRefa + 1;
      }

    });


    let servicios = orden_edit[0]?.SERVICIOS;
    let countFaltantes = 0;
    let serviciosCompletos = [];
    let serviciosFaltantes = [];
    servicios.forEach(item => {

      if (item.AUTORIZO == 1) {
        countFaltantes = 0;

        if (item.ESTADO == 1) {
          serviciosCompletos.push(item)
        }
        else {
          serviciosFaltantes.push(item)
        }
      }

    });

    if (estado == 5 || estado == 7) {

      if (serviciosFaltantes == 0 && countFaltantesRefa == 0) {
        Swal.fire({
          title: 'Atención',
          text: "Está por cambiar de Estado a la Orden de Servicio, ¿Desea de continuar con esta acción?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b7ddd',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(startChangeState(folio, id_emp, orden_edit[0].TECNICO_ASIGNADO, orden_edit[0].ESTADO, estado));
          } else {
            dispatch(startloadOrden(folio));
          }
        })
      } else {

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'No es posible marcar la Orden como completa hasta que se hayan realizados los servicios o se hayan instalado las piezas solicitadas como refacciones',
        })
        dispatch(startloadOrden(folio));
      }
    } else {
      Swal.fire({
        title: 'Atención',
        text: "Está por cambiar de Estado a la Orden de Servicio, ¿Desea de continuar con esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b7ddd',
        cancelButtonColor: '#dc3545',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(startChangeState(folio, id_emp, orden_edit[0].TECNICO_ASIGNADO, orden_edit[0].ESTADO, estado));
        } else {
          dispatch(startloadOrden(folio));
        }
      })
    }

    // console.log(servicios);

  }

  const handleChangeServicio = (e, idServ, folio) => {
    let estado = e.target.value; let servid = idServ;

    Swal.fire({
      title: 'Atención',
      text: "Está por cambiar de Estado del Servicio, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startChangeServicio(folio, idServ, estado));
      } else {
        dispatch(startloadOrden(folio));
      }
    })
  }

  const handleChangeaRefaccionOrden = (e, idServ, folio) => {
    let estado = e.target.value; let servid = idServ;

    Swal.fire({
      title: 'Atención',
      text: "Está por cambiar de Estado de la Refaccion, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startChangeRefaccion(folio, idServ, estado));
      } else {
        dispatch(startloadOrden(folio));
      }
    })
  }

  const handleChangeServicioAutorizo = (e, idServ, folio, item) => {
    let estado = e.target.value; let servid = idServ;


    console.log(item);

    Swal.fire({
      title: 'Atención',
      text: "Está por cambiar el Estado de la Autorizacion del Servicio, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {

        if (estado == 1) {

          if (item.PROD_ID != 0 && item.CANT_PROD != 0) {
            console.log('ejecutar minus');
            dispatch(startChangeServicioAutorizado(folio, servid, estado));
            dispatch(changeStockProd(item.PROD_ID, item.CANT_PROD))

            console.log(item.PROD_ID);
            console.log(item.CANT_PROD);
          }
          else {
            console.log('funcion normal');
            dispatch(startChangeServicioAutorizado(folio, servid, estado));
          }

        } else {
          console.log('funcion mas');

          console.log(item.PROD_ID);
          console.log(item.CANT_PROD);

          dispatch(startChangeServicioAutorizado(folio, servid, estado));
          dispatch(changeStockProdA(item.PROD_ID, item.CANT_PROD))
        }
      } else {
        dispatch(startloadOrden(folio));
      }
    })



  }

  const saveDiagnostico = (folio) => {
    let diagnostico = document.getElementById('diagnostico_text').value;
    if (diagnostico != "") {
      Swal.fire({
        title: 'Atención',
        text: "Está por Registrar el diagnostico para la Orden del Servicio, ¿Desea de continuar con esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b7ddd',
        cancelButtonColor: '#dc3545',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(startRegistroDiagnostico(folio, id_emp, diagnostico));
        } else {
          dispatch(startloadOrden(folio));
        }
      })
    } else {
      Toast.fire({
        icon: 'error',
        title: 'Debe introducir el diagnostico'
      })
    }
  }

  const updDescripFalla = (folio) => {
    // console.log(folio);
    let falla = document.getElementById('falla_text').value;

    if (falla != "") {
      Swal.fire({
        title: 'Atención',
        text: "Está por Actualizar la descripcion de falla en la Orden de Servicio, ¿Desea de continuar con esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b7ddd',
        cancelButtonColor: '#dc3545',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(startUpdDescripFalla(folio, falla));
        } else {
          dispatch(startloadOrden(folio));
        }
      })
    } else {

      Toast.fire({
        icon: 'error',
        title: 'Debe introducir el diagnostico'
      })

    }
  }


  const launchreport = (e) => {
    console.log(orden_edit.folio);
    window.open(`./assets/reports/ordenEntrega.html?folio=${e}`, '_blank');
  }

  if (isLoading) {
    return (
      <>
        <div className='div_center'>
          <div className='text-center'>
            <div className="spinner"></div>
          </div>
          <div className='text-center text-left-15'>
            <span className='fw-bold'>Cargando</span>
          </div>
          <div className='text-center text-left-15'>
            <div className="spinner"></div>
          </div>
        </div>
      </>
    )
  }

  if (status_ordenes == 'EN_ESPERA' || status_ordenes == 'ORDENES_NO_ENCONTRADOS') {
    return (
      <>
        <div className="mb-3">
          <h1 className="h4 d-inline align-middle fw-bold">Seguimiento a Orden de Servicio</h1>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
            <div className="card ">

              <form onSubmit={handleSubmit(handledSearch)}>
                <div className="card-body">
                  <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                  <div className="d-flex">
                    <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce folio de Orden de Servicio' })} 
                    // ref={(e) => {inputRef.current = e;  }}
                     />
                    <button type='submit' className="btn btn-outline-secondary">
                      <Search size='20' />
                    </button>
                  </div>
                  {
                    errors.textSearch &&
                    <div className='text-left-5 mt-2'>
                      <AlertTriangle size="18" color="#dc3545" />
                      <span className='text-left-10 fw-bold text-danger'><small>{errors.textSearch.message}</small></span>
                    </div>
                  }
                </div>
              </form>
            </div>
          </div>
          <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9'>
            <div className="alert alert-light" role="alert">
              <h6 className="fw-bold text-dark h3">Instrucciones para dar seguimiento a una Orden de Servicio</h6>
              <p className="card-text h4 justify">No es posible dar seguimiento una Orden de Servicio hasta que la busques, sigue los siguientes pasos...</p>
              <p className="card-text mt-2"><span className='fw-bold'>Paso #1:</span><br /> Introduce el Folio de la Orden de Servicio, estas tienen el prefijo HDL - #####</p>
              <p className="card-text"><span className='fw-bold'>Paso #2:</span><br /> Presiona el Icono de la Lupa para buscar, si es contrada la Orden se cargara la informacion de la misma.</p>
              <p className="card-text"><span className='fw-bold'>Por la dudas:</span><br />
                Si la Orden no es encontrada se te informara a traves de un mensaje y podras realizar una nueva busqueda.
              </p>
              {/* <p className="card-text"></p> */}
            </div>
          </div>
        </div>
      </>
    )
  }

  if (status_ordenes == 'ORDEN_ENCONTRADA') {
    let orden_detail = orden_edit[0]; let servicios = orden_detail.SERVICIOS; let subtotal_servicios = 0; let subtotal_refacciones = 0;
    let evidencias = orden_edit[0]["EVIDENCIAS"]; let CONTROLCC = orden_edit[0].CONTROL_CALIDAD;
    let refacciones = orden_edit[0]["REFACCIONES"];
    let anticipos = orden_edit[0]["ANTICIPOS"]; let total_anticipos = 0;

    let retiros = orden_edit[0]["RETIROS"];
    let diagnosticos = orden_edit[0]["DIAGNOSTICOS"];
    // console.log(retiros);

    if (retiros == undefined || retiros == 'undefined') {
      retiros = 0;
    }

    if (diagnosticos == undefined || diagnosticos == 'undefined') {
      diagnosticos = 0;
    }

    // console.log(retiros);


    servicios.forEach(item => {
      // console.log(item.ID);
      if (item.AUTORIZO == 1 && item.ESTADO == 1) {
        subtotal_servicios = subtotal_servicios + Number(item.COSTO);
      }
    });


    anticipos.forEach(item => {
      total_anticipos = total_anticipos + Number(item.MONTO);
    });

    refacciones.forEach(item => {
      if (item.ESTADO != 0 && item.ESTADO != 6) {
        subtotal_refacciones = subtotal_refacciones + Number(item.COSTO_CLIENTE);
      }

    })

    let subtotal = subtotal_refacciones + subtotal_servicios;
    // let balance =subtotal- total_anticipos;
    // console.log(balance);

    if (orden_detail.ESTADO == 0) {




      const handleChange = (e, folio) => {

        let id_empleado = e.target.value;

        Swal.fire({
          title: 'Atención',
          text: "Está por Asignar/Reasignar una Orden de Servicio, ¿Desea de continuar con esta acción?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3b7ddd',
          cancelButtonColor: '#dc3545',
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(startAsignacionOrden(usuario, id_empleado, folio));

            let data = { tecnico: 'TODOS', estadoOrden: 'TODOS' }
            dispatch(startloadOrdenesDash(data));
            navigate('/dashboard');

          }
        })
      }






      return (
        <div className='row'>
          <div className='col-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item btn-link" onClick={goNewSearch}><ArrowLeft size='20' /> Atras</li>
                <li className="breadcrumb-item active" aria-current="page">Detalles de la Orden</li>
              </ol>
            </nav>
          </div>

          <div className='col-10 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div className="alert alert-light" role="alert">
              <div className='row'>
                <div className='col-1'>
                  <AlertOctagon size='20' />
                </div>
                <div className='col-10 text-start'>
                  <h4>Esta Orden de Servicio aun NO se ha asignado a ningun tecnico. Puede ser asignado...</h4>

                  <div>
                    <select className="form-select " style={{ width: '300px' }} defaultValue={'TODOS'} onChange={e => handleChange(e, orden_detail.FOLIO)}  >
                      <option value={'TODOS'} >Seleccione un Tecnico</option>
                      {
                        empleados.map((item, index) => (
                          item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} </option>
                            : <option key={index} style={{ display: "none" }} ></option>
                        ))
                      }
                    </select>
                  </div>

                </div>
              </div>
            </div>

            <>
              <NewPart /> <Evidencias folio={orden_detail.FOLIO} />
              <Galeria />
              <ViewEvidencias />
              <NewServicios />
              <ViewInspeccionCC />
              <NewAnticipo folio={orden_detail.FOLIO} />

              <div className="mb-3">
                <h1 className="h4 d-inline align-middle fw-bold">Detalles de la Orden de Servicio</h1>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                  <div className="card ">
                    <div className="card-body">
                      <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                      <div className="d-flex">
                        <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />
                        <button className="btn btn-outline-secondary" onClick={goNewSearch}>
                          <Repeat size='20' />
                        </button>
                      </div>
                    </div>
                    <hr className="my-0" />
                    <div className="card-body">
                      <div className="row mb-1">
                        <div className="col-12">
                          <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                        </div>
                      </div>
                      <hr className="my-0" />
                      <div className="row">
                        <div className="col-12 mt-3">
                          <div className="mb-1">
                            <label className="form-label">Nombre:</label>
                            <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-1">
                            <label className="form-label">Telefono:</label>
                            <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-1">
                            <label className="form-label">Celular:</label>
                            <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Correo electronico:</label>
                            <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-1">
                        <div className="col-12">
                          <h5 className="fw-bold me-2 border-0">Equipo </h5>
                          <hr className="my-0" />
                        </div>
                        <div className="col-12 mt-1">
                          <div className="mb-2">
                            <label className="form-label">Equipo:</label>
                            <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-2">
                            <label className="form-label">S.N:</label>
                            <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-2">
                            <label className="form-label">Sistema Operativo:</label>
                            <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-1">
                            <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                            </label>
                            <input type="text" className="form-control" value={orden_detail.CARGADOR_SN} disabled />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-1">
                            <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                            <input type="text" className="form-control" value={orden_detail.BATERIA_SN} disabled />
                          </div>
                        </div>

                        <div className="col-12 mt-2">
                          <button type="button" className="btn btn-outline-dark border-0  w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">

                        <div className='col-12'>
                          {
                            orden_detail.ESTADO == 7 ?
                              <>

                                <div className="alert alert-success" role="alert">
                                  <p className='h4'> <CheckCircle size={22} /> Esta Orden ya esta lista para ser entregada al Cliente</p>
                                  <Link to={`/closeOrder/${orden_detail.FOLIO}`} className="btn btn-outline-dark"> Cerrar Orden </Link>
                                </div>
                              </> : <></>
                          }
                        </div>

                        <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5 col-xxl-5  ">
                          <div className="">
                            <div className="row ">
                              <div className='col-12'>
                                <label className="mb-1">Estado Orden:</label>
                                {
                                  orden_detail.ESTADO == 7 ?
                                    <><select className="form-select " defaultValue={orden_detail.ESTADO} disabled onChange={e => handleChange(e, orden_detail.FOLIO)}>
                                      <option value='TODOS'>Todos los estados </option>
                                      <option value={0}>Sin asignar</option>
                                      <option value={1}>Asignada</option>
                                      <option value={2}>Sin Diagnostico</option>
                                      <option value={3}>Con Diagnostico</option>
                                      <option value={4}>Esperando de componente</option>
                                      <option value={5}>Completada/Terminada</option>
                                      <option value={6}>NO aprobada a Entrega</option>
                                      <option value={7}>Aprobada Entrega</option>
                                      {/* <option value={8}>Entregada a Cliente</option> */}
                                      <option value={9}>CANCELADO POR CLIENTE</option>

                                    </select></> :
                                    <>
                                      <select className="form-select " defaultValue={orden_detail.ESTADO} disabled onChange={e => handleChange(e, orden_detail.FOLIO)}>
                                        <option value='TODOS'>Todos los estados </option>
                                        <option value={0}>Sin asignar</option>
                                        <option value={1}>Asignada</option>
                                        <option value={2}>Sin Diagnostico</option>
                                        <option value={3}>Con Diagnostico</option>
                                        <option value={4}>Esperando de componente</option>
                                        <option value={5}>Completada/Terminada</option>
                                        <option value={6}>NO aprobada a Entrega</option>
                                        {/* <option value={7}>Aprobada Entrega</option> */}
                                        {/* <option value={8}>Entregada a Cliente</option> */}
                                        <option value={9}>CANCELADO POR CLIENTE</option>
                                        <option value={10}>Retiro de equipo por peticion del cliente</option>
                                      </select></>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2'>
                          <h5 className="mt-4"> Total de Anticipos:
                            <span className="fw-bold text-left-10">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)}</span>
                          </h5>
                        </div>
                        <div className='col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-2'>
                          <h5 className="mt-4"> Subtotal de Servicios y Refacciones:
                            <span className="fw-bold text-left-10">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span>
                          </h5>
                        </div>
                      </div>
                    </div>

                    <hr className='my-0' />

                    <div className='card-body'>
                      <div className="row">
                        <div className='col-12'>
                          <h5 className="card-title fw-bold text-dark mb-2">Diagnostico</h5>
                          <hr className="my-0 mb-3 mt-1" />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                          <label className="form-label fw-bold">Descripción de la falla(s):</label>
                          <textarea className="form-control" rows="6" defaultValue={orden_detail.DESCRIPCION_FALLA} id='falla_text' disabled ></textarea>
                          <div className='text-start mt-2'>
                            {/* <button type="button" className="btn btn-outline-primary" onClick={() => updDescripFalla(orden_detail.FOLIO)}>
                          <Save size='20' />  Actualizar descrip. falla
                        </button> */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                          <label className="form-label fw-bold">Diagnostico:</label>
                          {orden_detail.ESTADO == 5 || orden_detail.ESTADO == 7 ?
                            <> <textarea className="form-control" id='diagnostico_text' rows="6" placeholder="Ingresa descripción (Maximo 500 caracteres)" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea></> :
                            <>
                              <textarea className="form-control" id='diagnostico_text' rows="6" placeholder="Aun no se ha realizado el diagnostico" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea></>
                          }

                        </div>
                      </div>

                      <div className="row">
                        <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2'>
                          <h5 className="card-title fw-bold text-dark mb-2">Seguimiento a Servicios</h5>

                          {
                            orden_detail.ESTADO == 5 || orden_detail.ESTADO == 7 ?
                              <>
                                {/* <button type="button" disabled className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newServiciosOrden">Agregar más Servicios</button> */}
                                <hr className="my-0 mb-3 mt-2" />

                                <div className="table-responsive mt-1 table-scrollbar-6">
                                  <table className="table table-sm  table-hover">
                                    <thead className=''>
                                      <tr>
                                        <th scope="col" className='th-sticky'>#</th>
                                        <th scope="col" className='th-sticky'>SERVICIO</th>
                                        <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                                        <th scope="col" className="text-center th-sticky">REALIZADO</th>
                                        <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                        <th scope="col" className="text-end th-sticky">COSTO</th>
                                        <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                      </tr>
                                    </thead>
                                    <tbody>

                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan="5" className="text-end fw-bold">Subtotal:</td>
                                        <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                                        <td></td>

                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </> :
                              <>
                                {/* <button type="button" className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newServiciosOrden">Agregar más Servicios</button> */}

                                <hr className="my-0 mb-3 mt-2" />

                                <div className="table-responsive mt-1 table-scrollbar-6">
                                  <table className="table table-sm  table-hover">
                                    <thead className=''>
                                      <tr>
                                        <th scope="col" className='th-sticky'>#</th>
                                        <th scope="col" className='th-sticky'>SERVICIO</th>
                                        <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                                        <th scope="col" className="text-center th-sticky">REALIZADO</th>
                                        <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                        <th scope="col" className="text-end th-sticky">COSTO</th>
                                        <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/* vARIANTE TODOS MENOS ADMIN */}
                                      {
                                        servicios.map((item, index) => (
                                          <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                            <td>{index + 1}</td>
                                            <td>{item.NOMBRE}</td>
                                            <td className="text-center">

                                              <select className="form-select" aria-label="Default select example" disabled defaultValue={item.AUTORIZO} onChange={e => handleChangeServicioAutorizo(e, item.ID, item.FOLIO, item)}>
                                                {/* <option >Open this select menu</option> */}
                                                <option value={0}>En espera</option>
                                                <option value={1}>Autorizado</option>
                                                <option value={2}>No Autorizado</option>
                                                <option value={3}>No Aplica</option>

                                              </select>

                                            </td>
                                            <td className="text-center" >
                                              <select className="form-select" aria-label="Default select example" disabled defaultValue={item.ESTADO} onChange={e => handleChangeServicio(e, item.ID, item.FOLIO)}>
                                                {/* <option >Open this select menu</option> */}
                                                <option value={0}>Pendiente</option>
                                                <option value={1}>Realizado</option>
                                              </select>
                                            </td>
                                            <td className="text-center">
                                              {
                                                evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                              }
                                            </td>
                                            <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>
                                            <td className="text-center">
                                              {
                                                item.AUTORIZO == 0 ?
                                                  <> </> :
                                                  <>
                                                    <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#registerEvidencias" >
                                                      <Edit size='20' />
                                                    </button>
                                                  </>
                                              }
                                            </td>
                                          </tr>
                                        ))
                                      }
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <td colSpan="5" className="text-end fw-bold">Subtotal:</td>
                                        <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                                        <td></td>

                                      </tr>
                                    </tfoot>
                                  </table>
                                </div>
                              </>
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )
    }


    if (orden_detail.ESTADO != 8 && orden_detail.ESTADO != 9) {
      return (
        <>
          <NewPart /> <Evidencias folio={orden_detail.FOLIO} /> <Galeria />  <ViewEvidencias /> <NewServicios /> <ViewInspeccionCC />
          <NewAnticipo folio={orden_detail.FOLIO} />
          <div className="mb-3">
            <h1 className="h4 d-inline align-middle fw-bold">Seguimiento a Orden de Servicio</h1>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
              <div className="card ">
                <div className="card-body">
                  <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                  <div className="d-flex">
                    <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />
                    <button className="btn btn-outline-secondary" onClick={goNewSearch}>
                      <Repeat size='20' />
                    </button>
                  </div>
                </div>
                <hr className="my-0" />
                <div className="card-body">
                  <div className="row mb-1">
                    <div className="col-12">
                      <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="row">
                    <div className="col-12 mt-3">
                      <div className="mb-1">
                        <label className="form-label">Nombre:</label>
                        <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Telefono:</label>
                        <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Celular:</label>
                        <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-3">
                        <label className="form-label">Correo electronico:</label>
                        <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-12">
                      <h5 className="fw-bold me-2 border-0">Equipo </h5>
                      <hr className="my-0" />
                    </div>
                    <div className="col-12 mt-1">
                      <div className="mb-2">
                        <label className="form-label">Equipo:</label>
                        <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <label className="form-label">S.N:</label>
                        <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="mb-2">
                        <label className="form-label">Sistema Operativo:</label>
                        <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                        </label>
                        <input type="text" className="form-control" value={orden_detail.CARGADOR_SN} disabled />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="mb-1">
                        <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                        <input type="text" className="form-control" value={orden_detail.BATERIA_SN} disabled />
                      </div>
                    </div>

                    <div className="col-12 mt-2">
                      <button type="button" className="btn btn-outline-dark border-0  w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
              <div className="card">
                <div className="card-body">
                  <div className="row">

                    <div className='col-12'>
                      {
                        orden_detail.ESTADO == 7 ?
                          <>

                            <div className="alert alert-success" role="alert">
                              <p className='h4'> <CheckCircle size={22} /> Esta Orden ya esta lista para ser entregada al Cliente</p>
                              <Link to={`/closeOrder/${orden_detail.FOLIO}`} className="btn btn-outline-dark"> Cerrar Orden </Link>
                            </div>
                          </> : <></>
                      }
                    </div>

                    <div className="col-8 col-sm-8 col-md-4 col-lg-4 col-xl-4 col-xxl-4  ">
                      <div className="row ">
                        <div className='col-12'>
                          <label className="mb-1">Estado Orden:</label>
                          {
                            orden_detail.ESTADO == 7 ?
                              <><select className="form-select " defaultValue={orden_detail.ESTADO} disabled onChange={e => handleChange(e, orden_detail.FOLIO)}>
                                <option value='TODOS'>Todos los estados </option>
                                <option value={0}>Sin asignar</option>
                                <option value={1}>Asignada</option>
                                <option value={2}>Sin Diagnostico</option>
                                <option value={3}>Con Diagnostico</option>
                                <option value={4}>Esperando de componente</option>
                                <option value={5}>Completada/Terminada</option>
                                <option value={6}>NO aprobada a Entrega</option>
                                <option value={7}>Aprobada Entrega</option>
                                {/* <option value={8}>Entregada a Cliente</option> */}
                                <option value={9}>CANCELADO POR CLIENTE</option>
                              </select></> :

                              orden_detail.ESTADO == 10 ?
                                <><select className="form-select " defaultValue={orden_detail.ESTADO} disabled onChange={e => handleChange(e, orden_detail.FOLIO)}>
                                  <option value={10}>Retiro de equipo por peticion del cliente</option>
                                </select></> :

                                <>
                                  <select className="form-select " defaultValue={orden_detail.ESTADO} disabled={orden_detail.ESTADO === '10'} onChange={e => handleChange(e, orden_detail.FOLIO)}>
                                    <option value='TODOS'>Todos los estados </option>
                                    <option value={0}>Sin asignar</option>
                                    <option value={1}>Asignada</option>
                                    <option value={2}>Sin Diagnostico</option>
                                    <option value={3}>Con Diagnostico</option>
                                    <option value={4}>Esperando de componente</option>
                                    <option value={5}>Completada/Terminada</option>
                                    <option value={6}>NO aprobada a Entrega</option>
                                    {/* <option value={7}>Aprobada Entrega</option> */}
                                    {/* <option value={8}>Entregada a Cliente</option> */}
                                    <option value={9}>CANCELADO POR CLIENTE</option>

                                  </select>
                                </>
                          }

                        </div>
                      </div>
                    </div>


                    {
                      (orden_detail.ESTADO == 4) ?
                        <>
                          <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                            <Link to={`/orderDeparture/${orden_detail.FOLIO}`} className="btn btn-outline-dark  mt-4 w-100"> Retirar Equipo </Link>
                          </div>
                        </> : <></>
                    }
                    {
                      (orden_detail.ESTADO == 10) ?
                        <>
                          <div className='col-4 col-sm-4 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
                            <Link to={`/orderDeparture/${orden_detail.FOLIO}/1`} className="btn btn-outline-dark  mt-4 w-100"> Reingresar Equipo </Link>
                          </div>
                        </> : <></>
                    }

                    <div className='col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2'>
                      <h5 className="mt-4"> Total de Anticipos:
                        <span className="fw-bold text-left-10">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_anticipos)}</span>
                      </h5>
                    </div>


                    <div className='col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 col-xxl-3 mt-2'>
                      <h5 className="mt-4"> Subtotal de Servicios y Refacciones:
                        <span className="fw-bold text-left-10">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span>
                      </h5>
                    </div>

                  </div>
                </div>



                {
                  (retiros.length != 0) ?
                    <>
                      <M_GaleriaRetiros />
                      <div className="accordion accordion-flush border-bottom" id="accordionFlushExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="flush-headingOne">
                            <button className="accordion-button collapsed fw-bold " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                              <span className='card-title fw-bold text-black'>Detalles de retiro del equipo</span>
                            </button>
                          </h2>
                          <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className='table-responsive p-4 table-scrollbar-4'>
                              <table className="table table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col" className='th-sticky'>ID</th>
                                    <th scope="col" className='th-sticky'>CAPTURO</th>
                                    <th scope="col" className='th-sticky'>FECHA SALIDA</th>
                                    <th scope="col" className='th-sticky'>HORA SALIDA</th>
                                    <th scope="col" className='th-sticky'>RETIRO</th>
                                    <th scope="col" className='th-sticky'>OBSERVACIONES</th>
                                    <th scope="col" className='th-sticky text-center'>REINGRESO</th>
                                    <th scope="col" className='th-sticky text-center'>FECHA ENTREGA</th>
                                    <th scope="col" className='th-sticky text-center'>HORA ENTREGA</th>
                                    <th scope="col" className='th-sticky text-center'>OBSERVACIONES</th>
                                    <th scope="col" className='th-sticky text-center'>EVIDENCIAS</th>
                                    <th scope="col" className='th-sticky text-center'>DONDE ESTA</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    retiros == 0 ?
                                      <>
                                        <tr>
                                          <td scope="row" colSpan={12}> No se han encontrado datos.</td>

                                        </tr>
                                      </> :
                                      retiros.map((item, index) => (
                                        <tr key={index} className={''}>
                                          <td>{item.ID}</td>
                                          <td>{item.EMPLEADO}</td>
                                          <td>{item.FECHA}</td>
                                          <td>{item.HORA}</td>
                                          <td>{item.RETIRA}</td>
                                          <td>{item.OBSERVACIONES_RETIRO}</td>
                                          <td>{item.INGRESA}</td>
                                          <td>{item.FECHA_REINGRESO}</td>
                                          <td>{item.HORA_REINGRESO}</td>
                                          <td>{item.OBSERVACIONES_REINGRESO}</td>
                                          <td>
                                            <button type="button" className="btn btn-outline-dark border-0  w-100" data-bs-toggle="modal" data-bs-target="#galeryDeparture" >
                                              <Eye size="20" />
                                            </button>
                                          </td>
                                          <td className='text-center'>{(item.ESTADO) == 0 ?
                                            <><span className="status-btn progress-btn"> CON EL CLIENTE </span></> :
                                            <><span className="status-btn complete-btn"> EN HDL </span></>}</td>
                                        </tr>
                                      ))
                                  }


                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </> : <></>
                }




                <div className='card-body'>
                  <div className="row">
                    <div className='col-12'>
                      <h5 className="card-title fw-bold text-dark mb-2">Diagnostico</h5>
                      <hr className="my-0 mb-3 mt-1" />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                      <label className="form-label fw-bold">Descripción de la falla(s):</label>
                      <textarea className="form-control" rows="6" defaultValue={orden_detail.DESCRIPCION_FALLA} id='falla_text' disabled ></textarea>
                      <div className='text-start mt-2'>
                        {/* <button type="button" className="btn btn-outline-primary" onClick={() => updDescripFalla(orden_detail.FOLIO)}>
                          <Save size='20' />  Actualizar descrip. falla
                        </button> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                      <label className="form-label fw-bold">Diagnostico:</label>
                      {orden_detail.ESTADO == 5 || orden_detail.ESTADO == 7 ? <> <textarea className="form-control" id='diagnostico_text' rows="6" placeholder="Ingresa descripción (Maximo 500 caracteres)" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea></> :
                        <> <textarea className="form-control" id='diagnostico_text' rows="6" placeholder="Ingresa descripción (Maximo 500 caracteres)" defaultValue={orden_detail.DIAGNOSTICO}></textarea></>
                      }
                      <div className='text-end mt-2'>
                        {orden_detail.ESTADO == 5 || orden_detail.ESTADO == 7 ?
                          <>
                            <button type="button" className="btn btn-outline-primary" disabled onClick={() => saveDiagnostico(orden_detail.FOLIO)}>
                              <Save size='20' />  Guardar Diagnostico
                            </button>
                          </> :
                          <>
                            <button type="button" className="btn btn-outline-primary" onClick={() => saveDiagnostico(orden_detail.FOLIO)}>
                              <Save size='20' />  Guardar Diagnostico
                            </button>
                          </>}
                      </div>
                    </div>
                    {
                      (diagnosticos.length > 1) ?
                        <>
                          <div className="accordion accordion-flush border-bottom" id="accordionFlushExample">
                            <div className="accordion-item">
                              <h2 className="accordion-header" id="flush-headingOne">
                                <button className="accordion-button collapsed fw-bold " type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                  <span className='card-title fw-bold text-black'>Diagnosticos registrados:</span>
                                </button>
                              </h2>
                              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                <div className='table-responsive p-4 table-scrollbar-4'>
                                  <table className="table table-sm">
                                    <thead>
                                      <tr>
                                        <th scope="col" className='th-sticky text-start' >CAPTURO</th>
                                        <th scope="col" className='th-sticky text-start'>FECHA</th>
                                        <th scope="col" className='th-sticky text-start'>HORA</th>
                                        <th scope="col" className='th-sticky text-start'>DIAGNOSTICO</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        diagnosticos == 0 ?
                                          <>
                                            <tr>
                                              <td scope="row" colSpan={4}> No se han encontrado datos.</td>

                                            </tr>
                                          </> :
                                          diagnosticos.map((item, index) => (
                                            <tr key={index} className={''}>
                                              <td className='text-start'>{item.EMPLEADO}</td>
                                              <td className='text-start'>{item.FECHA}</td>
                                              <td className='text-start'>{item.HORA}</td>
                                              <td className='text-start'>{item.DIAGNOSTICO}</td>
                                            </tr>
                                          ))
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </> : <></>
                    }

                  </div>

                  <div className="row">
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mt-2'>
                      <h5 className="card-title fw-bold text-dark mb-2">Seguimiento a Servicios</h5>

                      {
                        orden_detail.ESTADO == 5 || orden_detail.ESTADO == 7 ?
                          <>   <button type="button" disabled className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newServiciosOrden">Agregar más Servicios</button>
                            <hr className="my-0 mb-3 mt-2" />

                            <div className="table-responsive mt-1 table-scrollbar-6">
                              <table className="table table-sm  table-hover">
                                <thead className=''>
                                  <tr>
                                    <th scope="col" className='th-sticky'>#</th>
                                    <th scope="col" className='th-sticky'>SERVICIO</th>
                                    <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                                    <th scope="col" className="text-center th-sticky">REALIZADO</th>
                                    <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                    <th scope="col" className="text-end th-sticky">COSTO</th>
                                    <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {/* vARIANTE TODOS MENOS ADMIN */}
                                  {
                                    servicios.map((item, index) => (
                                      <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                        <td>{index + 1}</td>
                                        <td>{item.NOMBRE}</td>
                                        <td className="text-center">

                                          <select className="form-select" aria-label="Default select example" disabled defaultValue={item.AUTORIZO} onChange={e => handleChangeServicioAutorizo(e, item.ID, item.FOLIO)}>
                                            {/* <option >Open this select menu</option> */}
                                            <option value={0}>En espera</option>
                                            <option value={1}>Autorizado</option>
                                            <option value={2}>No Autorizado</option>
                                            <option value={3}>No Aplica</option>

                                          </select>

                                        </td>
                                        <td className="text-center" >
                                          <select className="form-select" aria-label="Default select example" disabled defaultValue={item.ESTADO} onChange={e => handleChangeServicio(e, item.ID, item.FOLIO)}>
                                            {/* <option >Open this select menu</option> */}
                                            <option value={0}>Pendiente</option>
                                            <option value={1}>Realizado</option>
                                          </select>
                                        </td>
                                        <td className="text-center">
                                          {
                                            evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                          }
                                        </td>
                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>
                                        <td className="text-center">
                                          {
                                            item.AUTORIZO == 0 ?
                                              <> </> :
                                              <>
                                                <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#registerEvidencias" disabled>
                                                  <Edit size='20' />
                                                </button>
                                              </>
                                          }
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan="5" className="text-end fw-bold">Subtotal:</td>
                                    <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                                    <td></td>

                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </> :
                          <>   <button type="button" className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newServiciosOrden">Agregar más Servicios</button>

                            <hr className="my-0 mb-3 mt-2" />

                            <div className="table-responsive mt-1 table-scrollbar-6">
                              <table className="table table-sm  table-hover">
                                <thead className=''>
                                  <tr>
                                    <th scope="col" className='th-sticky'>#</th>
                                    <th scope="col" className='th-sticky'>SERVICIO</th>
                                    <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                                    <th scope="col" className="text-center th-sticky">REALIZADO</th>
                                    <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                    <th scope="col" className="text-end th-sticky">COSTO</th>
                                    <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {
                                    servicios.map((item, index) => (
                                      <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                        <td>{index + 1}</td>
                                        <td>{item.NOMBRE}</td>
                                        <td className="text-center">

                                          <select className="form-select" aria-label="Default select example" defaultValue={item.AUTORIZO} onChange={e => handleChangeServicioAutorizo(e, item.ID, item.FOLIO, item)}>
                                            {/* <option >Open this select menu</option> */}
                                            <option value={0}>En espera</option>
                                            <option value={1}>Autorizado</option>
                                            <option value={2}>No Autorizado</option>
                                            <option value={3}>No Aplica</option>

                                          </select>

                                        </td>
                                        <td className="text-center" >
                                          <select className="form-select" aria-label="Default select example" disabled={item.AUTORIZO != 1} defaultValue={item.ESTADO} onChange={e => handleChangeServicio(e, item.ID, item.FOLIO)}>
                                            {/* <option >Open this select menu</option> */}
                                            <option value={0}>Pendiente</option>
                                            <option value={1}>Realizado</option>
                                          </select>
                                        </td>
                                        <td className="text-center">
                                          {
                                            evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                          }
                                        </td>
                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>
                                        <td className="text-center">
                                          {
                                            item.AUTORIZO == 0 ?
                                              <> </> :
                                              <>
                                                <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#registerEvidencias" >
                                                  <Edit size='20' />
                                                </button>
                                              </>
                                          }
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td colSpan="5" className="text-end fw-bold">Subtotal:</td>
                                    <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                                    <td></td>

                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </>
                      }

                    </div>
                  </div>

                  <h5 className="card-title fw-bold text-dark mb-1 mt-4">Refacciones para el equipo </h5>

                  {

                    orden_detail.ESTADO == 5 || orden_detail.ESTADO == 7 ?
                      <>
                        <button type="button" disabled className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newPart">Solicitar refacción</button>

                        <hr />
                        <div className="row mb-3">
                          <div className="table-responsive mt-1 table-scrollbar-6">
                            <table className="table table-sm table-striped table-hover">
                              <thead>
                                <tr>
                                  <th scope="col" className='th-sticky'>#</th>
                                  <th scope="col" className='th-sticky'>REFACCIÓN</th>
                                  <th scope="col" className="text-center th-sticky">COSTO ($)</th>
                                  <th scope="col" className="text-center th-sticky">C. CLIENTE. ($)</th>
                                  <th scope="col" className="text-center th-sticky">ESTADO</th>
                                  <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                  <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  refacciones.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.REFACCION}</td>
                                      <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                      <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                      <td className="text-center">

                                        <select className="form-select" aria-label="Default select example" disabled defaultValue={item.ESTADO} onChange={e => handleChangeaRefaccionOrden(e, item.ID, orden_detail.FOLIO)}>
                                          {/* <option >Open this select menu</option> */}
                                          <option value={0}>Solicitada</option>
                                          <option value={1}>Aprobada</option>
                                          <option value={2}>Comprada, en espera</option>
                                          <option value={3}>Llego</option>
                                          <option value={4}>Disponible</option>
                                          <option value={5}>Instalada</option>
                                          <option value={6}>Rechazo Cliente</option>
                                        </select>
                                      </td>
                                      <td className="text-center">
                                        {
                                          evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                        }
                                      </td>
                                      <td>
                                        <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#registerEvidencias" disabled >
                                          <Edit size='20' />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={3} className="text-end fw-bold">Subtotal:</td>
                                  <td className="fw-bold text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)}</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>








                      </> :
                      <> <button type="button" className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newPart">Solicitar refacción</button>
                        <hr />
                        <div className="row mb-3">
                          <div className="table-responsive mt-1 table-scrollbar-6">
                            <table className="table table-sm table-striped table-hover">
                              <thead>
                                <tr>
                                  <th scope="col" className='th-sticky'>#</th>
                                  <th scope="col" className='th-sticky'>REFACCIÓN</th>
                                  <th scope="col" className="text-center th-sticky">COSTO ($)</th>
                                  <th scope="col" className="text-center th-sticky">C. CLIENTE. ($)</th>
                                  <th scope="col" className="text-center th-sticky">ESTADO</th>
                                  <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                  <th scope="col" className="text-center th-sticky">ACCIONES</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  refacciones.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item.REFACCION}</td>
                                      <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                      <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                      <td className="text-center">

                                        <select className="form-select" aria-label="Default select example" defaultValue={item.ESTADO} onChange={e => handleChangeaRefaccionOrden(e, item.ID, orden_detail.FOLIO)}>
                                          {/* <option >Open this select menu</option> */}
                                          <option value={0}>Solicitada</option>
                                          <option value={1}>Aprobada</option>
                                          <option value={2}>Comprada, en espera</option>
                                          <option value={3}>Llego</option>
                                          <option value={4}>Disponible</option>
                                          <option value={5}>Instalada</option>
                                          <option value={6}>Rechazo Cliente</option>
                                        </select>
                                      </td>
                                      <td className="text-center">
                                        {
                                          evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                        }
                                      </td>
                                      <td>
                                        <button type="button" className="btn btn-outline-dark border-0" data-bs-toggle="modal" data-bs-target="#registerEvidencias" >
                                          <Edit size='20' />
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={3} className="text-end fw-bold">Subtotal:</td>
                                  <td className="fw-bold text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)}</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                  }
                  <div className='row'>
                    <div className='col-12'>
                      <div className="text-end">
                        <h5 className="">Subtotal de Servicios y Refacciones: <span
                          className="fw-bold">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span> </h5>
                      </div>
                    </div>
                  </div>
                  <div className='mt-2'>
                    <button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#newAnticipo">
                      Agregar anticipo
                    </button>
                  </div>
                  {
                    orden_detail.ESTADO == 5 ?
                      <>

                        <form onSubmit={handleSubmitCC(handleSaveCC)}>
                          <div className='row'>
                            <div className='col-12'>
                              <h5 className="card-title fw-bold text-dark mb-1 mt-4">Control de Calidad </h5>
                              <hr />
                              <p> Favor de llenar el siguiente formulario.</p>
                              <div className='row'>
                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                  <span className='fw-bold mb-1'>Revision de Sistema</span>
                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox"  {...registerCC("enciende")} />
                                      <label className="form-check-label" >Enciende el equipo (Funciona).</label>
                                    </div>
                                  </div>
                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox"  {...registerCC("cargador_bat")} />
                                      <label className="form-check-label" >Tengo el Cargador y Bateria correspondiente al equipo.</label>
                                    </div>
                                  </div>
                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" {...registerCC("sonido")} />
                                      <label className="form-check-label" >El equipo emite sonido (Revision de audio).</label>
                                    </div>
                                  </div>

                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox"  {...registerCC("red")} />
                                      <label className="form-check-label" >El equipo se conecta a Internet (Conexion Red).</label>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                  <span className='fw-bold mb-1'>Revision de Equipo</span>

                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" {...registerCC("ensamblado")} />
                                      <label className="form-check-label" >El equipo esta ensamblado por completo (Tornilleria).</label>
                                    </div>
                                  </div>

                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" {...registerCC("teclado")} />
                                      <label className="form-check-label" >El teclado del equipo funciona correctamente</label>
                                    </div>
                                  </div>

                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" {...registerCC("limpieza")} />
                                      <label className="form-check-label" >El equipo esta limpio</label>
                                    </div>
                                  </div>
                                </div>

                                <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                                  <span className='fw-bold mb-1'>Generales</span>
                                  <div className='mb-2'>
                                    <div className="form-check">
                                      <input className="form-check-input" type="checkbox" {...registerCC("retorno")} />
                                      <label className="form-check-label" >Se regresan al cliente todos los accesorios del equipo (Cargador/Bateria/HD/SSD/etc).</label>
                                    </div>
                                  </div>

                                  <div className='mb-2'>
                                    <div className="form-check mb-2">
                                      <input className="form-check-input" type="checkbox" {...registerCC("checklist")} />
                                      <label className="form-check-label" >Recibo el Checklist del Tecnico.</label>
                                    </div>
                                    {/* <input className="form-control form-control" id="fotografia_up" name="fotografia_up" type="file" accept="image/*" /> */}
                                    <input className="form-control" type="file" id='fileUpo' name='fileUpo' />
                                  </div>

                                  <div className='mb-2'>
                                    <div className="form-check mb-2">
                                      <input className="form-check-input" type="checkbox" {...registerCC("solo_rev")} />
                                      <label className="form-check-label fw-bold" >He revisado que se hayan realizado unicamente los servicios autorizados por el cliente.</label>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12 text-end mt-2'>
                                  <button type="submit" className="btn btn-outline-primary"><Save size='20' /> Guardar Checklist</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </> : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      )
    }
    else {
      return (
        <div className='row'>
          <div className='col-12'>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item btn-link" onClick={goNewSearch}><ArrowLeft size='20' /> Atras</li>
                <li className="breadcrumb-item active" aria-current="page">Detalles de la Orden</li>
              </ol>
            </nav>
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
            <div className="alert alert-light" role="alert">
              <div className='row'>
                <div className='col-1'>
                  <AlertOctagon size='20' />
                </div>
                <div className='col-10 text-start'>
                  <span className='fw-bold '>La Orden de Servicio con el Folio: {orden_detail.FOLIO}, ya fue
                    {
                      orden_detail.ESTADO == 8 ? <> Concluida por {orden_detail.EMPLEADO_CIERRA} el dia {orden_detail.FECHA_CERRADA} a las {orden_detail.HORA_CERRADA} </> : <></>
                    }
                    {
                      orden_detail.ESTADO == 9 ? <> Cancelada por peticion del Cliente {orden_detail.NOMBRE} el dia {orden_detail.FECHA_CERRADA} a las {orden_detail.HORA_CERRADA}  </> : <></>
                    }

                  </span><br />
                  <button type="button" className="btn btn-outline-dark mt-2 me-3" onClick={goNewSearch}>
                    Ok. Entiendo, vamos atras....
                  </button>
                  <button type="button" className="btn btn-outline-dark mt-2" onClick={() => launchreport(orden_detail.FOLIO)}>
                    <Printer size='20' /> Imprimir reporte
                  </button>

                </div>
              </div>
            </div>
          </div>


          {/* Aqui va */}
          <div className='col-12'>
            <ViewEvidencias /> <Galeria />
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                <div className="card ">
                  <div className="card-body">
                    <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                    <div className="d-flex">
                      <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />

                    </div>
                  </div>
                  <hr className="my-0" />
                  <div className="card-body">
                    <div className="row mb-1">
                      <div className="col-12">
                        <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                      </div>
                    </div>
                    <hr className="my-0" />
                    <div className="row">
                      <div className="col-12 mt-3">
                        <div className="mb-1">
                          <label className="form-label">Nombre:</label>
                          <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Telefono:</label>
                          <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Celular:</label>
                          <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-3">
                          <label className="form-label">Correo electronico:</label>
                          <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-1">
                      <div className="col-12">
                        <h5 className="fw-bold me-2 border-0">Equipo </h5>
                        <hr className="my-0" />
                      </div>
                      <div className="col-12 mt-1">
                        <div className="mb-2">
                          <label className="form-label">Equipo:</label>
                          <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label className="form-label">S.N:</label>
                          <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="mb-2">
                          <label className="form-label">Sistema Operativo:</label>
                          <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                          </label>
                          <input type="text" className="form-control" value={orden_detail.CARGADOR_SN} disabled />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-1">
                          <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                          <input type="text" className="form-control" value={orden_detail.BATERIA_SN} disabled />
                        </div>
                      </div>

                      <div className="col-12 mt-2">
                        <button type="button" className="btn btn-outline-dark border-0 w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                <div className="card">

                  <div className="card-body">

                    <h5 className="card-title fw-bold text-dark mb-2">Detalles de trabajo realizado</h5>
                    <hr className="my-0" />
                    {/* dIAGNOSTICO */}
                    <div className="row mt-2">
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                        <label className="form-label fw-bold">Descripción de la falla(s):</label>
                        <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DESCRIPCION_FALLA}></textarea>
                      </div>
                      <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-2">
                        <label className="form-label fw-bold">Diagnostico:</label>
                        <textarea className="form-control" rows="6" disabled defaultValue={orden_detail.DIAGNOSTICO}></textarea>
                      </div>
                    </div>

                    {/* sERVICIOS */}
                    <h5 className="card-title fw-bold text-dark mb-2 mt-3">Servicios realizados </h5>
                    <div className="row mb-3">
                      <div className="table-responsive mt-1 table-scrollbar-6">
                        <table className="table table-sm table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className='th-sticky'>#</th>
                              <th scope="col" className='th-sticky'>SERVICIO</th>
                              <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                              <th scope="col" className="text-center th-sticky">REALIZADO</th>
                              <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                              <th scope="col" className="text-end th-sticky">COSTO</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              servicios.map((item, index) => (
                                <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                  <td>{index + 1}</td>
                                  <td>{item.NOMBRE}</td>
                                  <td className="text-center">
                                    {
                                      item.AUTORIZO == 1 ? <>
                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-center" >
                                    {
                                      item.ESTADO == 1 ? <>
                                        <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-center">
                                    {
                                      evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                    }
                                  </td>
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>

                                </tr>
                              ))
                            }

                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={5} className="text-end fw-bold">Subtotal:</td>
                              <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_servicios)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    {/* Refacciones Instaladas */}
                    <h5 className="card-title fw-bold text-dark mb-1 mt-4">Refacciones Instaladas </h5>
                    <div className="row mb-3">
                      <div className="table-responsive mt-1 table-scrollbar-6">
                        <table className="table table-sm table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col" className='th-sticky'>#</th>
                              <th scope="col" className='th-sticky'>REFACCIÓN</th>
                              <th scope="col" className="text-center th-sticky">ESTADO</th>
                              <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                              <th scope="col" className="text-end th-sticky">COSTO ($)</th>
                              <th scope="col" className="text-end th-sticky">C. CLIENTE. ($)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              refacciones.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.REFACCION}</td>

                                  <td className="text-center">
                                    {item.ESTADO == 0 ? <> <span className="status-btn pending-btn"> Solicitada </span>  </> : <> </>}
                                    {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> Aprobada </span>  </> : <> </>}
                                    {item.ESTADO == 2 ? <> <span className="status-btn progress-btn"> Comprada, en espera </span>  </> : <> </>}
                                    {item.ESTADO == 3 ? <> <span className="status-btn tests-btn"> Llego </span>  </> : <> </>}
                                    {item.ESTADO == 4 ? <> <span className="status-btn vacaciones-btn"> Disponible </span>  </> : <> </>}
                                    {item.ESTADO == 5 ? <> <span className="status-btn complete-btn"> Instalada </span>  </> : <> </>}
                                    {item.ESTADO == 6 ? <> <span className="status-btn pending-btn"> Rechazo Cliente </span>  </> : <> </>}
                                  </td>
                                  <td className="text-center">
                                    {
                                      evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                    }
                                  </td>

                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                  <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                </tr>
                              ))
                            }

                          </tbody>
                          <tfoot>
                            <tr>
                              <td colSpan={5} className="text-end fw-bold">Subtotal:</td>
                              <td className="fw-bold text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)}</td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    <div className='row'>
                      <div className='col-12'>
                        <div className="text-end">
                          <h5 className="">Subtotal de Servicios y Refacciones: <span
                            className="fw-bold">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span> </h5>
                        </div>
                      </div>
                    </div>



                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

}