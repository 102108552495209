import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Home } from 'react-feather';
import { Link, NavLink } from 'react-router-dom';
import { ordenes, operativos, administracion, profile, clientes } from './SidebarItems';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreEquiposCliente } from '../../../app/store/equipos/thunks';
import { clearStoreSearch } from '../../../app/store/clientes/thunks';
import { clearStore } from '../../../app/store/ordenes/slice';

export const Sidebar = ({ showSidebar, setshowSidebar }) => {
  const dispatch = useDispatch();
  const clearData = () => {
    dispatch(clearStoreEquiposCliente());
    dispatch(clearStoreSearch());
    dispatch(clearStore());
    setshowSidebar(true);
  }

  const sidebarOpen = () => {
    setshowSidebar(true);
    // console.log(showSidebar);
  }


  const { usuario, nombre, puesto, avatar, rol } = useSelector(state => state.usuario);

  return (
    <nav id="sidebar" className={showSidebar ? 'sidebar js-sidebar ' : 'sidebar js-sidebar collapsed'}>
      <div className="sidebar-content js-simplebar">

        <Link className='sidebar-brand' to="/dashboard" onClick={sidebarOpen}>
          <span className="align-middle">HDL Technology</span>
        </Link>

        <Scrollbars style={{ width: "100%", height: "100%" }} renderThumbVertical={(props) => <div {...props} className="scrollbar-color" />}>

          <ul className="sidebar-nav">
            <li className="sidebar-header fw-bold">
              Principales
            </li>
            {
              ordenes.map((item) => {

                // console.log(item);
                return (
                  <li className='sidebar-item' key={item.id} onClick={clearData}>
                    <NavLink className={({ isActive }) => 'sidebar-link ' + (isActive ? 'item-active' : '')} to={item.path}>
                      {item.icon}
                      <span className="align-middle text-right-10">{item.title}</span>
                      {item.indicator}
                    </NavLink>
                  </li>
                )

              })
            }

            {
              (rol != 'Tecnico') ? <>
                {
                  clientes.map((its) => {
                    return (
                      <li className='sidebar-item ' key={its.id} onClick={sidebarOpen}>
                        <NavLink className={({ isActive }) => 'sidebar-link ' + (isActive ? 'item-active' : '')} to={its.path}>
                          {/* <a className="sidebar-link" > */}
                          {its.icon}
                          <span className="align-middle text-right-10 ">{its.title}</span>
                          {its.indicator}
                          {/* </a> */}
                        </NavLink>
                      </li>
                    )
                  })
                }
              </> : <>
              </>
            }

            {/* Especiales Recepcionista */}

            {
              (rol == 'Recepcionista') ? <>

                <li className="sidebar-header">
                  Operativos
                </li>
                {
                  operativos
                    .filter(it => it.id === 3) // Filtra el ítem con id 3
                    .map(it => (
                      <li className='sidebar-item' key={it.id} onClick={sidebarOpen}>
                        <NavLink className={({ isActive }) => 'sidebar-link ' + (isActive ? 'item-active' : '')} to={it.path}>
                          {it.icon}
                          <span className="align-middle text-right-10">{it.title}</span>
                          {it.indicator}
                        </NavLink>
                      </li>
                    ))
                }

              </> : <></>
            }








            {
              (rol != 'Tecnico' && rol !== 'Recepcionista') ? <>
                <li className="sidebar-header">
                  Operativos
                </li>
                {
                  operativos.map((it) => {

                    return (
                      <li className='sidebar-item ' key={it.id} onClick={sidebarOpen}>
                        <NavLink className={({ isActive }) => 'sidebar-link ' + (isActive ? 'item-active' : '')} to={it.path}>

                          {/* <a className="sidebar-link" > */}
                          {it.icon}
                          <span className="align-middle text-right-10 ">{it.title}</span>
                          {it.indicator}
                          {/* </a> */}
                        </NavLink>
                      </li>
                    )

                  })
                }</> : <>

              </>
            }


            <li className="sidebar-header">
              Administración
            </li>

            {
              profile.map((its) => {
                return (
                  <li className='sidebar-item ' key={its.id} onClick={sidebarOpen}>
                    <NavLink className={({ isActive }) => 'sidebar-link ' + (isActive ? 'item-active' : '')} to={its.path}>
                      {/* <a className="sidebar-link" > */}
                      {its.icon}
                      <span className="align-middle text-right-10 ">{its.title}</span>
                      {its.indicator}
                      {/* </a> */}
                    </NavLink>
                  </li>
                )
              })
            }

            {
              (rol != 'Tecnico' && rol !== 'Recepcionista') ? <>
                {
                  administracion.map((it) => {
                    return (
                      <li className='sidebar-item ' key={it.id} onClick={sidebarOpen}>
                        <NavLink className={({ isActive }) => 'sidebar-link ' + (isActive ? 'item-active' : '')} to={it.path}>

                          {/* <a className="sidebar-link" > */}
                          {it.icon}
                          <span className="align-middle text-right-10 ">{it.title}</span>
                          {it.indicator}
                          {/* </a> */}
                        </NavLink>
                      </li>
                    )
                  })
                }</> : <>

              </>
            }

          </ul>
        </Scrollbars>

      </div>
    </nav>
  )
}
