import React from 'react'

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row text-muted">
          <div className="col-6 text-start">
            <p className="mb-0 text-muted fw-bold">
              HDL Technology

            </p>
          </div>
          <div className="col-6 text-end">
            <ul className="list-inline">

              {/* <li className="list-inline-item">
                 {/* <a className="text-muted fw-bold" href="" target="_blank">Manual de Usuario</a> 
                <a className="text-muted fw-bold">Manual de Usuario</a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>

  )
}
