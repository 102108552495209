import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { loadRefaccionEdit, startChangeRefaccion, startloadOrden, startloadRefaccioneOrdenes } from '../../../../app/store/ordenes/thunks';
import { ArrowLeft, Edit } from 'react-feather';
import Swal from 'sweetalert2';
import { M_EditRefaccion } from './M_EditRefaccion';

export const EditPart = () => {
    const dispatch = useDispatch();
    const { orden_edit, indicadores, isLoading } = useSelector(state => state.ordenes);

    const goback = () => {
        dispatch(startloadRefaccioneOrdenes());
    }

    const handleChangeaRefaccionOrden = (e, idServ, folio) => {
        let estado = e.target.value; let servid = idServ;

        Swal.fire({
            title: 'Atención',
            text: "Está por cambiar de Estado de la Refaccion, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startChangeRefaccion(folio, idServ, estado));
            } else {
                dispatch(startloadOrden(folio));
            }
        })
    }


    const handledEdit = (data, item) => {

        dispatch(loadRefaccionEdit(item))
    }

    if (isLoading) {
        return (
            <>
                <div className='div_center'>
                    <div className='text-center'>
                        <div className="spinner"></div>
                    </div>
                    <div className='text-center text-left-15'>
                        <span className='fw-bold'>Cargando</span>
                    </div>
                    <div className='text-center text-left-15'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </>
        )
    }


    let orden_detail = orden_edit[0]; let subtotal_refacciones = 0;
    let evidencias = orden_edit[0]["EVIDENCIAS"]; let CONTROLCC = orden_edit[0].CONTROL_CALIDAD;
    let refacciones = orden_edit[0]["REFACCIONES"];



    refacciones.forEach(item => {
        if (item.ESTADO != 0 && item.ESTADO != 6) {
            subtotal_refacciones = subtotal_refacciones + Number(item.COSTO_CLIENTE);
        }

    })

    let subtotal = subtotal_refacciones;




    return (
        <>
            <M_EditRefaccion />

            <div className='row'>



                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item btn-link" onClick={goback}><ArrowLeft size='20' /> Atras</li>
                            <li className="breadcrumb-item active" aria-current="page">Detalles de Refacciones</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <div className="card ">
                        <div className="card-body">
                            <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                            <div className="d-flex">
                                <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO} />

                            </div>

                        </div>
                        <hr className="my-0" />
                        <div className="card-body">

                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                                </div>

                            </div>
                            <hr className="my-0" />

                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div className="mb-1">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" value={orden_detail.NOMBRE} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Telefono:</label>
                                        <input type="text" className="form-control" value={orden_detail.TELEFONO} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Celular:</label>
                                        <input type="text" className="form-control" value={orden_detail.CELULAR} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Correo electronico:</label>
                                        <input type="text" className="form-control" value={orden_detail.EMAIL} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Equipo </h5>
                                    <hr className="my-0" />
                                </div>
                                <div className="col-12 mt-1">
                                    <div className="mb-2">
                                        <label className="form-label">Equipo:</label>
                                        <input type="text" className="form-control" value={orden_detail.EQUIPO} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">S.N:</label>
                                        <input type="text" className="form-control" value={orden_detail.NO_SERIE} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Sistema Operativo:</label>
                                        <input type="text" className="form-control" value={orden_detail.SIS_OP} disabled />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9'>
                    <div className="card">

                        <div className="card-body">
                            <h5 className="card-title fw-bold text-dark mb-2 mt-1">Refacciones para el equipo </h5>
                            <button type="button" className="btn btn-outline-dark " data-bs-toggle="modal" data-bs-target="#newPart">Solicitar refacción</button>
                            <hr />
                            <div className="row mb-3">
                                <div className="table-responsive mt-1 table-scrollbar-2">
                                    <table className="table table-sm table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col" className='th-sticky'>#</th>
                                                <th scope="col" className='th-sticky'>REFACCIÓN</th>
                                                <th scope="col" className="text-center th-sticky">COSTO ($)</th>
                                                <th scope="col" className="text-center th-sticky">C. CLIENTE. ($)</th>
                                                <th scope="col" className="text-center th-sticky">ESTADO</th>
                                                <th scope="col" className="text-center th-sticky">ACCIONES</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                refacciones.map((item, index) => (
                                                    <tr key={index} className={item.ESTADO == 6 ? 'table-danger' : ''}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.REFACCION}</td>
                                                        <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                                        <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                                        <td className="text-center">

                                                            <select className="form-select" aria-label="Default select example" defaultValue={item.ESTADO} onChange={e => handleChangeaRefaccionOrden(e, item.ID, orden_detail.FOLIO)}>
                                                                {/* <option >Open this select menu</option> */}
                                                                <option value={0}>Solicitada</option>
                                                                <option value={1}>Aprobada</option>
                                                                <option value={2}>Comprada, en espera</option>
                                                                <option value={3}>Llego</option>
                                                                <option value={4}>Disponible</option>
                                                                {/* <option value={5}>Instalada</option> */}
                                                                <option value={6}>Rechazo Cliente</option>
                                                            </select>
                                                        </td>
                                                        <td className="text-center">
                                                            <button type="button" className="btn btn-outline-primary border-0" data-bs-toggle="modal" data-bs-target="#editRefaccionSol"
                                                                onClick={() => handledEdit(item.ID, item)}
                                                            >
                                                                <Edit size='20' />
                                                            </button>
                                                        </td>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={3} className="text-end fw-bold">Subtotal:</td>
                                                <td className="fw-bold text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal_refacciones)}</td>
                                                <td></td>
                                                <td></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-12'>
                                    <div className="text-end">
                                        <h5 className="">Subtotal de Refacciones: <span
                                            className="fw-bold">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(subtotal)}</span> </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
