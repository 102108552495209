import React, { useRef, useState } from 'react';
import { AlertTriangle, Plus, Save, Slash, Trash, Trash2 } from 'react-feather';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { startUploadEvidencias } from '../../../../app/store/ordenes/thunks';

export const Evidencias = ({ folio }) => {
    const [Fotografias, setFotografias] = useState([]);
    const dispatch = useDispatch();

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const closeModalEdit = useRef(null);
    const addFotografia = () => {
        // setEvidencias([]);
        let filesA = document.getElementById("fotografia_up").files;
        setFotografias([...Fotografias, ...filesA]);
        Toast.fire({
            icon: 'success',
            title: 'Fotografia del equipo agregada al Listado'
        })
        // let evidencia = Fotografias.filter(item => item.name == filesA[0].name);


        // // console.log(filesA[0].name);
        // if (evidencia.length === 0) {
        //     Toast.fire({
        //         icon: 'success',
        //         title: 'Fotografia del equipo agregada al Listado'
        //     })
        //     setFotografias([...Fotografias, ...filesA]);
        // }
        // else {
        //     Toast.fire({
        //         icon: 'error',
        //         title: 'Fotografia del Equipo repetida, favor de verificar fotografía'
        //     })
        // }
    }

    const removeFotografia = (name) => {
        Toast.fire({
            icon: 'success',
            title: 'Fotografia Eliminada de la lista'
        });
        let arrayANTERIOR = [...Fotografias];
        // console.log(arrayANTERIOR);
        let result = arrayANTERIOR.filter(item => item.name != name);
        setFotografias(result);
    }

    const removeAllFotografias = () => {
        if (Fotografias.length != 0) {
            setFotografias([]);
            Toast.fire({
                icon: 'success',
                title: 'Se ha eliminado la lista de fotografias'
            });
        }
    }

    const saveEvidencias = () => {

        if (Fotografias.length != 0) {
            dispatch(startUploadEvidencias(folio, Fotografias))
            closeModalEdit.current.click();
        }
        else {
            Toast.fire({
                icon: 'error',
                title: 'Debe agregar por lo menos una fotografia como evidencia'
            })
        }
    }


    const cancel = () => {
        setFotografias([]);
    }

    return (
        <div className="modal fade" id="registerEvidencias" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Registro Evidencias, Folio: {folio}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex">
                            <label htmlFor="formFile" className="form-label"></label>
                            <input className="form-control form-control" id="fotografia_up" type="file" accept="image/*" />
                            <button className="btn btn-outline-primary no border-0 " type="button" onClick={() => addFotografia()}>
                                <Plus size='20' />
                            </button>
                        </div>

                        <div className="mt-2 table-responsive table-scrollbar-5">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Archivo</th>
                                        <th scope="col" className="text-center">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {
                                        Fotografias.length === 0 ?
                                            <>
                                                <tr >
                                                    <td scope="row" colSpan={3} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han agregado fotografias, como Evidencias</td>
                                                </tr>
                                            </> :
                                            Fotografias.map((item, index) => (
                                                <tr key={index}>
                                                    <td scope="row">{index + 1}</td>
                                                    <td>{item.name}</td>
                                                    {/* <td className="text-end">{item.COSTO_SERV}</td> */}
                                                    <td className="text-center">
                                                        <button className="btn btn-outline-danger border-0 " type="button" onClick={() => removeFotografia(item.name)}>
                                                            <Trash2 size='20' />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <button type="button" className="btn btn-outline-danger mt-3 mb-4" onClick={removeAllFotografias} >Eliminar todos: ({Fotografias.length})</button>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={cancel}>
                            <Slash size='20' /> Cancelar
                        </button>
                        <button type="button" ref={closeModalEdit} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                        <button type="button" className="btn btn-outline-primary" onClick={saveEvidencias}>
                            <Save size='20' /> Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
