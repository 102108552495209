import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStore, loadingSale, notprocessAction, setError, setSale_Edit, setSales } from "./slice";
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const clearStoreSales = () => {
    return (dispatch, getState) => {
        dispatch(clearStore());
    }
}


export const startloadSales = () => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingSale());
        await URL_API.get(`Ventas/getVentasPedido`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setSales({ sale: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadSale = (id) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingSale());
        await URL_API.get(`Ventas/getVentaPedido/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setSale_Edit({ sale_edit: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
        });
    }
}


export const startloadSaleReport = (id) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingSale());
        await URL_API.get(`Ventas/getVenta/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setSale_Edit({ sale_edit: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
        });
    }
}



export const startChangeState = (folio, estado) => {

    return async (dispatch, getState) => {
        // dispatch(clearStore());
        // dispatch(loadingOrdenes());
        await URL_API.put(`Ventas/putChangeStateVenta/${folio}/${estado}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadSale(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Pedido actualizada'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar el Pedido',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar  el estado del Pedido',
            });
            dispatch(setError())
        });
    }
}

export const startCloseSale = (folio, capturante, total, formaPago, comentarios, pago, Pagos) => {
    let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
    let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
    if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;


    let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;



    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingSale());
        await URL_API.put(`Ventas/putVentaPedidoClose/${folio}`, {
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro,
            usuario_reg: capturante,
            total: total,
            formaPago: formaPago
        }).then(function (response) {

            console.log(response.data);
            if (response.data.status == 200) {


                if (Pagos.length != 0) {

                    URL_API.post(`Ingresos/postIngresoDetail/${folio}`, {
                        ingresos: Pagos
                    }).then(function (response) {

                        console.log(response.data);
                        if (response.data.status == 200) {
                            console.log(response.data);
                        }
                        if (response.data.status == 404) {
                            console.log(response.data);
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });

                } else {
                    URL_API.post(`Ingresos/postIngresoDetailInd/${folio}`, {
                        detalle: response.data.folio,
                        forma_pago: formaPago,
                        comentarios: comentarios,
                        monto: pago
                    }).then(function (response) {

                        console.log(response.data);
                        if (response.data.status == 200) {
                            console.log(response.data);
                        }
                        if (response.data.status == 404) {

                        }

                    }).catch(function (error) {
                    });

                }


                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Orden de Venta con el Folio: ' + folio + " cerrada con Exito",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('Ejecutar Reload...');
                        dispatch(notprocessAction());

                        window.open(`./assets/reports/ordenVenta.html?folio=${folio}&opcion=1`, '_blank');
                        window.location.reload();
                    }
                });
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible cerrar la Orden de Servicio',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible cerrar la Orden de Servicio',
            });
            dispatch(setError())
        });
    }









}