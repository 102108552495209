import { createSlice } from '@reduxjs/toolkit';

export const ordenesSlice = createSlice({
    name: 'ordenesSlice',
    initialState: {
        status_ordenes: 'EN_ESPERA',
        msg:'',
        ordenes: [],
        orden_edit:[],
        indicadores: [],
        refacciones: [],
        refaccion_edit: [],
        refaccion_edit_det: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {

        loadingOrdenes: (state, action) => {
            state.isLoading = true;
            state.status_ordenes = 'CARGANDO_ORDENES';
        },
        notloadingOrdenes: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = 'EN_ESPERA';
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status_ordenes = 'PROCESANDO_PETICION';
        },
        savingOrden: (state, action) => {//Se ejecuta mientras se realiza la operacion en el backend
            state.isLoading = true;
            state.status_ordenes = 'GUARDANDO_ORDEN';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = 'EN_ESPERA';
        },
        setOrdenes: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = "ORDENES_CARGADAS";
            state.ordenes = action.payload.ordenes;
        },
        setRefacciones: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = "REFACCIONES_CARGADAS";
            state.refacciones = action.payload.refacciones;
        },
        setRefacion_Edit: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = "REFACCION_ENCONTRADA";
            state.refaccion_edit = action.payload.refaccion_edit;
        },
        setRefacion_Edit_det: (state, action) => {
            state.isLoading = false;
            // state.status_ordenes = "REFACCION_ENCONTRADA";
            state.refaccion_edit_det = action.payload.refaccion_edit_det;
        },
        setOrden_Edit: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = "ORDEN_ENCONTRADA";
            state.orden_edit = action.payload.orden_edit;
        },
        setIndicadores: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = "INDICADORES_CARGADAS";
            state.indicadores = action.payload.indicadores;
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.status_ordenes = "ORDENES_NO_ENCONTRADOS";
            state.msg = action.payload.msg;
            state.ordenes = [];
            state.orden_edit = [];
            state.indicadores = [];
            state.refacciones = [];
            // state.facturacion_edit = [];
        },
        clearStore: (state, action) => {
            state.status_ordenes = 'EN_ESPERA';
            state.ordenes = [];
            state.orden_edit = [];
            state.indicadores = [];
            state.refacciones = [];
            // state.facturacion_edit = [];
        }
    }
});

export const { loadingOrdenes, notloadingOrdenes, processAction, notprocessAction,setRefacion_Edit_det,setRefacciones,setRefacion_Edit, setError,setIndicadores,setOrden_Edit, setOrdenes, clearStore,savingOrden } = ordenesSlice.actions;