import React from 'react'
import { useSelector } from 'react-redux';

export const ViewFacturacion = () => {


    const { facturacion_edit } = useSelector(state => state.facturacion);
    return (
        <div className="modal fade" id="viewFacturacion" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Datos facturación</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                                <div className="mb-3">
                                    <label className="form-label">RFC:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off'  disabled value={facturacion_edit.RFC}/>

                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                <div className="mb-3">
                                    <label className="form-label">Razon Social:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off'  disabled value={facturacion_edit.RAZON_SOCIAL}/>

                                </div>

                            </div>

                            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                                <div className="mb-3">
                                    <label className="form-label">Sociedad Mercantil:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off' disabled  value={facturacion_edit.SOCIEDAD_MERCANTIL} />

                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                <div className="mb-3">
                                    <label className="form-label">Regimen Fiscal:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off' disabled  value={facturacion_edit.REGIMEN_FISCAL} />

                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                <div className="mb-3">
                                    <label className="form-label">Correo electronico:</label>
                                    <input type="email" className="form-control" aria-label="First name" autoComplete='off' disabled value={facturacion_edit.CORREO} />

                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="mb-3">
                                    <label className="form-label">Telefono:</label>
                                    <input type="number" className="form-control" aria-label="First name" disabled value={facturacion_edit.TELEFONO} />

                                </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                <label className="form-label ">Dirección:</label>
                                <textarea className="form-control mb-2" rows="4" autoComplete='off' disabled value={facturacion_edit.DIRECCION} ></textarea>


                            </div>

                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="mb-3">
                                    <label className="form-label">CP:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off' disabled value={facturacion_edit.COD_POSTAL} />

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
