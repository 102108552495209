import React, { useEffect } from 'react'
import { ArrowLeft, Printer, Save, Search, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom'
import { clearStoreCorts, startRegistroCorte, startloadCorte } from '../../../../app/store/finanzas/thunk';
import { useDispatch, useSelector } from 'react-redux';
// import { CorteDetail } from './CorteDetail';
import Swal from 'sweetalert2';
export const NewCorteALT = () => {

    const { indicadores, corte, descuentos, status, isLoading } = useSelector(state => state.finanzas);
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ model: 'all' });
    const searchOrdenes = (data) => {
        dispatch(startloadCorte(data.fechaInicial, data.fechaFinal))
    }

    let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes;
    let today = f.getFullYear() + "-" + mes + "-" + dia;

    const cancelCort = () => {
        window.location.reload();
    }



    const saveCort = () => {

        let dateI = corte.FECHAI; let dateF = corte.FECHAF;
        let acumulado = document.getElementById('total_ingresos_acumulado').value;
        let dec_efec = document.getElementById('declarado_efectivo').value;
        let dec_trans = document.getElementById('declarado_trans').value;
        let dec_tdd = document.getElementById('declarado_tdd').value;
        let dec_tdc = document.getElementById('declarado_tdc').value;
        let dec_total = document.getElementById('declarado_total').value;
        let fondo = document.getElementById('total_fondo').value;
        let dif = document.getElementById('total_diferencia').value;
        dispatch(startRegistroCorte(usuario, dateI, dateF, acumulado, dec_efec, dec_trans, dec_tdd, dec_tdc, dec_total, fondo, dif));
    }



    if (status == 'CORTE_CARGADO') {

        let totales = corte.TOTALES; let servicios = corte.SERVICIOS; let descuentos = corte.DESCUENTOS; let anticipos = corte.ANTICIPOS; let ventas = corte.VENTAS; let ingresosVarios = corte.INGRESOS_VARIOS
        let dateI = corte.FECHAI.split("-").reverse().join("-"); let dateF = corte.FECHAF.split("-").reverse().join("-");

        let total_ingresos = 0;
        totales.forEach(item => {
            total_ingresos = total_ingresos + Number(item.TOTAL_INGRESO);
        });

        let Serv_total_efectivo = 0; let Serv_total_transferencias = 0; let Serv_tdc = 0; let Serv_tdd = 0;
        servicios.forEach(it => {
            if (it.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') Serv_total_transferencias = Serv_total_transferencias + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'EFECTIVO') Serv_total_efectivo = Serv_total_efectivo + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') Serv_tdd = Serv_tdd + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') Serv_tdc = Serv_tdc + Number(it.TOTAL);
        });

        let total_S_trans = (Serv_total_transferencias).toFixed(2); let total_S_efectivo = (Serv_total_efectivo).toFixed(2); let total_S_tdd = (Serv_tdd).toFixed(2); let total_S_tdc = (Serv_tdc).toFixed(2);

        let total_descuentos = 0;
        descuentos.forEach(items => {
            total_descuentos = total_descuentos + Number(items.TOTAL)
        });

        let total_S_descuentos = (total_descuentos).toFixed(2);

        let Ant_total_efectivo = 0; let Ant_total_transferencias = 0; let Ant_tdc = 0; let Ant_tdd = 0;
        anticipos.forEach(it => {
            if (it.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') Ant_total_transferencias = Ant_total_transferencias + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'EFECTIVO') Ant_total_efectivo = Ant_total_efectivo + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') Ant_tdd = Ant_tdd + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') Ant_tdc = Ant_tdc + Number(it.TOTAL);
        });

        let total_Ant_trans = (Ant_total_transferencias).toFixed(2); let total_Ant_efectivo = (Ant_total_efectivo).toFixed(2); let total_Ant_tdd = (Ant_tdd).toFixed(2); let total_Ant_tdc = (Ant_tdc).toFixed(2);



        let Vent_total_efectivo = 0; let Vent_total_transferencias = 0; let Vent_tdc = 0; let Vent_tdd = 0;
        ventas.forEach(it => {
            if (it.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') Vent_total_transferencias = Vent_total_transferencias + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'EFECTIVO') Vent_total_efectivo = Vent_total_efectivo + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') Vent_tdd = Vent_tdd + Number(it.TOTAL);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') Vent_tdc = Vent_tdc + Number(it.TOTAL);
        });

        let total_Vent_trans = (Vent_total_transferencias).toFixed(2);
        let total_Vent_efectivo = (Vent_total_efectivo).toFixed(2);
        let total_Vent_tdd = (Vent_tdd).toFixed(2);
        let total_Vent_tdc = (Vent_tdc).toFixed(2);




        let ingresosV_total_efectivo = 0; let ingresosV_total_transferencias = 0; let ingresosV_tdc = 0; let ingresosV_tdd = 0;
        ingresosVarios.forEach(it => {
            if (it.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') ingresosV_total_transferencias = ingresosV_total_transferencias + Number(it.MONTO);
            if (it.FORMA_PAGO_REGISTRADA == 'EFECTIVO') ingresosV_total_efectivo = ingresosV_total_efectivo + Number(it.MONTO);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') ingresosV_tdd = ingresosV_tdd + Number(it.MONTO);
            if (it.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') ingresosV_tdc = ingresosV_tdc + Number(it.MONTO);
        });

        // let total_t_ingVario_Efectivo = (Serv_total_efectivo + Ant_total_efectivo + Vent_total_efectivo).toFixed(2);

        let total_ingVario_trans = (ingresosV_total_transferencias).toFixed(2);
        let total_ingVario_efectivo = (ingresosV_total_efectivo).toFixed(2);
        let total_ingVario_tdd = (ingresosV_tdd).toFixed(2);
        let total_ingVario_tdc = (ingresosV_tdc).toFixed(2);

        let totales_t_Efectivo = (Serv_total_efectivo + Ant_total_efectivo + Vent_total_efectivo + ingresosV_total_efectivo).toFixed(2);
        let totales_t_Trans = (Serv_total_transferencias + Ant_total_transferencias + Vent_total_transferencias + ingresosV_total_transferencias).toFixed(2);
        let totales_t_TDD = (Serv_tdd + Ant_tdd + Vent_tdd + ingresosV_tdd).toFixed(2);
        let totales_t_TDC = (Serv_tdc + Ant_tdc + Vent_tdc + ingresosV_tdc).toFixed(2);

        let total_ingreso_a = Number(totales_t_Efectivo) + Number(totales_t_Trans) + Number(totales_t_TDD) + Number(totales_t_TDC);
        // console.log(total_ingreso_a);


        let declarado_efectivo_t = 0; let declarado_trans_t = 0; let declarado_tdd_t = 0; let declarado_tdc_t = 0; let total_declarado_total = 0;

        const calculated = () => {
            declarado_efectivo_t = document.getElementById('declarado_efectivo').value;
            declarado_trans_t = document.getElementById('declarado_trans').value;
            declarado_tdd_t = document.getElementById('declarado_tdd').value;
            declarado_tdc_t = document.getElementById('declarado_tdc').value;

            total_declarado_total = Number(declarado_efectivo_t) + Number(declarado_trans_t) + Number(declarado_tdd_t) + Number(declarado_tdc_t);
            document.getElementById("declarado_total").value = (total_declarado_total).toFixed(2);

        }

        let diferenciaTotal = 0;
        const calculatedDiferencia = () => {


            console.log(document.getElementById('total_ingresos_acumulado').value);
            console.log(document.getElementById('total_fondo').value);
            // 

            let tot = Number(document.getElementById('total_ingresos_acumulado').value) + Number(document.getElementById('total_fondo').value);
            console.log(tot);

            diferenciaTotal = Number(document.getElementById('declarado_total').value) - tot;
            document.getElementById("total_diferencia").value = (diferenciaTotal).toFixed(2);
        }



        const cleanCort = () => {
            dispatch(clearStoreCorts())
        }

        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <Link to={`/dashboard`} className='breadcrumb-item btn-link' onClick={cleanCort}>
                                    <ArrowLeft size='20' /> Atras
                                </Link>
                                <li className="breadcrumb-item active" aria-current="page">Corte diario</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header border-bottom">
                                <h4 className=" text-black fw-bold mb-0">CORTE DIARIO</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(searchOrdenes)} className='border-bottom'>
                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <div className="mb-3">
                                                <label htmlFor="formGroupExampleInput" className="form-label">Fecha Inicio:</label>
                                                <input type='date' className='form-control' defaultValue={today} {...register("fechaInicial")} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <div className="mb-3">
                                                <label htmlFor="formGroupExampleInput" className="form-label">Fecha Final:</label>
                                                <input type='date' className='form-control' defaultValue={today} {...register("fechaFinal")} />
                                            </div>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                                            <div className="mb-3 mt-4">
                                                <button type="submit" className="btn btn-outline-secondary">
                                                    <Search size="20" /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                                        <div className="card mt-3 border">
                                            <div className="card-header border-bottom  ">

                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <h5 className="card-title mb-0 fw-bold text-black">Ventas</h5>
                                                    </div>
                                                    {/* <div className='col-6 text-end'>
                                                        <button type="button" className="btn btn-outline-dark btn-sm border-0" onClick={generateRep}>
                                                            <Printer size={18} />
                                                        </button>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="card-body table-responsive">
                                                <table className="table table-sm table-hover">
                                                    <tbody>
                                                        {
                                                            totales?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">{item.FORMA_PAGO_REGISTRADA}</th>
                                                                    <td className='text-end'>
                                                                        {
                                                                            (item.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') ?
                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL_INGRESO)}</> : <></>
                                                                        }
                                                                        {
                                                                            (item.FORMA_PAGO_REGISTRADA == 'EFECTIVO') ?
                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL_INGRESO)}</> : <></>
                                                                        }
                                                                        {
                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') ?
                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL_INGRESO)}</> : <></>
                                                                        }
                                                                        {
                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') ?
                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL_INGRESO)}</> : <></>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <td className='fw-bold text-end'>TOTAL:</td>
                                                            <td className='fw-bold text-end'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_ingresos)}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9'>
                                        <div className="card border mt-3 ">
                                            <div className="card-header border-bottom">
                                                <h5 className="card-title mb-0 fw-bold text-black">Detalles de las ventas ({dateI} al {dateF})</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className='row'>
                                                    <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                                                        <h4 className=" fw-bold text-dark mb-2">Servicios</h4>
                                                        <div className='table-responsive mt-1 table-scrollbar-6'>
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className='text-center th-sticky'>FOLIO</th>
                                                                        <th scope="col" className='text-center th-sticky'>EFECTIVO</th>
                                                                        <th scope="col" className='text-center th-sticky'>TRANS.</th>
                                                                        <th scope="col" className='text-center th-sticky'>TDD</th>
                                                                        <th scope="col" className='text-center th-sticky'>TDC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        servicios?.length == 0 ?
                                                                            <>
                                                                                <tr>
                                                                                    <td colSpan={5}>No se encontraron servicios</td>
                                                                                </tr>
                                                                            </> :
                                                                            servicios?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <th scope="row" className='text-center'>{item.FOLIO}</th>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'EFECTIVO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                    }
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td className='text-end fw-bold'>TOTAL:</td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_efectivo)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_trans)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_tdd)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_tdc)}
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                                        <h4 className=" fw-bold text-dark mb-2 mt-3">Descuentos</h4>
                                                        <div className='table-responsive mt-1 table-scrollbar-6'>
                                                            <table className="table table-hover table-sm">

                                                                <tbody>
                                                                    {
                                                                        descuentos?.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <th scope="row">{item.APLICADO_A}</th>
                                                                                <td className='text-end'>
                                                                                    {
                                                                                        (item.APLICADO_A == 'ALMACEN') ?
                                                                                            <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <></>
                                                                                    }
                                                                                    {
                                                                                        (item.APLICADO_A == 'SERVICIOS') ?
                                                                                            <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <></>
                                                                                    }
                                                                                    {
                                                                                        (item.APLICADO_A == 'REFACCIONES') ?
                                                                                            <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <></>
                                                                                    }

                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    }

                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td className='text-end fw-bold'>TOTAL:</td>
                                                                        <td className='text-end fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_descuentos)}
                                                                        </td>


                                                                    </tr>
                                                                </tfoot>
                                                            </table>

                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                                                        <h4 className=" fw-bold text-dark mb-2">Anticipos</h4>
                                                        <div className='table-responsive mt-1 table-scrollbar-6'>
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className='text-center th-sticky'>FOLIO</th>
                                                                        <th scope="col" className='text-center th-sticky'>EFECTIVO</th>
                                                                        <th scope="col" className='text-center th-sticky'>TRANS.</th>
                                                                        <th scope="col" className='text-center th-sticky'>TDD</th>
                                                                        <th scope="col" className='text-center th-sticky'>TDC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        anticipos?.length == 0 ?
                                                                            <>
                                                                                <tr>
                                                                                    <td colSpan={5}>No se encontraron servicios</td>
                                                                                </tr>
                                                                            </> :
                                                                            anticipos?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <th scope="row" className='text-center'>{item.FOLIO_ID}</th>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'EFECTIVO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>

                                                                                </tr>
                                                                            ))
                                                                    }

                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td className='text-end fw-bold'>TOTAL:</td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_efectivo)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_trans)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_tdd)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_tdc)}
                                                                        </td>

                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>

                                                    <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8'>
                                                        <h4 className=" fw-bold text-dark mb-2">Ventas</h4>
                                                        <div className='table-responsive mt-1 table-scrollbar-6'>
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className='text-center th-sticky'>FOLIO</th>
                                                                        <th scope="col" className='text-center th-sticky'>EFECTIVO</th>
                                                                        <th scope="col" className='text-center th-sticky'>TRANS.</th>
                                                                        <th scope="col" className='text-center th-sticky'>TDD</th>
                                                                        <th scope="col" className='text-center th-sticky'>TDC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {
                                                                        ventas?.length == 0 ?
                                                                            <>
                                                                                <tr>
                                                                                    <td colSpan={5}>No se encontraron servicios</td>
                                                                                </tr>
                                                                            </> :
                                                                            ventas?.map((item, index) => (
                                                                                <tr key={index}>
                                                                                    <th scope="row" className='text-center'>{item.FOLIO}</th>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'EFECTIVO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TRANSFERENCIA') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE DEBITO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>
                                                                                    <td className='text-center'>
                                                                                        {
                                                                                            (item.FORMA_PAGO_REGISTRADA == 'TARJETA DE CREDITO') ?
                                                                                                <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.TOTAL)}</> : <>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format('0')}</>
                                                                                        }
                                                                                    </td>

                                                                                </tr>
                                                                            ))
                                                                    }

                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td className='text-end fw-bold'>TOTAL:</td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_efectivo)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_trans)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_tdd)}
                                                                        </td>
                                                                        <td className='text-center fw-bold'>
                                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_tdc)}
                                                                        </td>

                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                                                        <h4 className=" fw-bold text-dark mb-2">Totales</h4>
                                                        <div className='table-responsive mt-1 '>
                                                            <table className="table table-hover table-sm">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col" className='text-center'>CONCEPTO</th>
                                                                        <th scope="col" className='text-center'>EFECTIVO</th>
                                                                        <th scope="col" className='text-center'>TRANS.</th>
                                                                        <th scope="col" className='text-center'>TDD</th>
                                                                        <th scope="col" className='text-center'>TDC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* Iterar */}
                                                                    <tr>
                                                                        <th scope="row" className='text-center'>Ventas</th>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_efectivo)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_trans)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_tdd)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Vent_tdc)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" className='text-center'>Servicios</th>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_efectivo)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_trans)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_tdd)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_S_tdc)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" className='text-center'>Anticipos</th>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_efectivo)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_trans)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_tdd)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_Ant_tdc)}</td>

                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" className='text-center'>Ingresos diversos</th>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_ingVario_efectivo)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_ingVario_trans)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_ingVario_tdd)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(total_ingVario_tdc)}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" className='text-end'>TOTALES:</th>
                                                                        <td className='text-center'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totales_t_Efectivo)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totales_t_Trans)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totales_t_TDD)}</td>
                                                                        <td className='text-center'> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totales_t_TDC)}</td>
                                                                        <td className='text-center'>
                                                                            <input type='text' className='form-control text-end' defaultValue={(total_ingreso_a).toFixed(2) || 0} id='total_ingresos_acumulado' disabled style={{ width: '100px' }} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row" className='text-end'>DECLARADO:</th>
                                                                        <td className='text-center'> <input type='text' defaultValue={0} onChange={calculated} id='declarado_efectivo' className='form-control text-end' style={{ width: '100px' }} /></td>
                                                                        <td className='text-center'> <input type='text' defaultValue={0} onChange={calculated} id='declarado_trans' className='form-control text-end' style={{ width: '100px' }} /></td>
                                                                        <td className='text-center'> <input type='text' defaultValue={0} onChange={calculated} id='declarado_tdd' className='form-control text-end' style={{ width: '100px' }} /></td>
                                                                        <td className='text-center'> <input type='text' defaultValue={0} onChange={calculated} id='declarado_tdc' className='form-control text-end' style={{ width: '100px' }} /></td>
                                                                        <td className='text-center'>
                                                                            <input type='text' className='form-control text-end' id='declarado_total' style={{ width: '100px' }} />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>

                                                                        <td colSpan={5} className='text-end fw-bold'> FONDO:</td>
                                                                        <td className='text-center'>
                                                                            <input type='text' className='form-control text-end' id='total_fondo' onChange={calculatedDiferencia} style={{ width: '100px' }} />
                                                                        </td>
                                                                    </tr>
                                                                    {/* <tr>

                                                                        <td colSpan={5} className='text-end fw-bold'> DIFERENCIA:</td>
                                                                        <td className='text-center'>
                                                                            <input type='text' className='form-control text-end'  id='total_diferencia' disabled style={{ width: '100px', display:'none' }} />
                                                                        </td>
                                                                    </tr> */}

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-3'>
                                                    <div>
                                                        <button type="button" className="btn btn-outline-danger me-2" onClick={cancelCort}>
                                                            <Slash size={20} /> <span className='text-left-5'>Cancelar</span>
                                                        </button>
                                                        <button type="button" className="btn btn-outline-primary" onClick={saveCort}>
                                                            <Save size={20} /> <span className='text-left-5'>Guardar</span>
                                                        </button>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='row'>
                    <div className='col-12'>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <Link to={`/dashboard`} className='breadcrumb-item btn-link'>
                                    <ArrowLeft size='20' /> Atras
                                </Link>
                                <li className="breadcrumb-item active" aria-current="page">Corte diario</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header border-bottom">
                                <h4 className=" text-black fw-bold mb-0">CORTE DIARIO</h4>
                            </div>
                            <div className="card-body">
                                <form onSubmit={handleSubmit(searchOrdenes)} className='border-bottom'>
                                    <div className="row ">
                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <div className="mb-3">
                                                <label htmlFor="formGroupExampleInput" className="form-label">Fecha Inicio:</label>
                                                <input type='date' className='form-control'  {...register("fechaInicial")} />
                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                            <div className="mb-3">
                                                <label htmlFor="formGroupExampleInput" className="form-label">Fecha Final:</label>
                                                <input type='date' className='form-control' defaultValue={today} {...register("fechaFinal")} />
                                            </div>
                                        </div>

                                        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                                            <div className="mb-3 mt-4">
                                                <button type="submit" className="btn btn-outline-secondary">
                                                    <Search size="20" /> Buscar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>


                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }











}
