import React, { useEffect } from 'react'
import { AddFacturacion } from '../../customers/modals/AddFacturacion'
import { Edit, Eye, Plus, PlusCircle, RefreshCcw, Repeat, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import { loadDataFactruracion, loadFacturacion, startActFacturacion, startDelFacturacion } from '../../../../app/store/facturacion/thunks';
import { Table_2 } from '../../../utils/Table_2';
import { connectionFiles } from '../../../../app/filesConnection';
import Swal from 'sweetalert2';
import { EditFacturacion } from '../../customers/modals/EditFacturacion';

export const List = () => {

    const { isLoading, status_facturacion, facturacion, dataFacturacion } = useSelector(state => state.facturacion);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadDataFactruracion())
    }, []);

    const refreshData = () => {
        dispatch(loadDataFactruracion())
    }



    const dataU = [...dataFacturacion];
    const columnsType1 = [
        {
            header: 'ID',
            dataIndex: 'ID',
            classHeader: 'text-start',
            classRow: 'text-start fw-bold ',
        },
        {
            header: 'RFC',
            dataIndex: 'RFC',
            classHeader: 'text-start',
            classRow: 'text-start  ',
        },
        {
            header: 'RAZON SOCIAL',
            dataIndex: 'RAZON_SOCIAL',
            classHeader: 'text-start',
            classRow: 'text-start  ',
        },
        {
            header: 'SOCIEDAD',
            dataIndex: 'SOCIEDAD_MERCANTIL',
            classHeader: 'text-start',
            classRow: 'text-start  ',
        },
        {
            header: 'REGIMEN',
            dataIndex: 'REGIMEN_FISCAL',
            classHeader: 'text-start',
            classRow: 'text-start  ',
        },
        {
            header: 'TELEFONO',
            dataIndex: 'TELEFONO',
            classHeader: 'text-center',
            classRow: 'text-center  ',
        },
        {
            header: 'CORREO',
            dataIndex: 'CORREO',
            classHeader: 'text-start',
            classRow: 'text-start  ',
        },
        {
            header: 'DIRECCION',
            dataIndex: 'DIRECCION',
            classHeader: 'text-center',
            classRow: 'text-start  ',
        },
        {
            header: 'CP',
            dataIndex: 'COD_POSTAL',
            classHeader: 'text-center',
            classRow: 'text-center ',
        },
        {
            header: 'CSF',
            dataIndex: 'CSF',
            classHeader: 'text-center',
            classRow: 'text-center  ',
            renderer: (value) => (

                (value == "No registrado") ? value :
                    <a type="button" href={`${connectionFiles['csfURL']}/${value}`} target='_blank' className="btn btn-outline-dark border-0">  <Eye size="20" /></a>
            )
        },
        {
            header: 'ESTADO',
            dataIndex: 'ESTADO',
            classHeader: 'text-center',
            classRow: 'text-center',
            renderer: (value, row) => (

                (value == 1) ?
                    <><span className="status-btn complete-btn"> ACTIVO </span> </>
                    :
                    <> <span className="status-btn pending-btn"> ELIMINADO </span>
                    </>
            ),
        },
        {
            header: 'ACCIONES',
            dataIndex: 'ESTADO',
            classHeader: 'text-center',
            classRow: 'text-center',
            renderer: (value, row) => (
                <div className="btn-group border-0" role="group" aria-label="Basic example">
                    <button type="button" className="btn btn-outline-primary border-0" data-bs-toggle="modal" data-bs-target="#EditFacturacion"
                        onClick={e => handledEditFacturacion(row.ID)}
                    >
                        <Edit size="20" />
                    </button>
                    {
                        row.ESTADO == 1 ? <>
                            <button type="button" className="btn btn-outline-danger border-0"
                                onClick={e => handledDelFacturacion(row.ID)}
                            >
                                <Trash2 size="20" />
                            </button></> :
                            <>
                                <button type="button" className="btn btn-outline-success border-0"
                                    onClick={e => handledActFacturacion(row.ID)}
                                >
                                    <RefreshCcw size="20" />
                                </button>
                            </>
                    }
                </div>
            )
        },

    ];

    const handledEditFacturacion = (e) => {
        dispatch(loadFacturacion(e));
    }

    const handledDelFacturacion = (id) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un Dato de Facturacion, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelFacturacion(id, 0));
            }
        })
    }

    const handledActFacturacion = (id) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR a un Dato de Facturacion, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActFacturacion(id, 0));
            }
        })
    }

    return (
        <div className='row'>

            <AddFacturacion /> <EditFacturacion />


            <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center mt-3 border-bottom">
                <div className="btn-group me-2 mb-2 ">
                    <button type="button" className="btn btn-outline-secondary" onClick={refreshData}>
                        <Repeat size='20' />
                    </button>
                    <button type="button" className="btn btn-outline-primary me-3" data-bs-toggle="modal" data-bs-target="#newFacturacion">
                        <PlusCircle size='20' />  Nuevo Dato facturacion
                    </button>
                </div>
            </div>

            <div className='mt-3'>
                <Table_2 typeTable={1} columns={columnsType1} data={dataU} msg={''} />
            </div>

        </div>
    )
}
