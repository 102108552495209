import React from 'react'
import { AlertOctagon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { clearStoreSearch } from '../../../../app/store/ordenes/thunks';
import { clearStoreSales } from '../../../../app/store/sales/thunks';

export const MDetail = () => {
    const dispatch = useDispatch();
    const { orden_edit, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);
    const { sale_edit } = useSelector(state => state.ventasPedido);

    // console.log(orden_edit);
    // console.log(sale_edit);


    let orden_detail = [];
    let servicios = [];
    let refacciones = [];

    if (orden_edit != undefined) {
        orden_detail = orden_edit?.[0];
        servicios = orden_detail?.SERVICIOS;
        refacciones = orden_detail?.REFACCIONES;
    } else {
        orden_detail = [];
        servicios = [];
        refacciones = [];
    }

    let sale_detail = [];
    let productos = [];
    let productosEsp = [];
    let productosPedido = [];

    if (sale_edit != undefined) {
        sale_detail = sale_edit?.[0];
        productos = sale_detail?.PRODUCTOS_COMPRADOS;
        productosEsp = sale_detail?.PRODUCTOS_COMPRADOS_ESPECIAL;
        productosPedido = sale_detail?.PRODUCTOS_COMPRADOS_PEDIDO;
        // refacciones = orden_detail?.REFACCIONES;
    } else {
        sale_detail = [];
        productos = [];
        productosEsp = [];
        productosPedido = [];
    }


    const closeModal = () => {

        dispatch(clearStoreSearch())
        dispatch(clearStoreSales())
    }







    return (
        <div className="modal fade" id="detailFolio" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Detalles de Orden: {orden_detail?.FOLIO || sale_detail?.FOLIO} </h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">

                        {
                            (sale_edit.length != 0) ? <>

                                <div className="alert alert-dark" role="alert">
                                    <div className='row text-start'>
                                        <span className='fw-bold '>  <AlertOctagon size='20' /> La Orden de venta con el Folio: {sale_detail?.FOLIO}, fue realizada el dia: {sale_detail?.FECHA} a las {sale_detail?.HORA}, con total de compra de: {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(sale_detail?.TOTAL)}, pagado en/con: {sale_detail?.FORMA_PAGO_DESC}

                                        </span>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                                        <h6 className="fw-bold me-2 border-0">Cliente: </h6>
                                        <hr className='my-0' />
                                        <div className="mb-1 mt-1">
                                            <label className="form-label">Datos del cliente </label>
                                            <input type="text" className="form-control" value={sale_detail?.CLIENTE} disabled />
                                        </div>
                                        <div className="mb-1">
                                            <label className="form-label">Telefono:</label>
                                            <input type="text" className="form-control" value={sale_detail?.TELEFONO} disabled />
                                        </div>
                                        <div className="mb-1">
                                            <label className="form-label">Celular:</label>
                                            <input type="text" className="form-control" value={sale_detail?.CELULAR} disabled />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Correo electronico:</label>
                                            <input type="text" className="form-control" value={sale_detail?.EMAIL} disabled />
                                        </div>

                                    </div>

                                    <div className='col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9'>
                                        <h5 className="card-title fw-bold text-dark ">Productos comprados </h5>
                                        <div className="row mb-1">
                                            <div className="table-responsive mt-1 table-scrollbar-reporte">
                                                <table className="table table-sm table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className='th-sticky'>CANT</th>
                                                            <th scope="col" className='th-sticky'>PRODUCTO</th>
                                                            <th scope="col" className="text-center th-sticky">COSTO</th>
                                                            {/* <th scope="col" className="text-center th-sticky">EVIDENCIAS</th> */}
                                                            {/* <th scope="col" className="text-end th-sticky">COSTO</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            productos?.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td scope="row" colSpan={3}> No se han encontrado datos.</td>

                                                                    </tr>
                                                                </> :
                                                                productos?.map((item, index) => (
                                                                    <tr key={index} >
                                                                        <td>{item.CANTIDAD}</td>
                                                                        <td>{item.PRODUCTO}</td>

                                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.IMPORTE)}</td>

                                                                    </tr>
                                                                ))
                                                        }

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <h5 className="card-title fw-bold text-dark  ">Productos comprados (Especial) </h5>
                                        <div className="row mb-1">
                                            <div className="table-responsive mt-1 table-scrollbar-reporte">
                                                <table className="table table-sm table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className='th-sticky'>CANT</th>
                                                            <th scope="col" className='th-sticky'>PRODUCTO</th>
                                                            <th scope="col" className="text-center th-sticky">COSTO</th>
                                                            {/* <th scope="col" className="text-center th-sticky">EVIDENCIAS</th> */}
                                                            {/* <th scope="col" className="text-end th-sticky">COSTO</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            productosEsp?.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td scope="row" colSpan={3}> No se han encontrado datos.</td>

                                                                    </tr>
                                                                </> :
                                                                productosEsp?.map((item, index) => (
                                                                    <tr key={index} >
                                                                        <td>{item.CANTIDAD}</td>
                                                                        <td>{item.NOMBRE} - {item.DESCRIPCION}</td>

                                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.IMPORTE)}</td>

                                                                    </tr>
                                                                ))
                                                        }

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>

                                        <h5 className="card-title fw-bold text-dark  ">Productos comprados (Pedido) </h5>
                                        <div className="row mb-1">
                                            <div className="table-responsive mt-1 table-scrollbar-reporte">
                                                <table className="table table-sm table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className='th-sticky'>CANT</th>
                                                            <th scope="col" className='th-sticky'>PRODUCTO</th>
                                                            <th scope="col" className="text-center th-sticky">COSTO</th>
                                                            {/* <th scope="col" className="text-center th-sticky">EVIDENCIAS</th> */}
                                                            {/* <th scope="col" className="text-end th-sticky">COSTO</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            productosPedido?.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td scope="row" colSpan={3}> No se han encontrado datos.</td>

                                                                    </tr>
                                                                </> :
                                                                productosPedido?.map((item, index) => (
                                                                    <tr key={index} >
                                                                        <td>{item.CANTIDAD}</td>
                                                                        <td>{item.PRODUCTO}</td>

                                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.IMPORTE)}</td>

                                                                    </tr>
                                                                ))
                                                        }

                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </> : <>
                                <div className="alert alert-dark" role="alert">
                                    <div className='row text-start'>
                                        <span className='fw-bold '>  <AlertOctagon size='20' /> La Orden de Servicio con el Folio: {orden_detail?.FOLIO},
                                            {
                                                orden_detail?.ESTADO == 8 ? <> ya fue Concluida por {orden_detail?.EMPLEADO_CIERRA} el dia {orden_detail?.FECHA_CERRADA} a las {orden_detail?.HORA_CERRADA} </> : <></>
                                            }
                                            {
                                                orden_detail?.ESTADO == 9 ? <>  ya fue Cancelada por peticion del Cliente {orden_detail?.NOMBRE} el dia {orden_detail?.FECHA_CERRADA} a las {orden_detail?.HORA_CERRADA}  </> : <></>
                                            }

                                        </span>
                                    </div>
                                </div>
                                <div className='row'>

                                    <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                                        <h6 className="fw-bold me-2 border-0">Cliente: </h6>
                                        <hr className='my-0' />
                                        <div className="mb-1 mt-1">
                                            <label className="form-label">Datos del cliente </label>
                                            <input type="text" className="form-control" value={orden_detail?.NOMBRE} disabled />
                                        </div>
                                        <div className="mb-1">
                                            <label className="form-label">Telefono:</label>
                                            <input type="text" className="form-control" value={orden_detail?.TELEFONO} disabled />
                                        </div>
                                        <div className="mb-1">
                                            <label className="form-label">Celular:</label>
                                            <input type="text" className="form-control" value={orden_detail?.CELULAR} disabled />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Correo electronico:</label>
                                            <input type="text" className="form-control" value={orden_detail?.EMAIL} disabled />
                                        </div>
                                        <h6 className="fw-bold me-2 border-0">Datos del Equipo </h6>
                                        <hr className='my-0' />
                                        <div className="mb-2 mt-1">
                                            <label className="form-label">Equipo:</label>
                                            <input type="text" className="form-control" value={orden_detail?.EQUIPO} disabled />
                                        </div>
                                        <div className="mb-2">
                                            <label className="form-label">Sistema Operativo:</label>
                                            <input type="text" className="form-control" value={orden_detail?.SIS_OP} disabled />
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                        <div className=" mb-2">
                                            <label className="form-label text-black fw-bold">Descripción de la falla(s):</label>
                                            <textarea className="form-control" rows="4" disabled defaultValue={orden_detail?.DESCRIPCION_FALLA}></textarea>
                                        </div>
                                        <div>
                                            <h5 className="card-title fw-bold text-dark mb-2 mt-3">Servicios </h5>
                                            <div className="row mb-3">
                                                <div className="table-responsive mt-1 table-scrollbar-6">
                                                    <table className="table table-sm table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" className='th-sticky'>#</th>
                                                                <th scope="col" className='th-sticky'>SERVICIO</th>
                                                                <th scope="col" className="text-center th-sticky">AUTORIZADO</th>
                                                                <th scope="col" className="text-center th-sticky">REALIZADO</th>
                                                                {/* <th scope="col" className="text-center th-sticky">EVIDENCIAS</th> */}
                                                                {/* <th scope="col" className="text-end th-sticky">COSTO</th> */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                 servicios?.length === 0 ?
                                                                 <>
                                                                     <tr>
                                                                         <td scope="row" colSpan={4}> No se han encontrado datos.</td>
     
                                                                     </tr>
                                                                 </> :
                                                                
                                                                servicios?.map((item, index) => (
                                                                    <tr key={index} className={item.AUTORIZO != 1 ? 'table-warning' : ''}>
                                                                        <td>{index + 1}</td>
                                                                        <td>{item.NOMBRE}</td>
                                                                        <td className="text-center">
                                                                            {
                                                                                item.AUTORIZO == 1 ? <>
                                                                                    <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center" >
                                                                            {
                                                                                item.ESTADO == 1 ? <>
                                                                                    <img src="./assets/images/indicators/check.png" width="28" height="28" /></> : <><img src="./assets/images/indicators/close.png" width="28" height="28" /></>
                                                                            }
                                                                        </td>
                                                                        {/* <td className="text-center">
                                                                    {
                                                                        evidencias.length != 0 ? <><img src="./assets/images/indicators/image.png" width="28" height="28" data-bs-toggle="modal" data-bs-target="#viewEvidencias" /></> : <><img src="./assets/images/indicators/clock.png" width="28" height="28" /></>
                                                                    }
                                                                </td> */}
                                                                        {/* <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td> */}

                                                                    </tr>
                                                                ))
                                                            }

                                                        </tbody>

                                                    </table>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div className='col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
                                        <div className=" mb-2">
                                            <label className="form-label text-dark fw-bold">Diagnostico:</label>
                                            <textarea className="form-control" rows="4" disabled defaultValue={orden_detail?.DIAGNOSTICO}></textarea>
                                        </div>
                                        <div className="row mb-3">
                                        <h5 className="card-title fw-bold text-dark mb-2 mt-3">Refacciones </h5>
                                            <div className="table-responsive mt-1 table-scrollbar-6">
                                                <table className="table table-sm table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className='th-sticky'>#</th>
                                                            <th scope="col" className='th-sticky'>REFACCIÓN</th>
                                                            <th scope="col" className="text-center th-sticky">COSTO ($)</th>
                                                            <th scope="col" className="text-center th-sticky">C. CLIENTE. ($)</th>
                                                            <th scope="col" className="text-center th-sticky">ESTADO</th>
                                                            {/* <th scope="col" className="text-center th-sticky">EVIDENCIAS</th>
                                                    <th scope="col" className="text-center th-sticky">ACCIONES</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                             refacciones?.length === 0 ?
                                                             <>
                                                                 <tr>
                                                                     <td scope="row" colSpan={5}> No se han encontrado datos.</td>
 
                                                                 </tr>
                                                             </> :
                                                            refacciones?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.REFACCION}</td>
                                                                    <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                                                                    <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                                                                    <td className="text-center">

                                                                        <select className="form-select" aria-label="Default select example" defaultValue={item.ESTADO} disabled>
                                                                            {/* <option >Open this select menu</option> */}
                                                                            <option value={0}>Solicitada</option>
                                                                            <option value={1}>Aprobada</option>
                                                                            <option value={2}>Comprada, en espera</option>
                                                                            <option value={3}>Llego</option>
                                                                            <option value={4}>Disponible</option>
                                                                            <option value={5}>Instalada</option>
                                                                            <option value={6}>Rechazo Cliente</option>
                                                                        </select>
                                                                    </td>

                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>

                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div></>
                        }






                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-dark" data-bs-dismiss="modal" onClick={closeModal}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

    )
}
