import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStore, loadingOrdenes, notprocessAction, savingOrden, setError, setIndicadores, setOrden_Edit, setOrdenes, setRefacciones, setRefacion_Edit_det } from "./slice";
import { processAction } from "./slice";
import moment from 'moment/moment';
import { startloadSale } from "../sales/thunks";


const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});


export const startloadOrdenesCliente = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.get(`OrdenesServicio/getOrdenesCliente/${id_cliente}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setOrdenes({ ordenes: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}


export const startloadOrdenesEmpleados = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.get(`OrdenesServicio/getOrdenesEmpleados/${id_cliente}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setOrdenes({ ordenes: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadOrdenesDash = (data) => {

    // let fechaI = moment(data.fechaInicial).format('YYYY/MM/DD');
    // let fechaF = moment(data.fechaFinal).format('YYYY/MM/DD');

    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.post(`OrdenesServicio/postOrdenesDash/`, {
            // fechaInicial: fechaI,
            // fechaFinal: fechaF,
            tecnico: data.tecnico,
            estado: data.estadoOrden,
        }).then(function (response) {
            // console.log(response.data.indicadores);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setOrdenes({ ordenes: listItems }));
                dispatch(setIndicadores({ indicadores: response.data.indicadores[0] }))
            }
            if (response.data.status == 404) {
                console.log(response);

                dispatch(setError())
            }

        }).catch(function (error) {
            console.log();
            // console.log(response);
            dispatch(setError({ msg: error.response.data.msg }))
        });
    }
}

export const startAsignacionOrden = (capturante, empleado, folio) => {
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`OrdenesServicio/putAsignacionOrden/${folio}`, {
            empleado: empleado,
            asignado_por: capturante,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Orden de Servicio asignada con exito'
                })
                // dispatch(loadDataUser(id_emp));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se asigno la Orden de Servicio... Intente de Nuevo'
            })
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }

}

export const startRegistroAnticipo = (capturante, folio, data) => {
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`OrdenesServicio/postRegistroAnticipoOrden/${folio}`, {
            capturo: capturante,
            monto: data.montoAnticipo,
            forma_pago: data.tipo_pago,
            descripcion: data.descripcionAnticipo,
            asignado_por: capturante,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {

            // console.log(response)
            if (response.data.status = 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Anticipo registrado a la Orden de Servicio / Venta por pedido: ' + folio,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('Ejecutar Reload...');
                        dispatch(notprocessAction());



                        if (folio.includes("HDLP")) {
                            dispatch(startloadSale(folio));
                        }
                        window.open(`./assets/reports/compAnticipo.html?folio=${folio}`, '_blank');
                        // window.location.reload();
                    }
                });



            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                // title: 'No se asigno la Orden de Servicio... Intente de Nuevo'
                title: 'No se registro el Anticipo a la Orden de Servicio'
            })



            // dispatch(loadDataUser(id_emp));
            return;
        });

    }

}


//-------------------------



export const startOrderDeparture = (capturante, folio, data, cliente, equipo) => {
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`OrdenesServicio/postOrderDeparture/`, {
            folio: folio,
            empleado: capturante,
            fechaR: fechaRegistro,
            horaR: horaRegistro,
            cliente: cliente,
            equipo: equipo,
            retira: data.nombreRetira,
            observaciones: data.observaciones,

        }).then(function (response) {

            console.log(response)
            if (response.data.status = 200) {

                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Equipo retirado... Orden de Servicio: ' + folio + ', en espera de reingreso del equipo',
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.open(`./assets/reports/ordenSalida.html?folio=${response.data.last_id}`, '_blank');
                        dispatch(startloadOrden(folio));
                        // console.log('Ejecutar Reload...');
                        // dispatch(notprocessAction());
                        // 
                        // window.location.reload();

                    }
                });
            }
        }).catch(function (error) {
            console.log(error)

            return;
        });

    }

}

export const startOrderDepartureBack = (capturante, folio, data, cliente, equipo, idRetiro, fotografias) => {
    return async (dispatch, getState) => {

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        let formData = new FormData();
        for (let i = 0; i < fotografias.length; i++) {
            formData.append('file[]', fotografias[i]);
        }

        await URL_API.put(`OrdenesServicio/putOrderDeparture/`, {
            folio: folio,
            // empleado: capturante,
            fechaI: fechaRegistro,
            horaI: horaRegistro,
            // cliente: cliente,
            // equipo: equipo,
            ingresa: data.nombreRetira,
            observaciones: data.observaciones,
            id: idRetiro

        }).then(function (response) {

            console.log(response)
            if (response.data.status = 200) {

                // let folio = response.data.folio;

                if (fotografias.length != 0) {
                    URL_API.post(`OrdenesServicio/postUploadReingresoEquipo/${folio}/${idRetiro}`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        }
                    }).then(function (response) {
                        // console.log(response)
                        if (response.data.status = 200) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Exito',
                                text: 'Equipo Reingresado... Orden de Servicio: ' + folio + ', puede continuar con la orden de servicio ',
                                allowOutsideClick: false
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    dispatch(startloadOrden(folio));
                                    // console.log('Ejecutar Reload...');
                                    // dispatch(notprocessAction());
                                    // window.open(`./assets/reports/compAnticipo.html?folio=${folio}`, '_blank');
                                    // window.location.reload();

                                }
                            });
                        }
                    }).catch(function (error) {
                        console.log(error)
                        return;
                    });
                }



            }
        }).catch(function (error) {
            console.log(error)

            return;
        });

    }

}













//------------------------------
export const startRegistroOrden = (capturante, idCliente, data, servicios, fotografias) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        let ordenPremium = data.orden_premium == true ? 1 : 0;
        let formData = new FormData();
        for (let i = 0; i < fotografias.length; i++) {
            formData.append('file[]', fotografias[i]);
        }

        dispatch(savingOrden());

        await URL_API.post(`OrdenesServicio/postOrdenServicio`, {
            cliente_id: idCliente,
            equipo_id: data.equipo_id,
            cargador: data.cargador_sino,
            cargador_serie: data.cargador_serie,
            bateria: data.bateria_sino,
            bateria_serie: data.bateria_serie,
            enciende: data.enciende_sino,
            pwd_equipo: data.contrasena,
            servicios: servicios,
            descripcion_falla: data.descripcion_falla,
            usuario_reg: capturante,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro,
            premium: ordenPremium,
            estado: 0,

        }).then(function (response) {

            console.log(response.data);

            let folio = response.data.folio;

            if (fotografias.length != 0) {
                URL_API.post(`OrdenesServicio/postUploadFotosEquipo/${response.data.folio}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }).then(function (response) {
                    // console.log(response)
                    if (response.data.status = 200) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Exito',
                            text: 'Orden de Servicio Registrada, Folio: ' + folio,
                            allowOutsideClick: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // console.log('Ejecutar Reload...');
                                dispatch(notprocessAction());
                                window.open(`./assets/reports/ordenServicio.html?folio=${folio}`, '_blank');
                                window.location.reload();
                            }
                        });
                    }
                }).catch(function (error) {
                    console.log(error)
                    return;
                });
            }

        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Orden de Servicio... Intente de Nuevo'
            })
            return;
        });
    }
}

export const startRegistroDescuentoOrden = (data) => {
    return async (dispatch, getState) => {

        await URL_API.post(`Ingresos/postDescuento`, {

            data: data,
        }).then(function (response) {

            console.log(response);


        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startCloseOrden = (folio, idFacturacion, capturante, recoge, total, iva, subtotal, formaPago, comentarios, pago, Pagos) => {
    let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
    let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
    if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

    let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

    let facturacion = '';
    if (idFacturacion != undefined) {
        facturacion = 1;
    }
    if (idFacturacion == undefined) idFacturacion = '';
    // console.log(folio);
    // console.log(idFacturacion);
    // console.log(facturacion);

    // console.log(capturante);
    // console.log(recoge);
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.put(`OrdenesServicio/putOrdenServicioClose/${folio}`, {
            fecha_cerrada: fechaRegistro,
            hora_cerrada: horaRegistro,
            usuario: capturante,
            recoge: recoge,
            facturacion: facturacion,
            dato_facturacion: idFacturacion,
            iva: iva,
            subtotal: subtotal,
            total: total,
            formaPago: formaPago
        }).then(function (response) {

            console.log(response.data);
            if (response.data.status == 200) {

                // URL_API.post(`Ingresos/postIngresoDetail/${folio}`, {

                // }).then(function (response) {

                //     console.log(response.data);
                //     if (response.data.status == 200) {
                //         console.log(response.data);
                //     }
                //     if (response.data.status == 404) {

                //     }

                // }).catch(function (error) {

                // });


                if (Pagos.length != 0) {

                    URL_API.post(`Ingresos/postIngresoDetail/${folio}`, {
                        ingresos: Pagos
                    }).then(function (response) {

                        console.log(response.data);
                        if (response.data.status == 200) {
                            console.log(response.data);
                        }
                        if (response.data.status == 404) {
                            console.log(response.data);
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });

                } else {
                    URL_API.post(`Ingresos/postIngresoDetailInd/${folio}`, {
                        detalle: response.data.folio,
                        forma_pago: formaPago,
                        comentarios: comentarios,
                        monto: pago
                    }).then(function (response) {

                        console.log(response.data);
                        if (response.data.status == 200) {
                            console.log(response.data);
                        }
                        if (response.data.status == 404) {

                        }

                    }).catch(function (error) {
                    });

                }


                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Orden de Servicio con el Folio: ' + folio + " cerrada con Exito",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('Ejecutar Reload...');
                        dispatch(notprocessAction());

                        window.open(`./assets/reports/ordenEntrega.html?folio=${folio}`, '_blank');
                        window.location.reload();
                    }
                });
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible cerrar la Orden de Servicio',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible cerrar la Orden de Servicio',
            });
            dispatch(setError())
        });
    }
}

export const startloadOrden = (folio) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.get(`OrdenesServicio/getOrdenServicioDetail/${folio}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setOrden_Edit({ orden_edit: listItems }))
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible encontrar la Orden de Servicio que ingresaste. Introduce un Folio diferente...',
                    // footer: '<a href="">Why do I have this issue?</a>'
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible encontrar la Orden de Servicio que ingresaste... Introduce un Folio diferente',
                // footer: '<a href="">Why do I have this issue?</a>'
            });
            dispatch(setError())
        });
    }
}

export const startChangeState = (folio, capturo, tecnicoAsignado, estadoOriginal, estado) => {
    let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
    let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
    if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

    let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;


    console.log(estado);
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.put(`OrdenesServicio/putChangeStateOrden/${folio}`, {
            capturo:capturo,
            tecnico: tecnicoAsignado,
            estadoAnterior:estadoOriginal,
            estado: estado,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Orden de Servicio actualizada'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar  el estado de la Orden de Servicio',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar  el estado de la Orden de Servicio',
            });
            dispatch(setError())
        });
    }
}

export const startUpdDescripFalla = (folio, falla) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());

        await URL_API.put(`OrdenesServicio/putDescripFallaOrden/${folio}`, {
            falla: falla,
        }).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Descrip. Falla para la Orden de Servicio actualizada con exito'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible actualizar la Descrip, de la falla en la Orden de Servicio',
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible actualizar la Descrip, de la falla en la Orden de Servicio',
            });
            dispatch(setError())
        });
    }
}

export const startRegistroDiagnostico = (folio, id_emp, diagnostico) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.put(`OrdenesServicio/putDiagnosticoOrden/${folio}`, {
            tecnico: id_emp,
            diagnostico: diagnostico,
            fecha: fechaRegistro,
            hora: horaRegistro

        }).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Diagnostico para la Orden de Servicio registrada con exito'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible registrar el diagnostico de la Orden de Servicio',
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible registrar el diagnostico de la Orden de Servicio',
            });
            dispatch(setError())
        });
    }
}

export const startUploadEvidencias = (folio, fotografias) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        // let ordenPremium = data.orden_premium == true ? 1 : 0;
        let formData = new FormData();
        for (let i = 0; i < fotografias.length; i++) {
            formData.append('file[]', fotografias[i]);
        }

        dispatch(savingOrden());
        await URL_API.post(`OrdenesServicio/postUploadEvidencia/${folio}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(function (response) {
            console.log(response)
            if (response.data.status = 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Evidencia registrada con exito'
                })
            }
        }).catch(function (error) {
            console.log(error)
            return;
        });

    }
}

export const startChangeServicio = (folio, id, estado) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.put(`OrdenesServicio/putServicioOrdenAct/${folio}/${id}/${estado}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Servicio marcado como completo'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar el estado del Servicio',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar el estado del Servicio',
            });
            dispatch(setError())
        });
    }
}

export const startChangeRefaccion = (folio, id, estado) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.put(`OrdenesServicio/putRefaccionOrdenAct/${folio}/${id}/${estado}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Refaccion marcada como Instalada'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar el estado de la refaccion',
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar el estado de la refaccion',
            });
            dispatch(setError())
        });
    }
}

export const startChangeServicioAutorizado = (folio, id, estado) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.put(`OrdenesServicio/putServicioAutorizadoOrdenAct/${folio}/${id}/${estado}`).then(function (response) {
            if (response.data.status == 200) {

                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Estado de Autorizacion de Servicio Actualizada'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar el estado de Autorizacion de Servicio',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar el estado de Autorizacion de Servicio',
            });
            dispatch(setError())
        });
    }
}


export const changeStockProd = (product, cantidad) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        // dispatch(loadingOrdenes());

        console.log(product);
        console.log(cantidad);
        await URL_API.put(`Almacen/putExistenciaProductoMinus/${product}/${cantidad}`).then(function (response) {
            if (response.data.status == 200) {

                // dispatch(startloadOrden(folio));
                // Toast.fire({
                //     icon: 'success',
                //     title: 'Estado de Autorizacion de Servicio Actualizada'
                // })
            }
            if (response.data.status == 404) {
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Oops...',
                //     text: 'No fue posible Actualizar el estado de Autorizacion de Servicio',

                // });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar el Stock de productos',
            });
            dispatch(setError())
        });
    }
}

export const changeStockProdA = (product, cantidad) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        // dispatch(loadingOrdenes());

        console.log(product);
        console.log(cantidad);
        await URL_API.put(`Almacen/putExistenciaProductoAdd/${product}/${cantidad}`).then(function (response) {
            if (response.data.status == 200) {

                // dispatch(startloadOrden(folio));
                // Toast.fire({
                //     icon: 'success',
                //     title: 'Estado de Autorizacion de Servicio Actualizada'
                // })
            }
            if (response.data.status == 404) {
                // Swal.fire({
                //     icon: 'error',
                //     title: 'Oops...',
                //     text: 'No fue posible Actualizar el estado de Autorizacion de Servicio',

                // });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar el Stock de productos',
            });
            dispatch(setError())
        });
    }
}









export const startAddServiciosOrden = (folio, servicios) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.put(`OrdenesServicio/putAddServiciosOrden/${folio}`, {
            servicios: servicios
        }).then(function (response) {
            console.log(response.data);

            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Registro de Nuevo servicio a Orden Exitosa'
                })
            }



        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Orden de Servicio... Intente de Nuevo'
            })
            return;
        });

    }
}

export const startAddRefacciones = (folio, data, capturante) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        await URL_API.post(`OrdenesServicio/postRefaccion`, {
            folio: folio,
            refaccion: data.refaccion,
            solicitante: capturante,
            fecha_sol: fechaRegistro,
            hora_sol: horaRegistro,
            costo_hdl: data.costoHDL,
            costo_cliente: data.costoCliente,
            url_refaccion: data.enlace
        }).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Refaccion Solicitada con exito'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible solicitar la refaccion... Intente de Nuevo',
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible solicitar la refaccion... Intente de Nuevo',
            });
            dispatch(setError())
        });
    }
}

export const startAddControlCalidad = (folio, data, capturante, tecnico, filesA) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());

        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

        let enciende = data.enciende == true ? 1 : 0;
        let cargador_bat = data.cargador_bat == true ? 1 : 0;
        let sonido = data.sonido == true ? 1 : 0;
        let red = data.red == true ? 1 : 0;
        let ensamblado = data.ensamblado == true ? 1 : 0;
        let teclado = data.teclado == true ? 1 : 0;
        let limpieza = data.limpieza == true ? 1 : 0;
        let retorno = data.retorno == true ? 1 : 0;
        let checklist = data.checklist == true ? 1 : 0;
        let solo_rev = data.solo_rev == true ? 1 : 0;

        console.log(filesA);
        let formData = new FormData();
        if (filesA != "") {
            formData.append('file[]', filesA);
        }


        await URL_API.post(`OrdenesServicio/postAddControlCalidad`, {
            folio_id: folio,
            tecnico: tecnico,
            capturante: capturante,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro,
            enciende: enciende,
            cargador_bat: cargador_bat,
            sonido: sonido,
            red: red,
            ensamblado: ensamblado,
            teclado: teclado,
            limpieza: limpieza,
            retorno: retorno,
            recibo: checklist,
            checklist: checklist,
            solo_rev: solo_rev,

        }).then(function (response) {

            // console.log();

            if (filesA != "") {
                URL_API.post(`OrdenesServicio/postUploadControl/${response.data.id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }).then(function (response) {
                    console.log(response)
                }).catch(function (error) {

                });
            }

            if (response.data.status == 200) {
                dispatch(startloadOrden(folio));
                if (response.data.aprobado == 1) {

                    Swal.fire({
                        icon: 'success',
                        title: 'Exito',
                        text: 'Control de Calidad registrada: Resultados: Equipo listo para ser entregado a Cliente',
                        // footer: '<a href="">Why do I have this issue?</a>'
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Atencion',
                        text: 'Control de Calidad registrada: Resultados: El equipo no puede ser entregado al Cliente, ya que no paso control de calidad',
                        // footer: '<a href="">Why do I have this issue?</a>'
                    });
                }

            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible registrar la Inspeccion de Control de calidad... Intente de Nuevo',
                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible registrar la Inspeccion de Control de Calidad... Intente de Nuevo',
            });
            dispatch(setError())
        });
    }
}

export const startloadRefaccioneOrdenes = () => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.get(`OrdenesServicio/getRefacciones`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setRefacciones({ refacciones: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}



export const loadRefaccionEdit = (data) => {
    return (dispatch, getState) => {
        // console.log(data);
        dispatch(setRefacion_Edit_det({ refaccion_edit_det: data }));
    }
}

export const startEditRefaccion = (data, folio, id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`OrdenesServicio/putRefaccion/${id}`, {
            // nombre: data.nombreCliente,
            // telefono: data.telefonoCliente,
            // celular: data.celularCliente,
            // nacimiento: data.nacimientoCliente,
            // email: data.emailCliente,
            // tipo_cliente: data.tipoCliente,
            // estado: 1,
            refaccion: data.refaccion,
            // solicitante: capturante,
            // fecha_sol: fechaRegistro,
            // hora_sol: horaRegistro,
            costo_hdl: data.costoHDL,
            costo_cliente: data.costoCliente,
            url_refaccion: data.enlace

        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Refaccion actualizada con Exito'
                })
                window.location.reload();
                // dispatch(startloadOrden(folio));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo la refaccion... Intente de Nuevo'
            })

            window.location.reload();
            // dispatch(startloadOrden(folio));
            return;
        });
    }
}








export const clearStoreSearch = () => {
    return (dispatch, getState) => {
        dispatch(clearStore());
    }
}


// Venta Productos
export const startVentaProductos = (capturante, idFacturacion, productos, idCliente, total, iva, subtotal, formaPago, comentarios, pago, Pagos) => {
    let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
    let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
    if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

    let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

    let facturacion = '';
    if (idFacturacion != undefined) {
        facturacion = 1;
    }
    if (idFacturacion == undefined) idFacturacion = '';


    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingOrdenes());
        await URL_API.post(`Ventas/postVenta`, {
            usuario_reg: capturante,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro,
            cliente_id: idCliente,
            iva: iva,
            subtotal: subtotal,
            total: total,
            forma_pago: formaPago,
            facturacion: facturacion,
            dato_facturacion: idFacturacion,
            productos: productos
        }).then(function (response) {

            console.log(response.data);
            if (response.data.status == 200) {


                if (Pagos.length != 0) {

                    URL_API.post(`Ingresos/postIngresoDetail/${response.data.folio}`, {
                        ingresos: Pagos
                    }).then(function (response) {

                        console.log(response.data);
                        if (response.data.status == 200) {
                            console.log(response.data);
                        }
                        if (response.data.status == 404) {
                            console.log(response.data);
                        }

                    }).catch(function (error) {
                        console.log(error);
                    });

                } else {
                    URL_API.post(`Ingresos/postIngresoDetailInd/${response.data.folio}`, {
                        detalle: response.data.folio,
                        forma_pago: formaPago,
                        comentarios: comentarios,
                        monto: pago
                    }).then(function (response) {

                        console.log(response.data);
                        if (response.data.status == 200) {
                            console.log(response.data);
                        }
                        if (response.data.status == 404) {

                        }

                    }).catch(function (error) {
                    });

                }

                Swal.fire({
                    icon: 'success',
                    title: 'Exito',
                    text: 'Orden de Venta generada con el Folio: ' + response.data.folio + " con Exito",
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        // console.log('Ejecutar Reload...');
                        dispatch(notprocessAction());

                        window.open(`./assets/reports/ordenVenta.html?folio=${response.data.folio}&opcion=0`, '_blank');
                        window.location.reload();
                    }
                });
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible cerrar la Orden de Servicio',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible cerrar la Orden de Servicio',
            });
            dispatch(setError())
        });
    }
}


// Registro de detalles de ventas
// export const startRegistroDetailInd = (formaPago,folio,comentarios,monto) => {
//     // let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
//     // let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
//     // if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
//     // let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;

//     return async (dispatch, getState) => {
//         dispatch(clearStore());
//         dispatch(loadingOrdenes());
//         await URL_API.post(`Ingresos/postIngresoDetailInd/${folio}`, {
//             detalle: folio,
//             forma_pago: formaPago,
//             comentarios:comentarios,
//             monto:monto
//         }).then(function (response) {

//             console.log(response.data);
//             if (response.data.status == 200) {

//                 console.log(response.data);

//                 // Swal.fire({
//                 //     icon: 'success',
//                 //     title: 'Exito',
//                 //     text: 'Orden de Venta generada con el Folio: ' +response.data.folio + " con Exito",
//                 //     allowOutsideClick: false
//                 // }).then((result) => {
//                 //     if (result.isConfirmed) {
//                 //         // console.log('Ejecutar Reload...');
//                 //         dispatch(notprocessAction());

//                 //         window.open(`./assets/reports/ordenVenta.html?folio=${response.data.folio}`, '_blank');
//                 //         window.location.reload();
//                 //     }
//                 // });
//             }
//             if (response.data.status == 404) {
//                 // Swal.fire({
//                 //     icon: 'error',
//                 //     title: 'Oops...',
//                 //     text: 'No fue posible cerrar la Orden de Servicio',

//                 // });
//                 dispatch(setError());
//             }

//         }).catch(function (error) {
//             // console.log(error);
//             // Swal.fire({
//             //     icon: 'error',
//             //     title: 'Oops...',
//             //     text: 'No fue posible cerrar la Orden de Servicio',
//             // });
//             dispatch(setError())
//         });
//     }
// }












