import { createSlice } from '@reduxjs/toolkit';

export const FinanzasSlice = createSlice({
    name: 'Finanzas',
    initialState: {
        status: 'EN_ESPERA',
        indicadores: [],
        egresos: [],
        ingresos: [],
        descuentos: [],
        corte: [],
        cortes_reg: [],
        comisiones: [],
        comisiones_reg: [],
        comisiones_rep: [],
        conceptos_ingresos: [],
        conceptos_egresos: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        saveIngreso: (state, action) => {//Se ejecuta mientras se realiza la operacion en el backend
            state.isLoading = false;
            state.status = 'INGRESO_EGRESO_REGISTRADO';
        },
        setConceptos: (state, action) => {
            state.isLoading = false;
            state.status = "CONCEPTOS_INGRESOS";
            state.conceptos_ingresos = action.payload.conceptos_ingresos;
        },
        setEgresos: (state, action) => {
            state.isLoading = false;
            state.status = "EGRESOS_CARGADOS";
            state.egresos = action.payload.egresos;
        },
        setIngresos: (state, action) => {
            state.isLoading = false;
            state.status = "INGRESOS_CARGADOS";
            state.ingresos = action.payload.ingresos;
        },
        setDescuentos: (state, action) => {
            state.isLoading = false;
            state.status = "DESCUENTOS_CARGADOS";
            state.descuentos = action.payload.descuentos;
        },
        setCorte: (state, action) => {
            state.isLoading = false;
            state.status = "CORTE_CARGADO";
            state.corte = action.payload.corte;
        },
        setCortes_reg: (state, action) => {
            state.isLoading = false;
            state.status = "CORTES_CARGADOS";
            state.cortes_reg = action.payload.cortes_reg;
        },
        setComisiones: (state, action) => {
            state.isLoading = false;
            state.status = "COMISIONES_CARGADAS";
            state.comisiones = action.payload.comisiones;
        },
        setComisiones_reg: (state, action) => {
            state.isLoading = false;
            state.status = "COMISIONES_CARGADOS";
            state.comisiones_reg = action.payload.comisiones_reg;
        },
        setComisiones_rep: (state, action) => {
            state.isLoading = false;
            state.status = "COMISIONES_CARGADAS";
            state.comisiones_rep = action.payload.comisiones_rep;
        },
        setConceptosE: (state, action) => {
            state.isLoading = false;
            state.status = "CONCEPTOS_EGRESOS";
            state.conceptos_egresos = action.payload.conceptos_egresos;
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        setIndicadores: (state, action) => {
            state.isLoading = false;
            state.status = "INDICADORES_CARGADOS";
            state.indicadores = action.payload.indicadores;
        },
        setError: (state, action) => {
            state.isLoading = false;
            state.status = "ORDENES_NO_ENCONTRADOS";
            state.ingresos = [];
            state.egresos = [];
            state.indicadores = [];
            state.descuentos = [];
            // state.refacciones = [];
            // state.facturacion_edit = [];
        },

        clearStoreCort: (state, action) => {
            state.status = 'EN_ESPERA';
            state.corte = [];
            state.cortes_reg = [];
        },
        clearStoreComision: (state, action) => {
            state.status = 'EN_ESPERA';
            state.comisiones = [];

        }
    }
});


// Action creators are generated for each case reducer function
export const { processAction,setComisiones_rep, setComisiones_reg,clearStoreComision, notprocessAction, setComisiones, clearStoreCort, setCortes_reg, setCorte, setDescuentos, setConceptos, saveIngreso, setConceptosE, setEgresos, setIngresos, setError, setIndicadores } = FinanzasSlice.actions;