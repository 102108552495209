import { createSlice } from '@reduxjs/toolkit';

export const equiposSlice = createSlice({
    name: 'equiposSlice',
    initialState: {
        status_equipo: 'EN_ESPERA',
        equipos: [],
        equipo_edit: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        loadingEquipos: (state, action) => {
            state.isLoading = true;
            state.status_equipo = 'CARGANDO_EQUIPOS';
        },
        notloadingEquipos: (state, action) => {
            state.isLoading = false;
            state.status_equipo = 'EN_ESPERA';
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status_equipo = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status_equipo = 'EN_ESPERA';
        },
        setEquipos: (state, action) => {
            state.isLoading = false;
            state.status_equipo = "EQUIPOS_CARGADOS";
            state.equipos = action.payload.equipos;
        },
        setEquipo_Edit: (state, action) => {
            state.isLoading = false;
            state.status_equipo = "EQUIPOS_ENCONTRADO";
            state.equipo_edit = action.payload.equipo_edit;
        },
        setError: (state,action)=>{
            state.isLoading = false;
            state.status_equipo = "EQUIPOS_NO_ENCONTRADOS";
            state.equipos = [];
            state.equipo_edit = [];
        },
        clearStore: (state,action)=>{
            state.status_equipo = 'EN_ESPERA';
            state.equipos = [];
            state.equipo_edit = [];
        }
    }
});
export const { loadingEquipos, notloadingEquipos, processAction, notprocessAction, setEquipo_Edit, setEquipos,setError,clearStore } = equiposSlice.actions;