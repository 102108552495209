import { createSlice } from '@reduxjs/toolkit';

export const empleadosSlice = createSlice({
    name: 'empleadosSlice',
    initialState: {
        status: 'EN_ESPERA',
        empleados: [],
        recepcionistas: [],
        empleado_edit: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        loadingEmpleados: (state, action) => {
            state.isLoading = true;
            state.status = 'CARGANDO_EMPLEADOS';
        },
        notloadingEmpleados: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        setEmpleados: (state, action) => {
            state.isLoading = false;
            state.status = "EMPLEADOS_CARGADOS";
            state.empleados = action.payload.empleados;
        },
        setRecepcionistas: (state, action) => {
            state.isLoading = false;
            state.status = "RECEPCIONISTAS_CARGADOS";
            state.recepcionistas = action.payload.recepcionistas;
        },
        setEmpleado_Edit: (state, action) => {
            state.isLoading = false;
            state.status = "EMPLEADO_ENCONTRADO";
            state.empleado_edit = action.payload.empleado_edit;
        },
        clearStore: (state,action)=>{
            state.empleados = [];
            state.empleado_edit = [];
        },
        setError: (state,action)=>{
            state.isLoading = false;
            state.status = "EMPLEADOS_CARGADOS";
            state.empleados = [];
            state.empleado_edit = [];
        },
    }
});


// Action creators are generated for each case reducer function
export const { loadingEmpleados,setRecepcionistas, notloadingEmpleados, processAction, notprocessAction, setEmpleados, setEmpleado_Edit,clearStore,setError } = empleadosSlice.actions;