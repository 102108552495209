import React, { useEffect, useMemo, useState } from 'react'
import { ArrowLeft, Edit, Edit2, Eye, Plus, PlusSquare, RefreshCcw, Save, Search, Slash, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { loadSolicitudDetail, searchProductos, startRegistroSolicitudProducto, startloadProductos, startloadSolicitudProductos, startloadSolicitudProductosEstado } from '../../../../app/store/catalagos/thunks';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { EditSolicitud } from './EditSolicitud';
import { Table } from '../components/Table';



export const SolProduct = () => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const dispatch = useDispatch();
    const { productosAlm, isLoading, solicitudesProd, status } = useSelector(state => state.catalogos);
    const { usuario, nombre, rol, puesto, avatar } = useSelector(state => state.usuario);
    const [Productos, setProductos] = useState([]);

    const handleAddProducto = (e) => {
        let producto = Productos.filter(item => item.ID == e);
        if (producto.length === 0) {
            const result = productosAlm.filter(item => item.ID == e)[0];
            let servicioProd = Productos.filter(item => item.producto_id == result.ID)

            if (servicioProd == 0) {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto Agregado a la lista'
                });

                let pSol = {
                    "nombre": result.NOMBRE,
                    "modelo": result.MODELO,
                    "producto_id": result.ID,
                    "estado": result.ESTADO,
                    "sol": result.EXISTENCIA_MAX - result.STOCK,
                }

                setProductos([...Productos, pSol]);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Ese producto ya fue agregado al listado'
                });
            }
        }
    }

    const changeValue = (e, i) => {
        let add = Number(e.target.value);
        let nuewArray = Productos.map(item => {
            if (item.producto_id === i) {
                return { ...item, sol: add };
            }
            return item;
        });

        setProductos(nuewArray);
    }

    const handleRemoveProducto = (e) => {
        let arrayANTERIOR = [...Productos];
        let result = arrayANTERIOR.filter(item => item.producto_id != e);
        setProductos(result);
    }

    const handleRemoveAllProductos = (e) => {
        setProductos([]);
    }

    const handleSaveSolicitud = () => {
        if (Productos.length > 0) {
            Swal.fire({
                title: 'Atención',
                text: "Está por CREAR una Solicitud de compra de Productos, ¿Desea de continuar con esta acción?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3b7ddd',
                cancelButtonColor: '#dc3545',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(startRegistroSolicitudProducto(usuario, Productos));
                    setProductos([]);
                    // console.log(Productos);
                }
            })
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Debe agregar un producto al listado'
            })
        }
    }

    const showNuevaOrden = () => {
        document.getElementById('nuewOrden').style.display = 'block';
    }

    const cancel = () => {
        window.location.reload();
    }

    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            textSearch: "",
            param: "1"
        }
    });

    const handledSearch = (data) => {
        dispatch(searchProductos(data));
    }
    const handledsearchGeneral = () => {
        reset();
        dispatch(startloadProductos());
    }

    const handledDetailOrden = (id) => {
        // console.log(id);
        dispatch(loadSolicitudDetail(id))
    }


    const searchSolEstado = (e) => {
        if (e.target.value == "TODOS") {
            dispatch(startloadSolicitudProductos())
        } else {
            dispatch(startloadSolicitudProductosEstado(e.target.value))
        }

    }

    if (status == 'Solicitud_encontrada') {
        return (
            <EditSolicitud />
        )
    }

    const headersTabla = [
        { label: 'SKU', className: 'text-start' },
        { label: 'NOMBRE', className: 'text-start' },
        { label: 'MODELO', className: 'text-start' },
        { label: 'EXISTENCIA', className: 'text-center' },
        { label: 'EXIS. MIN.', className: 'text-end' },
        { label: 'EXIS. MAX.', className: 'text-end' },
        { label: 'ESTADO.', className: 'text-end' },
        { label: 'ESTADO', className: 'text-end' }
    ];

    const dataTabla = productosAlm;

    return (
        <>


            {
                (rol == "Recepcionista") ?
                    <>
                        <div className='row'>
                            <div className='col-12'>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <Link to={`/dashboard`} className='breadcrumb-item btn-link'>
                                            <ArrowLeft size='20' /> Atras
                                        </Link>
                                        <li className="breadcrumb-item active" aria-current="page">Solicitud de Productos</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>

                    </> : <>
                        <div className='row'>
                            <div className='col-12'>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <Link to={`/warehouse`} className='breadcrumb-item btn-link'>
                                            <ArrowLeft size='20' /> Atras
                                        </Link>
                                        <li className="breadcrumb-item active" aria-current="page">Solicitud de Productos</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </>
            }


            <div className='row'>

                <h1 className="h4 mb-3 fw-bold">Solicitar productos</h1>

                {/* Ocultar en recepcion */}
                {
                    ((rol != "Recepcionista") ?
                        <>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                                <div className="card">
                                    <div className="card-body">

                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                                            <h5 className="card-title mb-2">Listado de Ordenes</h5>
                                            {/* <button type="button" className="btn btn-outline-primary mb-2" onClick={() => showNuevaOrden()} >Generar Orden de Compra</button> */}
                                        </div>

                                        <div className="row mt-2">
                                            <div className='col-3'>
                                                <div className="mb-3">
                                                    <label htmlFor="formGroupExampleInput" className="form-label">Estado:</label>
                                                    <select className="form-select " onChange={e => searchSolEstado(e)}>
                                                        <option value='TODOS'>Todos los estados </option>
                                                        <option value={1}>En Espera</option>
                                                        <option value={2}>Aprobado</option>
                                                        <option value={3}>Terminada</option>
                                                        <option value={4}>Cancelada</option>

                                                    </select>
                                                </div>
                                            </div>

                                            <div className="table-responsive table-scrollbar-6">
                                                <table className="table table-hover table-sm">
                                                    <thead>
                                                        <tr className='th-sticky'>
                                                            <th scope="col">FOLIO</th>
                                                            <th scope="col">CAPTURO</th>
                                                            <th scope="col" className="text-center">FECHA SOL.</th>
                                                            <th scope="col" className="text-center">HORA SOL.</th>
                                                            <th scope="col" className="text-center">ESTADO</th>
                                                            <th scope="col" className="text-center">ACCIONES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            solicitudesProd.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td scope="row" colSpan={6}> No se han encontrado datos.</td>
                                                                    </tr>
                                                                </> :
                                                                solicitudesProd.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td scope="row">{item.ID}</td>
                                                                        <td>{item.CAPTURO} </td>
                                                                        <td className="text-center">{item.FECHA}</td>
                                                                        <td className="text-center">{item.HORA} hrs.</td>

                                                                        <td className="text-center">
                                                                            {item.ESTADO == 1 ? <> <span className="status-btn revision-btn"> ESPERA </span>  </> : <> </>}
                                                                            {item.ESTADO == 2 ? <> <span className="status-btn esperacom-btn"> APROBADO </span>  </> : <> </>}
                                                                            {item.ESTADO == 3 ? <> <span className="status-btn complete-btn"> TERMINADA </span>  </> : <> </>}
                                                                            {item.ESTADO == 4 ? <> <span className="status-btn pending-btn"> CANCELADO </span>  </> : <> </>}
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <button type="button" className="btn btn-outline-primary border-0" onClick={() => handledDetailOrden(item.ID)} >
                                                                                <i className="align-middle" data-feather="edit"></i>
                                                                                <Eye size='20' /> / <Edit size='20' />
                                                                            </button>

                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </> : <></>)
                }

                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                    <div className='card'>
                        <div className="card-body">
                            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-0">
                                <h5 className="card-title text-black mb-2">Nueva solicitud</h5>
                                {/* <button type="button" className="btn btn-outline-primary mb-2" onClick={() => showNuevaOrden()} >Generar Orden de Compra</button> */}
                            </div>
                            <Table headers={headersTabla} data={dataTabla} Productos={Productos} setProductos={setProductos} handleAddProducto={handleAddProducto} handledsearchGeneral={handledsearchGeneral} />
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                    <div className="card">
                        <div className="card-header border-bottom">
                            <h5 className="card-title text-dark mb-2">Listado de Productos Solicitados</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-scrollbar-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">SKU</th>
                                            <th scope="col">NOMBRE</th>
                                            <th scope="col">MODELO</th>
                                            <th scope="col">SOLICITAR</th>
                                            <th scope="col" className='text-center'>ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Productos.length === 0 ?
                                                <>
                                                    <tr>
                                                        <td scope="row" colSpan={5}> No se han agregado productos.</td>
                                                    </tr>
                                                </> :
                                                Productos.map((item, index) => (
                                                    <tr key={index} >
                                                        <td scope="row">{item.producto_id}</td>
                                                        <td className="text-start">{item.nombre}</td>
                                                        <td className="text-start">{item.modelo}</td>
                                                        <td className="text-center">
                                                            <input type="number" className="form-control" id="" placeholder="" defaultValue={item.sol} min={0} onChange={e => changeValue(e, item.producto_id)}></input>
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                item.estado == 1 ? <><button type="button" className="btn btn-outline-danger" onClick={() => handleRemoveProducto(item.producto_id)}><Trash2 size='20' /></button> </> : <> </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className='mb-2 mt-3 text-end'>
                                <button type="button" className="btn btn-outline-danger me-2" onClick={e => handleRemoveAllProductos(e)}>Eliminar listado ({Productos.length})</button>
                                <button type="button" className="btn btn-outline-dark me-2" onClick={() => cancel()}> <Slash size='20' /> Cancelar</button>
                                <button type="button" className="btn btn-outline-primary" onClick={() => handleSaveSolicitud()}> <Save size='20' /> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )


}


