import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { startloadAccounts } from '../../../../app/store/reportes/thunks';
import { startloadSaleReport } from '../../../../app/store/sales/thunks';
import { startloadOrden } from '../../../../app/store/ordenes/thunks';
import { RiFileExcel2Line } from 'react-icons/ri';
import { MDetail2 } from '../modals/MDetail2';
import * as XLSX from 'xlsx/xlsx.mjs';
import { DollarSign } from 'react-feather';

export const Accounts = () => {
    const dispatch = useDispatch();
    const { isLoading, status_report, accountsReceivable } = useSelector(state => state.reportes);

    useEffect(() => {
        dispatch(startloadAccounts())
    }, []);

    // console.log(accountsReceivable);

    const loadFolio = (folio) => {

        if (folio.includes("HDLV")) {
            dispatch(startloadSaleReport(folio))
        } else {
            dispatch(startloadOrden(folio));
        }

    }

    const exportToXLS = (data) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `Sumario_Ordenes_Servicio.xlsx`);
    }

    let totalRefaccion = 0; let totalServicio = 0; let totalAlmacenamiento = 0; let totalCobrar = 0;

    accountsReceivable?.forEach(item => {
        // console.log(item);

        totalRefaccion = totalRefaccion + Number(item.COSTO_REFACCION);
        totalServicio = totalServicio + Number(item.COSTO_SERVICIO);
        totalAlmacenamiento = totalAlmacenamiento + Number(item.COSTO_ALMACENAMIENTO);
        totalCobrar = totalCobrar + Number(item.BALANCE);

    });





    return (
        <div>

            {/* <MDetail2 /> */}
            <h2 className="h4 mb-3 fw-bold">Cuentas por cobrar</h2>

            <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
                {
                    (isLoading) ?
                        <>
                            <div className='div_center'>
                                <div className='text-center'>
                                    <div className="spinner"></div>
                                </div>
                                <div className='text-center text-left-15'>
                                    <span className='fw-bold'>Cargando</span>
                                </div>
                                <div className='text-center text-left-15'>
                                    <div className="spinner"></div>
                                </div>
                            </div>
                        </> :
                        <>


                            <div className='row'>
                                <div className='col-3'>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col mt-0">
                                                    <h5 className="card-title text-dark">Total por cobrar</h5>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="stat text-primary">
                                                        <DollarSign size='20' />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-center'>
                                                <h1 className="mt-1 "> {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalCobrar)}</h1>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex justify-content-between flex-md-nowrap align-items-center pt-3 pb-1 mb-1 border-bottom'>

                                <div></div>
                                <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold" onClick={() => exportToXLS(accountsReceivable)}>
                                    <RiFileExcel2Line size={25} /> <span className='text-left-5 align-middle'> Exportar </span>
                                </button>

                            </div>


                            <div className="table-responsive my-0 table-scrollbar_esp_reporte mt-3">



                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-center th-sticky">#</th>
                                            <th scope="col" className="text-center th-sticky">FOLIO</th>
                                            {/* <th scope="col" className="text-start th-sticky">COSTO HDL</th> */}
                                            <th scope="col" className="text-end th-sticky">C.REFACCION</th>
                                            <th scope="col" className="text-end th-sticky">C.SERVICIO</th>
                                            <th scope="col" className="text-end th-sticky">C.ALMACEN</th>
                                            <th scope="col" className="text-end th-sticky">ANTICIPOS</th>
                                            <th scope="col" className="text-end th-sticky">POR COBRAR</th>

                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {
                                            accountsReceivable?.length == 0 ?
                                                <>
                                                    <tr>
                                                        <td colSpan={8}>{status_report}</td>
                                                    </tr>
                                                </> :
                                                accountsReceivable?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td className="text-center">
                                                            <button type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#detailFolio" onClick={() => loadFolio(item.FOLIO)}>{item.FOLIO}</button>
                                                        </td>
                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_REFACCION)}</td>
                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_SERVICIO)}</td>
                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_ALMACENAMIENTO)}</td>
                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.ANTICIPOS)}</td>
                                                        <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.BALANCE)}</td>

                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2} className="text-end fw-bold">Totales</td>
                                            <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalRefaccion)}</td>
                                            <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalServicio)}</td>
                                            <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalAlmacenamiento)}</td>
                                            {/* <td className="text-end">22</td> */}
                                            <td className="text-end"></td>
                                            <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(totalCobrar)}</td>
                                        </tr>
                                    </tfoot>

                                </table>



                            </div>
                        </>
                }
            </div>


        </div>
    )
}
