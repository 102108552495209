import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStore, loadingEmpleados, processAction, setEmpleado_Edit, setEmpleados, setError, setRecepcionistas } from "./slice";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const startRegistroEmpleado = (data, capturante) => {
    return async (dispatch, getState) => {
        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

        let usuarioCap = capturante; let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;
        let usuarioSis = data.usuario; let usuarioPWD = data.password;
        let nombreEmp = data.nombreEmpleado; let rfcEmp = data.rfcEmpleado; let emailEmp = data.emailEmpleado; let telEmp = data.telEmpleado;
        let celEmp = data.celEmpleado; let rolEmp = data.rolEmpleado; let puestoEmp = data.puestoEmpleado;


        let letter = data.nombreEmpleado.charAt(0).toUpperCase();
        let avatarNuevo = letter + '.png';

        await URL_API.post(`Accesos/postAcceso`, {
            user: usuarioSis,
            email: emailEmp,
            pwd: usuarioPWD,
            usuario_reg: usuarioCap,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro,
            estado: 1,
        }).then(function (response) {
            if (response.data.status = 200) {
                console.log(response);
                console.log();

                URL_API.post(`Empleados/postEmpleado`, {
                    avatar: avatarNuevo,
                    nombre: nombreEmp,
                    rfc: rfcEmp,
                    telefono: telEmp,
                    celular: celEmp,
                    email: emailEmp,
                    puesto_id: puestoEmp,
                    rol_id: rolEmp,
                    acceso_id: response.data.last_id,
                    usuario_reg: usuarioCap,
                    fecha_reg: fechaRegistro,
                    hora_reg: horaRegistro,
                    estado: 1

                }).then(function (response) {
                    if (response.data.status = 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Empleado registrado con exito'
                        })
                        dispatch(startloadEmpleados());
                    }
                }).catch(function (error) {
                    console.log(error)
                    Toast.fire({
                        icon: 'error',
                        title: 'No se registro al Empleado... Intente de Nuevo'
                    })
                    dispatch(startloadEmpleados());
                    return;
                });
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro al Empleado... Intente de Nuevo'
            })
            dispatch(startloadEmpleados());
            return;
        });
        window.location.reload();

    }

}

export const startEditEmpleado = (data, id_emp, id_acceso) => {

    return async (dispatch, getState) => {
        let letter = data.nombreEmpleado.charAt(0).toUpperCase();
        let avatarNuevo = letter + '.png';

        let usuarioSis = data.usuario;
        let usuarioPWD = data.password;
        let nombreEmp = data.nombreEmpleado;
        let rfcEmp = data.rfcEmpleado;
        let emailEmp = data.emailEmpleado;
        let telEmp = data.telEmpleado;
        let celEmp = data.celEmpleado;
        let rolEmp = data.rolEmpleado;
        let puestoEmp = data.puestoEmpleado;

        console.log(usuarioPWD);


        if (usuarioPWD == "") {
            console.log('SOLO EMPLEADO');

            await URL_API.put(`Empleados/putEmpleado/${id_emp}`, {
                avatar: avatarNuevo,
                nombre: nombreEmp,
                rfc: rfcEmp,
                telefono: telEmp,
                celular: celEmp,
                email: emailEmp,
                puesto_id: puestoEmp,
                rol_id: rolEmp,
                estado: data.estadoEmpleado

            }).then(function (response) {
                if (response.data.status = 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Empleado actualizado con exito'
                    })
                    dispatch(startloadEmpleados());
                }
            }).catch(function (error) {
                console.log(error)
                Toast.fire({
                    icon: 'error',
                    title: 'No se actualizo al empleado... Intente de Nuevo'
                })
                dispatch(startloadEmpleados());
                return;
            });

            
        } else {
            await URL_API.put(`Accesos/putPWD/${id_acceso}`, {
                email: emailEmp,
                pwd: usuarioPWD,
                user: usuarioSis,
                estado: data.estadoEmpleado,
            }).then(function (response) {
                if (response.data.status = 200) {
                    // console.log(response);

                    URL_API.put(`Empleados/putEmpleado/${id_emp}`, {
                        avatar: avatarNuevo,
                        nombre: nombreEmp,
                        rfc: rfcEmp,
                        telefono: telEmp,
                        celular: celEmp,
                        email: emailEmp,
                        puesto_id: puestoEmp,
                        rol_id: rolEmp,


                        estado: data.estadoEmpleado

                    }).then(function (response) {
                        if (response.data.status = 200) {
                            Toast.fire({
                                icon: 'success',
                                title: 'Empleado actualizado con exito'
                            })
                            dispatch(startloadEmpleados());
                        }
                    }).catch(function (error) {
                        console.log(error)
                        Toast.fire({
                            icon: 'error',
                            title: 'No se actualizo al empleado... Intente de Nuevo'
                        })
                        dispatch(startloadEmpleados());
                        return;
                    });
                }
            }).catch(function (error) {
                console.log(error)
                Toast.fire({
                    icon: 'error',
                    title: 'No se registro al Empleado... Intente de Nuevo'
                })
                dispatch(startloadEmpleados());
                return;
            });
        }


    }

}

export const startloadEmpleados = () => {
    return async (dispatch, getState) => {
        dispatch(loadingEmpleados());

        await URL_API.get(`Empleados/getEmpleados`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setEmpleados({ empleados: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError());
        });
    }
}


export const startloadRecepcionistas = () => {
    return async (dispatch, getState) => {
        dispatch(loadingEmpleados());

        await URL_API.get(`Empleados/getRecepcionistas`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setRecepcionistas({ recepcionistas: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError());
        });
    }
}


export const loadEmpleado = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.get(`Empleados/getEmpleado/${id}`).then(function (response) {
            // console.log(response.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setEmpleado_Edit({ empleado_edit: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelEmp = (id_emp, id_acceso) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Empleados/putEmpleadoDel/${id_emp}`).then(function (response) {
            if (response.data.status == 200) {

                dispatch(startloadEmpleados());
            }
        }).catch(function (error) {
            console.log(error);
        });

        await URL_API.put(`Accesos/putAccesoDel/${id_acceso}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Empleado Desactivado con exito'
                })
                // dispatch(startloadEmpleados());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActEmp = (id_emp, id_acceso) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Empleados/putEmpleadoAct/${id_emp}`).then(function (response) {
            if (response.data.status == 200) {

                dispatch(startloadEmpleados());
            }
        }).catch(function (error) {
            console.log(error);
        });

        await URL_API.put(`Accesos/putAccesoAct/${id_acceso}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Empleado Activado con exito'
                })
                // dispatch(startloadEmpleados());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const searchEmpleados = (data) => {
    return async (dispatch, getState) => {
        dispatch(clearStore())
        dispatch(loadingEmpleados());

        await URL_API.get(`Empleados/getEmpleadosSearch/${data.param}/${data.textSearch}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setEmpleados({ empleados: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }
        }).catch(function (error) {
            dispatch(setError())
        });
    }
}