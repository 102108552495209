import React from 'react'
import { Dash_Admin } from './panels/Dash_Admin'
import { Dash_Tecnico } from './panels/Dash_Tecnico'
import { Dash_Recep } from './panels/Dash_Recep'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const Dashboard = () => {


  const { usuario, nombre, puesto, avatar, rol } = useSelector(state => state.usuario);
  return (
    <>
      {(rol == "Administrador") ? <><Dash_Admin  /></> : <></>}
      {(rol == "Tecnico") ? <><Dash_Tecnico /></> : <></>}
      {(rol == "Recepcionista") ? <><Dash_Recep  /></> : <></>}


    </>
  )
}


