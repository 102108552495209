import React from 'react'

export const SendEmail = () => {

    const launchEmail = () => {
        console.log('Dispatch send Email function');
    }
    return (

        <div className="modal fade" id="modalRecoverypwd" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header border-0">
                        <h4 className="modal-title text-black fw-bold" id="exampleModalLabel">Recuperación de Contraseña</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form>
                            <p className="text-justify">
                                Acude con algún Administrador del sistema para llevar a cabo el restablecimiento de tu contraseña.
                                Recuerda deberás cambiarla una vez que hayas accedido a tu cuenta, en el menú <b>Mi perfil</b>, por una de tu elección.
                            </p>
                            <div className="text-center">
                                <button type="button" className="btn btn-outline-primary w-75" data-bs-dismiss="modal">Ok. Entendido</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    )
}
