import React from 'react'
import { AlertTriangle, ArrowLeft, Edit, Plus, RefreshCcw, Trash2 } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loadCategoriaAlmEdit, loadMarcaAlmEdit, loadProveedoresAlmEdit, startActCategoriaAlm, startActMarcasAlm, startActProveedoresAlm, startDelCategoriaAlm, startDelMarcasAlm, startDelProveedoresAlm, startRegistroCat, startRegistroMarcaAlm, startRegistroProv } from '../../../../app/store/catalagos/thunks';
import { useForm } from 'react-hook-form';
import { EditCategoriaAlm } from '../modals/EditCategoriaAlm';
import { EditMarcasAlm } from '../modals/EditMarcasAlm';
import { EditProveedoresAlm } from '../modals/EditProveedoresAlm';


export const Settings = () => {


    const { categoriasAlm, marcasAlm, proveedoresAlm } = useSelector(state => state.catalogos);
    const { usuario, nombre, rol, puesto, avatar } = useSelector(state => state.usuario);
    const dispatch = useDispatch();

    const { register: registerCat, handleSubmit: handleSubmitCat, resetField: resetFieldCat, formState: { errors: errorsCat } } = useForm({ model: 'all' });
    const { register: registerMarc, handleSubmit: handleSubmitMarc, resetField: resetFieldMarc, formState: { errors: errorsMarc } } = useForm({ model: 'all' });
    const { register: registerProv, handleSubmit: handleSubmitProv, resetField: resetFieldProv, formState: { errors: errorsProv } } = useForm({ model: 'all' });


    const handleSaveMarc = (data) => {
        // console.log(data);
        let marca = data.nombreMarca; let usuario_cap = usuario;
        dispatch(startRegistroMarcaAlm(marca, usuario_cap));
        resetFieldMarc("nombreMarca");
    }

    const handledDelMarca = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una Marca de Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelMarcasAlm(e));
            }
        })
    }

    const handledActMarca = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Marca de Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActMarcasAlm(e));
            }
        })
    }


    const handleSaveCat = (data) => {
        // console.log(data);
        let marca = data.nombreClasificacion; let usuario_cap = usuario;
        dispatch(startRegistroCat(marca, usuario_cap));
        resetFieldCat("nombreClasificacion");
    }

    const handledDelCat = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una Categoria de Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelCategoriaAlm(e));
            }
        })
    }

    const handledActCat = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Categoria de Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActCategoriaAlm(e));
            }
        })
    }


    const handleSaveProv = (data) => {
        // console.log(data);
        let marca = data.nombreProv; let usuario_cap = usuario;
        dispatch(startRegistroProv(marca, usuario_cap));
        resetFieldProv("nombreProv");
    }

    const handledDelProv = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR a un Proveedor de Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelProveedoresAlm(e));
            }
        })
    }

    const handledActProv = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR a un Proveedor de Producto, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActProveedoresAlm(e));
            }
        })
    }


    const handledEditCategoria = (e) => {
        dispatch(loadCategoriaAlmEdit(e));
    }


    const handledMarcaCategoria = (e) => {
        dispatch(loadMarcaAlmEdit(e));
    }

    const handledEditProveedores = (e) => {
        dispatch(loadProveedoresAlmEdit(e));
    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <Link to={`/warehouse`} className='breadcrumb-item btn-link'>
                                <ArrowLeft size='20' /> Atras
                            </Link>
                            <li className="breadcrumb-item active" aria-current="page">Catalogos</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='row'>


                {/* Categorias */}

                <EditCategoriaAlm />
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card">
                        <div className="card-body">
                            {/* <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}> */}
                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Categorias: </h5>
                                </div>
                                <hr className="my-0 mb-3" />
                                <form onSubmit={handleSubmitCat(handleSaveCat)}>
                                    <div className='row'>
                                        <div className='col-9'>

                                            <input className="form-control me-2" type="text" autoComplete='off' {...registerCat('nombreClasificacion', { required: 'Escribe el Nombre de la Nueva Categoria de Productos', minLength: { value: 3, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 3 caracters' } })} />
                                            {
                                                errorsCat.nombreClasificacion &&
                                                <div className='text-left-5 mt-1 '>
                                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errorsCat.nombreClasificacion.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-1'>
                                            <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive p-2 mt-2 table-scrollbar-8">
                                    <table className="table table-striped table-hover table-sm">
                                        <thead className='th-sticky'>
                                            <tr>
                                                <th scope="col" className='th-sticky'>#</th>
                                                <th scope="col" className="th-sticky text-start">NOMBRE</th>
                                                <th scope="col" className="th-sticky text-center">ESTADO</th>
                                                <th scope="col" className="th-sticky text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                categoriasAlm.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                    categoriasAlm.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{item.ID}</td>
                                                            <td className="text-start">{item.NOMBRE}</td>
                                                            {/* <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.VALOR)}</td> */}
                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editCategoriaAlm" onClick={e => handledEditCategoria(item.ID)}>
                                                                    <Edit size="20" />
                                                                </button>
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCat(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCat(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Marcas */}
                <EditMarcasAlm />
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card">
                        <div className="card-body">
                            {/* <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}> */}
                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Marcas: </h5>
                                </div>
                                <hr className="my-0 mb-3" />
                                <form onSubmit={handleSubmitMarc(handleSaveMarc)}>
                                    <div className='row'>
                                        <div className='col-9'>
                                            <input className="form-control me-2" type="text" autoComplete='off' {...registerMarc('nombreMarca', { required: 'Escribe el Nombre de la Nueva Marca', minLength: { value: 2, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 2 caracters' } })} />
                                            {
                                                errorsMarc.nombreMarca &&
                                                <div className='text-left-5 mt-1 '>
                                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errorsMarc.nombreMarca.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-1'>
                                            <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive p-2 mt-2 table-scrollbar-8">
                                    <table className="table table-striped table-hover table-sm">
                                        <thead className='th-sticky'>
                                            <tr>
                                                <th scope="col" className='th-sticky'>#</th>
                                                <th scope="col" className="th-sticky text-start">NOMBRE</th>
                                                <th scope="col" className="th-sticky text-center">ESTADO</th>
                                                <th scope="col" className="th-sticky text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                marcasAlm.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                    marcasAlm.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{item.ID}</td>
                                                            <td className="text-start">{item.NOMBRE}</td>
                                                            {/* <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.VALOR)}</td> */}
                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editMarcaAlm" onClick={e => handledMarcaCategoria(item.ID)}>
                                                                    <Edit size="20" />
                                                                </button>
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelMarca(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActMarca(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Proveedores */}
                <EditProveedoresAlm />
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="card">
                        <div className="card-body">
                            {/* <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}> */}
                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Proveedores: </h5>
                                </div>
                                <hr className="my-0 mb-3" />
                                <form onSubmit={handleSubmitProv(handleSaveProv)}>
                                    <div className='row'>
                                        <div className='col-9'>

                                            <input className="form-control me-2" type="text" autoComplete='off' {...registerProv('nombreProv', { required: 'Escribe el Nombre del nuevo Proveedor de Productos', minLength: { value: 3, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 3 caracters' } })} />
                                            {
                                                errorsProv.nombreProv &&
                                                <div className='text-left-5 mt-1 '>
                                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errorsProv.nombreProv.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-1'>
                                            <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                        </div>
                                    </div>
                                </form>


                                <div className="table-responsive p-2 mt-2 table-scrollbar-8">
                                    <table className="table table-striped table-hover table-sm">
                                        <thead className='th-sticky'>
                                            <tr>
                                                <th scope="col" className='th-sticky'>#</th>
                                                <th scope="col" className="th-sticky text-start">NOMBRE</th>
                                                <th scope="col" className="th-sticky text-center">ESTADO</th>
                                                <th scope="col" className="th-sticky text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                proveedoresAlm.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                    proveedoresAlm.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{item.ID}</td>
                                                            <td className="text-start">{item.NOMBRE}</td>
                                                            {/* <td className="text-center">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.VALOR)}</td> */}
                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editProveedoresAlm" onClick={e => handledEditProveedores(item.ID)}>
                                                                    <Edit size="20" />
                                                                </button>
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelProv(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActProv(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
