import React from 'react'
import { AlertTriangle, Save, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startRegistroIngreso, startloadIndicadores, startloadIngresos } from '../../../../app/store/finanzas/thunk';
import { useEffect } from 'react';
import { useRef } from 'react';

export const NewIngreso = () => {

    const { cat_ingresos, isLoading, tipos_pagos } = useSelector(state => state.catalogos);
    const { status } = useSelector(state => state.finanzas);
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const dispatch = useDispatch();

    const closeModal = useRef(null);


    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            montoIngreso: "",
            conceptoIngreso: "",
            descripcionIngreso: "",
            detalleIngreso: ""

        }
    });

    const clearForm = () => {
        reset();
    }
    useEffect(() => {
        if (status == 'INGRESO_EGRESO_REGISTRADO') {
            reset();
            dispatch(startloadIngresos())
            closeModal.current.click();
            
        }
    }, [status])




    const handledSaveIngreso = (data) => {
        dispatch(startRegistroIngreso(data, usuario));
    }

    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    return (
        <div className="modal fade" id="newIngreso" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="exampleModalLabel">Nuevo Ingreso</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <form onSubmit={handleSubmit(handledSaveIngreso)}>
                        <div className="modal-body">
                            <div className='row'>
                                <div className="col-8">
                                    <label className="form-label ">Concepto:</label>
                                    <select className="form-select" {...register('conceptoIngreso', { required: 'Debe seleccione concepto' })}>
                                        <option value="">Seleccione un concepto</option>
                                        {
                                            cat_ingresos.map((item, index) => (
                                                item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                    : <option key={index} style={{ display: "none" }} ></option>
                                            ))
                                        }
                                    </select>
                                    {
                                        errors.conceptoIngreso &&
                                        <div className='text-left-5 mt-2'>
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.conceptoIngreso.message}</small></span>
                                        </div>
                                    }
                                </div>
                                <div className="col-4">
                                    <div className="mb-3">
                                        <label className="form-label">Monto:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' min={1} onKeyDown={solo_num}{...register("montoIngreso", { required: 'Ingresa el Monto' })} />
                                        {
                                            errors.montoIngreso &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.montoIngreso.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='mb-3'>
                                        <label className="form-label ">Metodo de Ingreso:</label>
                                        <select className="form-select" {...register('tipoPago', { required: 'Debe seleccionar el Metodo de pago del ingreso' })}>
                                            <option value="">Seleccione un metodo de pago</option>
                                            {
                                                tipos_pagos.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.tipoPago &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.tipoPago.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Descripcion:</label>
                                        <textarea className="form-control" rows="4" {...register("descripcionIngreso", { required: 'Ingresa el Monto' })} ></textarea>
                                        {
                                            errors.descripcionIngreso &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionIngreso.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Observaciones adicionales:</label>
                                        <textarea className="form-control" rows="4"  {...register("detalleIngreso")} ></textarea>
                                        {/* <input type="text" className="form-control" aria-label="First name" autoComplete='off' /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size='20' /> Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary">
                                <Save size='20' /> Guardar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
