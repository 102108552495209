import React from 'react'
import { ArrowLeft, Box, Edit, Edit2, Eye, Package, RefreshCcw, Search, Sliders, Trash2 } from 'react-feather'
import { NewProduct } from './modals/NewProduct'
import { Link, useParams } from 'react-router-dom';
import { Settings } from './panels/Settings';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCategoriasAlm, loadMarcasAlm, loadProducto, loadProveedoresAlm, searchProductos, startActProductos, startDelProductos, startloadProductos, startloadSolicitudProductos } from '../../../app/store/catalagos/thunks';
import Swal from 'sweetalert2';
import { useForm } from 'react-hook-form';
import { EditProduct } from './panels/EditProduct';
import { SolProduct } from './panels/SolProduct';

export const Warehouse = () => {
  const dispatch = useDispatch();
  const { categoriasAlm, marcasAlm, proveedoresAlm, productosAlm, productosAlmEdit, isLoading } = useSelector(state => state.catalogos);

  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
      param: "1"
    }
  });



  const handledSearch = (data) => {
    dispatch(searchProductos(data));
  }


  const handledsearchGeneral = () => {
    reset();
    dispatch(startloadProductos());
  }


  useEffect(() => {
    dispatch(startloadSolicitudProductos())
    dispatch(loadCategoriasAlm());
    dispatch(startloadProductos());
    dispatch(loadMarcasAlm());
    dispatch(loadProveedoresAlm());
  }, []);

  const handledEditProduct = (e) => {
    dispatch(loadProducto(e));
  }




  let paramsURL = useParams();

  console.log();

  // if (isLoading) {
  //   return (
  //     <>
  //       <div className='div_center'>
  //         <div className='text-center'>
  //           <div className="spinner"></div>
  //         </div>
  //         <div className='text-center text-left-15'>
  //           <span className='fw-bold'>Cargando</span>
  //         </div>
  //         <div className='text-center text-left-15'>
  //           <div className="spinner"></div>
  //         </div>
  //       </div>
  //     </>
  //   )
  // }

  if (productosAlmEdit.length != 0) {
    return (
      <>
        <EditProduct />
      </>
    )
  }


  if (!paramsURL.settings && !paramsURL.solProducts) {
    const handledDelCat = (e) => {
      Swal.fire({
        title: 'Atención',
        text: "Está por DESACTIVAR un Producto, ¿Desea de continuar con esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b7ddd',
        cancelButtonColor: '#dc3545',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(startDelProductos(e));
        }
      })
    }




    
    const handledActCat = (e) => {
      Swal.fire({
        title: 'Atención',
        text: "Está por REACTIVAR un Producto, ¿Desea de continuar con esta acción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3b7ddd',
        cancelButtonColor: '#dc3545',
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(startActProductos(e));
        }
      })
    }
    // console.log(productosAlm);
    return (
      <>
        <NewProduct />
        <h1 className="h4 mb-3 fw-bold">Inventario de Productos</h1>
        {/* <button type="button" className="btn btn-outline-dark"><Sliders size='20' /> Catalogos</button> */}
        <div className="row">

          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                  <form onSubmit={handleSubmit(handledSearch)}>
                    {/*  */}
                    <div className='mb-2'>
                      <label className="mb-1 text-dark">Buscar por:</label>
                      <div className="d-flex">
                        <select className="form-select w-75 me-1" {...register("param")}  >
                          {/* */}
                          <option value='1'>NOMBRE</option>
                          <option value='2'>DESCRIPCION</option>
                          <option value='3'>CODIGO BARRA</option>
                        </select>

                        <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                        {/*   */}
                        <button className="btn btn-outline-secondary" type="submit"  >
                          <Search size="20" />
                        </button>
                        <button className="btn btn-outline-success ms-2" type="button" onClick={handledsearchGeneral} >
                          {/* */}
                          <RefreshCcw size="20" />
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="btn-group me-2 mb-2 mt-4">
                    <button type="button" className="btn btn-outline-primary me-2" data-bs-toggle="modal" data-bs-target="#newProduct">Agregar Nuevo</button>
                    {/* <button type="button" className="btn btn-outline-secondary me-2" ></button> */}

                    <Link to={`/warehouse/solProducts`} className='btn btn-outline-dark me-2'>
                      <Package size='20' /> Solicitar Prod.
                    </Link>


                    <Link to={`/warehouse/settings`} className='btn btn-outline-dark'>
                      <Sliders size='20' /> Catalogos
                    </Link>

                  </div>
                </div>

                <div className="table-responsive p-2 mt-2 table-scrollbar-3">
                  <table className="table table-striped table-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">SKU</th>
                        <th scope="col" className="text-start">NOMBRE</th>
                        <th scope="col" className="text-start">DESCRIPCIÓN</th>
                        <th scope="col" className="text-start">MODELO</th>
                        <th scope="col" className="text-center">EXISTENCIA</th>
                        <th scope="col" className="text-center">EXIS. MIN.</th>
                        <th scope="col" className="text-center">EXIS. MAX.</th>
                        <th scope="col" className="text-end">COSTO</th>
                        <th scope="col" className="text-end">COSTO PUB.</th>
                        <th scope="col" className="text-center">ESTADO</th>
                        <th scope="col" className="text-center">ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        productosAlm.length === 0 ?
                          <>
                            <tr>
                              <td scope="row" colSpan={10}> No se han encontrado datos.</td>
                            </tr>
                          </> :
                          productosAlm.map((item, index) => (
                            <tr key={index} className={item.saldo <= 0 ? 'table-warning' : ''}>
                              <td scope="row">{item.ID}</td>
                              <td className="text-start">{item.NOMBRE}</td>
                              <td className="text-start">{item.DESCRIPCION}</td>
                              <td className="text-start">{item.MODELO}</td>
                              <td className="text-center">{item.STOCK}</td>
                              <td className="text-center">{item.EXISTENCIA_MIN}</td>
                              <td className="text-center">{item.EXISTENCIA_MAX}</td>
                              <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO)}</td>
                              <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_PUBLICO)}</td>

                              <td className="text-center">
                                {
                                  item.ESTADO == 1 ?
                                    <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                }
                              </td>
                              <td className="text-center">
                                {
                                  item.ESTADO == 1 ?
                                    <>
                                      <button type="button" className="btn btn-outline-primary border-0" onClick={e => handledEditProduct(item.ID)}>
                                        <Edit size="20" /> / <Eye size="20" />
                                      </button>
                                      <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCat(item.ID)}>
                                        <Trash2 size="20" />
                                      </button>
                                    </> :
                                    <>
                                      <button type="button" className="btn btn-outline-primary border-0" >
                                        <Edit size="20" /> / <Eye size="20" />
                                      </button>
                                      <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCat(item.ID)}>
                                        <RefreshCcw size="20" />
                                      </button>
                                    </>
                                }
                              </td>
                            </tr>
                          ))
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }


  if (paramsURL.solProducts == 'settings') {
    return (
      <>
        <Settings />
      </>
    )
  }
  // paramsURL.solProducts
  if ( paramsURL.solProducts == 'solProducts') {
    return (
      <>
        <SolProduct />
      </>
    )
  }







}
