import React, { useEffect, useRef } from 'react';
import { Slash, Save, AlertTriangle, Clock, CheckCircle, Link as LINKIMG, Loader, Search, PlusSquare, PlusCircle, MinusCircle, Percent, Box, File, FilePlus, FileText, FileMinus, CheckSquare, Check, AlertCircle, Link2, Eye, Activity, DollarSign, Package, } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { startloadEmpleados } from '../../../../app/store/empleados/thunks';
import { startAsignacionOrden, startloadOrdenesDash } from '../../../../app/store/ordenes/thunks';
import { loadCat_egresos } from '../../../../app/store/catalagos/thunks';
// import { AsignacionOrden } from '../modals/AsignacionOrden';
import Swal from 'sweetalert2';
import { Table } from '../../../utils/Table';
import { NewEgreso } from '../../finance/modals/NewEgreso';

export const Dash_Recep = () => {


  const searchButton = useRef(null);
  // let today = new Date();
  const { empleados } = useSelector(state => state.empleados);
  const { ordenes, msg, indicadores, isLoading } = useSelector(state => state.ordenes);
  const { usuario } = useSelector(state => state.usuario);
  let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes;
  let today = f.getFullYear() + "-" + mes + "-" + dia;
  // let today =  dia + "-" + mes + "-" +  f.getFullYear();

  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ defaultValues: {}, model: 'all' });


  const searchOrdenes = (data) => {
    dispatch(startloadOrdenesDash(data))
  }


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });



  const location = useLocation();

  useEffect(() => {
    if (location.state?.reload) {
      // Aquí ejecutas la lógica para recargar la data
      let data = { tecnico: 'TODOS', estadoOrden: 'TODOS' }
      dispatch(startloadOrdenesDash(data))
    }
  }, [location]);


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadCat_egresos());
    dispatch(startloadEmpleados());
    // searchButton.current.click();
    let data = { tecnico: 'TODOS', estadoOrden: 'TODOS' }
    dispatch(startloadOrdenesDash(data))


  }, [])

  // console.log(ordenes);

  const handleChange = (e, folio) => {

    let id_empleado = e.target.value;

    Swal.fire({
      title: 'Atención',
      text: "Está por Asignar/Reasignar una Orden de Servicio, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startAsignacionOrden(usuario, id_empleado, folio));


      }
    })
    // searchButton.current.click();
  }


  if (isLoading) {
    return (
      <>
        <div className='div_center'>
          <div className='text-center'>
            <div className="spinner"></div>
          </div>
          <div className='text-center text-left-15'>
            <span className='fw-bold'>Cargando</span>
          </div>
          <div className='text-center text-left-15'>
            <div className="spinner"></div>
          </div>
        </div>
      </>
    )
  }



  const dataU = [...ordenes];
  const columnsType1 = [
    {
      header: 'FOLIO',
      dataIndex: 'FOLIO',
      classHeader: 'text-center',
      classRow: 'text-center fw-bold text-primary',
      renderer: (value) => (
        <>
          <Link to={`/trackingOrder/${value}`}>
            {value}
          </Link>
        </>
      ),
    },
    {
      header: 'PREMIUM',
      dataIndex: 'PREMIUM',
      classHeader: 'text-center',
      classRow: 'text-center',
      renderer: (value, row) => (

        (value == 0) ?
          <> </>
          :
          <> <img src='./assets/images/indicators/star.png' className='img-indicator' width={30} />
          </>
      ),
    },
    {
      header: 'TÉCNICO',
      dataIndex: 'TECNICO_ASIGNADO',
      classHeader: 'text-center',
      classRow: 'text-center',
      renderer: (value, row) => (

        (value == null) ?
          <>
            <select className="form-select " style={{ width: '300px' }} value={'TODOS'} onChange={e => handleChange(e, row.FOLIO)}  >
              <option value={'TODOS'} >Seleccione un Tecnico</option>
              {
                empleados.map((item, index) => (
                  item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} </option>
                    : <option key={index} style={{ display: "none" }} ></option>
                ))
              }
            </select>
          </> :
          <>


            <select className="form-select " style={{ width: '350px' }} value={value} onChange={e => handleChange(e, row.FOLIO)}  >

              {
                empleados.map((item, index) => (
                  item.ESTADO == 1 && item.ROL_ID == 3 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} </option>
                    : <option key={index} style={{ display: "none" }} ></option>
                ))
              }
            </select>



          </>
      )
    },
    {
      header: 'F.RECIBIDO',
      dataIndex: 'FECHA_FORMATEADA',
      classHeader: 'text-center',
      classRow: 'text-center',

    },
    {
      header: 'F.ASIGNACIÓN',
      dataIndex: 'FECHA_ASIGNACION',
      classHeader: 'text-center',
      classRow: 'text-center',
      // renderer: (value) => (
      //   Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value)
      // ),
    },
    {
      header: 'F.DIAGNOSTICO',
      dataIndex: 'FECHA_DIAGNOSTICO',
      classHeader: 'text-center',
      classRow: 'text-center',

    },
    {
      header: 'ESTADO',
      dataIndex: 'ESTADO',
      classHeader: 'text-center',
      classRow: 'text-center',
      renderer: (value) => {
        switch (value) {
          case '0':
            return <span className="status-btn pending-btn"> Sin Asignar </span>;
          case '1':
            return <span className="status-btn revision-btn"> Asignada </span>;
          case '2':
            return <span className="status-btn progress-btn"> Sin Diagnostico </span>;
          case '3':
            return <span className="status-btn tests-btn"> Con Diagnostico </span>;
          case '4':
            return <span className="status-btn vacaciones-btn"> En espera de Componente </span>;
          case '5':
            return <span className="status-btn esperacom-btn"> Completa / Terminada </span>;
          case '6':
            return <span className="status-btn complete-btn"> No Aprobada a Entrega </span>;
          case '7':
            return <span className="status-btn complete-btn"> Aprobada a Entrega </span>;
          case '10':
            return <span className="status-btn vacaciones-btn"> Equipo retirado por cliente  </span>;

        }
      }

    },
  ];



  return (
    <>

      <NewEgreso />

      {/* INDICADORES */}
      <div className="row">
        <div className="mb-3">
          <h1 className="h4 d-inline align-middle fw-bold">Indicadores</h1>
        </div>

        <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Asignadas</h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <CheckSquare size='20' />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <h1 className="mt-1 mb-3">{indicadores.ASIGNADAS}</h1>
              </div>
              {/* <div className="mb-0 text-center">
                <span className="text-dark fw-bold small"> 01/05/2023 - 01/05/2023 </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Sin diagnostico</h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <AlertCircle size='20' />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <h1 className="mt-1 mb-3">{indicadores.NO_DIAGNOSTICO}</h1>
              </div>
              {/* <div className="mb-0 text-center">
                <span className="text-dark fw-bold small"> 01/05/2023 - 01/05/2023 </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col">
                  <h5 className="card-title text-dark">Con diagnostico</h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <Check size='20' />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <h1 className="mt-1 mb-3">{indicadores.CON_DIAGNOSTICO}</h1>
              </div>
              {/* <div className="mb-0 text-center">
                <span className="text-dark fw-bold small"> 01/05/2023 - 01/05/2023 </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">En espera de piezas</h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <Clock size='20' />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <h1 className="mt-1 mb-3">{indicadores.ESPERA_COMPONENTE}</h1>
              </div>
              {/* <div className="mb-0 text-center">
                <span className="text-dark fw-bold small"> 01/05/2023 - 01/05/2023 </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Terminadas</h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <Check size='20' />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <h1 className="mt-1 mb-3">{indicadores.TERMINADA}</h1>
              </div>
              {/* <div className="mb-0 text-center">
                <span className="text-dark fw-bold small"> 01/05/2023 - 01/05/2023 </span>
              </div> */}
            </div>
          </div>
        </div>

        <div className='col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2'>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Aprobada Entrega</h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <CheckCircle size='20' />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <h1 className="mt-1 mb-3">{indicadores.AUTORIZADAS}</h1>
              </div>

            </div>
          </div>
        </div>


        {/* Acceso Rapido */}
        <div className='col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-12">
                  <h5 className="card-title text-dark">Acceso Rapido </h5>
                </div>


                <div className='col-6 col-sm-6 col-md-12 col-xl-12 col-xxl-12 mb-2'>
                  <Link to={`/finance/newCortAlt`} className="btn btn-outline-primary fw-bold w-100 text-start">
                    <Activity size={20} /> Cortes de caja.
                  </Link>
                </div>

                <div className='col-6 col-sm-6 col-md-12 col-xl-12 col-xxl-12 mb-2'>
                  <button type="button" className="btn btn-outline-danger me-3 w-100 text-start" data-bs-toggle="modal" data-bs-target="#newEgreso">
                    <MinusCircle size='20' /> Nuevo Egreso
                  </button>
                </div>

                <div className='col-6 col-sm-6 col-md-12 col-xl-12 col-xxl-12 mb-2'>
                  <Link to={`/warehouse/solProducts`} className="btn btn-outline-dark me-3 fw-bold w-100 text-start">
                    <Package size={20} /> Solicitar productos
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      {/* <!-- Tabla --> */}
      <div className="row">
        <div className="mb-1">
          <div className="mb-3">
            <h1 className="h4 d-inline align-middle fw-bold">Listado de Ordenes</h1>
          </div>

          <div className="card">
            <div className="card-body ">

              <form onSubmit={handleSubmit(searchOrdenes)}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="mb-3">
                      <label htmlFor="formGroupExampleInput" className="form-label">Técnico:</label>
                      <select className="form-select "  {...register("tecnico")}>
                        <option value={'TODOS'} >Todos los empleados</option>
                        {
                          empleados.map((item, index) => (
                            item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >({item.USER}) | {item.NOMBRE} - {item.ROL}</option>
                              : <option key={index} style={{ display: "none" }} ></option>
                          ))
                        }

                      </select>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                    <div className="mb-3">
                      <label htmlFor="formGroupExampleInput" className="form-label">Estado:</label>
                      <select className="form-select " {...register("estadoOrden")}>
                        <option value='TODOS'>Todos los estados </option>
                        <option value={0}>Sin asignar</option>
                        <option value={1}>Asignada</option>
                        <option value={2}>Sin Diagnostico</option>
                        <option value={3}>Con Diagnostico</option>
                        <option value={4}>Autorizada</option>
                        <option value={5}>Esperando de componente</option>
                        <option value={6}>Completada/Terminada</option>
                      </select>
                    </div>
                  </div>


                  <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
                    <div className="mb-3 mt-4">
                      <button type="submit" className="btn btn-outline-secondary" ref={searchButton}>
                        <Search size="20" /> Buscar
                      </button>
                    </div>

                  </div>

                </div>
              </form>

              <hr className='my-0' />
              <div className=" my-0  mt-3">

                <Table typeTable={1} columns={columnsType1} data={dataU} msg={msg} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Indicadores finanzas --> */}
      {/* <div className="row">

        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Ingresos <br /><span
                    className="small text-muted">Al 11/03/2023</span></h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <PlusCircle size="20" />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a href="finance.html" className="h1 mt-3 mb-1">$300,000.00</a>
              </div>

            </div>
          </div>
        </div>


        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Egresos <br /><span
                    className="small text-muted">Al 11/03/2023</span></h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <MinusCircle size="20" />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a href="finance.html" className="h1 mt-3 mb-1">$10,000.00</a>
              </div>

            </div>
          </div>
        </div>


        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark">Descuentos <br /><span
                    className="small text-muted">Al 11/03/2023</span></h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <Percent size="20" />
                  </div>
                </div>
              </div>
              <div className="text-center">
                <a href="finance.html" className="h1 mt-3 mb-1">$2,000.00</a>
              </div>

            </div>
          </div>
        </div>


        <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title text-dark"> Refacciones sol. <br /><span
                    className="small text-muted">Al 11/03/2023</span></h5>
                </div>
                <div className="col-auto">
                  <div className="stat text-primary">
                    <Box size="20" />
                  </div>
                </div>
              </div>
              <div className="text-center">
                {/* <Link to="./finance"> 
                <span className="h1 mt-3 mb-1">3</span>
          
              </div>

            </div>
          </div>
        </div>

      </div> */}
    </>
  )
}
