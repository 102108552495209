
import React, { useEffect, useState } from 'react';
import { Plus, RefreshCcw } from 'react-feather';
import ReactPaginate from 'react-paginate';

export const Table = ({ data, headers, handleAddProducto, handledsearchGeneral }) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    // const perPage = mostrar;

    // Search component
    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (event) => {

        console.log(event.target.value);
        setSearchTerm(event.target.value);
        setCurrentPage(0);
    };

    const filteredData = data.filter(item =>
        Object.values(item).some(value =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const handlePerPageChange = (event) => {
        setPerPage(Number(event.target.value));
        setCurrentPage(0);
    };



    const offset = currentPage * perPage;
    const currentPageItems = filteredData.slice(offset, offset + perPage);

    return (
        <>
            <div className=" mb-3 mt-2 ">
                <div className='row'>

                    <div className='col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7'>
                        <div className='mb-2'>
                            <label className="form-label me-2 align-middle">Buscar:</label>
                            <input type="search" className='form-control' value={searchTerm} onChange={handleSearch} autoComplete='off' placeholder="Introduce SKU / NOMBRE / MODELO" />
                        </div>
                    </div>
                    <div className='col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3'>
                        <div className='mb-2'>
                            <label className='form-label'>Mostrar:</label>
                            <select id="perPageSelect" value={perPage} onChange={handlePerPageChange} className='form-select '>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={30}>30</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-6 col-sm-6 col-md-1 col-lg-1 col-xl-1 col-xxl-1'>
                        <div className='mb-2 mt-1'>
                            <button className="btn btn-outline-success mt-4" type="button" onClick={handledsearchGeneral} >
                                <RefreshCcw size="20" />
                            </button>
                        </div>
                    </div>

                </div>



            </div>

            <div className='table-responsive table-scrollbar mb-1'>
                <table className="table table-striped table-hover table-sm border-top">
                    <thead>
                        <tr className='th-sticky'>
                            {headers.map((style, index) => (
                                <th key={index} scope="col" className={style.className}>
                                    {style.label}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentPageItems.length == 0 ? <>
                                <tr>
                                    <td colSpan={headers.length}>No se encontraron datos en este folio</td>
                                </tr>
                            </> :
                                currentPageItems.map((it, index) => (
                                    <tr key={index} className={it.saldo <= 0 ? 'table-warning' : ''}>
                                        <td className='text-start'>{it.ID}</td>
                                        <td className='text-start'>{it.NOMBRE}</td>
                                        <td className='text-start'>{it.MODELO}</td>
                                        <td className='text-center'>{it.STOCK}</td>
                                        <td className='text-center'>{it.EXISTENCIA_MIN}</td>
                                        <td className='text-center'>{it.EXISTENCIA_MAX}</td>
                                        <td className="text-center">
                                            {
                                                it.ESTADO == 1 ? <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                            }
                                        </td>
                                        <td className="text-center">
                                            {
                                                it.ESTADO == 1 ? <><button type="button" className="btn btn-outline-dark" onClick={() => handleAddProducto(it.ID)}
                                                ><Plus size='20' /></button> </> : <> </>
                                            }
                                        </td>


                                    </tr>
                                ))
                        }
                    </tbody>
                </table>
            </div>
            <ReactPaginate
                containerClassName="pagination"
                pageClassName="page-item" pageLinkClassName="page-link"
                activeClassName={'active'} previousClassName="page-item"
                previousLinkClassName="page-link"
                previousLabel={'<'} nextLabel={'>'}
                nextClassName="page-item" nextLinkClassName="page-link"
                breakLabel={'...'} breakClassName={'break-me'}
                pageCount={Math.ceil(data.length / perPage)}
                marginPagesDisplayed={5} pageRangeDisplayed={5}
                onPageChange={handlePageChange}
            />
        </>

    )
}
