import { createSlice } from '@reduxjs/toolkit';

export const clientesSlice = createSlice({
    name: 'clientesSlice',
    initialState: {
        status: 'EN_ESPERA',
        clientes: [],
        cliente_edit: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        loadingClientes: (state, action) => {
            state.isLoading = true;
            state.status = 'CARGANDO_CLIENTES';
        },
        notloadingClientes: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        setClientes: (state, action) => {
            state.isLoading = false;
            state.status = "CLIENTES_CARGADOS";
            state.clientes = action.payload.clientes;
        },
        setCliente_Edit: (state, action) => {
            state.isLoading = false;
            state.status = "CLIENTE_ENCONTRADO";
            state.cliente_edit = action.payload.cliente_edit;
        },
        setError: (state,action)=>{
            state.isLoading = false;
            state.status = "CLIENTES_CARGADOS";
            state.clientes = [];
            state.cliente_edit = [];
        },
        clearStore: (state,action)=>{
            state.status = 'EN_ESPERA';
            state.clientes = [];
            state.cliente_edit = [];
        }
        
    }
});


// Action creators are generated for each case reducer function
export const { loadingClientes,notloadingClientes,processAction,notprocessAction,setCliente_Edit,setClientes,setError,clearStore } = clientesSlice.actions;