import React from 'react'
import { RefreshCcw, Search } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { startloadEgresos, startloadEgresosParam } from '../../../../app/store/finanzas/thunk';
import { RiFileExcel2Line } from "react-icons/ri";
import * as XLSX from 'xlsx/xlsx.mjs';
export const Tab_Egresos = ({ indicadores, egresos,isLoading }) => {


  let egresosA = egresos.EGRESOS;


  const dispatch = useDispatch();
  let f = new Date(); let dia = f.getDate(); let mes = f.getMonth() + 1; if (dia < 10) dia = "0" + dia; if (mes < 10) mes = "0" + mes;
  let today = f.getFullYear() + "-" + mes + "-" + dia;
  // let ingresosA = ingresos.INGRESOS;
  const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ model: 'all' });

  const searchOrdenes = (data) => {
    dispatch(startloadEgresosParam(data.fechaInicial, data.fechaFinal))
  }

  const exportToXLS = (data) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `ExportData.xlsx`);
  }

  const reloadEgresos = () => {
    dispatch(startloadEgresos())
  }

  if (isLoading) {
    return (
      <>
        <div className='div_center'>
          <div className='text-center'>
            <div className="spinner"></div>
          </div>
          <div className='text-center text-left-15'>
            <span className='fw-bold'>Cargando</span>
          </div>
          <div className='text-center text-left-15'>
            <div className="spinner"></div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(searchOrdenes)} >
        <div className="row mt-2 p-2">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="mb-3">
              <label htmlFor="formGroupExampleInput" className="form-label">Fecha Inicio:</label>
              <input type='date' className='form-control' {...register("fechaInicial")} />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
            <div className="mb-3">
              <label htmlFor="formGroupExampleInput" className="form-label">Fecha Final:</label>
              <input type='date' className='form-control' defaultValue={today} {...register("fechaFinal")} />
            </div>
          </div>

          <div className="col-6 col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-1">
            <div className="mb-3 mt-4">
              <button type="submit" className="btn btn-outline-secondary me-2"
              // ref={searchButton}
              >
                <Search size="20" /> Buscar
              </button>

              <button type="button" className="btn btn-outline-dark" onClick={reloadEgresos}>
                <RefreshCcw size="20" />
              </button>
            </div>

          </div>

        </div>
      </form>

      <hr className='my-0' />
      <div className='mt-1 mb-2'>
        <button type="button" className="btn btn-outline-success border-0 mt-1 fw-bold" onClick={() => exportToXLS(egresosA)}>
          <RiFileExcel2Line size={25} /> <span className='text-left-5 align-middle'> Exportar </span>
        </button>
      </div>
      <div className="table-responsive my-0 table-scrollbar-2 mt-3">
        <table className="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th scope="col" className="text-center th-sticky">#</th>
              <th scope="col" className="text-center th-sticky">CAPTURO</th>
              <th scope="col" className="text-center th-sticky">FECHA</th>
              <th scope="col" className="text-center th-sticky">HORA</th>
              <th scope="col" className="text-center th-sticky">DESCRIPCION</th>
              <th scope="col" className="text-center th-sticky">CONCEPTO</th>
              <th scope="col" className="text-center th-sticky">DETALLE</th>
              <th scope="col" className="text-end th-sticky">MONTO</th>
            </tr>
          </thead>
          <tbody className="">
            {
              egresosA?.length == 0 ?
                <>
                  <tr>
                    <td colSpan={8}>No se encontraron Ordenes de Servicio</td>
                  </tr>
                </> :
                egresosA?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-center">
                      {item.CAPTURO}
                    </td>
                    <td className="text-center">{item.FECHA}</td>
                    <td className="text-center">{item.HORA}</td>
                    <td className="text-center">{item.DESCRIPCION}</td>
                    <td className="text-center">{item.NOM_CONCEPTO}</td>
                    <td className="text-center">{item.DETALLE}</td>
                    <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.MONTO)}</td>
                  </tr>
                ))
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={7} className='text-end fw-bold'>TOTAL:</td>
              <td className='text-end fw-bold'>{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(egresos.TOTALES)}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </>
  )
}
