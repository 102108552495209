import React, { useEffect } from 'react'
import { Edit, Eye, RefreshCcw, Search } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import { startloadOrden, startloadRefaccioneOrdenes } from '../../../app/store/ordenes/thunks';
import { EditPart } from './panels/EditPart';
import { NewPart } from '../orderTracking/modals/NewPart';
import { Link } from 'react-router-dom';

export const Parts = () => {



  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startloadRefaccioneOrdenes())
  }, [])
  const { refacciones, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);
  // console.log(refacciones);

  const handledEditRefaccion = (folio) => {
    console.log(folio);

    dispatch(startloadOrden(folio))
  }

  if (isLoading) {
    return (
      <>
        <div className='div_center'>
          <div className='text-center'>
            <div className="spinner"></div>
          </div>
          <div className='text-center text-left-15'>
            <span className='fw-bold'>Cargando</span>
          </div>
          <div className='text-center text-left-15'>
            <div className="spinner"></div>
          </div>
        </div>
      </>
    )
  }

  if (status_ordenes == 'REFACCIONES_CARGADAS' || status_ordenes == 'ORDENES_NO_ENCONTRADOS') {
    return (

      <div className="row">
        <h1 className="h3 mb-3 fw-bold">Refacciones</h1>
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                <h5 className="card-title mb-3">Listado</h5>
                {/* <form onSubmit={handleSubmit(handledSearch)}> */}
                {/* <div className='mb-2'>
                  <label className="mb-1 text-dark">Orden de Servicio:</label>
                  <div className="d-flex">
                    <input className="form-control me-2" type="search" aria-label="Search" />
                    {/* {...register("textSearch", { required: 'Introduce termino a buscar' })} 
                    <button className="btn btn-outline-secondary" type="submit">
                      <Search size="20" />
                    </button>
                    <button className="btn btn-outline-success ms-2" type="button" >
                      {/* onClick={handledsearchGeneral} 
                      <RefreshCcw size="20" />
                    </button>
                  </div>
                </div> */}
                {/* </form> */}
              </div>
              <div className="row mt-2">
                <div className="table-responsive table-responsive p-2 mt-2 table-scrollbar-3 mb-4">
                  <table className="table tbale-hover table-sm">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">FOLIO</th>
                        <th scope="col" className="text-center">FECHA SOL.</th>
                        <th scope="col" className="text-end">COSTO HDL</th>
                        <th scope="col" className="text-end">COSTO CLIENTE</th>
                        <th scope="col" className="text-center">ESTADO</th>
                        <th scope="col" className="text-center">ACCIONES</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        refacciones.length == 0 ?
                          <>
                            <tr>
                              <td colSpan={7}>No se encontraron datos</td>
                            </tr>
                          </> :
                          refacciones.map((item, index) => (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>

                                <Link to={`/trackingOrder/${item.FOLIO_ID}`}>
                                  {item.FOLIO_ID}
                                </Link>
                                {/* <a href="#">{item.FOLIO_ID}</a> */}
                              </td>
                              <td className="text-center">{item.FECHA_SOL}</td>
                              <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_HDL)}</td>
                              <td className="text-end">{Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_CLIENTE)}</td>
                              <td className="text-center">
                                <span className="status-btn complete-btn">ORDEN ACTIVA</span>
                              </td>
                              <td className="text-center">
                                <button type="button" className="btn btn-outline-primary border-0" onClick={() => handledEditRefaccion(item.FOLIO_ID)}>
                                  <Edit size='20' /> / <Eye size='20' />
                                </button>
                              </td>
                            </tr>
                          ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }






  if (status_ordenes == 'ORDEN_ENCONTRADA') {
    return (
      <>
        <EditPart /> <NewPart />
      </>
    )
  }














}
