import React from 'react'
import { AlertTriangle, ArrowLeft, Edit, Plus, RefreshCcw, Trash2 } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { loadCat_egresosEdit, loadCat_ingresosEdit, startActCatEgreso, startActCatIngreso, startDelCatEgreso, startDelCatIngreso, startRegistroCatEgresos, startRegistroCatIngresos } from '../../../../app/store/catalagos/thunks';
import { EditCatIngreso } from '../modals/EditCatIngreso';
import { EditCatEgreso } from '../modals/EditCatEgreso';
// import { startActCategoriaAlm, startActMarcasAlm, startActProveedoresAlm, startDelCategoriaAlm, startDelMarcasAlm, startDelProveedoresAlm, startRegistroCat, startRegistroMarcaAlm, startRegistroProv } from '../../../../app/store/catalagos/thunks';
// import { useForm } from 'react-hook-form';


export const Settings = () => {


    // const { categoriasAlm, marcasAlm, proveedoresAlm } = useSelector(state => state.catalogos);
    // const { usuario, nombre, rol, puesto, avatar } = useSelector(state => state.usuario);
    const { cat_ingresos, cat_egresos, isLoading } = useSelector(state => state.catalogos);
    const dispatch = useDispatch();

    const { register: registerCat, handleSubmit: handleSubmitCat, resetField: resetFieldCat, formState: { errors: errorsCat } } = useForm({ model: 'all' });
    const { register: registerMarc, handleSubmit: handleSubmitMarc, resetField: resetFieldMarc, formState: { errors: errorsMarc } } = useForm({ model: 'all' });
    // const { register: registerProv, handleSubmit: handleSubmitProv, resetField: resetFieldProv, formState: { errors: errorsProv } } = useForm({ model: 'all' });


    const handleSaveMarc = (data) => {
        // console.log(data);
        let marca = data.nombreMarca;
        // let usuario_cap = usuario;
        dispatch(startRegistroCatEgresos(marca));
        resetFieldMarc("nombreMarca");
    }

    const handledDelMarca = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una categoria de Egreso, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                // dispatch(startDelMarcasAlm(e));
                dispatch(startDelCatEgreso(e));
            }
        })
    }

    const handledActMarca = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una categoria de Egreso, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                // dispatch(startActMarcasAlm(e));
                dispatch(startActCatEgreso(e));
            }
        })
    }


    const handleSaveCat = (data) => {
        // console.log(data);
        let marca = data.nombreClasificacion;
        dispatch(startRegistroCatIngresos(marca));
        resetFieldCat("nombreClasificacion");
    }

    const handledDelCat = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una categoria de Ingreso, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelCatIngreso(e));
            }
        })
    }

    const handledActCat = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una categoria de Ingreso, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActCatIngreso(e));
            }
        })
    }

    const handledEditCatIngresos = (e) => {
        dispatch(loadCat_ingresosEdit(e))
    }
    const handledEditCatEgresos = (e) => {
        dispatch(loadCat_egresosEdit(e))
    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <Link to={`/finance`} className='breadcrumb-item btn-link'>
                                <ArrowLeft size='20' /> Atras
                            </Link>
                            <li className="breadcrumb-item active" aria-current="page">Catalogos</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <div className='row'>


                {/* Ingresos */}
                <EditCatIngreso />
                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <div className="card">
                        <div className="card-body">
                            {/* <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}> */}
                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Cat. Ingresos: </h5>
                                </div>
                                <hr className="my-0 mb-3" />
                                <form onSubmit={handleSubmitCat(handleSaveCat)}>
                                    <div className='row'>
                                        <div className='col-9'>

                                            <input className="form-control me-2" type="text" autoComplete='off' {...registerCat('nombreClasificacion', { required: 'Escribe el Nombre del Ingreso', minLength: { value: 3, message: 'El Nombre del Ingreso debe tener por lo menos 3 caracters' } })} />
                                            {
                                                errorsCat.nombreClasificacion &&
                                                <div className='text-left-5 mt-1 '>
                                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errorsCat.nombreClasificacion.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-1'>
                                            <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive p-2 mt-2 table-scrollbar-8">
                                    <table className="table table-striped table-hover table-sm">
                                        <thead className='th-sticky'>
                                            <tr>
                                                <th scope="col" className='th-sticky'>#</th>
                                                <th scope="col" className="th-sticky text-start">NOMBRE</th>
                                                <th scope="col" className="th-sticky text-center">ESTADO</th>
                                                <th scope="col" className="th-sticky text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cat_ingresos.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                    cat_ingresos.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{item.ID}</td>
                                                            <td className="text-start">{item.NOMBRE}</td>

                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editCategoriaIngresos" onClick={e => handledEditCatIngresos(item.ID)}>
                                                                    <Edit size="20" />
                                                                </button>
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCat(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCat(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Egresos */}
                <EditCatEgreso />
                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                    <div className="card">
                        <div className="card-body">
                            {/* <form onSubmit={handleSubmit(handledUPDCredencialesCorreo)}> */}
                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Cat. Egresos: </h5>
                                </div>
                                <hr className="my-0 mb-3" />
                                <form onSubmit={handleSubmitMarc(handleSaveMarc)}>
                                    <div className='row'>
                                        <div className='col-9'>
                                            <input className="form-control me-2" type="text" autoComplete='off' {...registerMarc('nombreMarca', { required: 'Escribe el Nombre del Egreso', minLength: { value: 2, message: 'El Nombre del Egreso debe tener por lo menos 2 caracters' } })} />
                                            {
                                                errorsMarc.nombreMarca &&
                                                <div className='text-left-5 mt-1 '>
                                                    {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                    <AlertTriangle size="18" color="#dc3545" />
                                                    <span className='text-left-10 fw-bold text-danger'><small>{errorsMarc.nombreMarca.message}</small></span>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-1'>
                                            <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive p-2 mt-2 table-scrollbar-8">
                                    <table className="table table-striped table-hover table-sm">
                                        <thead className='th-sticky'>
                                            <tr>
                                                <th scope="col" className='th-sticky'>#</th>
                                                <th scope="col" className="th-sticky text-start">NOMBRE</th>
                                                <th scope="col" className="th-sticky text-center">ESTADO</th>
                                                <th scope="col" className="th-sticky text-center">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cat_egresos.length === 0 ?
                                                    <>
                                                        <tr>
                                                            <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                    cat_egresos.map((item, index) => (
                                                        <tr key={index}>
                                                            <td scope="row">{item.ID}</td>
                                                            <td className="text-start">{item.NOMBRE}</td>

                                                            <td className="text-center">
                                                                {
                                                                    item.ESTADO == 1 ?
                                                                        <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                }
                                                            </td>
                                                            <td className="text-center">
                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editCategoriaEgresos" onClick={e => handledEditCatEgresos(item.ID)}>
                                                                    <Edit size="20" />
                                                                </button>
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelMarca(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActMarca(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button>
                                                                        </>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}
