import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStore, loadingClientes, processAction, setCliente_Edit, setClientes, setError } from "./slice";
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const startRegistroCliente = (data, capturante) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let usuarioCap = capturante; let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;
        await URL_API.post(`Clientes/postCliente`, {
            nombre: data.nombreCliente,
            telefono: data.telefonoCliente,
            celular: data.celularCliente,
            nacimiento: data.nacimientoCliente,
            email: data.emailCliente,
            tipo_cliente: data.tipoCliente,
            como_conocieron: data.comoConocio,
            usuario_reg: usuarioCap,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro,
            estado: 1,

        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cliente registrado con exito'
                })
                dispatch(startloadClientes());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Cliente... Intente de Nuevo'
            })
            dispatch(startloadClientes());
            return;
        });
        
    }
   
}

export const startEditCliente = (data, id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Clientes/putCliente/${id_cliente}`, {
            nombre: data.nombreCliente,
            telefono: data.telefonoCliente,
            celular: data.celularCliente,
            nacimiento: data.nacimientoCliente,
            email: data.emailCliente,
            tipo_cliente: data.tipoCliente,
            estado: 1,

        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cliente Actualizado con exito'
                })
                dispatch(startloadClientes());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro al Cliente... Intente de Nuevo'
            })
            dispatch(startloadClientes());
            return;
        });
    }
}

export const startloadClientes = () => {
    return async (dispatch, getState) => {
        dispatch(loadingClientes());

        await URL_API.get(`Clientes/getClientes`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setClientes({ clientes: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError());
        });
    }
}

export const startDelCliente = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Clientes/putClienteDel/${id_cliente}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadClientes());
                Toast.fire({
                    icon: 'success',
                    title: 'Cliente Desactivado con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCliente = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Clientes/putClienteAct/${id_cliente}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadClientes());
                Toast.fire({
                    icon: 'success',
                    title: 'Cliente Activado con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCliente = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(loadingClientes());

        await URL_API.get(`Clientes/getCliente/${id_cliente}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setCliente_Edit({ cliente_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const searchClientes = (data) => {
    return async (dispatch, getState) => {
        dispatch(clearStore())
        dispatch(loadingClientes());

        // await URL_API.get(`Clientes/getClientesSearch/${data.param}/${data.textSearch}`).then(function (response) {
        await URL_API.post(`Clientes/getClientesSearch`,{
            param: data.param,
            word: data.textSearch,
        }).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setClientes({ clientes: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }
        }).catch(function (error) {
            dispatch(setError())
        });
    }
}


export const clearStoreSearch = () => {
    return (dispatch, getState) => {
        dispatch(clearStore());
    }
}

export const asignedCustomer =($data) =>{
    return async (dispatch, getState) => {
        dispatch(loadingClientes());
        dispatch(setCliente_Edit({ cliente_edit: $data }))
    }

}