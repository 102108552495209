import React from 'react'
import { LogOut } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import { close_login } from '../../../app/store/usuarios/slice';
export const Navbar = (props) => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});
  const { usuario, nombre, puesto, avatar,rol } = useSelector(state => state.usuario);
  const dispatch = useDispatch();

  const logout = () => {

    Toast.fire({
      icon: 'success',
      title: 'Hasta luego...'
  })

    dispatch(close_login());
    localStorage.clear();

  }

  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      <a className="sidebar-toggle js-sidebar-toggle" onClick={props.handleClick}>
        <i className="hamburger align-self-center"></i>
      </a>

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          <li className="nav-item dropdown">
            <a className="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#"
              data-bs-toggle="dropdown">
              <img src={`./assets/images/avatars/${avatar}`} className="avatar img-fluid rounded-circle me-1"
                alt={usuario} />
            </a>

            <a className="nav-link dropdown-toggle d-none d-sm-inline-block" href="#"
              data-bs-toggle="dropdown">
              <img src={`./assets/images/avatars/${avatar}`} className="avatar img-fluid rounded-circle me-1"
                alt={usuario} />
            </a>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end">

              <div className="p-3">
                <div className="row g-0 align-items-center ">
                  <div className="col-2">
                    <img src={`./assets/images/avatars/${avatar}`}
                      className="avatar img-fluid rounded-circle" alt={usuario} />
                  </div>
                  <div className="col-7 ps-2">
                    <div className="text-dark fw-bold text-dark">{nombre}</div>
                    <div className="text-muted text-dark fw-bold small mt-1">
                      {rol}
                    </div>
                  </div>

                  <div className='col-3'>
                    <Link className='btn btn-sm btn-outline-dark' to="/profile">
                      <span className="">Mi Perfil</span>
                    </Link>
                  </div>


                </div>
              </div>
              <div className="dropdown-divider"></div>

              <button className="dropdown-item" onClick={logout}>
                <LogOut size={20} />
                Cerrar Sesión</button>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}
