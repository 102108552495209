import React, { useState } from 'react'
import { AlertTriangle, ArrowLeft, Plus, Save, Slash, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Evidencias } from '../orderTracking/modals/Evidencias';
import { Galeria } from '../orderTracking/modals/Galeria';
import { ViewEvidencias } from '../orderTracking/modals/ViewEvidencias';
import { useForm } from 'react-hook-form';
import { startOrderDeparture, startOrderDepartureBack } from '../../../app/store/ordenes/thunks';
import Swal from 'sweetalert2';

export const OrderDeparture = () => {

    const { orden_edit, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);
    const { usuario, id_emp } = useSelector(state => state.usuario);
    const navigate = useNavigate();
    let paramsURL = useParams();

    // console.log(paramsURL);
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    let orden_detail = orden_edit[0];
    let retiros = orden_edit[0]["RETIROS"];
    let idRetiro = 0;

    if (retiros == undefined || retiros == 'undefined') {
        retiros = 0;
    } else {
        retiros.forEach(item => {
            if (item.ESTADO == 0) {
                idRetiro = item.ID
            }
        });
    }


    const goBack = () => {
        setFotografias([]);
        navigate(`/trackingOrder/${paramsURL.folio}`);
    }


    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            nombreRetira: "",
            observaciones: ""
        }
    });

    const dispatch = useDispatch();


    const [Fotografias, setFotografias] = useState([]);
    const addFotografia = () => {
        // setEvidencias([]);
        let filesA = document.getElementById("fotografia_up").files;
        // let evidencia = Fotografias.filter(item => item.name == filesA[0].name);
        setFotografias([...Fotografias, ...filesA]);
        Toast.fire({
            icon: 'success',
            title: 'Fotografia del equipo agregada al Listado'
        })
    }

    const removeFotografia = (name) => {
        Toast.fire({
            icon: 'success',
            title: 'Fotografia Eliminada de la lista'
        });
        let arrayANTERIOR = [...Fotografias];
        // console.log(arrayANTERIOR);
        let result = arrayANTERIOR.filter(item => item.name != name);
        setFotografias(result);
    }

    const removeAllFotografias = () => {
        if (Fotografias.length != 0) {
            setFotografias([]);
            Toast.fire({
                icon: 'success',
                title: 'Se ha eliminado la lista de fotografias'
            });
        }
    }




    const handledSave = (data) => {

        if (paramsURL.vista == 1) {

            if (Fotografias.length == 0) {
                Toast.fire({
                  icon: 'error',
                  title: 'NO se han agregado Fotografias para el registrar el reingreso'
                });
                return;
              }

            Swal.fire({
                title: 'Atención',
                text: "Está por reingresar un equipo para continuar con la orden de servicio, ¿Desea de continuar con esta acción?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3b7ddd',
                cancelButtonColor: '#dc3545',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(startOrderDepartureBack(id_emp, orden_detail.FOLIO, data, orden_detail.CLIENTE_ID, orden_detail.EQUIPO_ID, idRetiro,Fotografias ));
                    setFotografias([]);
                    goBack();
                }
            })

        } else {
            Swal.fire({
                title: 'Atención',
                text: "Está por retirar un equipo para entregarlo al Cliente, ¿Desea de continuar con esta acción?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3b7ddd',
                cancelButtonColor: '#dc3545',
                cancelButtonText: 'No',
                confirmButtonText: 'Si'
            }).then((result) => {
                if (result.isConfirmed) {
                    dispatch(startOrderDeparture(id_emp, orden_detail.FOLIO, data, orden_detail.CLIENTE_ID, orden_detail.EQUIPO_ID));
                    // setFotografias([]);
                    goBack();
                }
            })
        }
    }


    return (

        <div className='row'>

            <Galeria />
            <div className='col-12'>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item btn-link" onClick={goBack}><ArrowLeft size='20' /> Atras</li>

                        {(paramsURL.vista) == 1 ?
                            <><li className="breadcrumb-item active" aria-current="page">Reingreso de Equipo</li></> : <><li className="breadcrumb-item active" aria-current="page">Retiro de Equipo</li></>}


                    </ol>
                </nav>
            </div>


            <div className="mb-3">
                <h1 className="h4 d-inline align-middle fw-bold">Detalles de la Orden de Servicio</h1>
            </div>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">
                    <div className="card ">
                        <div className="card-body">
                            <label className="mb-1 fw-bold text-dark">Orden de Servicio:</label>
                            <div className="d-flex">
                                <input className="form-control me-2" type="search" aria-label="Search" disabled value={orden_detail.FOLIO || 0} />

                            </div>
                        </div>
                        <hr className="my-0" />
                        <div className="card-body">
                            <div className="row mb-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Datos del Cliente </h5>
                                </div>
                            </div>
                            <hr className="my-0" />
                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div className="mb-1">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" value={orden_detail.NOMBRE || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Telefono:</label>
                                        <input type="text" className="form-control" value={orden_detail.TELEFONO || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Celular:</label>
                                        <input type="text" className="form-control" value={orden_detail.CELULAR || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Correo electronico:</label>
                                        <input type="text" className="form-control" value={orden_detail.EMAIL || ""} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-1">
                                <div className="col-12">
                                    <h5 className="fw-bold me-2 border-0">Equipo </h5>
                                    <hr className="my-0" />
                                </div>
                                <div className="col-12 mt-1">
                                    <div className="mb-2">
                                        <label className="form-label">Equipo:</label>
                                        <input type="text" className="form-control" value={orden_detail.EQUIPO || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">S.N:</label>
                                        <input type="text" className="form-control" value={orden_detail.NO_SERIE || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Sistema Operativo:</label>
                                        <input type="text" className="form-control" value={orden_detail.SIS_OP || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Cargador: <span> {orden_detail.CARGADOR == 1 ? <> Si </> : <> No </>} </span>
                                        </label>
                                        <input type="text" className="form-control" value={orden_detail.CARGADOR_SN || ""} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-1">
                                        <label className="form-label">Bateria: <span> {orden_detail.BATERIA == 1 ? <> Si </> : <> No </>}</span></label>
                                        <input type="text" className="form-control" value={orden_detail.BATERIA_SN || ""} disabled />
                                    </div>
                                </div>

                                <div className="col-12 mt-2">
                                    <button type="button" className="btn btn-outline-dark border-0  w-100" data-bs-toggle="modal" data-bs-target="#galery">Ver fotografias</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(paramsURL.vista == 1) ?
                    <>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                            <div className="card">
                                <div className='card-body'>
                                    <div className="row border-bottom">
                                        <div className='col-12'>
                                            <h5 className="card-title fw-bold text-dark mb-2">Detalles de Reingreso del Equipo</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <form onSubmit={handleSubmit(handledSave)}>
                                            <div className="mb-3">
                                                <label className="form-label">Quien entrega el equipo:</label>
                                                <input type="text" className="form-control" placeholder="" autoComplete='off' {...register("nombreRetira", { required: 'Nombre de quien retira el equipo es requerido.' })} />
                                                {
                                                    errors.nombreRetira &&
                                                    <div className='text-left-5 mt-2'>
                                                        <AlertTriangle size="18" color="#dc3545" />
                                                        <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreRetira.message}</small></span>
                                                    </div>
                                                }
                                            </div>

                                            <div className='row'>
                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 '>
                                                    {/* */}
                                                    <div className="mb-3">
                                                        <label className="form-label">Observaciones:</label>
                                                        <textarea className="form-control" rows="6" {...register("observaciones", { required: 'Observaciones son requeridas' })} ></textarea>
                                                        {
                                                            errors.observaciones &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.observaciones.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>

                                                    <h5 className="card-title fw-bold text-dark mb-2">Fotografias del equipo</h5>
                                                    <hr className="my-0 mb-3" />
                                                    <div className="d-flex">
                                                        <label htmlFor="formFile" className="form-label"></label>
                                                        <input className="form-control form-control" id="fotografia_up" type="file" accept="image/*" />
                                                        <button className="btn btn-outline-primary no border-0 " type="button" onClick={() => addFotografia()}>
                                                            <Plus size='20' />
                                                        </button>
                                                    </div>

                                                    <div className="mt-2 table-responsive table-scrollbar-5">
                                                        <table className="table table-sm">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">#</th>
                                                                    <th scope="col">Archivo</th>
                                                                    <th scope="col" className="text-center">ACCIONES</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {
                                                                    Fotografias.length === 0 ?
                                                                        <>
                                                                            <tr >
                                                                                <td scope="row" colSpan={3} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han agregado fotografias, de como se ha recibido el equipo</td>
                                                                            </tr>
                                                                        </> :
                                                                        Fotografias.map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td scope="row">{index + 1}</td>
                                                                                <td>{item.name}</td>
                                                                                {/* <td className="text-end">{item.COSTO_SERV}</td> */}
                                                                                <td className="text-center">
                                                                                    <button className="btn btn-outline-danger border-0 " type="button" onClick={() => removeFotografia(item.name)}>
                                                                                        <Trash2 size='20' />
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <button type="button" className="btn btn-outline-danger mt-3 mb-4" onClick={removeAllFotografias} >Eliminar todos: ({Fotografias.length})</button>
                                                </div>

                                            </div>
                                            <div className="card-body text-end">
                                                <button type="button" className="btn btn-outline-danger mt-2" onClick={goBack}>
                                                    <Slash size='20' /> Cancelar
                                                </button>
                                                <button type="submit" className="btn btn-outline-primary mt-2 ms-3 ">
                                                    <Save size='20' /> Guardar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </> :
                    <>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9">
                            <div className="card">
                                <div className='card-body'>
                                    <div className="row border-bottom">
                                        <div className='col-12'>
                                            <h5 className="card-title fw-bold text-dark mb-2">Detalles de Retiro de Equipo</h5>
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        <form onSubmit={handleSubmit(handledSave)}>
                                            <div className="mb-3">
                                                <label className="form-label">Quien retira el equipo:</label>
                                                <input type="text" className="form-control" placeholder="" autoComplete='off' {...register("nombreRetira", { required: 'Nombre de quien retira el equipo es requerido.' })} />
                                                {
                                                    errors.nombreRetira &&
                                                    <div className='text-left-5 mt-2'>
                                                        <AlertTriangle size="18" color="#dc3545" />
                                                        <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreRetira.message}</small></span>
                                                    </div>
                                                }
                                            </div>

                                            <div className='row'>
                                                <div className='col-12 col-sm-12 '>
                                                    {/* col-md-6 col-lg-6 col-xl-6 col-xxl-6 */}
                                                    <div className="mb-3">
                                                        <label className="form-label">Observaciones:</label>
                                                        <textarea className="form-control" rows="6" {...register("observaciones", { required: 'Observaciones son requeridas' })} ></textarea>
                                                        {
                                                            errors.observaciones &&
                                                            <div className='text-left-5 mt-2'>
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.observaciones.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                                {/* <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
                                            <div className="mb-3">
                                              
                                            </div>
                                        </div> */}
                                            </div>
                                            <div className="card-body text-end">
                                                <button type="button" className="btn btn-outline-danger mt-2" onClick={goBack}>
                                                    <Slash size='20' /> Cancelar
                                                </button>
                                                <button type="submit" className="btn btn-outline-primary mt-2 ms-3 ">
                                                    <Save size='20' /> Guardar
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
            </div>
        </div>
    )
}
