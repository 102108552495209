import React, { useEffect, useRef } from 'react'
import { AlertTriangle, FilePlus, RefreshCcw, Search } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux';
import { asignedCustomer, clearStoreSearch, searchClientes } from '../../../../app/store/clientes/thunks';
import { useForm } from 'react-hook-form';

export const SearchCliente = () => {
    const dispatch = useDispatch();
    const closeModal = useRef(null);
    const { clientes, isLoading, status } = useSelector(state => state.clientes);


    useEffect(() => {
        document.getElementById("searchTool").style.display = "none";
    }, [isLoading])


    const clearStore = () => {
        reset();
        dispatch(clearStoreSearch());
    }

    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            textSearch: "",
            param: "1"
        }
    });

    const handledSearch = (data) => {
        dispatch(searchClientes(data))
    }

    const handledSelectedCustomer = ($id) => {
        // console.log($id);
        let customer = clientes.filter(item => item.ID === $id)[0];
        dispatch(asignedCustomer(customer));
        closeModal.current.click();
    }

    const showSearch = () => {
        // console.log('alivce');
        document.getElementById("searchTool").style.display = "block";
        clearStore();
    }



    return (
        <div className="modal fade" id="searchCustomer" data-bs-backdrop="static"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="exampleModalLabel">Buscador de Clientes </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearStore}></button>
                    </div>
                    <div className="modal-body p-3">


                        <form id='searchTool' onSubmit={handleSubmit(handledSearch)} style={{ display: "none" }}>
                            <h5 className='fw-bold'>Nueva busqueda</h5>
                            <hr className='my-0 mb-3' />
                            <div className="d-flex">
                                <select className="form-select w-25 me-1" {...register("param")}>
                                    <option value='1'>NOMB.</option>
                                    <option value='0'>CVE.</option>
                                    <option value='2'>TEL.</option>
                                    <option value='3'>CEL.</option>
                                </select>
                                <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                                <button className="btn btn-outline-secondary" type="submit">
                                    <Search size="20" />
                                </button>
                                <button className="btn btn-outline-success ms-2" type="button" onClick={clearStore}>
                                    <RefreshCcw size="20" />
                                </button>
                            </div>
                        </form>

                        <div className="mt-3">
                            {
                                isLoading ? <>
                                    
                                    
                                        <div className='div_center'>
                                            <div className='text-center'>
                                                <div className="spinner"></div>
                                            </div>
                                            <div className='text-center text-left-15'>
                                                <span className='fw-bold'>Cargando</span>
                                            </div>
                                            <div className='text-center text-left-15'>
                                                <div className="spinner"></div>
                                            </div>
                                        </div>
                                    
                                    
                                </> : <>
                                    <h5 className='fw-bold'>Listado de Coincidencias</h5>
                                    <hr className='my-0' />
                                    <div className="table-responsive table-scrollbar-6 mt-2">
                                        <table className="table table-hover table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className='th-sticky'>#</th>
                                                    <th scope="col" className='th-sticky'>CLIENTE</th>
                                                    <th scope="col" className='th-sticky text-center'>ACCIONES</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    clientes.length === 0 ?
                                                        <>
                                                            <tr>
                                                                <td scope="row" colSpan={3} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han encontrado clientes, puede realizar una nueva busqueda, en el formulario de arriba</td>
                                                            </tr>
                                                        </> :
                                                        clientes.map((item, index) => (
                                                            <tr key={index}>
                                                                <td scope="row">{index + 1}</td>
                                                                <td className="text-justify align-middle">
                                                                    ID: {item.ID}, {item.NOMBRE}, Cel: {item.CELULAR}, Email: {item.EMAIL}, Cliente: {item.CLAS_CLIENTE}
                                                                </td>
                                                                <td>
                                                                    <button type="button" className="btn btn-outline-primary" onClick={() => handledSelectedCustomer(item.ID)}>
                                                                        <FilePlus size='20' /> Escoger
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }


                        </div>

                        <button type="button" className="btn btn-outline-dark text-start" onClick={() => showSearch()}>Probar otra busqueda</button>
                    </div>

                    <div className="modal-footer">
                        <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={clearStore}>
                            Cerrar
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
