import React, { useEffect } from 'react';
import { Search, Trash2, Eye, Edit2, RefreshCcw, FileText, Monitor, BookOpen, Edit, AlertTriangle } from 'react-feather';
import { EditCustomer } from './panels/EditCustomer';
import { useDispatch, useSelector } from 'react-redux';
import { loadCliente, searchClientes, startActCliente, startDelCliente, startloadClientes } from '../../../app/store/clientes/thunks';
import Swal from 'sweetalert2';
import { NewCustomer } from './modals/NewCustomer';
// import { EditCustomer } from './panels/EditCustomer';
import { AddEquipo } from './modals/AddEquipo';
import { EditEquipo } from './modals/EditEquipo';
import { AddFacturacion } from './modals/AddFacturacion';
import { ViewFacturacion } from './modals/ViewFacturacion';
import { EditFacturacion } from './modals/EditFacturacion';
import { useForm } from 'react-hook-form';
import { Table_2 } from '../../utils/Table_2';

export const Customers = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(startloadClientes())
  }, [])
  const { clientes, isLoading, status } = useSelector(state => state.clientes);

  const handledDelCliente = (id) => {

    Swal.fire({
      title: 'Atención',
      text: "Está por DESACTIVAR a un Cliente, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startDelCliente(id));
      }
    })
  }

  const handledActCliente = (id) => {

    Swal.fire({
      title: 'Atención',
      text: "Está por REACTIVAR a un Cliente, ¿Desea de continuar con esta acción?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3b7ddd',
      cancelButtonColor: '#dc3545',
      cancelButtonText: 'No',
      confirmButtonText: 'Si'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(startActCliente(id));
      }
    })
  }

  const handledEditCliente = (e) => {
    dispatch(loadCliente(e));
  }

  const { register, reset, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      textSearch: "",
      param: "1"
    }
  });

  const handledSearch = (data) => {
    // console.log(data);
    dispatch(searchClientes(data))
  }

  const handledsearchGeneral = () => {
    reset();
    dispatch(startloadClientes());
  }



  const dataU = [...clientes];
  const columnsType1 = [
    {
      header: 'ID',
      dataIndex: 'ID',
      classHeader: 'text-center',
      classRow: 'text-center fw-bold ',
    },
    {
      header: 'NOMBRE',
      dataIndex: 'NOMBRE',
      classHeader: 'text-start',
      classRow: 'text-start fw-bold ',
    },
    {
      header: 'TELEFONO',
      dataIndex: 'TELEFONO',
      classHeader: 'text-center',
      classRow: 'text-center fw-bold ',
    },
    {
      header: 'CLASIFICACION',
      dataIndex: 'CLAS_CLIENTE',
      classHeader: 'text-center',
      classRow: 'text-center fw-bold ',
    },
    {
      header: 'ESTADO',
      dataIndex: 'ESTADO',
      classHeader: 'text-center',
      classRow: 'text-center',
      renderer: (value, row) => (

        (value == 1) ?
          <><span className="status-btn complete-btn"> ACTIVO </span> </>
          :
          <> <span className="status-btn pending-btn"> ELIMINADO </span>
          </>
      ),
    },
    {
      header: 'ACCIONES',
      dataIndex: 'ESTADO',
      classHeader: 'text-center',
      classRow: 'text-center',
      renderer: (value, row) => (
        <div className="btn-group border-0" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-outline-primary border-0" onClick={e => handledEditCliente(row.ID)}>
            <Edit size="20" />  /  <Eye size="20" />
          </button>
          {
            row.ESTADO == 1 ? <>
              <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCliente(row.ID)}>
                <Trash2 size="20" />
              </button></> :
              <>
                <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCliente(row.ID)}>
                  <RefreshCcw size="20" />
                </button>
              </>
          }
        </div>
      )
    },

  ];

  return (
    <>

      <NewCustomer />
      {
        isLoading ? <>
          <div className='div_center'>
            <div className='text-center'>
              <div className="spinner"></div>
            </div>
            <div className='text-center text-left-15'>
              <span className='fw-bold'>Cargando</span>
            </div>
            <div className='text-center text-left-15'>
              <div className="spinner"></div>
            </div>
          </div>

        </> : <></>
      }
      {
        status == "CLIENTES_CARGADOS" ? <>


          <h1 className="h4 mb-3 fw-bold">Clientes</h1>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">

                    <form onSubmit={handleSubmit(handledSearch)}>

                      <div className='mb-2'>
                        <label className="mb-1 text-dark">Buscar por:</label>
                        <div className="d-flex">
                          <select className="form-select w-75 me-1" {...register("param")}>
                            <option value='0'>CLAVE</option>
                            <option value='1'>NOMBRE</option>
                            <option value='2'>TELEFONO</option>
                            <option value='3'>CELULAR</option>
                          </select>

                          <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                          <button className="btn btn-outline-secondary" type="submit">
                            <Search size="20" />
                          </button>
                          <button className="btn btn-outline-success ms-2" type="button" onClick={handledsearchGeneral}>
                            <RefreshCcw size="20" />
                          </button>
                        </div>
                      </div>
                    </form>
                    <button type="button" className="btn btn-outline-primary mt-4 mb-2"
                      data-bs-toggle="modal" data-bs-target="#newCliente">Agregar Nuevo</button>
                  </div>


                  <Table_2 typeTable={1} columns={columnsType1} data={dataU} msg={''} />


                </div>
              </div>
            </div>
          </div>
        </> : <> </>
      }
      {
        status == "CLIENTE_ENCONTRADO" ? <>
          <EditCustomer /> <AddEquipo /> <EditEquipo /> <AddFacturacion /> <ViewFacturacion /> <EditFacturacion />
        </> : <>

        </>
      }
    </>
  )
}
