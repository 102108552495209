import React, { useEffect, useRef } from 'react';
import { AlertTriangle, FilePlus, RefreshCcw, Search } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { asignedFacturacion, clearStoreSearch, searchFacturacion, startAsignacionFacturacion } from '../../../../app/store/facturacion/thunks';

export const SearchFacturacion = ({ opc }) => {
    // const { clientes, isLoading, status } = useSelector(state => state.clientes);
    const { facturacion, facturacion_edit, id, isLoading, status_facturacion } = useSelector(state => state.facturacion);
    const dispatch = useDispatch();
    const closeModal = useRef(null);

    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            textSearch: "",

        }
    });

    useEffect(() => {
        // document.getElementById("searchTool").style.display = "none";
        clearStore();
    }, [])

    const handledSearch = (data) => {
        dispatch(searchFacturacion(data))
    }

    const clearStore = () => {
        reset();
        dispatch(clearStoreSearch());
    }

    // console.log(opc);
    // console.log(facturacion_edit);


    const handledSelectedFacturacion = ($id) => {
        // console.log($id);

        if (opc == 1) {

            let fact = facturacion.filter(item => item.ID === $id)[0];
            let variant = 1;
            let idFacturacion = fact.ID;

            let folio= id;
            if (folio.includes('HDLV')) variant = 2;

            // 
            // console.log(id);
            // console.log(variant);
            // console.log(fact);

            console.log(folio);
            console.log(variant);
            console.log(idFacturacion);

            dispatch(startAsignacionFacturacion(folio,idFacturacion, variant))

            closeModal.current.click();






        } else {
            let customer = facturacion.filter(item => item.ID === $id)[0];
            dispatch(asignedFacturacion(customer));
            closeModal.current.click();
        }










    }


    return (
        <div className="modal fade" id="searchFacturacion" data-bs-backdrop="static"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="exampleModalLabel">Buscador de Datos de Facturacion </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearStore}></button>
                        {/*  */}
                    </div>
                    <div className="modal-body p-3">


                        <form id='searchTool' onSubmit={handleSubmit(handledSearch)}  >

                            <h5 className='fw-bold'>Nueva busqueda</h5>
                            <hr className='my-0 mb-3' />
                            <div className="d-flex">
                                <select className="form-select w-25 me-1" {...register("param")} >
                                    {/* */}
                                    <option value='1'>RAZON SOCIAL.</option>
                                    <option value='0'>RFC</option>
                                    <option value='2'>TEL.</option>
                                </select>
                                <input className="form-control me-2" type="search" aria-label="Search" autoComplete='off' {...register("textSearch", { required: 'Introduce termino a buscar' })} />
                                {/*  */}
                                <button className="btn btn-outline-secondary" type="submit">
                                    <Search size="20" />
                                </button>
                                <button className="btn btn-outline-success ms-2" type="button" onClick={clearStore}>
                                    {/*  */}
                                    <RefreshCcw size="20" />
                                </button>
                            </div>
                        </form>

                        <div className="mt-3">
                            {
                                isLoading ? <>
                                    <div className='div_center'>
                                        <div className='text-center'>
                                            <div className="spinner"></div>
                                        </div>
                                        <div className='text-center text-left-15'>
                                            <span className='fw-bold'>Cargando</span>
                                        </div>
                                        <div className='text-center text-left-15'>
                                            <div className="spinner"></div>
                                        </div>
                                    </div>
                                </> : <>
                                    <h5 className='fw-bold'>Listado de Coincidencias</h5>
                                    <hr className='my-0' />
                                    <div className="table-responsive table-scrollbar-6 mt-2">
                                        <table className="table table-hover table-striped table-sm">
                                            <thead>
                                                <tr>

                                                    <th scope="col" className='th-sticky'>DATOS FACTURACION</th>
                                                    <th scope="col" className='th-sticky text-center'>ESTADO</th>
                                                    <th scope="col" className='th-sticky text-center'>ACCIONES</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    facturacion.length === 0 ? <>
                                                        <tr>
                                                            <td scope="row" colSpan={8}> No se han encontrado datos.</td>
                                                        </tr>
                                                    </> :
                                                        facturacion.map((item, index) => (
                                                            <tr key={index}>

                                                                <td className="text-start">RFC: {item.RFC}, {item.RAZON_SOCIAL}, {item.REGIMEN_FISCAL}, Tel: {item.TELEFONO}, Correo: {item.CORREO}</td>

                                                                <td className="text-center">
                                                                    {
                                                                        item.ESTADO == 1 ?
                                                                            <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                    }
                                                                </td>

                                                                <td className="text-center">

                                                                    <button type="button" className="btn btn-outline-primary border-0" onClick={() => handledSelectedFacturacion(item.ID)}>
                                                                        <FilePlus size='20' /> Escoger
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            }

                        </div>
                        {/* <button type="button" className="btn btn-outline-dark text-start" onClick={() => showSearch()}>Probar otra busqueda</button> */}
                    </div>

                    <div className="modal-footer">
                        <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={clearStore}>
                            {/*  */}
                            Cerrar
                        </button>

                    </div>
                </div>
            </div>
        </div>
    )
}
