import React, { useEffect, useState } from 'react'
import { AlertTriangle, Plus, Trash } from 'react-feather'
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';

export const SaleSpecial = ({ setProductosCompra, ProductosCompra }) => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
      // toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });


  const solo_num = (e) => {
    const allowedKeys = [
      'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
    ];

    const inputValue = e.target.value;
    const hasDecimal = inputValue.includes('.');

    // Si ya existe un punto decimal, solo permitir dos dígitos después de él
    if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
      e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
      return;
    }

    if (
      (e.key >= '0' && e.key <= '9') || // Números
      allowedKeys.includes(e.key) // Otras teclas permitidas
    ) {
      return true;
    } else {
      e.preventDefault(); // Evitar cualquier otra tecla
    }
  };

  const { register, handleSubmit, reset, setValue, getValues, formState: { errors }, unregister } = useForm({
    defaultValues: {
      nombre: "",
      descripcion: "",
      monto: "",
      // descripcionAnticipo: ""
    }
  });

  const [isSpecialSaleVisible, setIsSpecialSaleVisible] = useState(false);

  const addArticulo = (data) => {

    Toast.fire({
      icon: 'success',
      title: 'Producto Agregado a la lista'
    });


    let sol = {};
    if (isSpecialSaleVisible) {

      sol = {
        "ID": Date.now(),
        "NOMBRE": data.nombre,
        "DESCRIPCION": data.descripcion,
        "COSTO_PUBLICO": document.getElementById('anticipo') ? document.getElementById('anticipo').value : 0,
        "COSTO_VENTA": data.monto,
        "EXISTENCIA": Date.now(),
        "CANTIDAD": 1,
        "ACUMULADO": 1 * Number(document.getElementById('anticipo') ? document.getElementById('anticipo').value : 0),
        "VENTA_ESPECIAL": 2,
        "ENLACE": data.enlace || "",
        "ANTICIPO": document.getElementById('anticipo') ? document.getElementById('anticipo').value : 0,
        "RESTA": data.resta || 0,
      }

    } else {

        sol = {
          "ID": Date.now(),
          "NOMBRE": data.nombre,
          "DESCRIPCION": data.descripcion,
          "COSTO_PUBLICO": data.monto,
          "EXISTENCIA": Date.now(),
          "CANTIDAD": 1,
          "ACUMULADO": 1 * Number(data.monto),
          "VENTA_ESPECIAL": 1,
          // "ENLACE": data.enlace || "",
          // "ANTICIPO": document.getElementById('anticipo') ? document.getElementById('anticipo').value : 0,
          // "RESTA": data.resta || 0,
        }
    }


    setProductosCompra([...ProductosCompra, sol]);
    reset();
  }

  const clearForm = () => {
    reset();
  }


  const calculated = (e) => {
    let pago = e.target.value;
    let montoCobrar = getValues('monto');
    let resta = Number(montoCobrar) - Number(pago);
    setValue('resta', resta);
  }


  const showDivSpecialSale = (e) => {
    setIsSpecialSaleVisible(e.target.checked);
    if (!e.target.checked) {
      // Desregistramos los campos si están ocultos
      unregister('enlace');
      // unregister('anticipo');
      unregister('resta');
    }
  };



  return (
    <div className='mt-2'>
      <form className='container row' onSubmit={handleSubmit(addArticulo)}>
        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 mb-3">
          <label className="form-label">Producto:</label>
          <input type="text" className="form-control" aria-describedby="emailHelp" autoComplete='off' {...register("nombre", { required: 'Nombre del articulo' })} />
          {
            errors.nombre &&
            <div className='text-left-5 mt-2'>
              <AlertTriangle size="18" color="#dc3545" />
              <span className='text-left-10 fw-bold text-danger'><small>{errors.nombre.message}</small></span>
            </div>
          }
        </div>

        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mb-3">
          <label className="form-label">Descripcion:</label>
          <input type="text" className="form-control" aria-describedby="emailHelp"{...register("descripcion")} />
        </div>

        <div className="col-8 col-sm-8 col-md-2 col-lg-2 col-xl-2 col-xxl-2 mb-3">
          <label className="form-label">Costo:</label>
          <input type="number" className="form-control" aria-describedby="emailHelp" min={1} autoComplete='off' onKeyDown={solo_num} {...register("monto", { required: 'Costo del articulo' })} />
          {
            errors.monto &&
            <div className='text-left-5 mt-2'>
              <AlertTriangle size="18" color="#dc3545" />
              <span className='text-left-10 fw-bold text-danger'><small>{errors.monto.message}</small></span>
            </div>
          }
        </div>
        <div className="col-4 col-sm-4 col-md-1 col-lg-1 col-xl-1 col-xxl-1 mb-3 mt-4">
          <div className="btn-group" role="group" aria-label="Basic example">

            <button type="button" className="btn btn-outline-danger mt-1" onClick={clearForm} ><Trash size='20' /></button>
            <button type="submit" className="btn btn-outline-dark mt-1"  ><Plus size='20' /></button>
          </div>
        </div>
        <div className='col-12'>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" value="1" onClick={e => showDivSpecialSale(e)} id="flexCheckDefault" />
            <label className="form-check-label" >
              Compra por pedido
            </label>
          </div>
        </div>


        {
          isSpecialSaleVisible &&
          (
            <div className='col-12' id='saleSpecial_div'>
              <div className='row mt-1'>
                <div className='col-12 col-md-8'>
                  <div className="mb-2">
                    <label className="form-label">Enlace:</label>
                    <input type='text' className="form-control" {...register("enlace", { required: 'Enlace para la compra es requerido' })} />
                    {errors.enlace && (
                      <div className='text-left-5 mt-2'>
                        <AlertTriangle size="18" color="#dc3545" />
                        <span className='text-left-10 fw-bold text-danger'>
                          <small>{errors.enlace.message}</small>
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-6 col-md-2">
                  <div className="mb-2">
                    <label className="form-label">Anticipo:</label>
                    <input type="number" className="form-control" autoComplete='off' id='anticipo' onKeyDown={solo_num} min={1} onChange={e => calculated(e)} />

                  </div>
                </div>

                <div className="col-6 col-md-2">
                  <div className="mb-2">
                    <label className="form-label">Resta:</label>
                    <input type="number" className="form-control" autoComplete='off' disabled onKeyDown={solo_num} min={0} {...register("resta")} />
                  </div>
                </div>
              </div>
            </div>
          )
        }





      </form >
    </div >
  )
}
