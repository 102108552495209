import React, { useEffect, useRef } from 'react';
import { Slash, Save, AlertTriangle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startRegistroEmpleado } from '../../../../app/store/empleados/thunks';


export const NewEmployee = () => {
    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const closeModal = useRef(null);
    const { puestos, roles } = useSelector(state => state.catalogos);
    const { usuario } = useSelector(state => state.usuario);
    const { status, isLoading } = useSelector(state => state.empleados);
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            nombreEmpleado: "",
            rfcEmpleado: "",
            emailEmpleado: "",
            telEmpleado: "",
            celEmpleado: "",
            puestoEmpleado: "",
            rolEmpleado: "",
            usuario: "",
            password: "",
        }
    });



    const generatePWF = () => {
        const alph = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz1234567890!@#";
        let pwdgenerated = "";
        for (let index = 0; index <= 10; index++) {
            pwdgenerated += alph.charAt(Math.floor(Math.random() * alph.length))
        }
        setValue('password', pwdgenerated);
        // console.log(pwdgenerated);
    }

    const clearForm = () => {
        reset();
    }

    

    const handleSaveEmpleado = (data) => {
        dispatch(startRegistroEmpleado(data, usuario));
        clearForm();
    }

    useEffect(() => {
        if (status == 'EMPLEADOS_CARGADOS') {
            reset();
            closeModal.current.click();
        }
    }, [status])



    return (
        <div className="modal fade" id="newEmployee" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Nuevo Empleado</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <form onSubmit={handleSubmit(handleSaveEmpleado)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                    <div className="mb-2">
                                        <label className="form-label">Nombre:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreEmpleado", { required: 'Nombre del empleado es requerido.' })} />
                                        {
                                            errors.nombreEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-2">
                                        <label className="form-label">RFC:</label>
                                        <input type="text" className="form-control" aria-label="First name"  {...register("rfcEmpleado", {
                                            required: 'RFC requerido.',
                                            minLength: { value: 12, message: 'RFC minimo 12 caracteres, sin guiones ni espacios' },
                                            maxLength: { value: 13, message: 'RFC maximo 13 caracteres, sin guiones ni espacios' }
                                        })} />
                                        {
                                            errors.rfcEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rfcEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                    <div className="mb-2">
                                        <label className="form-label">Correo Electronico:</label>
                                        <input type="email" className="form-control" aria-label="First name" autoComplete='off' {...register("emailEmpleado", {
                                            required: 'Email del empleado es requerido.',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Introduce una direccion de correo',
                                            },
                                        })} />
                                        {
                                            errors.emailEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.emailEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-2">
                                        <label className="form-label">Telefono:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("telEmpleado", {
                                            required: 'Telefono requerido.',
                                            minLength: { value: 10, message: 'Telefono debe tener 10 digitos' },
                                            maxLength: { value: 10, message: 'Telefono debe tener 10 digitos' }
                                        })} />
                                         {
                                            errors.telEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.telEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-2">
                                        <label className="form-label">Puesto:</label>
                                        <select className="form-select" {...register('puestoEmpleado', { required: 'Debe seleccionar el Puesto' })} >
                                            <option value="">Seleccione un puesto</option>
                                            {
                                                puestos.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.puestoEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.puestoEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-2">
                                        <label className="form-label">Rol:</label>
                                        <select className="form-select" {...register('rolEmpleado', { required: 'Debe seleccionar el Rol' })}>
                                            <option value="">Seleccione un rol</option>
                                            {
                                                roles.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {
                                            errors.rolEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rolEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-2">
                                        <label className="form-label">Celular:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("celEmpleado", {
                                            required: 'Celular requerido.',
                                            minLength: { value: 10, message: 'Celular debe tener 10 digitos' },
                                            maxLength: { value: 10, message: 'Celular debe tener 10 digitos' }
                                        })} />
                                        {
                                            errors.celEmpleado &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.celEmpleado.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 mb-3">
                                    <h5 className="fw-bold me-2 border-0 mt-2">Acceso al Sistema </h5>
                                    <hr className="my-0" />
                                </div>

                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Usuario:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("usuario", { required: 'Usuario del empleado es requerido.' })} />
                                        {
                                            errors.usuario &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.usuario.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                    <div className="mb-2">
                                        <label className="form-label">Contraseña:</label>
                                        <input type="text" className="form-control" aria-label="First name"
                                            {...register("password", { required: 'La contraseña es requerida.', minLength: { value: 8, message: 'La contraseña debe contener minimo 8 caracteres' } })} />
                                        {
                                            errors.password &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.password.message}</small></span>
                                            </div>
                                        }
                                        <button type="button" className="btn btn-outline-success mt-2" onClick={generatePWF}>Generar Contraseña</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button> */}
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button"  className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size="20" /> Cancelar
                            </button>

                            {
                                status == "PROCESANDO_PETICION" ?
                                    <>
                                        <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  Guardando...
                                        </button></> :
                                    <>
                                        <button type="submit" className="btn btn-outline-primary"  >
                                            <Save size="20" /> Guardar
                                        </button>
                                    </>
                            }

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
