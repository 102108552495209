import React from 'react'

export const ViewServicio = () => {
    return (
        <div className="modal fade" id="viewDetail" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Detalle Servicio: Servicio Interno</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">

                            <div className="col-12">
                                <div className="mb-2">
                                    <label className="form-label">Servicio:</label>
                                    <input type="text" className="form-control" aria-label="First name" disabled/>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-2">
                                    <label className="form-label">Costo Serv:</label>
                                    <input type="text" className="form-control" aria-label="First name" disabled />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-2">
                                    <label className="form-label">Comision Recepcionista:</label>
                                    <input type="text" className="form-control" aria-label="First name" disabled  />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-2">
                                    <label className="form-label">Comision Tecnico:</label>
                                    <input type="text" className="form-control" aria-label="First name" disabled/>
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="mb-2">
                                    <label className="form-label">Tiempo p/ realizar:</label>
                                    <input type="text" className="form-control" aria-label="First name" disabled />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-2">
                                    <label className="form-label">Descripción:</label>
                                    <textarea className="form-control" rows="6" disabled></textarea>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
