import React, { useEffect, useRef } from 'react'
import { AlertTriangle, Save, Slash } from 'react-feather'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { startEditCategoria, startEditDescuento, startEditMarca } from '../../../../../app/store/catalagos/thunks';

export const EditDescuento = () => {

    const dispatch = useDispatch();
    const closeModal = useRef(null);

    const { descuentos_edit, status } = useSelector(state => state.catalogos);
    const { register, reset, formState: { errors }, handleSubmit } = useForm({
        defaultValues: {
            nombre: "",
            valor: ""
        }
    });

    const clearForm = () => {
        reset();
    }

    useEffect(() => {
        if (status == 'Descuentos Cargados') {
            clearForm();
        }
    }, [status])

    if (status == 'Descuentos Cargados') closeModal.current.click();


    const handleSave = (data) => {
        console.log(data);
        dispatch(startEditDescuento(descuentos_edit.ID, data.nombre,data.valor))
    }

    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    return (
        <div className="modal fade" id="editDescuento" aria-labelledby="exampleModalLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold" id="exampleModalLabel">Editar categoria: {descuentos_edit.NOMBRE}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>

                    <form onSubmit={handleSubmit(handleSave)}>
                        <div className="modal-body">
                            <div className='row'>

                                <div className="col-6 mb-2">
                                    <label className="form-label">Nombre:</label>
                                    <input type="text" className="form-control" aria-label="First name" autoComplete='off'
                                        //  {...register("nombre"),  required: 'Escribe el Nombre de la Marca', minLength: { value: 3, message: 'El Nombre de la Marca debe tener por lo menos 3 caracters' } } 
                                        {...register('nombre', { required: 'Escribe el Nombre de la Categoria', minLength: { value: 1, message: 'El Nombre de la Categoria debe tener por lo menos 1 caracters' } })}
                                    />
                                    {
                                        errors.nombre &&
                                        <div className='text-left-5 mt-1 '>
                                            {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.nombre.message}</small></span>
                                        </div>
                                    }
                                </div>
                                <div className='col-6'>
                                    <label className="form-label">Valor:</label>
                                    <input className="form-control me-2" type="text" autoComplete='off' onKeyDown={solo_num} min={0}
                                        {...register('valor', { required: 'Escribe el  valor del descuento, Solo numeros sin decimales' })}
                                    />

                                    {
                                        errors.valor &&
                                        <div className='text-left-5 mt-1 '>
                                            {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.valor.message}</small></span>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" onClick={clearForm} data-bs-dismiss="modal"> <Slash size='20' />  Cancelar</button>
                            <button type="submit" className="btn btn-outline-primary"> <Save size='20' />  Guardar </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
