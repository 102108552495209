import React from 'react';
import { Bookmark, Award, Lock, Clipboard, CreditCard, Trash2, RefreshCcw, Plus, Edit, Eye, AlertTriangle } from 'react-feather';
import { EditPuesto } from '../modals/Puestos/EditPuesto';
import { NewPuesto } from '../modals/Puestos/NewPuesto';
import Swal from 'sweetalert2';
import { NewServicio } from '../modals/Servicios/NewServicio';
import { EditServicio } from '../modals/Servicios/EditServicio';
import { ViewServicio } from '../modals/Servicios/ViewServicio';
import { NewRol } from '../modals/Roles/NewRol';
import { EditRol } from '../modals/Roles/EditRol';
import { useDispatch, useSelector } from 'react-redux';
import { loadCConocieronEdit, loadCategoriaEdit, loadMarcaEdit, loadTipoCliente, startActCConocieron, startActCategoria, startActCategoriaClientes, startActMarca, startActPuesto, startActRol, startActServicio, startDelCConocieron, startDelCategoria, startDelCategoriaClientes, startDelMarca, startDelPuesto, startDelRol, startDelServicio, startRegistroCConocieron, startRegistroCategoria, startRegistroClasficacion, startRegistroMarca, startloadPuesto, startloadRol, startloadServicio } from '../../../../app/store/catalagos/thunks';
import { useForm } from "react-hook-form";
import { connectionFiles } from '../../../../app/filesConnection'
import { ClasificacionClienteEdit } from '../modals/Clasificacion/ClasificacionClienteEdit';
import { EditMarca } from '../modals/ToDo/EditMarca';
import { EditCategoria } from '../modals/ToDo/EditCategoria';
import { EditConocieron } from '../modals/ToDo/EditConocieron';

export const Catalogos = ({ solo_num }) => {

    const { marcas, categorias, puestos, roles, tiposClientes, servicios, como_conocieron } = useSelector(state => state.catalogos);
    const { usuario, nombre, rol, puesto, avatar } = useSelector(state => state.usuario);
    const dispatch = useDispatch();
    // console.log(roles);

    const { register, handleSubmit, resetField, formState: { errors } } = useForm({ model: 'all' });
    const { register: registerMarc, handleSubmit: handleSubmitMarc, resetField: resetFieldMarc, formState: { errors: errorsMarc } } = useForm({ model: 'all' });
    const { register: registerCla, handleSubmit: handleSubmitCla, resetField: resetFieldCla, formState: { errors: errorsCla } } = useForm({ model: 'all' });
    const { register: registerCConocieron, handleSubmit: handleSubmitCConocieron, resetField: resetFieldCConocieron, formState: { errors: errorsCConocieron } } = useForm({ model: 'all' });

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
    // Toast.fire({
    //   icon: 'success',
    //   title: 'Categoria Desactivada'
    // })

    // Categorias

    const handleSaveCategoria = (data) => {
        // console.log(data);
        let categoria = data.nombreCategoria;
        let usuario_cap = usuario;
        dispatch(startRegistroCategoria(categoria, usuario_cap));
        resetField("nombreCategoria");
    }

    const handledDelCat = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una Categoria de Equipo, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelCategoria(e));
            }
        })
    }

    const handledActCat = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Categoria de Equipo, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActCategoria(e));
            }
        })
    }

    // Marcas
    const handleSaveMarc = (data) => {
        // console.log(data);
        let marca = data.nombreMarca; let usuario_cap = usuario;
        dispatch(startRegistroMarca(marca, usuario_cap));
        resetFieldMarc("nombreMarca");
    }

    const handledDelMar = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una Marca, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelMarca(e));
            }
        })
    }

    const handledActMar = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Marca, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActMarca(e));
            }
        })
    }

    // Puestos
    const handledDelPuesto = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un Puesto de Trabajo, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelPuesto(e))
            }
        })
    }

    const handledActPuesto = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR un Puesto de Tarbajo, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActPuesto(e))
            }
        })
    }

    const handledEditPuesto = (e) => {
        dispatch(startloadPuesto(e));
    }

    //Clasificacion
    const handleSaveClasificacion = (data) => {
        // console.log(data);
        let filesA = document.getElementById("fileUpo").files[0];
        let clasificacion = data.nombreClasificacion;
        let usuario_cap = usuario;
        dispatch(startRegistroClasficacion(clasificacion, usuario_cap, filesA));
        resetFieldCla("nombreClasificacion");
        resetFieldCla("imagenClasificacion");
    }

    const handledDelCla = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un Clasificacion de Cliente, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                // console.log('Ejecutar Thunks');
                // Toast.fire({
                //   icon: 'success',
                //   title: 'Puesto Desactivado'
                // })

                dispatch(startDelCategoriaClientes(e))
            }
        })
    }

    const handledActCla = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Clasificacion de Cliente, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActCategoriaClientes(e))
            }
        })
    }

    // Roles
    const handledDelRol = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un Rol de Usuario, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelRol(e));
            }
        })
    }

    const handledActRol = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR un Rol de Usuario, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActRol(e));
            }
        })
    }

    const handledEditRol = (e) => {
        dispatch(startloadRol(e));
    }

    //Servicios
    const handledDelServicios = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR un Servicio, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelServicio(e));
            }
        })
    }

    const handledActServicios = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Servicio, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActServicio(e));
            }
        })
    }

    const handledEditServicio = (e) => {
        dispatch(startloadServicio(e));
    }


    const handledDelCConocieron = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por DESACTIVAR una Razon de Como nos conocieron, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startDelCConocieron(e));
            }
        })
    }

    const handledActCConocieron = (e) => {
        Swal.fire({
            title: 'Atención',
            text: "Está por REACTIVAR una Razon de Como nos conocieron, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startActCConocieron(e));
            }
        })
    }

    const handleSaveCConocieron = (data) => {
        // console.log(data);
        let clasificacion = data.descripCConocieron;
        let usuario_cap = usuario;
        dispatch(startRegistroCConocieron(clasificacion, usuario_cap));
        resetFieldCConocieron("descripCConocieron");
    }

    const handledEditClaCliente = (e) => {
        console.log(e);
        dispatch(loadTipoCliente(e))
    }

    const handledEditMarca = (e) => {
        // console.log(e);
        dispatch(loadMarcaEdit(e))
    }

    const handledCatEquipo = (e) => {
        // console.log(e);
        dispatch(loadCategoriaEdit(e))
    }


    const handledEditConocieron = (e) => {
        console.log(e);
        dispatch(loadCConocieronEdit(e))
    }



    return (
        <>
            <div className="mb-3">
                <h1 className="h4 d-inline align-middle fw-bold">Catalogos</h1>
            </div>

            <div className="accordion accordion-flush" >

                {/* Categorias y Marcas :  Completo */}
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <Bookmark size="20" /> <span className='text-left-10'>Categorias y Marcas</span>
                        </button>
                    </h2>

                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">

                            <div className="row">
                                <div className="col-12">
                                    <div className="row">

                                        <EditCategoria />
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-end mt-3">
                                            <h1 className="h5 mb-3 fw-bold">Categorias de Equipos</h1>

                                            <form onSubmit={handleSubmit(handleSaveCategoria)}>
                                                <div className='row'>
                                                    <div className='col-10'>
                                                        <input className="form-control me-2" type="text" autoComplete='off' {...register('nombreCategoria', { required: 'Escribe el Nombre de la Nueva Categoria', minLength: { value: 3, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 3 caracters' } })} />
                                                        {
                                                            errors.nombreCategoria &&
                                                            <div className='text-left-5 mt-1 '>
                                                                {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreCategoria.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='col-1'>
                                                        <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="table-responsive p-2 mt-2 table-scrollbar">
                                                <table className="table table-striped table-hover table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col" className="text-start">NOMBRE</th>
                                                            <th scope="col" className="text-center">ESTADO</th>
                                                            <th scope="col" className="text-center">ACCIONES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            categorias.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td scope="row" colSpan={4}> No se han encontrado datos.</td>

                                                                    </tr>
                                                                </> :
                                                                categorias.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td scope="row">{item.ID}</td>
                                                                        <td className="text-start">{item.NOMBRE}</td>
                                                                        <td className="text-center">
                                                                            {
                                                                                item.ESTADO == 1 ?
                                                                                    <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editCategoria" onClick={e => handledCatEquipo(item.ID)}>
                                                                                <Edit size="20" />
                                                                            </button>
                                                                            {
                                                                                item.ESTADO == 1 ? <>
                                                                                    <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCat(item.ID)}>
                                                                                        <Trash2 size="20" />
                                                                                    </button></> :
                                                                                    <>
                                                                                        <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCat(item.ID)}>
                                                                                            <RefreshCcw size="20" />
                                                                                        </button>
                                                                                    </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>


                                        <EditMarca />
                                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-3">
                                            <h1 className="h5 mb-3 fw-bold">Marcas</h1>
                                            <form onSubmit={handleSubmitMarc(handleSaveMarc)}>
                                                <div className='row'>
                                                    <div className='col-10'>
                                                        <input className="form-control me-2" type="text" autoComplete='off' {...registerMarc('nombreMarca', { required: 'Escribe el Nombre de la Nueva Marca', minLength: { value: 2, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 2 caracters' } })} />
                                                        {
                                                            errorsMarc.nombreMarca &&
                                                            <div className='text-left-5 mt-1 '>
                                                                {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                                <AlertTriangle size="18" color="#dc3545" />
                                                                <span className='text-left-10 fw-bold text-danger'><small>{errorsMarc.nombreMarca.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className='col-1'>
                                                        <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                                    </div>
                                                </div>
                                            </form>
                                            <div className="table-responsive p-2 mt-2 table-scrollbar">
                                                <table className="table table-striped table-hover table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">ID</th>
                                                            <th scope="col" className="text-start">NOMBRE</th>
                                                            <th scope="col" className="text-center">ESTADO</th>
                                                            <th scope="col" className="text-center">ACCIONES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            marcas.length === 0 ?
                                                                <>
                                                                    <tr>
                                                                        <td scope="row" colSpan={4}> No se han encontrado datos.</td>
                                                                    </tr>
                                                                </> :
                                                                marcas.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td scope="row">{item.ID}</td>
                                                                        <td className="text-start">{item.NOMBRE}</td>
                                                                        <td className="text-center">
                                                                            {
                                                                                item.ESTADO == 1 ?
                                                                                    <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                            }
                                                                        </td>
                                                                        <td className="text-center">
                                                                            <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editMarca" onClick={e => handledEditMarca(item.ID)}>
                                                                                <Edit size="20" />
                                                                            </button>
                                                                            {
                                                                                item.ESTADO == 1 ? <>
                                                                                    <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelMar(item.ID)}>
                                                                                        <Trash2 size="20" />
                                                                                    </button></> :
                                                                                    <>
                                                                                        <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActMar(item.ID)}>
                                                                                            <RefreshCcw size="20" />
                                                                                        </button>
                                                                                    </>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Puestos :  Completo */}
                <NewPuesto /> <EditPuesto />
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <Award size="20" /> <span className='text-left-10'>Puestos</span>
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <h1 className="h4 mb-3 fw-bold">Puestos de Empleados</h1>
                            <div className="row">
                                <div className="col-12">
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                                        <h5 className="card-title mb-0">Listado</h5>
                                        <button type="button" className="btn btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#newPuesto">Agregar Nuevo</button>
                                    </div>
                                    <div className="table-responsive p-2 mt-2 table-scrollbar">
                                        <table className="table table-striped table-hover table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col" className="text-start">NOMBRE</th>
                                                    <th scope="col" className="text-start">DESCRIPCIÓN</th>
                                                    <th scope="col" className="text-center">SALARIO</th>
                                                    <th scope="col" className="text-center">ESTADO</th>
                                                    <th scope="col" className="text-center">ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    puestos.length === 0 ?
                                                        <>
                                                            <tr>
                                                                <td scope="row" colSpan={6}> No se han encontrado datos.</td>
                                                            </tr>
                                                        </> :
                                                        puestos.map((item, index) => (
                                                            <tr key={index}>

                                                                <td scope="row">{item.ID}</td>
                                                                <td className="text-start">{item.NOMBRE}</td>
                                                                <td className="text-start">{item.DESCRIPCION} </td>
                                                                <td className="text-center">
                                                                    {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.SALARIO)}
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        item.ESTADO == 1 ?
                                                                            <>  <span className="status-btn complete-btn"> ACTIVO </span></> :
                                                                            <><span className="status-btn pending-btn"> INACTIVO </span></>
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    <div className="btn-group border-0" role="group"
                                                                        aria-label="Basic example">
                                                                        <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editPuesto" onClick={e => handledEditPuesto(item.ID)}>
                                                                            <Edit size="20" />
                                                                        </button>
                                                                        {
                                                                            item.ESTADO == 1 ? <>
                                                                                <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelPuesto(item.ID)}>
                                                                                    <Trash2 size="20" />
                                                                                </button></> :
                                                                                <>
                                                                                    <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActPuesto(item.ID)}>
                                                                                        <RefreshCcw size="20" />
                                                                                    </button></>
                                                                        }
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Roles :  Completo */}
                <NewRol /> <EditRol />
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <Lock size="20" /> <span className='text-left-10'>Roles</span>
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">

                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                                <h5 className="card-title mb-0">Listado</h5>
                                <button type="button" className="btn btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#newRol">Agregar Nuevo</button>
                            </div>
                            <div className="table-responsive p-2 mt-2 table-scrollbar">
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col" className="text-start">NOMBRE</th>
                                            <th scope="col" className="text-start">DESCRIPCIÓN</th>
                                            <th scope="col" className="text-start">DASBOARDS</th>
                                            <th scope="col" className="text-center">ESTADO</th>
                                            <th scope="col" className="text-center">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            roles.length === 0 ?
                                                <>
                                                    <tr>
                                                        <td scope="row" colSpan={6}> No se han encontrado datos.</td>

                                                    </tr>
                                                </> :
                                                roles.map((item, index) => (
                                                    <tr key={index}>
                                                        <td scope="row">{item.ID}</td>
                                                        <td className="text-start">{item.NOMBRE}</td>
                                                        <td className="text-start">{item.DESCRIPCION}</td>
                                                        <td className="text-start">{item.DASH_ADMIN},{item.DASH_TEC},{item.DASH_RECP},{item.DASH_CAJA},{item.DASH_ALM}, {item.DASH_AUX_OP}</td>
                                                        <td className="text-center">
                                                            {
                                                                item.ESTADO == 1 ?
                                                                    <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editRol" onClick={e => handledEditRol(item.ID)}>
                                                                <Edit size="20" />
                                                            </button>
                                                            {
                                                                item.ESTADO == 1 ? <>
                                                                    <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelRol(item.ID)}>
                                                                        <Trash2 size="20" />
                                                                    </button></> :
                                                                    <>
                                                                        <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActRol(item.ID)}>
                                                                            <RefreshCcw size="20" />
                                                                        </button>
                                                                    </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                        }


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Servicios : Completo */}
                <NewServicio /> <EditServicio /> <ViewServicio />
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                            <Clipboard size="20" /> <span className='text-left-10'>Servicios</span>
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <div
                                className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center  border-bottom mb-3">
                                <h5 className="card-title mb-0">Listado</h5>
                                <button type="button" className="btn btn-outline-primary mb-2" data-bs-toggle="modal" data-bs-target="#newService">Agregar Nuevo</button>
                            </div>
                            <div className="table-responsive p-2 mt-2 table-scrollbar">
                                <table className="table table-striped table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col" className="text-start">NOMBRE</th>
                                            <th scope="col" className="text-start">DESCRIPCIÓN</th>
                                            <th scope="col" className="text-center">COSTO</th>
                                            <th scope="col" className="text-center">ESTADO</th>
                                            <th scope="col" className="text-center">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            servicios.length === 0 ?
                                                <>
                                                    <tr>
                                                        <td scope="row" colSpan={6}> No se han encontrado datos.</td>
                                                    </tr>
                                                </> :
                                                servicios.map((item, index) => (
                                                    <tr key={index}>

                                                        <td scope="row">{item.ID}</td>
                                                        <td className="text-start">{item.NOMBRE}</td>
                                                        <td className="text-start">{item.DESCRIPCION} </td>
                                                        <td className="text-center">
                                                            {Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(item.COSTO_SERV)}
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                item.ESTADO == 1 ?
                                                                    <>  <span className="status-btn complete-btn"> ACTIVO </span></> :
                                                                    <><span className="status-btn pending-btn"> INACTIVO </span></>
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="btn-group border-0" role="group"
                                                                aria-label="Basic example">

                                                                <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editService" onClick={e => handledEditServicio(item.ID)}>
                                                                    <Edit size="20" />
                                                                </button>
                                                                {
                                                                    item.ESTADO == 1 ? <>
                                                                        <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelServicios(item.ID)}>
                                                                            <Trash2 size="20" />
                                                                        </button></> :
                                                                        <>
                                                                            <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActServicios(item.ID)}>
                                                                                <RefreshCcw size="20" />
                                                                            </button></>
                                                                }
                                                            </div>
                                                        </td>

                                                    </tr>
                                                ))
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Categorias  Clientes :  Completo */}
                <ClasificacionClienteEdit />
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <CreditCard size="20" /> <span className='text-left-10'>Clasificacion /Como nos conocieron los Clientes</span>
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">

                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 border-end mt-3">
                                    <h1 className="h5 mb-3 fw-bold">Clasificacion de Clientes</h1>
                                    <form onSubmit={handleSubmitCla(handleSaveClasificacion)}>
                                        <div className='row'>
                                            <div className='col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4'>
                                                <label className="form-label">Nombre:</label>
                                                <input className="form-control me-2" type="text" autoComplete='off' {...registerCla('nombreClasificacion', { required: 'Escribe el Nombre de la Nueva Clasificacion de Cliente', minLength: { value: 3, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 3 caracters' } })} />
                                                {
                                                    errorsCla.nombreClasificacion &&
                                                    <div className='text-left-5 mt-1 '>
                                                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                        <AlertTriangle size="18" color="#dc3545" />
                                                        <span className='text-left-10 fw-bold text-danger'><small>{errorsCla.nombreClasificacion.message}</small></span>
                                                    </div>
                                                }
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-1'>
                                                <label className="form-label">Imagen:</label>
                                                <input className="form-control" type="file" id='fileUpo' name='fileUpo' {...registerCla('imagenClasificacion', { required: 'Adjunta archivo.' })} />
                                                {
                                                    errorsCla.imagenClasificacion &&
                                                    <div className='text-left-5 mt-1 '>

                                                        <AlertTriangle size="18" color="#dc3545" />
                                                        <span className='text-left-10 fw-bold text-danger'><small>{errorsCla.imagenClasificacion.message}</small></span>
                                                    </div>
                                                }

                                                {/* 
                                                <input className="form-control me-2" type="text" autoComplete='off' {...registerCla('nombreClasificacion', { required: 'Escribe el Nombre de la Nueva Clasificacion de Cliente', minLength: { value: 3, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 3 caracters' } })} />
                                               } */}
                                            </div>
                                            <div className='col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 col-xxl-1 mt-4'>
                                                <button type="submit" className="btn btn-outline-primary mt-2">  <Plus size="22" /> </button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="table-responsive p-2 mt-2 table-scrollbar">
                                        <table className="table table-striped table-hover table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    {/* <th scope="col" className="text-start">NOMBRE</th> */}
                                                    <th scope="col" className="text-start">DESCRIPCION</th>
                                                    <th scope="col" className="text-start">IMAGEN</th>
                                                    <th scope="col" className="text-center">ESTADO</th>
                                                    <th scope="col" className="text-center">ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tiposClientes.length === 0 ?
                                                        <>
                                                            <tr>
                                                                <td scope="row" colSpan={4}> No se han encontrado datos.</td>

                                                            </tr>
                                                        </> :
                                                        tiposClientes.map((item, index) => (
                                                            <tr key={index}>
                                                                <td scope="row">{item.ID}</td>
                                                                <td className="text-start">{item.NOMBRE}</td>
                                                                <td className="text-start">
                                                                    <a type="button"
                                                                        href={`${connectionFiles['imagenes']}/${item.IMAGEN}`}
                                                                        target='_blank' className="btn btn-outline-dark border-0">  <Eye size="20" /></a>
                                                                </td>
                                                                <td className="text-center">
                                                                    {
                                                                        item.ESTADO == 1 ?
                                                                            <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editClaCliente" onClick={e => handledEditClaCliente(item.ID)}>
                                                                        <Edit size="20" />
                                                                    </button>
                                                                    {
                                                                        item.ESTADO == 1 ? <>
                                                                            <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCla(item.ID)}>
                                                                                <Trash2 size="20" />
                                                                            </button></> :
                                                                            <>
                                                                                <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCla(item.ID)}>
                                                                                    <RefreshCcw size="20" />
                                                                                </button>
                                                                            </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <EditConocieron />
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 mt-3">
                                    <h1 className="h5 mb-3 fw-bold">Como nos conocieron</h1>
                                    <form onSubmit={handleSubmitCConocieron(handleSaveCConocieron)}>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <input className="form-control me-2" type="text" autoComplete='off' {...registerCConocieron('descripCConocieron', { required: 'Escribe Nueva Razon de como nos conocieron', minLength: { value: 3, message: 'El Nombre de la Nueva Categoria debe tener por lo menos 3 caracters' } })} />
                                                {
                                                    errorsCConocieron.descripCConocieron &&
                                                    <div className='text-left-5 mt-1 '>
                                                        {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                        <AlertTriangle size="18" color="#dc3545" />
                                                        <span className='text-left-10 fw-bold text-danger'><small>{errorsCConocieron.descripCConocieron.message}</small></span>
                                                    </div>
                                                }
                                            </div>
                                            <div className='col-1'>
                                                <button type="submit" className="btn btn-outline-primary">  <Plus size="22" /> </button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="table-responsive p-2 mt-2 table-scrollbar">
                                        <table className="table table-striped table-hover table-sm">
                                            <thead>
                                                <tr>
                                                    <th scope="col">ID</th>
                                                    <th scope="col" className="text-start">DESCRIPCION</th>
                                                    <th scope="col" className="text-center">ESTADO</th>
                                                    <th scope="col" className="text-center">ACCIONES</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    como_conocieron.length === 0 ?
                                                        <>
                                                            <tr>
                                                                <td scope="row" colSpan={4}> No se han encontrado datos.</td>

                                                            </tr>
                                                        </> :
                                                        como_conocieron.map((item, index) => (
                                                            <tr key={index}>
                                                                <td scope="row">{item.ID}</td>
                                                                <td className="text-start">{item.DESCRIPCION}</td>

                                                                <td className="text-center">
                                                                    {
                                                                        item.ESTADO == 1 ?
                                                                            <> <span className="status-btn complete-btn"> ACTIVO </span>  </> : <> <span className="status-btn pending-btn"> ELIMINADO </span> </>
                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    <button type="button" className="btn btn-outline-secondary border-0" data-bs-toggle="modal" data-bs-target="#editConocieron" onClick={e => handledEditConocieron(item.ID)}>
                                                                        <Edit size="20" />
                                                                    </button>
                                                                    {
                                                                        item.ESTADO == 1 ? <>
                                                                            <button type="button" className="btn btn-outline-danger border-0" onClick={e => handledDelCConocieron(item.ID)}>
                                                                                <Trash2 size="20" />
                                                                            </button></> :
                                                                            <>
                                                                                <button type="button" className="btn btn-outline-success border-0" onClick={e => handledActCConocieron(item.ID)}>
                                                                                    <RefreshCcw size="20" />
                                                                                </button>
                                                                            </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))
                                                }


                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
