import React from 'react';
import { Slash, Save, AlertTriangle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startRegistroServicio } from '../../../../../app/store/catalagos/thunks';


export const NewServicio = () => {

    const { usuario } = useSelector(state => state.usuario);
    const { status, isLoading, productosAlm } = useSelector(state => state.catalogos);

    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            nombreServicio: "",
            costoServicio: "",
            comisionRecepcionista: "",
            comisionTecnico: "",
            tiempoDemoraServicio: "",
            descripcionServicio: "",
        }
    });


    const closeModal = useRef(null);
    const dispatch = useDispatch();
    const handleSaveServicio = (data) => {
        let usuario_cap = usuario;
        console.log(data);
        dispatch(startRegistroServicio(data, usuario_cap));
    }

    const clearForm = () => {
        reset();
    }

    useEffect(() => {
        if (status == 'Servicios Obtenidos') {
            reset();
        }
    }, [status])



    if (status == 'Servicios Obtenidos') closeModal.current.click();
    return (
        <div className="modal fade" id="newService" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog ">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Nuevo Servicio</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <form onSubmit={handleSubmit(handleSaveServicio)}>
                        <div className="modal-body">
                            <div className="row">

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Servicio:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("nombreServicio", { required: 'Nombre del Servicio es requerido.', minLength: { value: 5, message: 'El nombre debe contener minimo 5 caracteres' } })} />
                                        {
                                            errors.nombreServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.nombreServicio.message}</small></span>
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Costo Serv:</label>
                                        <input type="number" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} min={0} {...register("costoServicio", { required: 'Costo del Servicio es requerido.' })} />
                                        {
                                            errors.costoServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.costoServicio.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Comision Recepcionista:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} {...register("comisionRecepcionista", { required: 'C. para la Recep. es requerido' })} />
                                        {
                                            errors.comisionRecepcionista &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.comisionRecepcionista.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Comision Tecnico:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} {...register("comisionTecnico", { required: 'C. para el Tecnico es requerido' })} />
                                        {
                                            errors.comisionTecnico &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.comisionTecnico.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Tiempo p/ realizar:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} {...register("tiempoDemoraServicio", { required: 'Tiempo p/ realizar es requerido' })} />
                                        {
                                            errors.tiempoDemoraServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.tiempoDemoraServicio.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Descripción:</label>
                                        <textarea className="form-control" rows="4"
                                            {...register('descripcionServicio', {
                                                required: 'La descripción del Servicio es requerido.',
                                                minLength: { value: 10, message: 'La Descripción debe contener por los menos 10 caracteres' },
                                                maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                            })}></textarea>
                                        {
                                            errors.descripcionServicio &&
                                            <div className='text-left-20 mt-2'>
                                                <AlertTriangle size="18" className='text-danger' />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.descripcionServicio.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="mb-2">
                                        <label className="form-label">Terminos de Garantia:</label>
                                        <textarea className="form-control" rows="4"
                                            {...register('garantiaServicio', {
                                                required: 'Los Term. de garantia es requerido.',
                                                minLength: { value: 10, message: 'Los Term. de Garantia deben contener por los menos 10 caracteres' },
                                                // maxLength: { value: 200, message: 'La Descripción debe contener maximo 200 caracteres' }
                                            })}></textarea>
                                        {
                                            errors.garantiaServicio &&
                                            <div className='text-left-20 mt-2'>
                                                <AlertTriangle size="18" className='text-danger' />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.garantiaServicio.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="mb-2">
                                        <label className="form-label">Dias garantia:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} {...register("tiempoGarantiaServicio", { required: 'Dias de garantia es requerido' })} />
                                        {
                                            errors.tiempoGarantiaServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.tiempoGarantiaServicio.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="mb-2">
                                        <label className="form-label">Producto de Inventario:</label>
                                        <select className="form-select" {...register("prodReqServicio")}>
                                            <option value={'0'}>Seleccione producto</option>
                                            {
                                                productosAlm.map((item, index) => (
                                                    item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' > {item.DESCRIPCION} - {item.MODELO}</option>
                                                        : <option key={index} style={{ display: "none" }} ></option>
                                                ))
                                            }
                                        </select>
                                        {/* <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("cantReqServicio", { required: 'Cantidad Requerida' })} /> */}
                                        {/* {
                                            errors.cantReqServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cantReqServicio.message}</small></span>
                                            </div>
                                        } */}
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div className="mb-2">
                                        <label className="form-label">Cant. Req:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} min={0} {...register("cantReqServicio")} />
                                        {/* {
                                            errors.cantReqServicio &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cantReqServicio.message}</small></span>
                                            </div>
                                        } */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size="20" />  Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary" >
                                <Save size="20" />  Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}
