import { createSlice } from '@reduxjs/toolkit';

export const salesSlice = createSlice({
    name: 'salesSlice',
    initialState: {
        status: 'EN_ESPERA',
        sale: [],
        sale_edit: [],
        isProcessAction: false,
        isLoading: false,
    },
    reducers: {
        loadingSale: (state, action) => {
            state.isLoading = true;
            state.status = 'CARGANDO_VENTA';
        },
        notloadingSale: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        processAction: (state, action) => {
            state.isLoading = true;
            state.status = 'PROCESANDO_PETICION';
        },
        notprocessAction: (state, action) => {
            state.isLoading = false;
            state.status = 'EN_ESPERA';
        },
        setSales: (state, action) => {
            state.isLoading = false;
            state.status = "VENTAS_POR_PEDIDO_CARGADAS";
            state.sale = action.payload.sale;
        },
        setSale_Edit: (state, action) => {
            state.isLoading = false;
            state.status = "VENTA_ENCONTRADA";
            state.sale_edit = action.payload.sale_edit;
        },
        setError: (state,action)=>{
            state.isLoading = false;
            state.status = "VENTA_POR_PEDIDO_NO_ENCONTRADAS";
            state.sale = [];
            state.sale_edit = [];
        },
        clearStore: (state,action)=>{
            state.sale = [];
            state.sale_edit = [];
        }
    }
});
export const {setError,setSale_Edit,setSales,clearStore,notloadingSale,loadingSale,processAction,notprocessAction   } = salesSlice.actions;