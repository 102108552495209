import React from 'react'
import { useSelector } from 'react-redux';
import { connectionFiles } from '../../../../app/filesConnection';

export const ViewEvidencias = () => {

    const { orden_edit } = useSelector(state => state.ordenes);
    let urlImg = orden_edit[0]["EVIDENCIAS"];

    return (
        <div className="modal fade" id="viewEvidencias" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Galeria: Evidencias </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body p-4">
                        <div id="carouselExampleControls1" className="carousel slide" data-bs-ride="carousel">
                            {
                                urlImg.map((item, index) => (
                                    <div className="" key={index}>
                                        <hr />
                                        <img src={`${connectionFiles['imgsEvidenciasURL']}/${item.IMAGEN}`} className="d-block w-100" />
                                        <div className="text-center mt-2">
                                            <a href={`${connectionFiles['imgsEvidenciasURL']}/${item.IMAGEN}`} target="_blank" className="btn btn-primary">Ver en tamaño completo</a>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
