import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { clearStore, loadingEquipos, processAction, setEquipo_Edit, setEquipos, setError } from "./slice";

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const startloadEquipos = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingEquipos());
        await URL_API.get(`Equipos/getEquipos/${id_cliente}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setEquipos({ equipos: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}


export const startloadEquiposOrden = (id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(loadingEquipos());
        await URL_API.get(`Equipos/getEquiposOrden/${id_cliente}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setEquipos({ equipos: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }

        }).catch(function (error) {
            // console.log(error);
            dispatch(setError())
        });
    }
}

export const startRegistroEquipo = (data, capturante,id_cliente) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        
        let usuarioCap = capturante; let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;
        let idCliente= id_cliente;

        await URL_API.post(`Equipos/postEquipo`, {
            tipo_id: data.categoriaEquipo,
            marca_id: data.marcaEquipo,
            modelo: data.modeloEquipo,
            no_serie: data.noSerie,
            s_operativo: data.soEquipo,           
            cliente_id: idCliente,
            usuario: usuarioCap,
            fecha: fechaRegistro,
            hora: horaRegistro,
            estado: 1,

        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Equipo registrado con exito'
                })
                dispatch(startloadEquipos(id_cliente));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Equipo... Intente de Nuevo'
            })
            dispatch(startloadEquipos(id_cliente));
            return;
        });
    }
}

export const startDelEquipo = (id,cliente_id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Equipos/putEquipoDel/${id}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadEquipos(cliente_id));
                Toast.fire({
                    icon: 'success',
                    title: 'Equipo Desactivado con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActEquipo = (id,cliente_id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Equipos/putEquipoAct/${id}`).then(function (response) {
            if (response.data.status == 200) {
                dispatch(startloadEquipos(cliente_id));
                Toast.fire({
                    icon: 'success',
                    title: 'Equipo Activado con exito'
                })
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadEquipo = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.get(`Equipos/getEquipo/${id}`).then(function (response) {
            // console.log(response.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setEquipo_Edit({ equipo_edit: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const asignedEquipo =($data) =>{
    return async (dispatch, getState) => {
        dispatch(loadingEquipos());
        dispatch(setEquipo_Edit({ equipo_edit: $data }))
    }

}

export const clearStoreEquiposCliente = () => {
    return (dispatch, getState) => {
        dispatch(clearStore())
      
    }
}


