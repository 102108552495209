import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { connectionFiles } from '../../../app/filesConnection'
import { startChangeStateFacturacion, startloadSolFacturacion } from '../../../app/store/reportes/thunks';
import { Link } from 'react-router-dom';

import Swal from 'sweetalert2';
import { ListPending } from './panels/ListPending';
import { List } from './panels/List';



export const Invoicing = () => {
    // const { isLoading, status_report, facturacion } = useSelector(state => state.reportes);



    // if (isLoading) {
    //     return (
    //         <>
    //             <div className='div_center'>
    //                 <div className='text-center'>
    //                     <div className="spinner"></div>
    //                 </div>
    //                 <div className='text-center text-left-15'>
    //                     <span className='fw-bold'>Cargando</span>
    //                 </div>
    //                 <div className='text-center text-left-15'>
    //                     <div className="spinner"></div>
    //                 </div>
    //             </div>
    //         </>
    //     )
    // }
    return (
        <>
            {/* <AddFacturacion /> */}
            <h3 className="h3 mb-3 fw-bold">Facturación</h3>
            <div className="card">

                <div className="card-body">
                    <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Listado</button>
                            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Pendientes</button>

                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" >
                            <List />
                        </div>
                        <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" >
                            <ListPending />

                        </div>


                    </div>

                </div>
            </div>





        </>
    )
}
