import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AlertTriangle, Plus, RefreshCcw, Save, Search, Slash, Trash2 } from 'react-feather';
import Swal from 'sweetalert2';
import { startAddServiciosOrden } from '../../../../app/store/ordenes/thunks';

export const NewServicios = () => {

    const { servicios } = useSelector(state => state.catalogos);
    const closeModalEdit = useRef(null);

    const { orden_edit, indicadores, isLoading, status_ordenes } = useSelector(state => state.ordenes);
    const dispatch = useDispatch();
    const [Servicios, setServicios] = useState([]);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
            // toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    const addServicio = () => {
        let id_servicio = document.getElementById("servicio_realizar").value;

        if (id_servicio != '') {
            let servicio = servicios.filter(item => item.ID === id_servicio)[0];

          
            let servicioExist = Servicios.filter(item => item.ID == id_servicio)

            if (servicioExist == 0) {


                let PROD = {
                    "ID": servicio.ID,
                    "NOMBRE": servicio.NOMBRE,
                    "DESCRIPCION": servicio.DESCRIPCION,
                    "HR_REALIZAR": servicio.HR_REALIZAR,
                    "COSTO_SERV": servicio.COSTO_SERV,
                    "GARANTIA": servicio.GARANTIA,
                    "ESTADO": servicio.ESTADO,
                    "RECEPCION": 1,
                    "PROD_ID": servicio.PRODUCTO_ID,
                    "CANT_PROD": servicio.CANTIDAD_PROD
                  }


                Toast.fire({
                    icon: 'success',
                    title: 'Servicio Agregado de la lista'
                });
                setServicios([...Servicios, PROD]);


                console.log(PROD);
            } else {
                Toast.fire({
                    icon: 'error',
                    title: 'Ese servicio ya fue agregado'
                });
            }
        } else {
            Toast.fire({
                icon: 'error',
                title: 'Debe seleccionar a un servicio de la listas'
            });
        }

        
    }

    const removeServiciO = (id) => {
        Toast.fire({
            icon: 'success',
            title: 'Servicio Eliminado de la lista'
        });
        let arrayANTERIOR = [...Servicios];
        // console.log(arrayANTERIOR);
        let result = arrayANTERIOR.filter(item => item.ID != id);
        setServicios(result);
    }

    const removeAllServicio = () => {
        if (Servicios.length != 0) {
            setServicios([]);
            Toast.fire({
                icon: 'success',
                title: 'Se ha eliminado la lista de Servicios a realizar'
            });
        }
    }

    const clearForm = () => {
        if (Servicios.length != 0) {
            setServicios([]);
        }
    }

    const saveServicios = () => {
        if (Servicios.length == 0) {
            Toast.fire({
                icon: 'error',
                title: 'Debe agregar algun servicio a la lista para poder Guardar'
            });
        } else {
            dispatch(startAddServiciosOrden(orden_edit[0].FOLIO, Servicios));
            closeModalEdit.current.click();
        }
    }

    return (
        <div className="modal fade" id="newServiciosOrden" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-dark fw-bold" id="staticBackdropLabel">Agregar servicios: {orden_edit[0].FOLIO} </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <div className="modal-body">
                        <div className="d-flex">
                            <select className="form-select" id='servicio_realizar'>
                                <option value="" >Seleccione un servicio</option>
                                {
                                    servicios.map((item, index) => (
                                        item.ESTADO == 1 ? <option key={index} value={item.ID} className='text-uppercase' >{item.NOMBRE} - ${item.COSTO_SERV} </option>
                                            : <option key={index} style={{ display: "none" }} ></option>
                                    ))
                                }
                            </select>

                            <button className="btn btn-outline-primary no border-0 " type="button" onClick={() => addServicio()} >
                                <Plus size='20' />
                            </button>
                        </div>

                        <div className="mt-2 table-responsive table-scrollbar-5">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">SERVICIO</th>
                                        <th scope="col" className="text-end">COSTO</th>
                                        <th scope="col" className="text-center">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Servicios.length === 0 ?
                                            <>
                                                <tr >
                                                    <td scope="row" colSpan={4} className='text-danger fw-bold'> <AlertTriangle size={20} className='text-danger fw-bold me-2' /> No se han agregado nuevos servicios a la orden</td>
                                                </tr>
                                            </> :
                                            Servicios.map((item, index) => (
                                                <tr key={index}>
                                                    <td scope="row">{index + 1}</td>
                                                    <td>{item.NOMBRE}</td>
                                                    <td className="text-end">{item.COSTO_SERV}</td>
                                                    <td className="text-center">
                                                        <button className="btn btn-outline-danger border-0 " type="button" onClick={() => removeServiciO(item.ID)}>
                                                            <Trash2 size='20' />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                    }
                                </tbody>
                            </table>

                        </div>
                        <button type="button" className="btn btn-outline-danger mt-3 mb-4" onClick={removeAllServicio} >Eliminar todos: ({Servicios.length})</button>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                            <Slash size='20' /> Cancelar
                        </button>
                        <button type="button" ref={closeModalEdit} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                        <button type="button" className="btn btn-outline-primary" onClick={e => saveServicios()}>
                            <Save size='20' /> Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
