import { URL_API } from "../../apiConnection";
import { clearStore, clearStoreEditSolicitud, loadingGlobal, lodingGlobalComplete, processAction, setCConocieron, setCConocieron_Edit, setCat_egresos, setCat_egresos_edit, setCat_ingresos, setCat_ingresos_Edit, setCategorias, setCategoriasAlm, setCategoriasAlm_edit, setCategorias_Edit, setCredenciales, setDescuentos, setDescuentos_Edit, setDetailSolicitud, setError, setMarcas, setMarcasAlm, setMarcasAlm_edit, setMarcas_Edit, setProductosALM, setProductosALMEdit, setProveedoresAlm, setProveedoresAlm_edit, setPuesto_Edit, setPuestos, setRol_Edit, setRoles, setServicio_Edit, setServicios, setSolicitudes, setTarifaAlm, setTiposCliente_Edit, setTiposClientes, setTipospagos, setTipospagos_Edit } from "./slice";
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        // toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const startCatalogos = () => {
    return async (dispatch, getState) => {
        dispatch(loadingGlobal());
        // Marcas
        await URL_API.get(`Configuracion/getMarcas`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setMarcas({ marcas: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        await URL_API.get(`Configuracion/getDescuentos`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setDescuentos({ descuentos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        await URL_API.get(`Configuracion/getCConocieron`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCConocieron({ como_conocieron: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        // Categorias
        await URL_API.get(`Configuracion/getCategorias`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCategorias({ categorias: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        // Puestos
        await URL_API.get(`Configuracion/getPuestos`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setPuestos({ puestos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        await URL_API.get(`Configuracion/getRoles`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setRoles({ roles: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        // Categorias CLientes
        await URL_API.get(`Configuracion/getTiposClientes`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTiposClientes({ tclientes: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        // Servicios
        await URL_API.get(`Configuracion/getServicios`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setServicios({ servicios: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

        await URL_API.get(`Configuracion/getMetodoPago`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTipospagos({ tipos_pagos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
        dispatch(startloadProductos());

        dispatch(lodingGlobalComplete());
    }
}


export const startloadCredenciales = () => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Configuracion/getCorreoAcceso/1`).then(function (response) {
            // console.log(response.data.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCredenciales({ credenciales: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditCredenciales = (data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.put(`Configuracion/putCorreoAcceso/1`, {
            host: data.host,
            email: data.email,
            pwd: data.password
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Credenciales de Correo actualizadas'
                })
                dispatch(startloadCredenciales());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizaron las Credenciales... Intente de Nuevo'
            })
            dispatch(startloadCredenciales());
            return;
        });
    }
}

export const startloadTarifaAlm = () => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Configuracion/getAlmacenamiento/1`).then(function (response) {
            // console.log(response.data.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTarifaAlm({ tarifaAlm: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditTarifaAlm = (data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.put(`Configuracion/putAlmacenamiento/1`, {
            costo: data.costoAlm,
            dias_gracia: data.diasGracia,
            diagnostico_i: data.costoDiagnosticoI,
            diagnostico_t: data.costoDiagnosticoT


        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Tarifa de Almacenamiento Actualizada'
                })
                dispatch(startloadTarifaAlm());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo la Tarifa de Almacenamiento... Intente de Nuevo'
            })
            dispatch(startloadTarifaAlm());
            return;
        });
    }
}


export const startEditGarantia = (data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        await URL_API.put(`Configuracion/putGarantia/1`, {
            // costo: data.costoAlm,
            dias_garantia: data.diasGarantia,
            con_garantia: data.conGarantia,
            // diagnostico_t: data.costoDiagnosticoT
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Garantia Actualizada'
                })
                dispatch(startloadTarifaAlm());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo la Garantia... Intente de Nuevo'
            })
            dispatch(startloadTarifaAlm());
            return;
        });
    }
}


//==========================================
// Puestos -- Puestos -- Puestos -- Puestos
//==========================================
export const startRegistroRol = (data, capturante) => {
    return async (dispatch, getState) => {

        // dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;


        let usuario = capturante;
        let rol = data.nombreRol;
        let descripcion = data.descripcionRol;
        let dash_admin = data.dash_admin == false ? "0" : "1"; let dash_rep = data.dash_repc == false ? "0" : "1"; let dash_caja = data.dash_caja == false ? "0" : "1";
        let dash_tec = data.dash_tecnico == false ? "0" : "1"; let dash_aux = data.dash_aux == false ? "0" : "1"; let dash_alm = data.dash_ALM == false ? "0" : "1";

        await URL_API.post(`Configuracion/postRol`, {
            nombre: rol,
            descripcion: descripcion,
            dash_admin: dash_admin,
            dash_tec: dash_tec,
            dash_rep: dash_rep,
            dash_caja: dash_caja,
            dash_aux: dash_aux,
            dash_alm: dash_alm,
            estado: 1,
            usuario: usuario,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Rol registrado con exito'
                })
                dispatch(loadRoles());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Rol... Intente de Nuevo'
            })
            dispatch(loadRoles());
            return;
        });

    }

}

export const startloadRol = ($id) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Configuracion/getRol/${$id}`).then(function (response) {
            // console.log(response.data.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setRol_Edit({ rol: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditRol = (data, $id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        let rol = data.nombreRol;
        let descripcion = data.descripcionRol;
        let dash_admin = data.dash_admin == false ? "0" : "1"; let dash_rep = data.dash_repc == false ? "0" : "1"; let dash_caja = data.dash_caja == false ? "0" : "1";
        let dash_tec = data.dash_tecnico == false ? "0" : "1"; let dash_aux = data.dash_aux == false ? "0" : "1"; let dash_alm = data.dash_ALM == false ? "0" : "1";
        await URL_API.put(`Configuracion/putRol/${$id}`, {
            nombre: rol,
            descripcion: descripcion,
            dash_admin: dash_admin,
            dash_tec: dash_tec,
            dash_rep: dash_rep,
            dash_caja: dash_caja,
            dash_aux: dash_aux,
            dash_alm: dash_alm,
            estado: 1,
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Rol actualizado con exito'
                })
                dispatch(loadRoles());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo el Rol... Intente de Nuevo'
            })
            dispatch(loadRoles());
            return;
        });

    }

}

export const startDelRol = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putRolDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Rol Desactivado con exito'
                })
                dispatch(loadRoles());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActRol = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putRolAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Rol Activado con exito'
                })
                dispatch(loadRoles());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadRoles = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getRoles`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setRoles({ roles: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

//==========================================
// Categorias -- Categorias -- Categorias
//==========================================
export const startRegistroCategoria = (categoria, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Configuracion/postCategoria`, {

            nombre: categoria,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Registrada con exito'
                })
                dispatch(loadCategorias());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Categoria... Intente de Nuevo'
            })
            dispatch(loadCategorias());
            return;
        });
    }
}

export const startDelCategoria = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putCategoriaDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Desactivada con exito'
                })
                dispatch(loadCategorias());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCategoria = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putCategoriaAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Activada con exito'
                })
                dispatch(loadCategorias());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCategorias = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getCategorias`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCategorias({ categorias: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditCategoria = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putCategoria/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Actualizada con exito'
                })
                dispatch(loadCategorias());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCategoriaEdit = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getCategoria/${id}`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCategorias_Edit({ categorias_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

//============================================
// Clasificacion Clientes -- Clasificacion Clientes
//============================================

export const startRegistroClasficacion = (clasificacion, usuario_cap, filesA) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);
        let formData = new FormData();
        if (filesA != "") {
            formData.append('file[]', filesA);
        }

        await URL_API.post(`Configuracion/postTipoClientes`, {

            nombre: clasificacion,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {

                // Subida de archivo
                URL_API.post(`Configuracion/postUploadImagenTipoCliente/${response.data.last_id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }).then(function (response) {
                    // console.log(response)
                    Toast.fire({
                        icon: 'success',
                        title: 'Clasificacion de Cliente registrada con exito'
                    })
                    dispatch(loadCategoriaClientes());
                }).catch(function (error) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Clasificacion de Cliente registrada con exito'
                    })
                    dispatch(loadCategoriaClientes());
                });

            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Clasificacion de cliente... Intente de Nuevo'
            })
            dispatch(loadCategoriaClientes());
            return;
        });
    }
}

export const startDelCategoriaClientes = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putTipoClienteDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Clasificacion de Cliente desactivada con exito'
                })
                dispatch(loadCategoriaClientes());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCategoriaClientes = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putTipoClienteAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Clasificacion de Cliente activada con exito'
                })
                dispatch(loadCategoriaClientes());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCategoriaClientes = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getTiposClientes`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTiposClientes({ tclientes: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const updateRegistroClasficacion = (filesA, id, nombreCla) => {

    return async (dispatch, getState) => {
        dispatch(processAction());

        let formData = new FormData();
        if (filesA != "") {
            formData.append('file[]', filesA);
        }
        await URL_API.post(`Configuracion/postUploadImagenTipoClienteEdit/${id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        }).then(function (response) {
            // console.log(response)
            if (response.data.status = 200) {

                URL_API.put(`Configuracion/putNombreTipoCliente/${id}`, {
                    nombre: nombreCla,
                }).then(function (response) {
                    // console.log(response.data);
                    // const listItems = response.data.data;
                    if (response.data.status == 200) {
                        Toast.fire({
                            icon: 'success',
                            title: 'Clasificacion de Cliente Actualizadas con exito'
                        })
                        dispatch(loadCategoriaClientes());
                    }
                }).catch(function (error) {
                    console.log(error);
                });

            }
        }).catch(function (error) {
            console.log(error);
            Toast.fire({
                icon: 'success',
                title: 'No fue posible actualizar la clasificacion del cliente'
            })
            dispatch(loadCategoriaClientes());
            return;
        });



    }

}

//==========================================
// Marcas -- Marcas -- Marcas -- Marcas
//==========================================
export const startRegistroMarca = (marca, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Configuracion/postMarca`, {

            nombre: marca,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca registrada con exito'
                })
                dispatch(loadMarcas());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Marca... Intente de Nuevo'
            })
            dispatch(loadMarcas());
            return;
        });
    }
}

export const startDelMarca = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putMarcaDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca Desactivada con exito'
                })
                dispatch(loadMarcas());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActMarca = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putMarcaAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca Activada con exito'
                })
                dispatch(loadMarcas());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditMarca = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putMarca/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca Actualizada con exito'
                })
                dispatch(loadMarcas());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadMarcaEdit = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getMarca/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setMarcas_Edit({ marcas_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const loadMarcas = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getMarcas`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setMarcas({ marcas: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


//==========================================
// Puestos -- Puestos -- Puestos -- Puestos
//==========================================
export const startRegistroPuesto = (data, capturante) => {
    return async (dispatch, getState) => {

        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        let puesto = data.nombrePuesto;
        let salario = data.salarioPuesto;
        let descripcion = data.descripcionPuesto;
        let usuario = capturante;

        await URL_API.post(`Configuracion/postPuesto`, {
            nombre: puesto,
            descripcion: descripcion,
            salario: salario,
            estado: 1,
            usuario: usuario,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Puesto registrado con exito'
                })
                dispatch(loadPuestos());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Puesto... Intente de Nuevo'
            })
            dispatch(loadPuestos());
            return;
        });
    }
}

export const startloadPuesto = ($id) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Configuracion/getPuesto/${$id}`).then(function (response) {
            // console.log(response.data.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setPuesto_Edit({ puesto: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditPuesto = (data, $id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        let puesto = data.editnombrePuesto;
        let salario = data.editsalarioPuesto;
        let descripcion = data.editdescripcionPuesto;
        await URL_API.put(`Configuracion/putPuesto/${$id}`, {
            nombre: puesto,
            descripcion: descripcion,
            salario: salario,
            estado: 1,
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Puesto actualizado con exito'
                })
                dispatch(loadPuestos());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo el Puesto... Intente de Nuevo'
            })
            dispatch(loadPuestos());
            return;
        });

    }

}

export const startDelPuesto = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putPuestoDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Puesto Desactivado con exito'
                })
                dispatch(loadPuestos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActPuesto = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putPuestoAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Puesto Activado con exito'
                })
                dispatch(loadPuestos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadPuestos = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getPuestos`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setPuestos({ puestos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

//============================================
// Clasificacion Clientes -- Clasificacion Clientes
//============================================

export const startRegistroServicio = (data, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        let servicio = data.nombreServicio;
        let descripcion = data.descripcionServicio;
        let tiempo = data.tiempoDemoraServicio;
        let costo = data.costoServicio;
        let cRecepcionista = data.comisionRecepcionista;
        let cTecnico = data.comisionTecnico;
        let garantia = data.garantiaServicio;
        let dagarantia = data.tiempoGarantiaServicio;

        console.log(data);

        await URL_API.post(`Configuracion/postServicio`, {
            nombre: servicio,
            descripcion: descripcion,
            hr_realizar: tiempo,
            costo: costo,
            comision_tec: cTecnico,
            comision_rep: cRecepcionista,
            garantia: garantia,
            dias_garantia: dagarantia,
            producto_id: data.prodReqServicio,
            cant_id: data.cantReqServicio,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Servicio registrado con exito'
                })
                dispatch(loadServicio());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Servicio... Intente de Nuevo'
            })
            dispatch(loadServicio());
            return;
        });
    }
}

export const startEditServicio = (data, $id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let servicio = data.editnombreServicio;
        let descripcion = data.editdescripcionServicio;
        let tiempo = data.edittiempoDemoraServicio;
        let costo = data.editcostoServicio;
        let cRecepcionista = data.editcomisionRecepcionista;
        let cTecnico = data.editcomisionTecnico;
        let garantia = data.garantiaServicio;
        let dagarantia = data.tiempoGarantiaServicio;

        await URL_API.put(`Configuracion/putServicio/${$id}`, {
            nombre: servicio,
            descripcion: descripcion,
            hr_realizar: tiempo,
            costo: costo,
            comision_tec: cTecnico,
            comision_rep: cRecepcionista,
            garantia: garantia,
            producto_id: data.prodReqServicio,
            cant_id: data.cantReqServicio,
            dias_garantia: dagarantia,
            estado: 1,
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Servicio actualizado con exito'
                })
                dispatch(loadServicio());
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo el Servicio... Intente de Nuevo'
            })
            dispatch(loadServicio());
            return;
        });

    }

}

export const startloadServicio = ($id) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Configuracion/getServicio/${$id}`).then(function (response) {
            // console.log(response.data.data);
            const item = response.data.data;
            if (response.data.status == 200) {
                dispatch(setServicio_Edit({ servicio: item }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelServicio = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putServicioDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Servicio desactivado con exito'
                })
                dispatch(loadServicio());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActServicio = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putServicioAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Servicio activado con exito'
                })
                dispatch(loadServicio());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadServicio = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getServicios`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setServicios({ servicios: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

//==========================================
// Como conocieron
//==========================================
export const startRegistroCConocieron = (categoria, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Configuracion/postConocieron`, {

            descripcion: categoria,
            estado: 1,
            usuario_reg: usuario_cap,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Razon de Como nos conocieron Registrada con exito'
                })
                dispatch(loadCConocieron());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Razon de como nos conocieron... Intente de Nuevo'
            })
            dispatch(loadCConocieron());
            return;
        });
    }
}

export const startDelCConocieron = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putCConocieronDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Razon de Como nos conocieron Desactivada con exito'
                })
                dispatch(loadCConocieron());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCConocieron = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putCConocieronAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Razon de Como nos conocieron Activada con exito'
                })
                dispatch(loadCConocieron());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCConocieronEdit = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getCConocieronU/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCConocieron_Edit({ como_conocieron_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditCConocieron = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putCConocieron/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Como nos Conocieron Actualizada con exito'
                })
                dispatch(loadCConocieron());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCConocieron = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getCConocieron`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCConocieron({ como_conocieron: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}







//==========================================
// Descuentos
//==========================================
export const startRegistroDescuento = (data, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Configuracion/postDescuentos`, {

            nombre: data.nombre,
            valor: data.valor,
            estado: 1,
            usuario_reg: usuario_cap,
            fecha_reg: fechaRegistro,
            hora_reg: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Descuento registrador con Exito'
                })
                dispatch(loadDescuentos());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro el descuento... Intente de Nuevo'
            })
            dispatch(loadDescuentos());
            return;
        });
    }
}

export const startDelDescuento = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putDescuentosDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Descuento Desactivado con exito'
                })
                dispatch(loadDescuentos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActDescuento = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putDescuentosAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Descuento Activad0 con exito'
                })
                dispatch(loadDescuentos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadDescuentos = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getDescuentos`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setDescuentos({ descuentos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadDescuentoEdit = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getDescuento/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setDescuentos_Edit({ descuentos_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditDescuento = ($id, nombre, valor) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putDescuento/${$id}`, {
            nombre: nombre,
            valor: valor
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Descuento Actualizado con exito'
                })
                dispatch(loadDescuentos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


//----------------------------
//  Inventario - Inventario
//----------------------------

export const startRegistroCat = (clasificacion, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Almacen/postCategoria`, {

            nombre: clasificacion,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria de Producto registrada con exito'
                })
                dispatch(loadCategoriasAlm());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Categoria de Producto... Intente de Nuevo'
            })
            dispatch(loadCategoriasAlm());
            return;
        });
    }
}

export const loadCategoriasAlm = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Almacen/getCategorias`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCategoriasAlm({ categoriasAlm: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelCategoriaAlm = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putCategoriaDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Desactivada con exito'
                })
                dispatch(loadCategoriasAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCategoriaAlm = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putCategoriaAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Activada con exito'
                })
                dispatch(loadCategoriasAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startRegistroMarcaAlm = (marca, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Almacen/postMarca`, {

            nombre: marca,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca registrada con exito'
                })
                dispatch(loadMarcasAlm());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Marca... Intente de Nuevo'
            })
            dispatch(loadMarcasAlm());
            return;
        });
    }
}


export const loadCategoriaAlmEdit = (id) => {
    return async (dispatch, getState) => {

        dispatch(processAction());

        console.log(id);
        await URL_API.get(`Almacen/getCategoria/${id}`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCategoriasAlm_edit({ categoriasAlm_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditCategoriaAlm = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putCategoria/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Actualizada con exito'
                })
                dispatch(loadCategoriasAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadMarcasAlm = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Almacen/getMarcas`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setMarcasAlm({ marcasAlm: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelMarcasAlm = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putMarcaDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca Desactivada con exito'
                })
                dispatch(loadMarcasAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActMarcasAlm = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putMarcaAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca Activada con exito'
                })
                dispatch(loadMarcasAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadMarcaAlmEdit = (id) => {
    return async (dispatch, getState) => {

        dispatch(processAction());

        console.log(id);
        await URL_API.get(`Almacen/getMarca/${id}`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setMarcasAlm_edit({ marcasAlm_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditMarcaAlm = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putMarca/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Marca Actualizada con exito'
                })
                dispatch(loadMarcasAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const startRegistroProv = (proveedor, usuario_cap) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Almacen/postProveedor`, {

            nombre: proveedor,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Proveedor de Productos registrado con exito'
                })
                dispatch(loadProveedoresAlm());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro al Proveedor de Productos... Intente de Nuevo'
            })
            dispatch(loadProveedoresAlm());
            return;
        });
    }
}

export const loadProveedoresAlm = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Almacen/getProveedores`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setProveedoresAlm({ proveedoresAlm: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelProveedoresAlm = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putProveedorDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Proveedor Desactivado con exito'
                })
                dispatch(loadProveedoresAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActProveedoresAlm = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putProveedorAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Proveedor Activado con exito'
                })
                dispatch(loadProveedoresAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadProveedoresAlmEdit = (id) => {
    return async (dispatch, getState) => {

        dispatch(processAction());
        // console.log(id);
        await URL_API.get(`Almacen/getProveedor/${id}`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setProveedoresAlm_edit({ proveedoresAlm_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditProveedoresAlm = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putProveedor/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Proveedor Actualizada con exito'
                })
                dispatch(loadProveedoresAlm());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


// Productos Almacen
export const startRegistroProducto = (usuario_cap, data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.post(`Almacen/postProducto`, {

            ALM_MARCA: data.marcaProducto,
            ALM_CATEGORIA: data.categoriaProducto,
            ALM_PROV: data.proveedorProducto,
            NOMBRE: data.nombreProducto,
            DESCRIPCION: data.descripcionProducto,
            MODELO: data.modeloProducto,
            STOCK: data.existenciaProducto,
            EXISTENCIA_MIN: data.existenciaMINProducto,
            EXISTENCIA_MAX: data.existenciaMAXProducto,
            COSTO: data.costoProducto,
            COSTO_PUBLICO: data.costoPUBProducto,
            COD_BARRA: data.codigoBarraProducto,
            estado: 1,
            usuario: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto registrado con exito'
                })
                dispatch(startloadProductos());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro el Producto... Intente de Nuevo'
            })
            dispatch(startloadProductos());
            return;
        });
    }
}

export const startUpdateProducto = (data, id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);

        await URL_API.put(`Almacen/putProducto/${id}`, {

            ALM_MARCA: data.marcaProducto,
            ALM_CATEGORIA: data.categoriaProducto,
            ALM_PROV: data.proveedorProducto,
            NOMBRE: data.nombreProducto,
            DESCRIPCION: data.descripcionProducto,
            MODELO: data.modeloProducto,
            STOCK: data.existenciaProducto,
            EXISTENCIA_MIN: data.existenciaMINProducto,
            EXISTENCIA_MAX: data.existenciaMAXProducto,
            COSTO: data.costoProducto,
            COSTO_PUBLICO: data.costoPUBProducto,
            COD_BARRA: data.codigoBarraProducto,
            estado: 1
            // usuario: usuario_cap,
            // fecha: fechaRegistro,
            // hora: horaRegistro
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto actualizado con exito'
                })
                dispatch(startloadProductos());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo el Producto... Intente de Nuevo'
            })
            dispatch(startloadProductos());
            return;
        });
    }
}

export const updExistenciaProducto = (id, exis) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putProductoExistencia/${id}/${exis}`).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto actualizado con exito'
                })
                dispatch(startloadProductos());
            }
        }).catch(function (error) {
            console.log(error);
            // dispatch(setError())
        });
    }
}

export const startloadProductos = () => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(processAction());

        await URL_API.get(`Almacen/getProductos`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setProductosALM({ productosAlm: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError())
        });
    }
}

export const loadProducto = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Almacen/getProducto/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setProductosALMEdit({ productosAlmEdit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError())
        });
    }
}

export const searchProductos = (data) => {
    return async (dispatch, getState) => {
        // dispatch(clearStore())
        dispatch(processAction());

        await URL_API.get(`Almacen/getProductosSearch/${data.param}/${data.textSearch}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            // console.log(listItems);
            if (response.data.status == 200) {
                dispatch(setProductosALM({ productosAlm: listItems }))
            }
            if (response.data.status == 404) {
                dispatch(setError())
            }
        }).catch(function (error) {
            dispatch(setError())
        });
    }
}

export const startDelProductos = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putProductoDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto Desactivado con exito'
                })
                dispatch(startloadProductos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActProductos = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Almacen/putProductoAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Producto Activado con exito'
                })
                dispatch(startloadProductos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

// Proceso de Solicitud de Productos

export const startUpdateExistenciasProducto = (id, data, usuario, filesA) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);
        let formData = new FormData();
        if (filesA != "") {
            formData.append('file[]', filesA);
        }

        await URL_API.put(`Almacen/putProductoExistencias/${id}`, {
            productos: data
        }).then(function (response) {
            if (response.data.status = 200) {
                // console.log(response.data);

                URL_API.post(`Almacen/postUploadEvidenciaCierre/${id}`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }).then(function (response) {
                    // console.log(response)

                    URL_API.put(`Almacen/putChangeStateSol/${id}/3/${fechaRegistro}/${horaRegistro}/${usuario}`).then(function (response) {
                        if (response.data.status == 200) {

                            dispatch(loadSolicitudDetail(id));
                            Toast.fire({
                                icon: 'success',
                                title: 'Solicitud de Productos actualizada'
                            })
                        }
                        if (response.data.status == 404) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'No fue posible Actualizar  el estado de la Solicitud de Productos',

                            });
                            dispatch(setError());
                        }

                    }).catch(function (error) {
                        // console.log(error);
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'No fue posible Actualizar  el estado de la Solicitud de Productos',
                        });
                        dispatch(setError())
                    });
                }).catch(function (error) {

                });

            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo el Producto... Intente de Nuevo'
            })
            dispatch(startloadProductos());
            return;
        });
    }
}

export const startRegistroSolicitudProducto = (usuario_cap, data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let dat = new Date();
        let d = dat.getDate();
        let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d

        let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        let horaRegistro = `${hora}:${minuto}:${segundo}`;

        // console.log(usuario_cap,fechaRegistro,horaRegistro);
        // console.log(data);

        await URL_API.post(`Almacen/postSolicitud`, {
            capturo: usuario_cap,
            fecha: fechaRegistro,
            hora: horaRegistro,
            productos: data
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Productos registrada con exito'
                })
                dispatch(startloadSolicitudProductos());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Solicitud Productos... Intente de Nuevo'
            })
            dispatch(startloadSolicitudProductos());
            return;
        });
    }
}

export const startloadSolicitudProductos = () => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(processAction());

        await URL_API.get(`Almacen/getSolicitudesCompra`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setSolicitudes({ solicitudesProd: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError())
        });
    }
}

export const startloadSolicitudProductosEstado = (estado) => {
    return async (dispatch, getState) => {
        dispatch(clearStore());
        dispatch(processAction());

        await URL_API.get(`Almacen/getSolicitudesCompraDet/${estado}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setSolicitudes({ solicitudesProd: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(startloadSolicitudProductos())
        });
    }
}

export const loadSolicitudDetail = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Almacen/getSolicitudDetail/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setDetailSolicitud({ solicitudesProddetail: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
            dispatch(setError())
        });
    }
}

export const startChangeStateSolicitud = (folio, estado, usuario) => {
    let dat = new Date(); let d = dat.getDate(); let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
    let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
    if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;

    let fechaRegistro = dat.getFullYear() + "-" + m + "-" + d; let horaRegistro = `${hora}:${minuto}:${segundo}`;


    // console.log(estado);
    return async (dispatch, getState) => {
        // dispatch(clearStore());
        dispatch(processAction());
        await URL_API.put(`Almacen/putChangeStateSol/${folio}/${estado}/${fechaRegistro}/${horaRegistro}/${usuario}`).then(function (response) {
            if (response.data.status == 200) {

                dispatch(loadSolicitudDetail(folio));
                Toast.fire({
                    icon: 'success',
                    title: 'Solicitud de Productos actualizada'
                })
            }
            if (response.data.status == 404) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No fue posible Actualizar  el estado de la Solicitud de Productos',

                });
                dispatch(setError());
            }

        }).catch(function (error) {
            // console.log(error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'No fue posible Actualizar  el estado de la Solicitud de Productos',
            });
            dispatch(setError())
        });
    }
}

export const clearStoreEdit = () => {
    return async (dispatch, getState) => {
        dispatch(clearStoreEditSolicitud());
    }
}

// Formas de Pago
export const startRegistroFormaPago = (nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        // let dat = new Date();
        // let d = dat.getDate();
        // let m = dat.getMonth() + 1; if (d < 10) d = "0" + d; if (m < 10) m = "0" + m;
        // let fechaRegistro = dat.getFullYear() + "/" + m + "/" + d
        // let fechahora = new Date(); let hora = fechahora.getHours(); let minuto = fechahora.getMinutes(); let segundo = fechahora.getSeconds();
        // if (minuto < 10) minuto = "0" + minuto; if (segundo < 10) segundo = "0" + segundo;
        // let horaRegistro = `${hora}:${minuto}:${segundo}`;
        // console.log(categoria,usuario_cap,fechaRegistro,horaRegistro);
        await URL_API.post(`Configuracion/postMetodosPago`, {
            nombre: nombre,
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Forma de pago Registrada con exito'
                })
                dispatch(loadFormasPago());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Forma de pago... Intente de Nuevo'
            })
            dispatch(loadFormasPago());
            return;
        });
    }
}

export const startDelFormaPago = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putMetodosPagoDel/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Forma de Pago Desactivada con exito'
                })
                dispatch(loadFormasPago());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActFormaPago = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putMetodosPagoAct/${$id}`).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Forma de Pago Activada con exito'
                })
                dispatch(loadFormasPago());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startFormaPago = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Configuracion/putMetodoPago/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Forma de pago Actualizado con exito'
                })
                dispatch(loadFormasPago());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadFormasPago = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getMetodoPago`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTipospagos({ tipos_pagos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });

    }
}

export const loadFormapagoEdit = (id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getMetodoPagoU/${id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTipospagos_Edit({ tipos_pagos_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

// Ingresos y Egresos
export const startRegistroCatIngresos = (nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.post(`Ingresos/postIngresosCat`, {
            nombre: nombre,
            estado: 1
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cat. Ingreso Registrado con exito'
                })
                dispatch(loadCat_ingresos());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Cat. de Ingreso... Intente de Nuevo'
            })
            dispatch(loadCat_ingresos());
            return;
        });
    }
}

export const loadCat_ingresos = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Ingresos/getIngresosCat`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCat_ingresos({ cat_ingresos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelCatIngreso = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Ingresos/putIngresosCatDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cat. de Ingreso Desactivado con exito'
                })
                dispatch(loadCat_ingresos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCatIngreso = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Ingresos/putIngresosCatAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cat. de Ingreso Activado con exito'
                })
                dispatch(loadCat_ingresos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadCat_ingresosEdit = (id) => {
    return async (dispatch, getState) => {

        dispatch(processAction());

        // console.log(id);
        await URL_API.get(`Ingresos/getIngresoCat/${id}`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCat_ingresos_Edit({ cat_ingresos_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const startEditCategoriaIngresos = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Ingresos/putIngresoCat/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Ingreso Actualizada con exito'
                })
                dispatch(loadCat_ingresos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}


export const startRegistroCatEgresos = (nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.post(`Ingresos/postEgresosCat`, {
            nombre: nombre,
            estado: 1
        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cat. Egreso Registrado con exito'
                })
                dispatch(loadCat_egresos());
            }
        }).catch(function (error) {
            console.log(error)
            // dispatch(setError({ message: error.response.data.msg }));
            Toast.fire({
                icon: 'error',
                title: 'No se registro la Cat. de Egreso... Intente de Nuevo'
            })
            dispatch(loadCat_egresos());
            return;
        });
    }
}

export const loadCat_egresos = () => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Ingresos/getEgresosCat`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCat_egresos({ cat_egresos: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startDelCatEgreso = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Ingresos/putEgresosCatDel/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cat. de Egreso Desactivado con exito'
                })
                dispatch(loadCat_egresos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startActCatEgreso = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Ingresos/putEgresosCatAct/${$id}`).then(function (response) {
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Cat. de Egreso Activado con exito'
                })
                dispatch(loadCat_egresos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const loadTipoCliente = ($id) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.get(`Configuracion/getTiposCliente/${$id}`).then(function (response) {
            // console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setTiposCliente_Edit({ tiposCliente_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}



export const loadCat_egresosEdit = (id) => {
    return async (dispatch, getState) => {

        dispatch(processAction());

        // console.log(id);
        await URL_API.get(`Ingresos/getEgresoCat/${id}`).then(function (response) {
            console.log(response.data);
            const listItems = response.data.data;
            if (response.data.status == 200) {
                dispatch(setCat_egresos_edit({ cat_egresos_edit: listItems }))
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}

export const startEditCategoriaEgresos = ($id, nombre) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Ingresos/putEgresoCat/${$id}`, {
            nombre: nombre,
        }).then(function (response) {
            // console.log(response.data);
            // const listItems = response.data.data;
            if (response.data.status == 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Categoria Egreso Actualizado con exito'
                })
                dispatch(loadCat_egresos());
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
}
