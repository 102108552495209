import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Save, Slash, AlertTriangle } from 'react-feather'
import { startEditFacturacion } from '../../../../app/store/facturacion/thunks';

export const EditFacturacion = () => {

    const solo_num = (e) => {
        const allowedKeys = [
          'Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter', 'Decimal', '.'
        ];
      
        const inputValue = e.target.value;
        const hasDecimal = inputValue.includes('.');
      
        // Si ya existe un punto decimal, solo permitir dos dígitos después de él
        if (hasDecimal && inputValue.split('.')[1].length >= 2 && (e.key >= '0' && e.key <= '9')) {
          e.preventDefault(); // Evitar más de 2 dígitos después del punto decimal
          return;
        }
      
        if (
          (e.key >= '0' && e.key <= '9') || // Números
          allowedKeys.includes(e.key) // Otras teclas permitidas
        ) {
          return true;
        } else {
          e.preventDefault(); // Evitar cualquier otra tecla
        }
      };

    const { facturacion_edit } = useSelector(state => state.facturacion);
    const { status_facturacion, isLoading } = useSelector(state => state.facturacion);
    const dispatch = useDispatch();
    const closeModal = useRef(null);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            rfcFacturacion: "",
            rSocialFacturacion: "",
            rSociedadFacturacion: "",
            regimenFacturacion: "",
            telefonoFacturacion: "",
            emailFacturacion: "",
            direccionFacturacion: "",
            cpFacturacion: ""
        }
    });

    const clearForm = () => {
        reset();
    }

    const handledSaveFacturacion = (data) => {
        // console.log(data);
        dispatch(startEditFacturacion(data, facturacion_edit.ID, facturacion_edit.CLIENTE_ID));
        clearForm();
    }

    useEffect(() => {
        if (status_facturacion == 'FACTURACION_ENCONTRADO') {
            setValue('rfcFacturacion', facturacion_edit.RFC);
            setValue('rSocialFacturacion', facturacion_edit.RAZON_SOCIAL);
            setValue('rSociedadFacturacion', facturacion_edit.SOCIEDAD_MERCANTIL);
            setValue('regimenFacturacion', facturacion_edit.REGIMEN_FISCAL);
            setValue('telefonoFacturacion', facturacion_edit.TELEFONO);
            setValue('emailFacturacion', facturacion_edit.CORREO);
            setValue('direccionFacturacion', facturacion_edit.DIRECCION);
            setValue('cpFacturacion', facturacion_edit.COD_POSTAL);
        }
        if (status_facturacion == 'FACTURACION_CARGADOS' || status_facturacion == 'DATA_FACTURACION_CARGADOS') {
            reset();
            closeModal.current.click();
        }
    }, [status_facturacion])


    return (
        <div className="modal fade" id="EditFacturacion" data-bs-backdrop="static" data-bs-keyboard="false"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Editar Datos de facturación: </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clearForm}></button>
                    </div>
                    <form onSubmit={handleSubmit(handledSaveFacturacion)}>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                                    <div className="mb-3">
                                        <label className="form-label">RFC:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("rfcFacturacion", {
                                            required: 'RFC requerido.',
                                            minLength: { value: 12, message: 'RFC minimo 12 caracteres, sin guiones ni espacios' },
                                            maxLength: { value: 13, message: 'RFC maximo 13 caracteres, sin guiones ni espacios' }
                                        })} />
                                        {
                                            errors.rfcFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rfcFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                    <div className="mb-3">
                                        <label className="form-label">Razon Social:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("rSocialFacturacion", { required: 'Razon Social es requerido.' })} />
                                        {
                                            errors.rSocialFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rSocialFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>

                                </div>

                                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5">
                                    <div className="mb-3">
                                        <label className="form-label">Sociedad Mercantil:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("rSociedadFacturacion", { required: 'Sociedad Mercantil es requerido.' })} />
                                        {
                                            errors.rSociedadFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.rSociedadFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                                    <div className="mb-3">
                                        <label className="form-label">Regimen Fiscal:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' {...register("regimenFacturacion", { required: 'Regimen Fiscal es requerido.' })} />
                                        {
                                            errors.regimenFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.regimenFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                    <div className="mb-3">
                                        <label className="form-label">Correo electronico:</label>
                                        <input type="email" className="form-control" aria-label="First name" autoComplete='off' {...register("emailFacturacion", {
                                            required: 'Correo Electronico es requerido.',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Introduce una direccion de correo',
                                            },
                                        })} />
                                        {
                                            errors.emailFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.emailFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-3">
                                        <label className="form-label">Telefono:</label>
                                        <input type="number" className="form-control" aria-label="First name" onKeyDown={solo_num} {...register("telefonoFacturacion", {
                                            required: 'Telefono es requerido.',
                                            minLength: { value: 10, message: 'Debe tener10 digitos, sin guiones ni espacios' },
                                            maxLength: { value: 10, message: 'Debe tener10 digitos, sin guiones ni espacios' }
                                        })} />
                                        {
                                            errors.telefonoFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.telefonoFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                    <label className="form-label ">Dirección:</label>
                                    <textarea className="form-control mb-2" rows="4" autoComplete='off' {...register("direccionFacturacion", { required: 'Direccion es requerido.' })}></textarea>
                                    {
                                        errors.direccionFacturacion &&
                                        <div className='text-left-5 mt-2'>
                                            <AlertTriangle size="18" color="#dc3545" />
                                            <span className='text-left-10 fw-bold text-danger'><small>{errors.direccionFacturacion.message}</small></span>
                                        </div>
                                    }

                                </div>

                                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <div className="mb-3">
                                        <label className="form-label">CP:</label>
                                        <input type="text" className="form-control" aria-label="First name" autoComplete='off' onKeyDown={solo_num} {...register("cpFacturacion", { required: 'CP es requerido.' })} />
                                        {
                                            errors.cpFacturacion &&
                                            <div className='text-left-5 mt-2'>
                                                <AlertTriangle size="18" color="#dc3545" />
                                                <span className='text-left-10 fw-bold text-danger'><small>{errors.cpFacturacion.message}</small></span>
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" ref={closeModal} hidden className="btn btn-secondary" data-bs-dismiss="modal" >CERRAR</button>
                            <button type="button" className="btn btn-outline-danger" data-bs-dismiss="modal" onClick={clearForm}>
                                <Slash size='20' /> Cancelar
                            </button>
                            <button type="submit" className="btn btn-outline-primary" >
                                <Save size='20' /> Guardar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
