// import logo from './logo.svg';
// import './App.css';

import { Layout } from "./layout/Layout";
import { AppRouter } from "./router/AppRouter";

function App() {
  return (

    <AppRouter />
  );
}

export default App;
