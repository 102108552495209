import { URL_API } from "../../apiConnection";
import Swal from 'sweetalert2';
import { close_login, processAction, setDataUsuario, set_error } from "./slice";
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});



export const starLogin = (data) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        if (!data.text_user.includes('@')) {
            await URL_API.post(`Usuarios/Login`, {
                usuario: data.text_user,
                correo: '',
                pwd: data.text_pwd

            }).then(function (response) {
                if (response.data.status = 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Bienvenido...'
                    })
                    // console.log();
                    let data = response.data.data;
                    // console.log(data);
                    // dispatch(loadDataUser(id_emp));
                    dispatch(setDataUsuario({
                        usuario: data.USER,
                        nombre: data.NOMBRE,
                        id_emp: data.EMP_ID,
                        id_acceso: data.ACC_ID,
                        puesto_id: data.PUESTO_ID,
                        email: data.EMAIL,
                        rol: data.ROL,
                        puesto: data.PUESTO,
                        avatar: data.AVATAR,
                        celular: data.CELULAR,
                        telefono: data.TELEFONO,
                    }));

                    localStorage.setItem('user_logged', JSON.stringify(data));
                }
            }).catch(function (error) {
                console.log(error);
                dispatch(set_error({ msg: 'Datos de acceso incorrectos... Favor de Verificarlos ' }))

                return;
            });
        } else {
            await URL_API.post(`Usuarios/Login`, {
                usuario: '',
                correo: data.text_user,
                pwd: data.text_pwd

            }).then(function (response) {
                if (response.data.status = 200) {
                    Toast.fire({
                        icon: 'success',
                        title: 'Bienvenido...'
                    })
                    // console.log(response.data);
                    let data = response.data.data;
                    // dispatch(loadDataUser(id_emp));
                    dispatch(setDataUsuario({
                        usuario: data.USER,
                        nombre: data.NOMBRE,
                        id_emp: data.EMP_ID,
                        id_acceso: data.ACC_ID,
                        puesto_id: data.PUESTO_ID,
                        email: data.EMAIL,
                        rol: data.ROL,
                        puesto: data.PUESTO,
                        avatar: data.AVATAR,
                        celular: data.CELULAR,
                        telefono: data.TELEFONO,
                    }))
                    localStorage.setItem('user_logged', JSON.stringify(data));
                }
            }).catch(function (error) {
                console.log(error);
                dispatch(set_error({ msg: 'Datos de acceso incorrectos... Favor de Verificarlos ' }))

                return;
            });
        }


    }
}

export const startChangeDataUser = (data, id_acceso, id_emp) => {
    return async (dispatch, getState) => {
        dispatch(processAction());

        let letter = data.nombreEmpleado.charAt(0).toUpperCase();
        let avatarNuevo = letter + '.png';
        let empleado = data.nombreEmpleado; let email = data.emailEmpleado; let celular = data.celularEmpleado;
        let telefono = data.telefonoEmpleado;

        await URL_API.put(`Usuarios/putProfile/${id_emp}`, {
            nombre: empleado,
            email: email,
            telefono: telefono,
            celular: celular,
            acceso_id: id_acceso,
            avatar: avatarNuevo

        }).then(function (response) {
            if (response.data.status = 200) {
                Toast.fire({
                    icon: 'success',
                    title: 'Su información fue Actualizada con exito'
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Sesion cerrada...'
                })
                localStorage.clear();
                dispatch(close_login());
            }
            else{
                Toast.fire({
                    icon: 'error',
                    title: 'Su información no fue actualizada'
                })
                window.location.reload();
            }

        }).catch(function (error) {
            // console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo su información... Intente de Nuevo'
            })
            dispatch(close_login());
            window.location.reload();
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }

}

export const loadDataUser = (id) => {
    return async (dispatch, getSate) => {

        dispatch(processAction());
        await URL_API.get(`Usuarios/getProfile/${id}`).then(function (response) {
            console.log(response.data.data);
            const item = response.data.data;
            if (response.data.status == 200) {

                Toast.fire({
                    icon: 'success',
                    title: 'Informacion Actualizada...'
                })

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

}

export const startChangePWD = (data, id_acceso) => {
    return async (dispatch, getState) => {
        dispatch(processAction());
        await URL_API.put(`Usuarios/putPWD/${id_acceso}`, {
            pwd: data.confirmPWD,
            pwd_old: data.actualPWD
        }).then(function (response) {
            if (response.data.status = 200) {
                console.log(response);
                Toast.fire({
                    icon: 'success',
                    title: 'Su contraseña fue actualizada....'
                })

                Toast.fire({
                    icon: 'success',
                    title: 'Sesion cerrada...'
                })
                dispatch(close_login());
                localStorage.clear();

                // dispatch(loadDataUser(id_emp));
            }
        }).catch(function (error) {
            console.log(error)
            Toast.fire({
                icon: 'error',
                title: 'No se actualizo su Contraseña... Intente de Nuevo'
            })
            dispatch(close_login());
            localStorage.clear();
            // dispatch(loadDataUser(id_emp));
            return;
        });

    }

}
