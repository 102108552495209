import React from 'react'
import { SendEmail } from './SendEmail'
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { AlertTriangle } from 'react-feather';
import { starLogin } from '../../app/store/usuarios/thunks';
import { useEffect } from 'react';

export const Login = () => {


    const { status, msg, isLoading } = useSelector(state => state.usuario);
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm({ model: 'all' });
    const dispatch = useDispatch();


    const launchLogin = (data) => {
        // console.log(data);
        dispatch(starLogin(data))
    }

    useEffect(() => {
        let string = '';
        if (msg != '') {
            string += `<div class="alert alert-warning" role="alert"> ${msg}</div>`;
            document.getElementById('alert_credencial').innerHTML = string;
        }
    }, [status]);


    return (
        <>
            <main className="d-flex w-0">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-4">
                                            <div className="text-center">
                                                <img src="./assets/images/logo.png" width="40%" />
                                                <h4 className="fw-bold mt-4">Bienvenido de nuevo...</h4>
                                                <p>Utiliza tu nombre de usuario o correo electrónico, para acceder..</p>
                                            </div>
                                            <form onSubmit={handleSubmit(launchLogin)}>

                                                <div className="p-3">
                                                    <div id='alert_credencial'></div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Usuario:</label>
                                                        <input className="form-control form-control-lg" type="text"
                                                            autoComplete='off'  {...register("text_user", { required: 'El nombre de Usuario o Email es requerido' })} />
                                                        {
                                                            errors.text_user &&
                                                            <div className='mb-3 mt-2'>
                                                                <AlertTriangle size="20" color='#dc3545' />
                                                                {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                                <span className='fw-bold text-danger ms-1'><small>{errors.text_user.message}</small></span>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="form-label">Contraseña:</label>
                                                        <input className="form-control form-control-lg" type="password"
                                                            autoComplete='off' {...register("text_pwd", { required: 'La Contraseña requerida' })} />
                                                        {
                                                            errors.text_pwd &&
                                                            <div className='mb-3 mt-2'>
                                                                <AlertTriangle size="20" color='#dc3545' />
                                                                {/* <FontAwesomeIcon icon={faTriangleExclamation} className="fa text-danger" /> */}
                                                                <span className='fw-bold text-danger ms-1'><small>{errors.text_pwd.message}</small></span>
                                                            </div>
                                                        }

                                                        <a className="btn btn-link" data-bs-toggle="modal"
                                                            data-bs-target="#modalRecoverypwd">¿Olvidaste tu Contraseña?</a>
                                                    </div>
                                                    <div className="text-end">
                                                        <button type='submit' className="btn btn-lg btn-outline-primary">Acceder</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <!-- Section Modals --> */}
            <SendEmail />
        </>
    )

}
