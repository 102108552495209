import React from 'react'

export const ViewInspeccionCC = () => {
    return (
        <div className="modal fade" id="viewInspeccionCC" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Resultados Control de Calidad</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">TÓPICO</th>
                                    <th scope="col" className='text-center'>ESTADO</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Enciende el equipo (Funciona).</td>
                                    <td className='text-center'><span className="status-btn complete-btn text-uppercase">SOLICITADA</span></td>
                                </tr>
                                <tr>
                                    <td>Tengo el Cargador y Batería correspondiente al equipo.</td>
                                    <td className='text-center'><span className="status-btn complete-btn text-uppercase">SOLICITADA</span></td>
                                </tr>
                                <tr>
                                    <td>El equipo emite sonido (Revision de audio).</td>
                                    <td className='text-center'><span className="status-btn complete-btn text-uppercase">SOLICITADA</span></td>
                                </tr>
                                <tr>
                                    <td>El equipo se conecta a Internet (Conexión Red).</td>
                                    <td className='text-center'>
                                        <span className="status-btn complete-btn text-uppercase">SOLICITADA</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>El equipo esta ensamblado por completo (Tornilleria).</td>
                                    <td className='text-center'>
                                        <span className="status-btn complete-btn text-uppercase">SOLICITADA</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>El teclado del equipo funciona correctamente</td>
                                    <td className='text-center'>
                                        <span className="status-btn complete-btn text-uppercase">SOLICITADA</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>El equipo esta limpio.</td>
                                    <td className='text-center'>
                                        <span className="status-btn complete-btn text-uppercase">SOLICITADA</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Se regresan al cliente todos los accesorios del equipo (Cargador/Batería/HD/SSD/etc).</td>
                                    <td className='text-center'>
                                        <span className="status-btn complete-btn text-uppercase">SOLICITADA</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Recibo el Checklist del Técnico.</td>
                                    <td className='text-center'>
                                        <span className="status-btn complete-btn text-uppercase">SOLICITADA</span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">CERRAR</button>

                    </div>
                </div>
            </div>
        </div>
    )
}
