import React, { useEffect } from 'react'
import { SumaryOrders } from './panels/SumaryOrders'
import { Accounts } from './panels/Accounts'
import { useDispatch } from 'react-redux';
import { startloadAccounts } from '../../../app/store/reportes/thunks';
import { MDetail } from './modals/MDetail';


export const Report = () => {
  //   
  const dispatch = useDispatch();
  const loadFacturas = () => {
    dispatch(startloadAccounts())
  }

  return (
    <>
      {/* <SumaryOrders/> */}

      <MDetail />
      <div className="mb-3">
        <h1 className="h4 d-inline align-middle fw-bold">Reportes</h1>
      </div>
      <div className="card">

        <div className="card-body">

          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Ordenes Servicio / Ventas</button>
            </li>
            <li className="nav-item" role="presentation">
              <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false" onClick={loadFacturas}>Cuentas por Cobrar</button>
            </li>

          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" >
              <SumaryOrders />
            </div>
            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" >
              <Accounts />
            </div>

          </div>

        </div>
      </div>




    </>
  )
}
