import React from 'react';
import { ArrowLeft, Eye, Save, Trash2 } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clearStoreEditSolicitud } from '../../../../app/store/catalagos/slice';
import Swal from 'sweetalert2';
import { loadSolicitudDetail, startChangeStateSolicitud, startUpdateExistenciasProducto, startloadSolicitudProductos } from '../../../../app/store/catalagos/thunks';
import { useState } from 'react';
import { connectionFiles } from '../../../../app/filesConnection'

export const EditSolicitud = () => {
    const dispatch = useDispatch();
    const { isLoading, solicitudesProddetail, status } = useSelector(state => state.catalogos);
    const { usuario, nombre, rol, puesto, avatar } = useSelector(state => state.usuario);

    let detail = solicitudesProddetail[0]; let Productos = solicitudesProddetail[0].PRODUCTOS;
    // const [Productos, setProductos] = useState([]);

    const [ExistenciaProd, setExistenciaProd] = useState(solicitudesProddetail[0].PRODUCTOS);

    // console.log(ExistenciaProd);

    const goBack = () => {
        dispatch(clearStoreEditSolicitud());
        dispatch(startloadSolicitudProductos());

    }

    const changeValue = (e, i) => {
        // console.log(i);
        let add = Number(e.target.value);
        console.log(add);
        let nuewArray = ExistenciaProd.map(item => {
            if (item.PRODUCTO_ID === i) {
                return { ...item, REGISTRO: add };
            }
            return item;
        });

        // console.log(nuewArray);
        setExistenciaProd(nuewArray);
    }

    const handleChange = (e, i) => {
        let estado = e.target.value;
        Swal.fire({
            title: 'Atención',
            text: "Está por cambiar de Estado de la Solicitud de Productos, ¿Desea de continuar con esta acción?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3b7ddd',
            cancelButtonColor: '#dc3545',
            cancelButtonText: 'No',
            confirmButtonText: 'Si'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(startChangeStateSolicitud(i, estado, usuario));
            } else {
                dispatch(loadSolicitudDetail(detail.ID));
            }
        })

    }

    const handleSaveSolicitud = () => {


        let filesA = document.getElementById("fileUpo").files[0];
        let exist = 0;
        ExistenciaProd.forEach(item => {
            if (item.REGISTRO != 0) exist = 1;
        });

        console.log();


        if (exist == 0) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Debe agregar ingresar la cantidad de productos recibida, verifique la tabla',
            });

        } else
            if (filesA == undefined) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Debe agregar la evidencia de la compra de los productos... Adjunte fotografia/archivo/etc',
                });



            } else {

                console.log(ExistenciaProd);
                Swal.fire({
                    title: 'Atención',
                    text: "Está por REGISTRAR NUEVAS EXISTENCIAS a Productos, ¿Desea de continuar con esta acción?",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3b7ddd',
                    cancelButtonColor: '#dc3545',
                    cancelButtonText: 'No',
                    confirmButtonText: 'Si'
                }).then((result) => {
                    if (result.isConfirmed) {

                        dispatch(startUpdateExistenciasProducto(detail.ID, ExistenciaProd, usuario,filesA));
                    }
                })

            }


    }

    return (
        <>
            <div className='row'>
                <div className='col-12'>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <Link className='breadcrumb-item btn-link' onClick={goBack}>
                                <ArrowLeft size='20' /> Atras
                            </Link>
                            <li className="breadcrumb-item active" aria-current="page">Detalle Solicitud de Producto</li>
                        </ol>
                    </nav>
                </div>
            </div>

            <div className='row'>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-3">

                    <div className="card ">

                        <div className="card-body">

                            <div className="row mb-1">
                                <div className="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8">
                                    <h5 className="fw-bold me-2 border-0">Datos de la Solicitud: {detail.ID} </h5>
                                </div>

                            </div>
                            <hr className="my-0" />

                            <div className="row">
                                <div className="col-12 mt-3">
                                    <div className="mb-3">
                                        <label className="form-label">Capturo:</label>
                                        <input type="text" className="form-control" defaultValue={detail.CAPTURO} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Fecha:</label>
                                        <input type="text" className="form-control" defaultValue={detail.FECHA} disabled />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="mb-3">
                                        <label className="form-label">Hora:</label>
                                        <input type="text" className="form-control" defaultValue={detail.HORA} disabled />
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='mb-3'>
                                        <label className="form-label">Evidencia:</label>
                                        {detail.EVIDENCIA_CIERRE == null ? <><input className="form-control" type="file" id='fileUpo' name='fileUpo' /></> :
                                            <><br/>
                                                {/* <button type="button" className="btn btn-dark w-50"><Eye size='20' /> Ver Evidencia</button> */}

                                                <a type="button" href={`${connectionFiles['imgsEvidenciasProd']}/${detail.EVIDENCIA_CIERRE}`} target='_blank' className="btn btn-dark border-0">  <Eye size="20" /> Ver Evidencia</a>
                                            </>}

                                    </div>
                                </div>


                                <div className="col-12">
                                    <div className="mb-3">
                                        <label className="form-label">Estado solicitud:</label>
                                        <select className="form-select " defaultValue={detail.ESTADO} onChange={e => handleChange(e, detail.ID)} disabled={detail.ESTADO == 3 || detail.ESTADO == 4}    >
                                            <option value={1}>ESPERA</option>
                                            <option value={2}>APROBADO</option>
                                            <option value={3}>TERMINADO</option>
                                            <option value={4}>CANCELADO</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-9'>
                    <div className="card">
                        <div className="card-header border-bottom">
                            <h5 className="card-title text-dark mb-2">Listado de Productos Solicitados</h5>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-scrollbar-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">SKU</th>
                                            <th scope="col">COD. BARRA</th>
                                            <th scope="col">NOMBRE</th>
                                            <th scope="col">MODELO</th>
                                            <th scope="col">SOLICITADO</th>
                                            {detail.ESTADO == 2 ? <><th scope="col" className='text-center'>RECIBIDO</th></> : <><th scope="col" className='text-center'>REGISTRADO</th></>}
                                            {/*  */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {

                                            ///NUEVO ARREGLO PARA ESTO Y PETICION PARA GUARDAR
                                            Productos.length === 0 ?
                                                <>
                                                    <tr>
                                                        <td scope="row" colSpan={5}> No se han agregado productos.</td>
                                                    </tr>
                                                </> :
                                                Productos.map((item, index) => (
                                                    <tr key={index} >
                                                        <td scope="row">{item.PRODUCTO_ID}</td>
                                                        <td scope="row">{item.COD_BARRA}</td>
                                                        <td className="text-start">{item.NOMBRE}</td>
                                                        <td className="text-start">{item.MODELO}</td>
                                                        <td className="text-center"> {item.SOLICITAR}</td>
                                                        <td className="text-center">
                                                            {detail.ESTADO == 2 ? <><input type="number" className="form-control" id="" placeholder="" min={0} onChange={e => changeValue(e, item.PRODUCTO_ID)} /></> : <>{item.REGISTRO}</>}
                                                            {/* {item.ESTADO == 4 ? <><input type="number" className="form-control" id="" placeholder="" min={0} /> </> : <> </>} */}
                                                        </td>
                                                        {/* <td className="text-center">
                                                         
                                                        </td> */}
                                                    </tr>
                                                ))
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className='mb-2 mt-3 text-end'>
                                {/* <button type="button" className="btn btn-outline-danger me-2" onClick={e => handleRemoveAllProductos(e)}>Eliminar listado ({Productos.length})</button>
                                <button type="button" className="btn btn-outline-dark me-2" onClick={() => cancel()}> <Slash size='20' /> Cancelar</button> */}
                                {detail.ESTADO == 2 ? <button type="button" className="btn btn-outline-primary" onClick={() => handleSaveSolicitud()}> <Save size='20' /> Guardar</button> : <></>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
